import { Component, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PDLabeledControlComponent, FormHandlerService} from '@otris/ng-core';
import { ComponentTypeET, AbstractEnumeration,IEnumerationItem, IDropDownComponent, TypeET } from '@otris/ng-core-shared';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Observable } from 'rxjs';

/**
 * Bei einem string array gleicht ergName dem value
 */
interface IChoiceData {
	ergName: string;
	value: IEnumerationItem | string;
}

/**
 * [formControl]="formGroup.controls[propertyName]"
 */
@Component({
	selector: 'otris-pd-drop-down',
	template: `
		<otris-pd-labeled-control-frame [labeledControl]="this" (toolBarButtonClick)="onToolBarButtonClick($event)" [pdObject]="pdObject" [relatedFormControl]="this.control">
			<ng-container *ngIf="!isReadonly; else readonly">
				<!-- https://www.telerik.com/kendo-angular-ui/components/dropdowns/dropdownlist/ -->
				<!-- kendo-dropdownlist: [data]: Array<string> oder Array<{string, T}> [valueField]=T(von data) [data]=string(von Data) -->
				<!-- [valueField] scheinbar ohne Funktion bei dieser Art von Data-Binding -->
				<!-- [disabled]="!isEnabled" ist nötig da kein direktes Databinding mit formctrl besteht  -->
				<kendo-dropdownlist #dropDownList class="dropdown" [data]="choice" [textField]="'ergName'" [valueField]="'value'" [valuePrimitive]="false"
					[value]="selectedValue" (valueChange)="onValueChange($event)" [disabled]="!isEnabled"
					(selectionChange)="onSelectionChange($event)">
				</kendo-dropdownlist>
			</ng-container>
		</otris-pd-labeled-control-frame>

		<ng-template #readonly>
			<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
		</ng-template>
	`,
	styles: [`
		.dropdown {
			flex: 1 1 auto;
		}
	`]
})
export class PDDropDownComponent extends PDLabeledControlComponent implements IDropDownComponent {
	// choice: IEnumerationItem[];
	choice: IChoiceData[];
	private _customChoiceProvider: () => Observable<string[]>;

	@ViewChild('dropDownList', { static: true }) kendoDropDownList: DropDownListComponent;

	selectedValue: IChoiceData;

		//private _selectedValue: string | IEnumerationItem; 
	private _selectedItem: string | IEnumerationItem; 

	get selectedItem(): string | IEnumerationItem | undefined {
		return this._selectedItem;
	}

	@Input()
	set selectedItem(item: string | IEnumerationItem | undefined) {
		if (this._selectedItem != item) {
			this._selectedItem = item;
			let ctrl = this.control;
			if (ctrl) {
				ctrl.setValue(item);
				ctrl.markAsDirty();
			}
			this.updateSelectedValue();
		}
	}
	
	set customChoiceProvider(cb: () => Observable<string[]>) {
		this._customChoiceProvider = cb;
		this.updateChoice();
	}

	private get abstractEnumeration(): AbstractEnumeration {
		if (!this.pdObject || !this.propertyName) {
			return undefined;
		}

		let enumPropName = this.propertyName + "Object";
		if (!(this.pdObject.pdObjectRaw[enumPropName] instanceof AbstractEnumeration)) {
			throw Error('Property is not of type AbstractEnumeration');
		}
		return <AbstractEnumeration>this.pdObject.pdObjectRaw[enumPropName];
	}

	private get isEnumChoice(): boolean {
		if (this.pdObject && this.propertyName) {
			return this.pdObject.metaData.getPropertyType(this.propertyName) === TypeET.Enum;
		}
		return false;
	}

	private get isStringChoice(): boolean {
		if (this.pdObject && this.propertyName) {
			return this.pdObject.metaData.getPropertyType(this.propertyName) === TypeET.String;
		}
		return false;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, private cdref: ChangeDetectorRef) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();
		if (!this.isEnumChoice && !this.isStringChoice) {
			throw Error('Property is not a type of String or Enum');
		}
		if (this.isEnumChoice) {
			let enumPropName = this.propertyName + "Object";
			if (!(this.pdObject.pdObjectRaw[enumPropName] instanceof AbstractEnumeration)) {
				throw Error('Property is not a type of AbstractEnumeration');
			}
		}
		this.updateChoice();


		/*if (this.pdObject.metaData.getPropertyType(this.propertyName) === TypeET.Enum) {
			let enumPropName = this.propertyName + "Object";
			if (!(this.pdObject.pdObjectRaw[enumPropName] instanceof AbstractEnumeration)) {
				throw Error('Property is not a type of AbstractEnumeration');
			}
			this.updateChoice();
		} else if (this.pdObject.metaData.getPropertyType(this.propertyName) === TypeET.String) {
			// if (!(this.pdObject.pdObjectRaw[this.propertyName] instanceof String)) {
			// 	throw Error('Property is not a type of String');
			// }
			this.updateChoice();
		}*/
		//TODO: fehler
		//this.choice = (<AbstractEnumeration>this.pdObject[enumPropName]).choice.map(item => <IChoiceData>{ ergName: item.ergName, value: item });
		
		// let e = this.abstractEnumeration;
		// this.choice = e.choice;
	}

	protected onLanguageChanged() {
		super.onLanguageChanged();

		//this.updateChoice();
		if (this.isEnumChoice) {
			this.abstractEnumeration.updateItemErgNames().subscribe(
				() => {
					this.updateChoice();
					let ctrl = this.control;
					let tmp = ctrl.value;
					ctrl.setValue(undefined);
					this.cdref.detectChanges();
					ctrl.setValue(tmp);
					this.cdref.detectChanges();
				}
			);
			return;
		}
		this.updateChoice();
	}

	protected onPDObjectChanged() {
		super.onPDObjectChanged();
		this.updateSelectedValue();
	}

	onValueChange(val: IChoiceData): void {
		this.selectedValue = val;
		this.selectedItem = val ? val.value : undefined;
	}

	// Todo: Mit Telerik klären was genau der Unterschied zw. onValueChange() und onSelectionChange() ist!
	onSelectionChange(value: any): void {
		//console.log(`onSelectionChange: ${value}`);
	}

	updateChoice() {
		if (this.isEnumChoice) {
			if (this.abstractEnumeration != undefined) {
				this.choice = [];
				this.abstractEnumeration.choice.forEach(obj => {
					this.choice.push(<IChoiceData>{ergName: obj.ergName, value: obj});
					//TODO: Behandelt nicht den customChoiceProvider
				});
				this.updateSelectedValue();
			}
		} else if (this.isStringChoice) {
			//TODO: Behandelt nur den _customChoiceProvider
			if (this._customChoiceProvider) {
				this._customChoiceProvider().subscribe(str => {
					if (str) {
						this.choice = [];
						str.forEach(s => {
							this.choice.push(<IChoiceData>{ergName: s, value: s});
						});
						this.updateSelectedValue();
					}
				})
			}
		}
	}

	private updateSelectedValue(): void {
		let ctrl = this.control;
		if (!ctrl) {
			return;
		}

		let newVal: IChoiceData;
		if (ctrl.value && this.choice) {
			if (this.isEnumChoice) {
				newVal = this.choice.find(c => (<IEnumerationItem>c.value).enumConst === (<IEnumerationItem>ctrl.value).enumConst);
			}
			else if (this.isStringChoice) {
				newVal = this.choice.find(c => c.value === ctrl.value);
			}
		}
		if (this.selectedValue != newVal) {
			this.selectedValue = newVal;
		}
	}

	reset(): void {
		this._selectedItem = undefined;
		this.selectedValue = undefined;
		super.reset();
		//this.updateSelectedValue();
	}

	get componentType(): ComponentTypeET {
		return ComponentTypeET.DropDownList;
	}
}
