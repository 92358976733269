import { Injectable, Inject, ErrorHandler } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
// import { Observable } from 'rxjs-compat';
import { bindCallback, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IPDObjectId, IAuthService } from '@otris/ng-core-shared';

import {
	ServiceLocator, IPDAccessService, IGetExtentByPageResult, ICallOperationResult, PDObject as CorePDObject, DefaultPDObjectMeta,
	IErrorHandler, IErrorHandlerToken, IError, IPDObjectRaw
} from '@otris/ng-core-shared';

import { JafWebPDObject } from '../model/jaf-web-pd-object';
import { EMPTY } from 'rxjs';

declare var JafWeb: any;
declare var JParamPacker, JResponse, PDClass, PDObject, ClientInfo: any;

class CustomHttpParamEncoder extends HttpUrlEncodingCodec {
	encodeKey(value: string): string {
		return encodeURIComponent(value);
	}
	encodeValue(value: string): string {
		return encodeURIComponent(value);
	}
}

JafWeb.isDocumentReady = function () { }

JafWeb.ajaxRequest = function (cfg) {
	console.log(`JafWeb.ajaxRequest(callerName: ${cfg.callerName})`);

	JafWebService.doHttpRequest(cfg);

	/*console.log(`cfg: ${cfg}`);
	PDAccessService.doHttpGet(cfg).subscribe(res => {
		if (cfg.success) {
			cfg.success({responseText: res});
		}
	});*/
}

function isMultiLangWrapper(url: string, params: any, callback: ((res: boolean) => void)) {

	JafWeb.ajaxRequest({
		url: url,
		method: 'GET',
		asynchronous: true,
		encoding: 'UTF-8',
		params: params,
		disableCaching: true,
		success: (req, options) => {
			var resp = new JResponse(req, options);
			let res = resp.getBool('multilang', false);
			return callback(res);
		},
		failure: () => {
			// todo
		}
	});
}

function getExtentWrapper(pdclass: string | number, filter: string, sort: string, attrs: string[] | undefined, observableCallback: ((res: any) => void)) {

	PDClass.getExtent(pdclass, filter, sort, null, 0, 0, (result) => {
		observableCallback(result);
	}, false, attrs ? attrs : null);
}

function getExtentByPageWrapper(pdclass: string | number, pageIndex: number, pageSize: number, filter: string, sort: string,
	attrs: string[] | undefined, observableCallback: ((res: any) => void)) {
	PDClass.getExtent(pdclass, filter, sort, null, pageSize, pageIndex, (result) => {
		observableCallback(result);
	}, false, attrs ? attrs : null);
}

function disconnectClientWrapper(observableCallback: ((res: boolean) => void)) {
	ClientInfo.disconnectClient(result => observableCallback(result));
}

function changeUserWrapper(login: string, pwd: string, observableCallback: ({ msg: string, errorCode: number }) => void) {
	PDClass.changeUser(login, pwd, undefined, undefined, undefined, result => observableCallback({ msg: result.msg, errorCode: result.errCode ? result.errCode : 0 }));
}

function callOperationWrapper<T extends CorePDObject>(opName: string, inParams: string | string[], inObjs: IPDObjectId | IPDObjectId[] | undefined, pdObjectCreator: (rawObj: IPDObjectRaw) => T,
	observableCallback: (result: ICallOperationResult<T>) => void) {

	let strs;
	if (Array.isArray(inParams)) {
		strs = inParams;
	}
	else if (inParams != null && inParams!= undefined) {
		strs = [inParams];
	}
	else {
		strs = [];
	}
	
	let oids;
	if (Array.isArray(inObjs)) {
		oids = inObjs.map(oid => oid.oid)
	}
	else if (inObjs) {
		oids = [inObjs.oid];
	}
	else {
		oids = [];
	}

	PDClass.callOperation(opName, true, strs, oids,
		(outStrs: string | string[], outObjs: any, res: number) => {
			let outObjWrapper = [];
			if (Array.isArray(outObjs)) {
				//outObjWrapper = outObjs.map(obj => obj ? new JafWebPDObject(obj) : undefined );
				outObjWrapper = outObjs.map(obj => obj ? pdObjectCreator(obj) : undefined );
			}
			else if (outObjs) {
				outObjWrapper = [outObjs ? pdObjectCreator(outObjs) : undefined];
			}
			observableCallback(<ICallOperationResult<T>>{ result: res, outStrs: outStrs, outObjs: outObjWrapper })
		});
}

function callObjectOperationWrapper(objWrapper: CorePDObject, opName: string, inParams: string | string[], inObjs: IPDObjectId | IPDObjectId[] | undefined,
	observableCallback: (result: ICallOperationResult<CorePDObject>) => void) {

	let strs;
	if (Array.isArray(inParams)) {
		strs = inParams;
	}
	else if (inParams != null && inParams!= undefined) {
		strs = [inParams];
	}
	else {
		strs = [];
	}

	let oids;
	if (Array.isArray(inObjs)) {
		oids = inObjs.map(oid => oid.oid);
	}
	else if (inObjs) {
		oids = [inObjs.oid];
	}
	else {
		oids = [];
	}

	objWrapper.pdObjectRaw.callOperation(opName, true, strs, oids, [''],
		(outStrs: string | string[], outObjs: any, res: number) => {
			let outObjWrapper = [];
			if (Array.isArray(outObjs)) {
				outObjWrapper = outObjs.map(obj => obj ? new JafWebPDObject(obj) : undefined );
			}
			else if (outObjs) {
				outObjWrapper = [outObjs ? new JafWebPDObject(outObjs) : undefined];
			}
			observableCallback(<ICallOperationResult<CorePDObject>>{ result: res, outStrs: outStrs, outObjs: outObjWrapper })
		});
}

function getRelationObjectsWrapper(objWrapper: CorePDObject, roleName: string, filter: string, sort: string, attrs: string[] | undefined,
	observableCallback: ((res: any) => void)) {
		objWrapper.pdObjectRaw.getExtent(roleName, filter, sort, null, 0, 0,
			(result) => {
				observableCallback(result);
			},
			false, attrs ? attrs : null
		);
}

function getObjectWrapper(oid: string, attrs: string[] | undefined, observableCallback: ((res: any) => void)) {
	PDClass.ptr(oid, true, attrs ? attrs : true, false, (result) => {
		observableCallback(result);
	});
}

function getObjectsWrapper(oids: string[], attrs: string[] | undefined, observableCallback: ((res: any) => void)) {
	PDClass.ptrs(oids, attrs ? attrs : true, false, (result) => {
		observableCallback(result);
	});
}

@Injectable()
export class JafWebService implements IPDAccessService {

	// todos:
	// synchrone Aufrufe?
	// Content-Type immer text?
	static doHttpRequest(cfg) {
		let pars = (cfg.params || {});
		pars.fmt = 'json';

		let http = <HttpClient>ServiceLocator.injector.get(HttpClient);
		let headers = new HttpHeaders();

		switch (cfg.method) {
			case 'GET': {
				switch (cfg.dataType) {
					case 'text':
						headers = headers.set('Content-Type', 'text/plain');
						break;
					default:
						headers = headers.set('Content-Type', 'application/json');
						break;
					/*case 'html':
						headers = headers.set('Content-Type', 'text/plain');
						break;*/
				}

				// UB 05.11.2019: Bugfix IE
				headers = headers.set('Cache-Control', 'no-cache');
				headers = headers.set('Pragma', 'no-cache');
				headers = headers.set('Expires', '0');

				http.get(cfg.url, { params: pars, headers: headers }).subscribe(
					res => {
						if (cfg.success) {
							//cfg.success({responseText: res});
							cfg.success(res);
						}
					},
					err => {
						console.log(err);
						if (cfg.failure) {
							cfg.failure();
						}
					});
				break;
			}
				
			case 'POST': {
				headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
				let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder(), fromObject: pars });
				http.post(cfg.url, httpParams, { headers: headers }).subscribe(
					res => {
						if (cfg.success) {
							cfg.success(res);
						}
					},
					err => {
						console.log(err);
						if (cfg.failure) {
							cfg.failure();
						}
					});
	
					// todo
					break;
			}


		}
	}

	/*static doHttpGet(cfg): Observable<any> {
		let http = <HttpClient>ServiceLocator.injector.get(HttpClient);
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'text/plain');
		return http.get<boolean>(cfg.url, { params: cfg.params, headers: headers });
	}*/

	private get authService(): IAuthService {
		return <IAuthService>ServiceLocator.injector.get(IAuthService);
	}

	private get errorHandler(): IErrorHandler {
		return <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
	}

	constructor(/*@Inject(IErrorHandlerToken) private errorHandler: IErrorHandler*/) {
		JafWeb.loadPD({
			rootURL: 'janus',
			logoutEvent: 'logout',
			authToken: '123456789', // ???
			downloadURL: 'download',
			uploadURL: 'upload',
		});
	}

	// test
	isMultiLang(): Observable<boolean> {
		var pars = new JParamPacker('Multilang');
		var params = {
			JanusApplicationName: 'GenericJanusApplication',
			janusWebEvent: 'serverEvent',
			serverEvent: pars.getEventString(false)
		};
		console.log(params);

		/*let successFn = function(req, options) {
			var resp = new JResponse(req, options);
			let res = resp.getBool('multilang', false);
			console.log(res);
			callback(res)
		} */

		let f = bindCallback(isMultiLangWrapper);
		return f("janus", params);

		//doAjaxRequest("", () => {})

		/*var successFn = function(req, options)
		{
			var resp = new JResponse(req, options);
			//window._multilang = resp.getBool('multilang', false);
			// in window._multilang steht jetzt das Ergebnis
			console.log(resp.getBool('multilang', false));
		};*/

		/*JafWeb.ajaxRequest({
			//url: getRootUrl(), UIApplication
			url: '',
			method: 'GET',
			asynchronous: true,
			encoding: 'UTF-8',
			params: params,
			disableCaching: true,
			success: successFn,
			failure: () => {
				// todo
			}
		});*/
	}

	changeUser(login: string, pwd: string): Observable<{ msg: string, errorCode: number }> {
		console.log(`JafWebService.changeUser(login: ${login})`);

		let wrapperFunc = bindCallback(changeUserWrapper);
		return wrapperFunc(login, pwd);
	}

	disconnect(): Observable<boolean> {
		console.log(`JafWebService.disconnect()`);

		let wrapperFunc = bindCallback(disconnectClientWrapper);
		return wrapperFunc();
	}

	getExtent(pdclass: string | number, filter?: string, sort?: string, attrs?: string[]): Observable<CorePDObject[]> {
		console.log(`JafWebService.getExtent(pdClass: ${pdclass})`);

		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getExtent(${pdclass}): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(getExtentWrapper);
		let errorHandler = <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
		return wrapperFunc(pdclass, filter ? filter : "", sort ? sort : "", attrs).pipe(
			map(resObj => {
				if (!resObj) {
					errorHandler.handleError(<IError>{ details: 'Fatal error in JafWebService.getExtent()' });
					return [];
				}
				if (Array.isArray(resObj.rows)) {
					let result: CorePDObject[] = [];
					for (let obj of resObj.rows) {
						result.push(new JafWebPDObject(obj));
					}
					return result;
				}
				return [];
			}),
			catchError(err => {
				errorHandler.handleError(<IError>{ details: 'Fatal error in JafWebService.getExtent()' });
				return of([]);
			})
		);
	}

	getExtentByPage(pdclass: string | number, pageIndex: number, pageSize: number, ignoreErrors: boolean = false,
		filter?: string, sort?: string, attrs?: string[]): Observable<IGetExtentByPageResult> {
		console.log(`JafWebService.getExtentByPage(pdClass: ${pdclass})`);

		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getExtentByPage(${pdclass}): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(getExtentByPageWrapper);
		let errorHandler = <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
		return wrapperFunc(pdclass, pageIndex, pageSize, filter ? filter : "", sort ? sort : "", attrs).pipe(
			map(resObj => {
				if (!resObj) {
					if (ignoreErrors) {
						return <IGetExtentByPageResult>{ data: [], itemCount: 0 };
					}
					throw new Error('Fatal error in remote call for getExtentByPage().');
				}

				if (resObj.retCode !== 0) {
					if (!ignoreErrors) {
						throw new Error(`Error in remote call for getExtentByPage(). Error code: ${resObj.retCode}`);
					}
					return <IGetExtentByPageResult>{ data: [], itemCount: 0 };
				}

				let data: CorePDObject[] = [];
				if (Array.isArray(resObj.rows)) {
					for (let obj of resObj.rows) {
						data.push(new JafWebPDObject(obj)); // todo
					}
				}
				return <IGetExtentByPageResult>{ data: data, itemCount: resObj.total };
			}),
			catchError(err => {
				if (ignoreErrors) {
					return of(<IGetExtentByPageResult>{ data: [], itemCount: 0 });
				}
				errorHandler.handleError(<IError>{ details: 'Fatal error in JafWebService.getExtentByPage()' });
				return of(<IGetExtentByPageResult>{ data: [], itemCount: 0 });
			})
		);
	}

	/**
	 * Ruft eine Wrapper-Funktion auf. ?
	 * Bei einem Error wird -1 zurück übergeben 
	 * @param opName Name der Operation
	 * @param inParams Parameter für die Operation
	 * @param inObjs ?
	 */
	callOperation(opName: string, inParams: string | string[], inObjs: IPDObjectId | IPDObjectId[]): Observable<ICallOperationResult<CorePDObject>> {
		console.log(`JafWebService.callOperation(${opName})`);

		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.callOperation(${opName}): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(callOperationWrapper);
		return wrapperFunc(opName, inParams, inObjs, (rawObj) => <CorePDObject>new JafWebPDObject(rawObj)).pipe(
			catchError(err => {
				this.errorHandler.handleError(<IError>{ details: `Fatal error in JafWebService.callOperation(${opName})`});
				return of(<ICallOperationResult<CorePDObject>>{ result: -1, outStrs: undefined, outObjs: undefined });
			})
		)
	}

	callOperationTyped<T extends CorePDObject>(opName: string, inParams: string | string[], inObjs: IPDObjectId | IPDObjectId[], pdObjectCreator: (rawObj: IPDObjectRaw) => T): Observable<ICallOperationResult<T>> {
		console.log(`JafWebService.callOperationTyped(${opName})`);

		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.callOperation(${opName}): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(callOperationWrapper);
		return wrapperFunc(opName, inParams, inObjs, pdObjectCreator).pipe(
			catchError(err => {
				this.errorHandler.handleError(<IError>{ details: `Fatal error in JafWebService.callOperation(${opName})`});
				return of(<ICallOperationResult<T>>{ result: -1, outStrs: undefined, outObjs: undefined });
			})
		)
	}

	getRelationObjects(objWrapper: CorePDObject, relationName: string, filter?: string, sort?: string, attrs?: string[]): Observable<CorePDObject[]> {

		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getRelationObjects(${relationName}): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(getRelationObjectsWrapper);
		let errorHandler = <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
		return wrapperFunc(objWrapper, relationName, filter ? filter : "", sort ? sort : "", attrs).pipe(
			map(res => {
				if (!res || !res.rows) {
					errorHandler.handleError(<IError>{ details: 'Fatal error in JafWebService.getRelationObjects()' });
					return [];
				}
				if (Array.isArray(res.rows)) {
					let result: CorePDObject[] = [];
					for (let obj of res.rows) {
						result.push(new JafWebPDObject(obj));
					}
					return result;
				}
				return [];
			}),
			catchError(err => {
				errorHandler.handleError(<IError>{ details: 'Fatal error in JafWebService.getRelationObjects()' });
				return of([]);
			})
		)
	}

	callObjectOperation(objWrapper: CorePDObject, opName: string, inParams: string | string[], inObjs: IPDObjectId | IPDObjectId[]): Observable<ICallOperationResult<CorePDObject>> {
		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.callObjectOperation(${opName}): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(callObjectOperationWrapper);
		return wrapperFunc(objWrapper, opName, inParams, inObjs).pipe(
			catchError(err => {
				let errorHandler = <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
				errorHandler.handleError(<IError>{ details: `Fatal error in JafWebService.callObjectOperation(${opName})` });
				return of(<ICallOperationResult<CorePDObject>>{ result: -1, outStrs: undefined, outObjs: undefined });
			})
		)
	}

	getObject(id: string, attrs?: string[]): Observable<CorePDObject> {
		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getObject(): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(getObjectWrapper);
		let errorHandler = <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
		return wrapperFunc(id, attrs).pipe(
			map(res => {
				if (res) {
					return new JafWebPDObject(res);
				}
				return undefined;
			}),
			catchError(err => {
				errorHandler.handleError(<IError>{ details: 'Fatal error in JafWebService.getObject()' });
				return of(undefined);
			})
		)
	}

	getObjects(ids: string[], attrs?: string[]): Observable<CorePDObject[]> {
		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.getObjects(): Not logged in` });
			return EMPTY;
		}

		let wrapperFunc = bindCallback(getObjectsWrapper);
		let errorHandler = <IErrorHandler>ServiceLocator.injector.get(IErrorHandlerToken);
		return wrapperFunc(ids, attrs).pipe(
			map(res => {
				if (Array.isArray(res)) {
					return res.map(r => r ? new JafWebPDObject(r) : undefined);
				}
				return [];
			}),
			catchError(err => {
				errorHandler.handleError(<IError>{ details: 'Fatal error in JafWebService.getObjects()' });
				return of([]);
			})
		)
	}

	downloadObjectDocument(objWrapper: CorePDObject, attr: string): void {
		if (!this.authService.isLoggedIn) {
			this.errorHandler.handleError(<IError>{ details: `Error in JafWebService.downloadObjectDocument(): Not logged in` });
			return;
		}

		objWrapper.pdObjectRaw.downloadDocument(attr);
	}
}
