import { Inject, Injectable } from '@angular/core'
import { IError, IErrorHandler, IErrorHandlerToken, IPDAccessService, IPDAccessServiceToken } from '@otris/ng-core-shared'
import { AuthenticationType, AzureADAuthenticationParams, IPrivacyBackendServiceHook, IPrivacyNgModuleInfoService } from '@otris/privacy-ng-lib'
import { EMPTY, Observable, of } from 'rxjs'
import { switchMap } from 'rxjs/operators'

@Injectable()
export class PrivacyBackendServiceHook implements IPrivacyBackendServiceHook {
	constructor(@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService,
		@Inject(IErrorHandlerToken) private errorHandler: IErrorHandler,
		private privacyNgModuleInfoService: IPrivacyNgModuleInfoService) {}

	get getAuthenticationType(): (origVal: AuthenticationType) => Observable<AuthenticationType> {
		return (origVal: AuthenticationType) => {
			let methodName = this.privacyNgModuleInfoService.initParamsRequestMethod;
			return this.pdAccessService.callOperation(methodName, ['getLandingPageAuthParams'], undefined).pipe(
				switchMap(res => {
					if (Array.isArray(res.outStrs) && res.outStrs.length == 2 && !res.outStrs[1]) {
						let result = JSON.parse(res.outStrs[0]);
						if (result.success) {
							let authType: AuthenticationType = {
								type: 'azureAD',
								params: result.result
							};
							return of(authType);
						}
					}
					this.errorHandler.handleError(<IError>{ details: `Authentication params not found in handleNgModuleRequest()` });
					return EMPTY;
				})
			);
			/*let authType: AuthenticationType = {
				type: 'azureAD',
				params: <AzureADAuthenticationParams>{
					clientId: '9721e922-3fac-4381-b626-454dbea9315c',
					authority: 'https://login.microsoftonline.com/e9410288-becf-4933-bb04-35af086638f3',
					redirectUri: 'https://localhost/landing-page/login/azureAD'
				}
			};
			return of(authType);*/
		}
	}

	/*getAuthenticationType = (origVal: AuthenticationType) => {
		return of(origVal);
	}*/
}


							/*let result = <IPrivacyNgModuleRequestResult>JSON.parse(res.outStrs[0]);
							if (result.success) {
								if (result.result && result.result.resultType === requiredResultType) {

									return result.result;
								}
								// Daten haben nicht das richtige Format
								let msg = `Error in ${context}: The data transmitted by the server is in the wrong format.`;
								this.errorHandler.handleError(<IError>{ details: msg });								
								return undefined;								
							}
							// falls keine errMsg anbegeben wurde => fataler Fehler
							if (result.errorDetails) {
								if (handleCustomError) {
									this.errorHandler.handleError(<IError>{ details: `Error in ${context}: ${result.errorDetails}` });
									return undefined;
								}
								return result.errorDetails;
							}
						}
						else {
							// Fehler im privacy Javascript-Framework
							this.errorHandler.handleError(<IError>{ details: `Error in ${context}: ${res.outStrs[1]}` });
							return undefined;
						}*/
