import { Component, Inject, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, BehaviorSubject, forkJoin, defer } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import {
	PDLabeledControlComponent, MultiSelectRelationWidgetInfo, FormHandlerService, IPDChoiceSpec, MultiSelectRelationWidgetTypeET,
	IAsyncActionManagerService, IAsyncActionManagerServiceToken, IPopupManagerService, IPDObjectEditContext, IPopupManagerServiceToken,
	IPDObjectEditContextToken, MainUIService
} from '@otris/ng-core';
import {
	ComponentTypeET, PDObject, IPDAccessService, IPDAccessServiceToken, IMultiSelectComponent, ICustomChoiceProviderResult, IToolBarItemSpec, IToolBarButtonSpec,
	ToolBarItemTypeET, IToolBarItemResult, ISelectObjectOptions, IInteractionService, IInteractionServiceToken, ISelectPDObjectsResult, IPDListColumnInfo, ServiceLocator,
	TypeET, ILocalizationServiceToken, ILocalizationService, IPDClassToken, MultiSelectValueTypeET, CustomPDObject
} from '@otris/ng-core-shared';
// import { RemoveTagEvent } from '@progress/kendo-angular-dropdowns/dist/es2015/common/remove-tag-event';
import { RemoveTagEvent } from '@progress/kendo-angular-dropdowns/dist/es2015/common/models/remove-tag-event';


interface IChoiceData {
	ergName: string;
	value: PDObject | string;
	isNew?: boolean;
	className?: string;
	classErgName?: string;
}

enum ToolBarButtonIdET {
	Select = 'idSelect',
	New = 'idNew'
}

/**
 * Das PDMultiSelect ist ein Widget welches PDObjects oder Strings entgegen nimmt um daraus
 * eine Mehrfachselektion zu relasieren. Diese Selektion erfolgt über eine einfache Dropdownlist
 * oder über eine Overlay indem direkt mehrere Objekte selektiert werden können. Letzteres ist
 * für Strings-Input nicht implementiert. 
 */
//[popupSettings]="{ height: 500 }" [listHeight]="500"
@Component({
	selector: 'otris-pd-multi-select',
	template: `
		<ng-container *ngIf="active else inactive">
			<ng-container [ngSwitch]="multiSelectRelationWidgetType">

				<ng-container *ngSwitchCase="multiSelectRelationWidgetTypeET.SelectionList">
					<otris-pd-labeled-control-frame [labeledControl]="this" (toolBarButtonClick)="onToolBarButtonClick($event)" [pdObject]="pdObject" [relatedFormControl]="this.control">
						<ng-container *ngIf="isChangeable; else readonly">
							<kendo-multiselect #kendoMultiSelect class="multi-select" [textField]="'ergName'" [valueField]="'value'"
								[value]="items" (valueChange)="onValueChange($event)" [filterable]="false" [disabled]="!isEnabled" (open)="onOpen($event)"
								(removeTag)="onRemoveItem($event)">
								<ng-template kendoMultiSelectTagTemplate let-dataItem>
									<ng-container *ngTemplateOutlet="tagTemplate; context: { $implicit: dataItem }">
									</ng-container>
								</ng-template>
							</kendo-multiselect>
							<otris-tool-bar *ngIf="isEnabled" class="toolbar" (buttonClick)="onToolBarButtonClick($event)" [itemSpecs]="toolBarItems">
							</otris-tool-bar>
						</ng-container>
					</otris-pd-labeled-control-frame>
				</ng-container> <!-- *ngSwitchCase="multiSelectRelationWidgetTypeET.SelectionList" -->

				<ng-container *ngSwitchDefault>
					<ng-container *ngIf="choiceData$ | async else loading">

						<otris-pd-labeled-control-frame [labeledControl]="this" (toolBarButtonClick)="onToolBarButtonClick($event)"[pdObject]="pdObject" [relatedFormControl]="this.control">
							<ng-container *ngIf="isChangeable; else readonly">
								<kendo-multiselect #kendoMultiSelect class="multi-select" [data]="choiceDataFiltered" [textField]="'ergName'" [valueField]="'value'"
									[value]="items" (valueChange)="onValueChange($event)" [filterable]="true" (filterChange)="onFilterChange($event)"
									(removeTag)="onRemoveItem($event)" [disabled]="!isEnabled" [readonly]="isReadonly || canChange===false">
									<ng-template kendoMultiSelectTagTemplate let-dataItem>
										<ng-container *ngTemplateOutlet="tagTemplate; context: { $implicit: dataItem }">
										</ng-container>
									</ng-template>
								</kendo-multiselect>
								<otris-tool-bar *ngIf="isEnabled" class="toolbar" (buttonClick)="onToolBarButtonClick($event)" [itemSpecs]="toolBarItems">
								</otris-tool-bar>
							</ng-container>
						</otris-pd-labeled-control-frame>

					</ng-container>
					<ng-template #loading>
						<div>Loading ...</div>
					</ng-template> <!-- #loading -->
				</ng-container> <!-- #ngSwitchDefault -->

			</ng-container> <!-- [ngSwitch]="multiSelectRelationWidgetType" -->

			<ng-template #tagTemplate let-dataItem>
				<div class="tag-template">
					<div *ngIf="dataItem.isNew" class="tag-template-icon">
						<i class="fa fa-plus-square"></i>
					</div>
					<div>{{dataItem.ergName}}</div>
				</div>
			</ng-template>

			<ng-template #readonly>
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			</ng-template> <!-- #readonly -->

		</ng-container> <!-- inactive -->

		<ng-template #inactive>
			<otris-pd-labeled-control-frame [labeledControl]="this">
				<kendo-multiselect class="multi-select" [disabled]="true">
				</kendo-multiselect>
			</otris-pd-labeled-control-frame>
		</ng-template>
	`,
	styles: [`
		.multi-select {
			flex: 1;
		}
		.toolbar {
			height: 100%;
		}
		.tag-template {
			display: flex;
		}
		.tag-template-icon {
			margin-right: 0.5em;
		}
		.tag-template-icon > i {
			vertical-align: middle;
		}
	`]
})
export class PDMultiSelectComponent extends PDLabeledControlComponent implements IMultiSelectComponent {
	
	@ViewChild('kendoMultiSelect') kendoMultiSelect: MultiSelectComponent;

	private subscription: Subscription;

	choiceDataFiltered: GroupResult[] | IChoiceData[];

	choiceData$: Observable<IChoiceData[]>;

	choiceData: GroupResult[] | IChoiceData[];

	private choiceDataMap: Map<string, IChoiceData> = new Map<string, IChoiceData>();

	get items(): IChoiceData[] {
		return this._items;
	}

	private _items: IChoiceData[] = [];

	/**
	 * Setzt für das Formular die Selektierten Objekte vom SelectionList und informiert die Subscriber
	 */
	set selectedValues(vals: IChoiceData[]) {
		if (this._selectedValues !== vals || (vals && this.selectedValues.length != vals.length)) {
			this._selectedValues = vals;
			this.onItemsChanged();

			// todo: auch bei newItem! umbenennen in itemsChangedSubject
			/*let params = this._valueType == MultiSelectValueTypeET.String ?
				this.items.map(v => <string>v.value) :
				this.items.map(v => <PDObject>v.value)
			this._selectionChangedSubject$.next(params);*/

			/*let ctrl = this.control;
			if (!ctrl) {
				return;
			}
			this._selectedValues = vals; // todo: newItems herausfischen
			ctrl.markAsDirty();
			ctrl.setValue((vals && vals.length > 0) ? vals.map(v => v.value) : null);*/
			// FIXME: Explizites casten auf PDObject, kann nur schief gehen bei strings
		}
	}

	private _selectedValues: IChoiceData[]; // umbenennen in _selectedItems

	private _newItems: IChoiceData[] = []
	
	/**
	 * Liefert die ergonomischen Namen der selektierten Objekte.
	 */
	getSelectedValuesErgNameString(): string {
		if (this._selectedValues) {
			return this._selectedValues.map(obj => obj.ergName).join(", ");
		}
		return "";
	}

	private get multiSelectWidgetInfo(): MultiSelectRelationWidgetInfo {
		if (this.pdItemSpec.widgetInfo instanceof MultiSelectRelationWidgetInfo) {
			return <MultiSelectRelationWidgetInfo>this.pdItemSpec.widgetInfo;
		}
		return undefined;
	}

	private get choiceSpecs(): IPDChoiceSpec[] {
		if (!this.multiSelectWidgetInfo || !this.multiSelectWidgetInfo.choiceSpec) {
			return [];
		}
		if (!Array.isArray(this.multiSelectWidgetInfo.choiceSpec)) {
			return [this.multiSelectWidgetInfo.choiceSpec];
		}
		return this.multiSelectWidgetInfo.choiceSpec;
	}

	private get hasChoiceGroups(): boolean {
		return this._hasChoiceGroups;// || this.choiceSpecs.length > 1;
	}

	private _hasChoiceGroups: boolean = false;

	multiSelectRelationWidgetTypeET = MultiSelectRelationWidgetTypeET;

	get multiSelectRelationWidgetType(): MultiSelectRelationWidgetTypeET {
		let wi = this.multiSelectWidgetInfo;
		return wi ? wi.type : MultiSelectRelationWidgetTypeET.DropDownList;
	}

	toolBarItems: IToolBarItemSpec[];

	private _choiceDataAction$: Observable<IChoiceData[]>;

	canChange: boolean;

	get isChangeable(): boolean {
		return this.isReadonly !== true && this.canChange !== false;
	}

	private get mainUIService(): MainUIService {
		return ServiceLocator.injector.get(MainUIService); 
	}

	//private _isCreatingItem = false; // todo: muss ggf. auf andere Art realisiert werden. Aktuell reicht das so

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService,
		@Inject(IInteractionServiceToken) private interactionService: IInteractionService,
		@Inject(ILocalizationServiceToken) private localizationService: ILocalizationService,
		@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService,
		@Inject(IAsyncActionManagerServiceToken) private asyncActionManagerService: IAsyncActionManagerService,
		@Inject(IPopupManagerServiceToken) private popupManagerService: IPopupManagerService,
		@Inject(IPDObjectEditContextToken) private pdObjectEditContext: IPDObjectEditContext) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();

		let wi = this.multiSelectWidgetInfo;
		if (wi) {
			this.active = wi.active;
		}

		this.updateChoice();

		this.subscription = this.formGroup.valueChanges.subscribe(v => {
			if (v.hasOwnProperty(this.propertyName) && !v[this.propertyName]) {
				//this.selectedValues = this._emptySelectedValues;
				if (this.kendoMultiSelect) {
					this.kendoMultiSelect.reset();
				}
			}
		});

		this.toolBarItems = [];
		if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.SelectionList) {
			this.toolBarItems.push(
				<IToolBarButtonSpec>{
					id: ToolBarButtonIdET.Select, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-list',
					shortDescriptionId: 'system.kendo-ui.components.pd-multi-select.tool-bar-button-select'
					}
			);
		}
		if (wi && wi.newAction) {
			this.toolBarItems.push(
				<IToolBarButtonSpec>{
					id: ToolBarButtonIdET.New, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-file-o',
					shortDescriptionId: 'system.kendo-ui.components.pd-multi-select.tool-bar-button-new'
				}
			);
		}

		this._hasChoiceGroups = this.choiceSpecs.length > 1;

		this.localizationService.changeHandler.subscribe(lang => {
			this.updateChoice();
			this.updateSelectedValuesErgName();
		});

		this.subscription.add(this.pdObjectEditContext.getRelationObjectCreated(this.propertyName).subscribe(
			data => {
				if (data[2] == this.pdObject.objectId.oid) {
					let obj = data[1];
					let wi = this.multiSelectWidgetInfo;
					let newItem: IChoiceData = {
						className: obj.getClassName(),
						isNew: true,
						ergName: obj.getStatus(wi.editingSpec.objectInfo),
						value: obj
					};
					this._newItems.push(newItem);
					this.onItemsChanged();
					this.mainUIService.addCreatedObject(obj);
				}
			}
		));

		this.applyAccessRights();
		this.updateToolbar();
	}

	ngOnDestroy() {
		this.removeAllCreatedItems();

		if (this._choiceDataAction$) {
			this.asyncActionManagerService.notifyActionFinished(this._choiceDataAction$);
		}
		
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		super.ngOnDestroy();
	}

	protected onPDObjectChanged() {
		super.onPDObjectChanged();
		if (this.pdObject) {
			this._valueType = this.pdObject.metaData.getPropertyType(this.propertyName) === TypeET.StringArray ? 
				MultiSelectValueTypeET.String : MultiSelectValueTypeET.PDObject;
		}
		else {
			this._valueType = undefined;
		}

		this.updateSelectedValues();
		this.updateNewItems();
	}

	protected onReadonlyChanged(): void {
	}

	protected onMetaDataChanged(): void {
		super.onMetaDataChanged();
		//this.applyAccessRights();
		this.updateToolbar();
	}

	/**
	 * Fügt selektierte Objekte in die Ausgewähltenliste hinzu
	 */
	private updateSelectedValues() {
		if (!this.pdObject || !this.propertyName) {
			return;
		}
		// es könnten sein, dass das Holen der Selektion aus dem PDObject problematisch ist!
		let newSelection: IChoiceData[] = [];
		let sel = this.pdObject.pdObjectRaw[this.propertyName];
		if (Array.isArray(sel)) {
			if (this._valueType == MultiSelectValueTypeET.String) {
				if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.DropDownList) {

					for (let itemString of sel) {
						if (this.choiceDataMap.has(itemString)) { 
							newSelection.push(this.choiceDataMap.get(itemString));
						}
					}

				}
			} else {
				for (let obj of sel) {
					if (obj instanceof CustomPDObject) {
						continue;
					}
					if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.SelectionList) {
						if (this.choiceSpecs.length == 1) {
							let data = <IChoiceData>{ ergName: obj.getStatus(this.getRelationInfo(this.choiceSpecs[0])), value: obj, className: this.choiceSpecs[0].className };
							newSelection.push(data);
						}
					}
					else if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.DropDownList) {
						if (this.choiceDataMap.has(obj.objectId.oid)) {
							newSelection.push(this.choiceDataMap.get(obj.objectId.oid));
						}
					}
				}
			}
		}
		if (newSelection.length == 0 && !this._selectedValues) {
			return;
		}
		this.selectedValues = newSelection;
	}

	private updateNewItems(): void {
		this._newItems = [];
		let sel = this.pdObject.pdObjectRaw[this.propertyName];
		if (Array.isArray(sel) && this._valueType == MultiSelectValueTypeET.PDObject) {
			sel.forEach(obj => {
				if (obj instanceof CustomPDObject) {
					let wi = this.multiSelectWidgetInfo;
					let newItem: IChoiceData = {
						className: obj.getClassName(),
						isNew: true,
						ergName: obj.getStatus(wi.editingSpec.objectInfo),
						value: obj
					};
					this._newItems.push(newItem);
					this.onItemsChanged();
					this.mainUIService.addCreatedObject(obj);	
				}
			});
		}
	}

	private updateSelectedValuesErgName(): void {
		if (this.multiSelectRelationWidgetType !== MultiSelectRelationWidgetTypeET.SelectionList || !this.selectedValues || this.choiceSpecs.length != 1) {
			return;
		}
		this.selectedValues.forEach(val => {
			if (val.value instanceof PDObject) {
				val.ergName = val.value.getStatus(this.getRelationInfo(this.choiceSpecs[0]));
			} else {
				val.ergName = val.value;
			}
		});
	}

	updateChoice() {
		if (this.multiSelectRelationWidgetType != MultiSelectRelationWidgetTypeET.DropDownList) {
			return;
		}
		
		if (this.active) {
			let action$: Observable<IChoiceData[]>;
			if (this._valueType == MultiSelectValueTypeET.PDObject) {
				let choiceClassNames: string[];
				let data$: Observable<[string, string, PDObject[]][]>;
				if (this._customChoiceProvider) {
					let customRes = this._customChoiceProvider();
					choiceClassNames = customRes.map(res => res.className);
					this._hasChoiceGroups = customRes.length > 1 && customRes.findIndex(item => item.className !== customRes[0].className) != -1;
					let extents = customRes.map(r => r.data.pipe(map(d => <[string, string, PDObject[]]>[r.className, r.objectInfo, d])));
					data$ = forkJoin(extents);
				}
				else if (this.choiceSpecs.length > 0) {
					choiceClassNames = this.choiceSpecs.map(s => s.className);
					let getExtentCalls: Observable<[string, string, PDObject[]]>[] = [];
					for (let spec of this.choiceSpecs) {
						let filterExpr = this.getChoiceFilterExpression(spec);
						let call: Observable<[string, string, PDObject[]]> = this.pdAccessService.getExtent(spec.className, filterExpr, spec.sortExpr, spec.properties).pipe(
							map(res => <[string, string, PDObject[]]>[spec.className, this.getRelationInfo(spec), res])
						);
						getExtentCalls.push(call);
					}
					data$ = forkJoin(getExtentCalls);
				}

				if (data$) {
					let getClassErgNames$ = choiceClassNames.map(cls => this.metaDataService.getClassErgName(cls).pipe(
						map(erg => <[string, string]>[cls, erg]))
					);

					action$ = forkJoin(getClassErgNames$).pipe(
						switchMap(ergNames => {
							let classErgNames = ergNames.reduce(
								(map, val) => {
									map.set(val[0], val[1]);
									return map;
								},
								new Map<string, string>()
							);
							return data$.pipe(
								map(results => {
									let result: IChoiceData[] = [];
									this.choiceDataMap.clear();
									for (let res of results) {
										for (let obj of res[2]) {
											let classErgName = classErgNames.has(res[0]) ? classErgNames.get(res[0]) : res[0];
											let data = <IChoiceData>{ ergName: obj.getStatus(res[1]), value: obj, className: res[0], classErgName: classErgName };
											result.push(data);
											this.choiceDataMap.set(obj.objectId.oid, data);
										}
									}
									return result;
								}),
								tap(
									(res) => {
										let fGroup = res.length > 1 && res.findIndex(item => item.className !== res[0].className) != -1;
										this.choiceData = fGroup ? groupBy(res, [{ field: "classErgName" }]) : res;
										this.choiceDataFiltered = this.choiceData;
										this.updateSelectedValues();
									}
								)
							);
						})
					);
				}
			} else {
				if (this._customChoiceProvider) {
					let res = this._customChoiceProvider();
					if (res.length == 1) {
						
						// action$ = res[0].data.map(data => (<string[]>data).map(s => <IChoiceData>{ ergName: s, value: s }));

						action$ = res[0].data.pipe(
							map(data => {
								this.choiceData = (<string[]>data).map(s => <IChoiceData>{ ergName: s, value: s });
								this.choiceDataFiltered = this.choiceData;
								for (let cd of this.choiceData) {
									this.choiceDataMap.set(cd.ergName, cd);
								}
								this.updateSelectedValues();
								return this.choiceData;
							})
						);
					}
				}
			}

			if (action$) {
				this.choiceData$ = defer(
					() => {
						if (this._choiceDataAction$) {
							this.asyncActionManagerService.notifyActionFinished(this._choiceDataAction$);
						}
						this.asyncActionManagerService.notifyActionStarted(action$);
						this._choiceDataAction$ = action$;
						return action$;

					}).pipe(
						tap(
							() => { },
							() => { this.asyncActionManagerService.notifyActionFinished(action$); this._choiceDataAction$ = undefined; },
							() => { this.asyncActionManagerService.notifyActionFinished(action$); this._choiceDataAction$ = undefined; }
						)
					);
				return;
			}
		}
		this.choiceData$ = undefined;
		this.choiceData = [];
		this.choiceDataFiltered = [];
		this.choiceDataMap.clear();
		this.updateSelectedValues();
	}

	onFilterChange(value): void {
		if (!this.hasChoiceGroups) {
			this.choiceDataFiltered = (<IChoiceData[]>this.choiceData).filter(d => d.ergName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
		}
		else {
			let resFiltered: GroupResult[] = []
			for (let obj of <GroupResult[]>this.choiceData) {
				let g: GroupResult = Object.assign({}, obj);
				g.items = (<IChoiceData[]>g.items).filter(d => d.ergName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
				resFiltered.push(g);
			}
			this.choiceDataFiltered = resFiltered;
		}
	}

	onOpen(event: Event): void {
		event.preventDefault();
	}

	onToolBarButtonClick(item: IToolBarItemResult) {
		switch (item.id) {
			case ToolBarButtonIdET.Select:
				this.selectObjects();
				return;

			case ToolBarButtonIdET.New:
				this.createItem();
				return;
		}
		super.onToolBarButtonClick(item);
	}

	onValueChange(vals: IChoiceData[]) {
		if (!vals || vals.length == 0) {
			this.removeAllCreatedItems();
			this.selectedValues = [];
		}
		else {
			this.selectedValues = vals.filter(v => v.isNew !== true);
		}
	}

	onRemoveItem(evt: RemoveTagEvent) {
		let itemsToRemove: IChoiceData[] = Array.isArray(evt.dataItem) ? evt.dataItem : [evt.dataItem];
		itemsToRemove.forEach(i => {
			let pos = this._newItems.indexOf(i);
			if (pos >= 0) {
				if (this._newItems[pos].value instanceof CustomPDObject) {
					this.mainUIService.removeCreatedObject(<CustomPDObject>this._newItems[pos].value);
				}
				this._newItems.splice(pos, 1);
			}
			else {
				pos = this._selectedValues.indexOf(i);
				if (pos >= 0) {
					this._selectedValues.splice(pos, 1);
				}
			}
		});
		this.onItemsChanged();
		evt.preventDefault();
	}

	//private updateControlValue(): void {
	private onItemsChanged(): void {
		// items mit selectedValues und newItems aktualisieren
		let vals = [];
		if (this._selectedValues) {
			vals.push(...this._selectedValues);
		}
		if (this._newItems) {
			vals.push(...this._newItems);
		}
		this._items = vals;

		// ctrl value aktualisieren
		let ctrl = this.control;
		if (ctrl) {
			ctrl.markAsDirty();
			let vals = this.items;
			ctrl.setValue((vals && vals.length > 0) ? vals.map(v => v.value) : null);
		}

		// selectionChanged event
		let params = this._valueType == MultiSelectValueTypeET.String ?
			this.items.map(v => <string>v.value) :
			this.items.map(v => <PDObject>v.value)
		this._selectionChangedSubject$.next(params);
	}

	private getChoiceFilterExpression(spec: IPDChoiceSpec): string | undefined {
		if (this._customChoiceFilterProvider) {
			let res = this._customChoiceFilterProvider();
			if (this.choiceSpecs.length == 1) {
				if (typeof (res) === 'string') {
					return res;
				}
			}
			else if (res instanceof Map && res.has(spec.className)) {
				return res.get(spec.className);
			}
		}

		return spec.filterExpr;
	}

	/**
	 * Öffnet ein Overlay zur Mehrfachselektion
	 */
	private selectObjects() {
		if (this.choiceSpecs.length != 1) {
			return;
		}

		let className = this.choiceSpecs[0].className;
		let listSpec = (this.multiSelectWidgetInfo && this.multiSelectWidgetInfo.selectionListSpec) ? this.multiSelectWidgetInfo.selectionListSpec : undefined;
		let options: ISelectObjectOptions = {
			width: listSpec && listSpec.width ? listSpec.width : '60vw',
			height: listSpec && listSpec.width ? listSpec.width : '90vh',
			//columnInfos: listSpec && listSpec.columns ? listSpec.columns : undefined,
			sortExpr: this.choiceSpecs[0].sortExpr
		}

		if (this._selectionListColumnProvider) {
			options.columnInfos = this._selectionListColumnProvider();
		}
		else if (listSpec && listSpec.columns) {
			options.columnInfos = listSpec.columns;
		}

		if (this._customChoiceFilterProvider) {
			let filter = this._customChoiceFilterProvider();
			if (typeof (filter) === 'string') {
				options.filterExpr = filter;
			}
		}
		else if (this.choiceSpecs[0].filterExpr) {
			options.filterExpr = this.choiceSpecs[0].filterExpr;
		}

		let evalResult: (res: ISelectPDObjectsResult) => void =
			(res) => {
				if (res.selection) {
					let newSelection = res.selection.map(
						obj => <IChoiceData>{
							className: className, ergName: obj.getStatus(this.getRelationInfo(this.choiceSpecs[0])), value: obj
						}
					);
					if (this._selectedValues) {
						for (let obj of this._selectedValues) {
							if (newSelection.findIndex(s => (<PDObject>s.value).objectId.oid === (<PDObject>obj.value).objectId.oid) == -1) {
								newSelection.splice(0, 0, obj);
							}
						}
					}
					this.selectedValues = newSelection;
				}
			};

		let showSelectDialog: (title: string) => Observable<ISelectPDObjectsResult> =
			(title) => {
				if (this._customChoiceProvider) {
					let res = this._customChoiceProvider();
					if (res && res.length == 1) {
						/*return res[0].data.switchMap(objects => {
							options.choiceObjects = objects;
							return this.interactionService.selectPDObjects(className, title, options).do(res => evalResult(res)); // todo: mit evalResult zusammenfassen
						});*/
						options.choiceObjects = <Observable<PDObject[]>>res[0].data;
						return this.interactionService.selectPDObjects(className, title, options).pipe(tap(res => evalResult(res))); // todo: mit evalResult zusammenfassen
					}
					return of(<ISelectPDObjectsResult>{ selection: [] })
				}
				else if (listSpec && listSpec.pdObjectProvider) {
					options.choiceObjects = listSpec.pdObjectProvider(this.pdAccessService);
					return this.interactionService.selectPDObjects(className, title, options).pipe(tap(res => evalResult(res))); // todo: mit evalResult zusammenfassen
				}
				else {
					return this.interactionService.selectPDObjects(className, title, options).pipe(tap(res => evalResult(res))); // todo: mit evalResult zusammenfassen
				}
			};

		if (listSpec) {
			if (listSpec.title) {
				showSelectDialog(listSpec.title).subscribe();
				return;
			}
			else if (listSpec.titleId) {
				this.localizationService.getString(listSpec.titleId, { value: className }).pipe(
					switchMap(title => showSelectDialog(title))
				).subscribe();
				return;
			}
		}

		this.metaDataService.getClassErgName(className).pipe(
			switchMap(erg => {
				return this.localizationService.getSystemString('kendo-ui.components.pd-multi-select.selection-list-title', { value: erg }).pipe(
					switchMap(title => showSelectDialog(title))
				)
			})
		).subscribe();
	}

	private createItem(): void {
		let wi = this.multiSelectWidgetInfo;
		if (!wi || !wi.editingSpec) {
			throw new Error('No editing spec defined.');
		}

		if (Array.isArray(wi.editingSpec.className)) {
			// todo; Klasse auswählen
			return;
		}
		let clsName = wi.editingSpec.className
		/*let pdClass = ServiceLocator.injector.get(IPDClassToken);
		let obj = pdClass.createInstance(clsName, undefined, true);
		let newItem: IChoiceData = {
			className: clsName,
			ergName: obj.getStatus(wi.editingSpec.objectInfo),
			value: obj
		};
		this._newItems.push(newItem);
		this.onItemsChanged();*/

		let url = [];
		url.push(...wi.editingSpec.url);
		url.push(this.propertyName);
		url.push(clsName);
		url.push(this.pdObject.objectId.oid);
		// propertyName entspricht der relationRole, diese wird im PDObjectResolver dazu benutzt das Beziehungsobjekt zu erzeugen
		// todo: Mechanismus notwendig, falls Beziegungsklasse eine Vererbungshierarchie aufweist
		this.popupManagerService.showPopup(wi.editingSpec.outletName, url, this.route.parent, 'Popup_' + this.propertyName);
	}

	private removeAllCreatedItems(): void {
		if (this._newItems) {
			this._newItems.forEach(i => {
				if (i.value instanceof CustomPDObject) {
					this.mainUIService.removeCreatedObject(<CustomPDObject>i.value);
				}
			});
			this._newItems = [];
		}
	}

	private getRelationInfo(choiceSpec: IPDChoiceSpec) {
		return choiceSpec.relationInfoProvider ? choiceSpec.relationInfoProvider(this.localizationService) :
			(choiceSpec.relationInfo ? choiceSpec.relationInfo : 'n.a.');
	}

	protected applyAccessRights(): void {
		super.applyAccessRights();
		let relAccessRights = this.pdObject.metaData.getRelationMeta(this.propertyName)?.accessRights;
		this.canChange = (relAccessRights && relAccessRights.change !== undefined) ? relAccessRights.change : undefined;
	}

	private updateToolbar(): void {
		if (this.multiSelectRelationWidgetType != MultiSelectRelationWidgetTypeET.SelectionList || !this.toolBarItems) {
			return;
		}

		let tbItemSelect = this.toolBarItems.find(item => item.id == ToolBarButtonIdET.Select);
		tbItemSelect.disabled = this.canChange === false;
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.MultiSelect;
	}

	reset(): void {
		super.reset();
		this.removeAllCreatedItems();
		this.selectedValues = undefined;
	}

	disable(flag: boolean): void {
		if (!this.active && !flag) {
			console.debug(`An inactive component can't be enabled (id: '${this.id}')`);
			return;
		}
		super.disable(flag);
	}

	//
	// IMultiSelectComponent
	//

	get active(): boolean {
		return this._active;
	}

	set active(val: boolean) {
		if (val != this._active) {
			this._active = val;
			this.disable(!val);
			this.updateChoice();
		}
	}

	private _active: boolean = true;

	private _valueType: MultiSelectValueTypeET;

	get valueType(): MultiSelectValueTypeET {
		return this._valueType;
	}

	get selection(): (string | PDObject)[] | undefined {
		return this._selectedValues ? this._selectedValues.map(s => s.value) : undefined;
	}

	set selection(objs: (string | PDObject)[] | undefined) {
		if (!objs || objs.length == 0) {
			//this.selectedValues = undefined;
			this.selectedValues = [];
			return;
		}

		let newSelection: IChoiceData[] = [];

		if (this._valueType == MultiSelectValueTypeET.String) {
			if (this.multiSelectRelationWidgetType === MultiSelectRelationWidgetTypeET.DropDownList) {

				for (let itemString of objs) {
					if (this.choiceDataMap.has(<string>itemString)) { 
						newSelection.push(this.choiceDataMap.get(<string>itemString));
					}
				}
			}
		} else {
			for (let obj of <PDObject[]>objs) {
				switch (this.multiSelectRelationWidgetType) {
					case MultiSelectRelationWidgetTypeET.DropDownList:
						{
							if (this.choiceDataMap.has(obj.objectId.oid)) {
								newSelection.push(this.choiceDataMap.get(obj.objectId.oid));
							}
							break;
						}
	
					case MultiSelectRelationWidgetTypeET.SelectionList:
						if (this.choiceSpecs.length == 1) {
							newSelection.push(<IChoiceData>{
								className: this.choiceSpecs[0].className, ergName: obj.getStatus(this.getRelationInfo(this.choiceSpecs[0])), value: obj
							});
						}
						break;
				}
			}
		}
		this.selectedValues = newSelection;
	}

	private _selectionChangedSubject$: BehaviorSubject<PDObject[] | string[] | undefined> = new BehaviorSubject(undefined);

	private _selectionChanged$: Observable<PDObject[] | string[] | undefined>;

	get selectionChanged(): Observable<PDObject[] | string[] | undefined> {
		if (!this._selectionChanged$) {
			this._selectionChanged$ = this._selectionChangedSubject$.asObservable();
		}
		return this._selectionChanged$;
	}

	set customChoiceProvider(cb: () => ICustomChoiceProviderResult[]) {
		this._customChoiceProvider = cb;
		this.updateChoice();
	}

	private _customChoiceProvider: () => ICustomChoiceProviderResult[];

	set customChoiceFilterProvider(cb: () => string | Map<string, string>) {
		this._customChoiceFilterProvider = cb;
		this.updateChoice();
	}

	private _customChoiceFilterProvider: () => string | Map<string, string>;

	set selectionListColumnProvider(cb: () => IPDListColumnInfo[]) {
		this._selectionListColumnProvider = cb;
	}

	private _selectionListColumnProvider: () => IPDListColumnInfo[];

	get choiceObjects(): (PDObject | string)[] {
		if (this.multiSelectRelationWidgetType !== MultiSelectRelationWidgetTypeET.DropDownList || this.choiceDataMap.size == 0) {
			return [];
		}
		return Array.from(this.choiceDataMap.values()).map(item => item.value);
	}
}
