import { Observable } from 'rxjs';
import * as Layout from './i-pd-layout';
import {
	ToolbarSpec, PDObject, LabelPositionET, VisibilityET, ICssStyle, IUIDrawerItem, DrawerModeET, DrawerExpandModeT, DrawerPositionT, IInteractionService
} from '@otris/ng-core-shared';
import { IEventProcessingContext } from './i-event-processing-context';
import { WidgetET } from './i-pd-layout';
import { ILocalizationService } from '@otris/ng-core-shared';
import { MainUIService } from '../services/main-ui.service';

interface IContainerLike {
	container: IContainer | UIRootContainerSpec;
	allItems: UIItemSpec[];
	childItemMargin: string | undefined;
	childItemMarginTop: string | undefined;
	childItemMarginRight: string | undefined;
	childItemMarginBottom: string | undefined;
	childItemMarginLeft: string | undefined;
	disableChildItemMarginsInContainer: boolean;
}

interface IContainer {
	layout?: LayoutSpec;
}

//-------------------------------------------------------------------------------------------------
// ToolbarSpec
//-------------------------------------------------------------------------------------------------

/*export class ToolbarSpec {
	private _items: IToolBarItemSpec[] = [];

	constructor(spec: Layout.IToolbarSpec) {
		if (spec.items) {
			this._items = spec.items;
		}
	}

	get items(): IToolBarItemSpec[] {
		return this._items;
	}
}*/

//-------------------------------------------------------------------------------------------------
// RootContainerSpec
//-------------------------------------------------------------------------------------------------

export abstract class UIRootContainerSpec implements IContainerLike {
	private _header?: string;
	private _headerId?: string;
	private _content?: UIPanelSpec;
	private _width?: string;
	private _minWidth?: string;
	private _maxWidth?: string;
	private _height?: string;
	private _minHeight?: string;
	private _maxHeight?: string;
	private _showHeader: boolean = true;
	// private _transparent: boolean = true; // über get()
	// private _backgroundColor: string;

	constructor(protected spec: Layout.IUIRootContainerSpec) {
		if (spec.header) {
			this._header = spec.header;
		}
		if (spec.headerId) {
			this._headerId = spec.headerId;
		}
		if (spec.content) {
			this._content = new UIPanelSpec(spec.content, this);
		}
		if (spec.width) {
			this._width = spec.width;
		}
		if (spec.minWidth) {
			this._minWidth = spec.minWidth;
		}
		if (spec.maxWidth) {
			this._maxWidth = spec.maxWidth;
		}
		if (spec.height) {
			this._height = spec.height;
		}
		if (spec.minHeight) {
			this._minHeight = spec.minHeight;
		}
		if (spec.maxHeight) {
			this._maxHeight = spec.maxHeight;
		}
		if (spec.showHeader != undefined) {
			this._showHeader = spec.showHeader;
		}
		// if (spec.showBackground != undefined) {
		// 	this._showBackground = spec.showBackground;
		// }
		// if (spec.backgroundColor) {
		// 	this._backgroundColor = spec.backgroundColor;
		// }
	}
	
	get transparent(): boolean {
		return this.spec.transparent !== false;
	}

	get container(): UIRootContainerSpec {
		return this;
	}

	get header(): string {
		return this._header;
	}

	get headerId(): string {
		return this._headerId;
	}

	get content(): UIPanelSpec {
		return this._content;
	}

	get width(): string {
		return this._width;
	}

	get minWidth(): string {
		return this._minWidth;
	}

	get maxWidth(): string {
		return this._maxWidth;
	}

	get height(): string {
		return this._height;
	}

	get minHeight(): string {
		return this._minHeight;
	}

	get maxHeight(): string {
		return this._maxHeight;
	}

	get showHeader(): boolean {
		return this._showHeader;
	}

	// get showBackground(): boolean {
	// 	return this._showBackground;
	// }

	// get backgroundColor(): string {
	// 	return this._backgroundColor;
	// }

	get allItems(): UIItemSpec[] {
		return this._content ? this._content.allItems : [];
	}

	get childItemMargin(): string | undefined {
		return this.spec.childItemMargin;
	}

	get childItemMarginTop(): string | undefined {
		return this.spec.childItemMarginTop;
	}

	get childItemMarginRight(): string | undefined {
		return this.spec.childItemMarginRight;
	}

	get childItemMarginBottom(): string | undefined {
		return this.spec.childItemMarginBottom;
	}

	get childItemMarginLeft(): string | undefined {
		return this.spec.childItemMarginLeft;
	}

	get disableChildItemMarginsInContainer(): boolean {
		return this.spec.disableChildItemMarginsInContainer === true;
	}
}


//-------------------------------------------------------------------------------------------------
// FormSpec
//-------------------------------------------------------------------------------------------------

export class UIFormToolbarSpec {
	constructor(private spec: Layout.IUIFormToolbarSpec) {}

	get save(): boolean {
		return this.spec.save !== false;
	}

	get reset(): boolean {
		return this.spec.reset !== false;
	}

	get language(): boolean {
		return this.spec.language !== false;
	}
}

export class UIFormSpec extends UIRootContainerSpec {
	private _showToolbar: boolean = true;

	private _toolbarSpec: UIFormToolbarSpec;

	constructor(spec: Layout.IUIFormSpec) {
		super(spec);
		if (spec.showToolbar != undefined) {
			this._showToolbar = spec.showToolbar;
		}
		if (spec.toolbarSpec) {
			this._toolbarSpec = new UIFormToolbarSpec(spec.toolbarSpec);
		}
	}

	private get uiFormSpec(): Layout.IUIFormSpec {
		return <Layout.IUIFormSpec>this.spec;
	}

	get showToolbar(): boolean {
		return this._showToolbar;
	}

	get toolbarSpec(): UIFormToolbarSpec | undefined {
		return this._toolbarSpec;
	}

	get savingHandler(): ((formData: any, mainUIService: MainUIService, interactionService: IInteractionService, localizationService: ILocalizationService) => Observable<boolean>) | undefined {
		return this.uiFormSpec.savingHandler;
	}
}

//-------------------------------------------------------------------------------------------------
// PreviewSpec
//-------------------------------------------------------------------------------------------------

export class UIPreviewSpec extends UIRootContainerSpec {

	constructor(spec: Layout.IUIPreviewSpec) {
		super(spec);
	}
}

//-------------------------------------------------------------------------------------------------
// Items
//-------------------------------------------------------------------------------------------------

export abstract class UIItemSpec {
	protected static createUIItemSpec(spec: Layout.IUIItemSpec, parent: IContainerLike): UIItemSpec {
		switch (spec.type) {
			case Layout.ItemTypeET.UIPanel:
				return new UIPanelSpec(<Layout.IUIPanelSpec>spec, parent);
			case Layout.ItemTypeET.PDItem:
				return new PDItemSpec(<Layout.IPDItemSpec>spec, parent);
			case Layout.ItemTypeET.UIContent:
				return new UIContentSpec(<Layout.IUIContentSpec>spec, parent);
			case Layout.ItemTypeET.PDRadioButton:
				return new PDRadioButtonSpec(<Layout.IPDRadioButtonSpec>spec, parent);
			case Layout.ItemTypeET.UIGroupbox:
				return new UIGroupboxSpec(<Layout.IUIGroupboxSpec>spec, parent);
			case Layout.ItemTypeET.UIRadioButtonGroup:
				return new UIRadioButtonGroupSpec(<Layout.IUIRadioButtonGroupSpec>spec, parent);
			case Layout.ItemTypeET.UIAccordion:
				return new UIAccordionSpec(<Layout.IUIAccordionSpec>spec, parent);
			case Layout.ItemTypeET.UITabView:
				return new UITabViewSpec(<Layout.IUITabViewSpec>spec, parent);
			case Layout.ItemTypeET.UIDrawer:
				return new UIDrawerSpec(<Layout.IUIDrawerSpec>spec, parent);
			case Layout.ItemTypeET.PDRelationTab:
				return new PDRelationTabSpec(<Layout.IPDRelationTabSpec>spec, parent);
		}
		throw Error(`Unknown item type ${spec.type}`);
	}

	protected spec: Layout.IUIItemSpec;
	protected _parent: IContainerLike;
	protected _widget: Layout.WidgetET;
	protected _widgetInfo?: WidgetInfo;
	private _type: Layout.ItemTypeET;
	private _id: string;
	private _placement?: UIItemPlacement;
	private _width?: string;
	private _height?: string;
	private _minWidth?: string;
	private _maxWidth?: string;
	private _margin?: string;
	private _marginLeft?: string;
	private _marginTop?: string;
	private _marginRight?: string;
	private _marginBottom?: string;
	private _padding?: string;
	private _paddingLeft?: string;
	private _paddingTop?: string;
	private _paddingRight?: string;
	private _paddingBottom?: string;
	private _pdClassContext?: string[];
	private _editContext?: string[];
	private _invalidEditContext?: string[];
	private _disabled: boolean = false;
	private _customData: any;

	// todo: widget als Parameter
	constructor(itemSpec: Layout.IUIItemSpec, parent: IContainerLike) {
		this.spec = itemSpec;
		if (parent) {
			this._parent = parent;
		}
	}

	protected create() {
		this._id = this.spec.id;
		this._type = this.spec.type;
		if (this.parentContainer) {
			this._placement = UIItemPlacement.createUIItemPlacement(this.parentContainer.layout, this.spec.placement);
		}
		if (this.spec.width) {
			this._width = this.spec.width;
		}
		if (this.spec.height) {
			this._height = this.spec.height;
		}
		if (this.spec.minWidth) {
			this._minWidth = this.spec.minWidth;
		}
		if (this.spec.maxWidth) {
			this._maxWidth = this.spec.maxWidth;
		}
		if (this.spec.margin) {
			this._margin = this.spec.margin;
		}
		if (this.spec.marginLeft) {
			this._marginLeft = this.spec.marginLeft;
		}
		if (this.spec.marginTop) {
			this._marginTop = this.spec.marginTop;
		}
		if (this.spec.marginRight) {
			this._marginRight = this.spec.marginRight;
		}
		if (this.spec.marginBottom) {
			this._marginBottom = this.spec.marginBottom;
		}
		if (this.spec.padding) {
			this._padding = this.spec.padding;
		}
		if (this.spec.paddingLeft) {
			this._paddingLeft = this.spec.paddingLeft;
		}
		if (this.spec.paddingTop) {
			this._paddingTop = this.spec.paddingTop;
		}
		if (this.spec.paddingRight) {
			this._paddingRight = this.spec.paddingRight;
		}
		if (this.spec.paddingBottom) {
			this._paddingBottom = this.spec.paddingBottom;
		}
		if (this.spec.pdClassContext) {
			this._pdClassContext = this.spec.pdClassContext;
		}
		if (this.spec.editContext) {
			this._editContext = this.spec.editContext;
		}
		if (this.spec.invalidEditContext) {
			this._invalidEditContext = this.spec.invalidEditContext;
		}
		if (this.spec.widgetInfo) {
			this._widgetInfo = WidgetInfo.createWidgetInfo(this.spec.widgetInfo, this._widget);
		}
		if (this.spec.disabled === true) {
			this._disabled = true;
		}
		if (this.spec.customData) {
			this._customData= this.spec.customData;
		}
	}

	getAncestorOfType(type: Layout.ItemTypeET): UIItemSpec {
		if (this.parentContainer) {
			if (this.parentContainer.type === type) {
				return this.parentContainer;
			}
			return this.parentContainer.getAncestorOfType(type);
		}
		return undefined;
	}

	get isContainerLike(): boolean {
		return false;
	}

	get id(): string {
		return this._id;
	}

	get type(): Layout.ItemTypeET {
		return this._type;
	}

	get placement(): UIItemPlacement {
		return this._placement;
	}

	get widget(): Layout.WidgetET {
		return this._widget;
	}

	get width(): string {
		return this._width;
	}

	get height(): string {
		return this._height;
	}

	get minWidth(): string {
		return this._minWidth;
	}

	get maxWidth(): string {
		return this._maxWidth;
	}

	get margin(): string {
		return this._margin;
	}

	get marginLeft(): string {
		return this._marginLeft;
	}

	get marginTop(): string {
		return this._marginTop;
	}

	get marginRight(): string {
		return this._marginRight;
	}

	get marginBottom(): string {
		return this._marginBottom;
	}

	get padding(): string {
		return this._padding;
	}

	get paddingLeft(): string {
		return this._paddingLeft;
	}

	get paddingTop(): string {
		return this._paddingTop;
	}

	get paddingRight(): string {
		return this._paddingRight;
	}

	get paddingBottom(): string {
		return this._paddingBottom;
	}

	get widgetInfo(): WidgetInfo {
		return this._widgetInfo;
	}

	get parentContainer(): UIContainerSpec | undefined {
		return this._parent.container instanceof UIContainerSpec ? <UIContainerSpec>(this._parent.container) : undefined; 
	}

	get rootForm(): UIFormSpec {
		if (this._parent.container instanceof UIFormSpec) {
			return <UIFormSpec>(this._parent.container)
		}
		return this.parentContainer.rootForm;
	}

	get pdClassContext(): string[] | undefined {
		return this._pdClassContext;
	}

	get editContext(): string[] | undefined {
		return this._editContext;
	}

	get invalidEditContext(): string[] | undefined {
		return this._invalidEditContext;
	}

	get disabled(): boolean {
		return this._disabled;
	}

	get customData(): any {
		return this._customData;
	}

	get mandatoryCustomActivated(): boolean {
		return this.spec.mandatoryCustomActivated === true;
	}

	get visible(): boolean {
		return this.spec.visible !== false;
	}
}

export class UIContentSpec extends UIItemSpec {

	private _template: string;

	constructor(uiContentSpec: Layout.IUIContentSpec, parent: IContainerLike) {
		super(uiContentSpec, parent);
		this.create();
	}

	protected create() {
		super.create();
		this._widget = Layout.WidgetET.ContentTemplate;
		if (this.uiContentSpec.template) {
			this._template = this.uiContentSpec.template;
		}
	}

	get uiContentSpec(): Layout.IUIContentSpec {
		return <Layout.IUIContentSpec>this.spec;
	}

	get template(): string {
		return this._template;
	}
}

export class PDItemSpec extends UIItemSpec {
	private _property: string;
	//private _mandatoryCustomActivated: boolean = false;

	constructor(pdItemSpec: Layout.IPDItemSpec, parent: IContainerLike) {
		super(pdItemSpec, parent);
		this.create();
	}

	protected create() {
		if (this.pdItemSpec.property) {
			this._property = this.pdItemSpec.property;
		}
		/*if (this.pdItemSpec.mandatoryCustomActivated) {
			this._mandatoryCustomActivated = true;
		}*/
		this._widget = this.pdItemSpec.widget;
		super.create();
	}

	get pdItemSpec(): Layout.IPDItemSpec {
		return <Layout.IPDItemSpec>this.spec;
	}

	get property(): string {
		return this._property;
	}

	/*get shortDescriptionFormat(): Layout.ShortDescritionFormatET {
		return this.pdItemSpec.shortDescriptionFormat ? this.pdItemSpec.shortDescriptionFormat : Layout.ShortDescritionFormatET.Text
	}
	
	get shortDescriptionStyle(): string | undefined {
		return this.pdItemSpec.shortDescriptionStyle;
	}*/

	/*get mandatoryCustomActivated(): boolean {
		return this._mandatoryCustomActivated;
	}*/
}

export class PDRadioButtonSpec extends PDItemSpec implements IContainer, IContainerLike {
	private _content: UIPanelSpec;

	constructor(radioButtonSpec: Layout.IPDRadioButtonSpec, parent: IContainerLike) {
		super(radioButtonSpec, parent);
		this.create();
	}

	protected create() {
		if (this.radioButtonSpec.widget !== WidgetET.RadioButton) {
			throw Error('Invalid widget.');
		}
		super.create();
		if (!!this.radioButtonSpec.content) {
			this._content = new UIPanelSpec(this.radioButtonSpec.content, this);
		}
	}

	get isContainerLike(): boolean {
		return !!this.content;
	}

	get layout(): LayoutSpec {
		return !this.content ? undefined : this.content.layout;
	}

	get container(): IContainer | UIFormSpec {
		return this;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		if (!!this.content) {
			list.push(this.content);
			let childs = this.content.allItems;
			list.push(...childs);
		}
		return list;
	}

	get radioButtonSpec(): Layout.IPDRadioButtonSpec {
		return <Layout.IPDRadioButtonSpec>this.spec;
	}

	get content(): UIPanelSpec {
		return this._content;
	}

	get childItemMargin(): string | undefined {
		return undefined;
	}

	get childItemMarginTop(): string | undefined {
		return undefined;
	}

	get childItemMarginRight(): string | undefined {
		return undefined;
	}

	get childItemMarginBottom(): string | undefined {
		return undefined;
	}

	get childItemMarginLeft(): string | undefined {
		return undefined;
	}

	get disableChildItemMarginsInContainer(): boolean {
		return false;
	}
}

// extends UIItemSpec
export abstract class UIContainerSpec extends UIItemSpec implements IContainer, IContainerLike {
	private _layout?: LayoutSpec;
	private _items: UIItemSpec[] = [];
	private _backgroundColor: string;
	/*private _childItemMargin: string;
	private _childItemMarginTop: string;
	private _childItemMarginRight: string;
	private _childItemMarginBottom: string;
	private _childItemMarginLeft: string;*/

	constructor(containerSpec: Layout.IUIContainerSpec, parent: IContainerLike) {
		super(containerSpec, parent);
	}

	protected create() {
		super.create();
		if (this.containerSpec.layout) {
			this._layout = LayoutSpec.createLayoutSpec(this.containerSpec.layout);
		}
		if (this.containerSpec.backgroundColor) {
			this._backgroundColor = this.containerSpec.backgroundColor;
		}
		/*if (this.containerSpec.childItemMargin) {
			this._childItemMargin = this.containerSpec.childItemMargin
		}*/
		if (this.containerSpec.items) {
			for (let item of this.containerSpec.items) {
				this._items.push(UIItemSpec.createUIItemSpec(item, this));
			}
		}
	}

	get isContainerLike(): boolean {
		return true;
	}

	get container(): IContainer | UIFormSpec {
		return this;
	}

	get containerSpec(): Layout.IUIContainerSpec {
		return <Layout.IUIContainerSpec>this.spec;
	}

	get layout(): LayoutSpec {
		return this._layout;
	}

	get items(): UIItemSpec[] {
		return this._items;
	}

	get backgroundColor(): string | undefined {
		return this._backgroundColor;
	}

	get borderRadius(): string | undefined {
		return this.containerSpec.borderRadius;
	}

	get childItemMargin(): string | undefined {
		if (this.containerSpec.childItemMargin) {
			return this.containerSpec.childItemMargin;
		}
		return this._parent.childItemMargin;
	}

	get childItemMarginTop(): string | undefined {
		if (this.containerSpec.childItemMarginTop) {
			return this.containerSpec.childItemMarginTop;
		}
		return this._parent.childItemMarginTop;
	}

	get childItemMarginRight(): string | undefined {
		if (this.containerSpec.childItemMarginRight) {
			return this.containerSpec.childItemMarginRight;
		}
		return this._parent.childItemMarginRight;
	}

	get childItemMarginBottom(): string | undefined {
		if (this.containerSpec.childItemMarginBottom) {
			return this.containerSpec.childItemMarginBottom;
		}
		return this._parent.childItemMarginBottom;
	}

	get childItemMarginLeft(): string | undefined {
		if (this.containerSpec.childItemMarginLeft) {
			return this.containerSpec.childItemMarginLeft;
		}
		return this._parent.childItemMarginLeft;
	}
	get disableChildItemMarginsInContainer(): boolean {
		if (this.containerSpec.disableChildItemMarginsInContainer === true) {
			return true;
		}
		return this._parent.disableChildItemMarginsInContainer;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		for (let item of this.items) {
			list.push(item);
			//if (item instanceof UIContainerSpec) {
			if (item.isContainerLike) {
				let childs = (<IContainerLike>(<any>item)).allItems;
				list.push(...childs);
			}
		}
		return list;
	}
}

export class UIPanelSpec extends UIContainerSpec {
	private _transparent = true;

	constructor(panelSpec: Layout.IUIPanelSpec, parent: IContainerLike) {
		super(panelSpec, parent);
		this._widget = Layout.WidgetET.Panel;
		this.create();

	}
	
	protected create() {
		super.create();
	}

	private get uiPanelSpec(): Layout.IUIPanelSpec {
		return <Layout.IUIPanelSpec>this.spec;
	}

	get transparent(): boolean {
		// TODO: Logik testen!!!
		return this.uiPanelSpec.transparent !== false;
	}
}

export class UIGroupboxSpec extends UIContainerSpec {
	private _content: UIPanelSpec;
	private _header: string;
	private _headerId: string;
	private _headerTemplateId: string;

	constructor(spec: Layout.IUIGroupboxSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = Layout.WidgetET.Groupbox;
		this.create();
	}

	protected create() {
		super.create();

		if (this.uiGroupboxSpec.items || this.uiGroupboxSpec.layout) {
			throw Error('Invalid GroupboxSpec.');
		}

		if (this.uiGroupboxSpec.header) {
			this._header = this.uiGroupboxSpec.header;
		}
		if (this.uiGroupboxSpec.headerId) {
			this._headerId = this.uiGroupboxSpec.headerId;
		}
		if (this.uiGroupboxSpec.headerTemplateId) {
			this._headerTemplateId = this.uiGroupboxSpec.headerTemplateId;
		}
		if (this.uiGroupboxSpec.content) {
			this._content = new UIPanelSpec(this.uiGroupboxSpec.content, this);
		}
	}

	private get uiGroupboxSpec(): Layout.IUIGroupboxSpec {
		return <Layout.IUIGroupboxSpec>this.spec;
	}

	get content(): UIPanelSpec {
		return this._content;
	}

	get header(): string {
		return this._header;
	}

	get headerId(): string {
		return this._headerId;
	}

	get headerTemplateId(): string {
		return this._headerTemplateId;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		if (this.content) {
			list.push(this.content);
			let childs = this.content.allItems;
			list.push(...childs);
		}
		return list;
	}
}

export class UIRadioButtonGroupSpec extends UIContainerSpec {
	private _dataType: Layout.RadioButtonGroupDataTypeET;
	private _content?: UIPanelSpec;
	private _header?: string;
	private _property?: string;

	constructor(spec: Layout.IUIRadioButtonGroupSpec, parent: IContainerLike) {
		super(spec, parent);

		if (spec.content) {
			for (let item of spec.content.items) {
				if ((item.type != Layout.ItemTypeET.PDItem && item.type != Layout.ItemTypeET.PDRadioButton) ||
					(<Layout.IPDRadioButtonSpec>item).widget != Layout.WidgetET.RadioButton) {
					throw Error('Invalid RadioButtonGroupSpec.');		
				}
			}
		}
		this._widget = Layout.WidgetET.RadioButtonGroup;
		this.create();
	}

	protected create() {
		super.create();

		if (this.uiRadioButtonGroupSpec.items || this.uiRadioButtonGroupSpec.layout) {
			throw Error('Invalid RadioButtonGroupSpec.');
		}

		this._dataType = this.uiRadioButtonGroupSpec.dataType;
		if (this.uiRadioButtonGroupSpec.header) {
			this._header = this.uiRadioButtonGroupSpec.header;
		}
		if (this.uiRadioButtonGroupSpec.property) {
			this._property = this.uiRadioButtonGroupSpec.property;
		}
		if (this.uiRadioButtonGroupSpec.content) {
			this._content = new UIPanelSpec(this.uiRadioButtonGroupSpec.content, this);
		}
	}

	private get uiRadioButtonGroupSpec(): Layout.IUIRadioButtonGroupSpec {
		return <Layout.IUIRadioButtonGroupSpec>this.spec;
	}

	get content(): UIPanelSpec {
		return this._content;
	}

	get header(): string {
		return this._header;
	}

	get property(): string {
		return this._property;
	}

	get dataType(): Layout.RadioButtonGroupDataTypeET {
		return this._dataType;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		if (this.content) {
			list.push(this.content);
			let childs = this.content.allItems;
			list.push(...childs);
		}
		return list;
	}

	get wrapLabel(): boolean {
		return this.uiRadioButtonGroupSpec.wrapLabel !== false;
	}
}

export class UIAccordionSpec extends UIContainerSpec {
	private _tabs: UIAccordionTabSpec[] = [];

	constructor(spec: Layout.IUIAccordionSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = Layout.WidgetET.Accordion;
		this.create();
	}

	protected create() {
		super.create();

		if (this.uiAccordionSpec.items || this.uiAccordionSpec.layout) {
			throw Error('Invalid UIAccordionSpec.');
		}

		if (this.uiAccordionSpec.tabs) {
			for (let tab of this.uiAccordionSpec.tabs) {
				this._tabs.push(new UIAccordionTabSpec(tab, this));
			}
		}
	}

	private get uiAccordionSpec(): Layout.IUIAccordionSpec {
		return <Layout.IUIAccordionSpec>this.spec;
	}

	get tabs(): UIAccordionTabSpec[] {
		return this._tabs;
	}
}

export class UIAccordionTabSpec extends UIContainerSpec {
	private _content?: UIPanelSpec;
	private _header?: string;

	constructor(spec: Layout.IUIAccordionTabSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = Layout.WidgetET.AccordionTab;
		this.create();
	}

	protected create() {
		super.create();

		if (this.uiAccordionTabSpec.items || this.uiAccordionTabSpec.layout) {
			throw Error('Invalid UIAccordionTabSpec.');
		}

		if (this.uiAccordionTabSpec.header) {
			this._header = this.uiAccordionTabSpec.header;
		}
		if (this.uiAccordionTabSpec.content) {
			this._content = new UIPanelSpec(this.uiAccordionTabSpec.content, this);
		}
	}

	private get uiAccordionTabSpec(): Layout.IUIAccordionTabSpec {
		return <Layout.IUIAccordionTabSpec>this.spec;
	}

	get content(): UIPanelSpec {
		return this._content;
	}

	get header(): string {
		return this._header;
	}
}

export class UITabViewSpec extends UIContainerSpec {
	private _panels: UITabPanelSpec[] = [];

	constructor(spec: Layout.IUITabViewSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = Layout.WidgetET.TabView;
		this.create();
	}

	protected create() {
		super.create();

		if (this.uiTabViewSpec.items || this.uiTabViewSpec.layout) {
			throw Error('Invalid UITabViewSpec.');
		}

		if (this.uiTabViewSpec.panels) {
			for (let panel of this.uiTabViewSpec.panels) {
				this.panels.push(new UITabPanelSpec(panel, this));
			}
		}
	}

	private get uiTabViewSpec(): Layout.IUITabViewSpec {
		return <Layout.IUITabViewSpec>this.spec;
	}

	get panels(): UITabPanelSpec[] {
		return this._panels;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		for (let panel of this.panels) {
			list.push(panel);
			let childs = panel.allItems;
			list.push(...childs);
		}
		return list;
	}
}

export class UITabPanelSpec extends UIContainerSpec {
	private _content?: UIPanelSpec;
	private _header?: string;
	private _headerId: string;

	constructor(spec: Layout.IUITabPanelSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = Layout.WidgetET.TabPanel;
		this.create();
	}

	protected create() {
		super.create();

		if (this.uiTabPanelSpec.items || this.uiTabPanelSpec.layout) {
			throw Error('Invalid UITabPanelSpec.');
		}

		if (this.uiTabPanelSpec.header) {
			this._header = this.uiTabPanelSpec.header;
		}
		if (this.uiTabPanelSpec.headerId) {
			this._headerId = this.uiTabPanelSpec.headerId;
		}
		if (this.uiTabPanelSpec.content) {
			this._content = new UIPanelSpec(this.uiTabPanelSpec.content, this);
		}
	}

	private get uiTabPanelSpec(): Layout.IUITabPanelSpec {
		return <Layout.IUITabPanelSpec>this.spec;
	}

	get content(): UIPanelSpec {
		return this._content;
	}

	get header(): string {
		return this._header;
	}

	get headerId(): string {
		return this._headerId;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		if (this.content) {
			list.push(this.content);
			let childs = this.content.allItems;
			list.push(...childs);
		}
		return list;
	}
}

export class UIDrawerItemSpec {

	get item(): IUIDrawerItem {
		return this._item;
	}

	get content(): UIPanelSpec {
		return this._content;
	}

	constructor(private _item: IUIDrawerItem, private _content: UIPanelSpec) {
	}
}

export class UIDrawerSpec extends UIContainerSpec {
	
	private _uiDrawerItemSpecs: UIDrawerItemSpec[] = [];

	constructor(spec: Layout.IUIDrawerSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = Layout.WidgetET.Drawer;
		this.create();
	}

	protected create() {
		super.create();

		for (let item of this.uiDrawerSpec.drawerItems) {
			this._uiDrawerItemSpecs.push(new UIDrawerItemSpec(item.item, new UIPanelSpec(item.content, this)));
		}
	}

	private get uiDrawerSpec(): Layout.IUIDrawerSpec {
		return <Layout.IUIDrawerSpec>this.spec;
	}

	get drawerItems(): UIDrawerItemSpec[] {
		return this._uiDrawerItemSpecs;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		for (let item of this._uiDrawerItemSpecs) {
			list.push(item.content);
			let childs = item.content.allItems;
			list.push(...childs);
		}
		return list;
	}

	get mode(): DrawerModeET | undefined {
		return this.uiDrawerSpec.mode;
	}

	get expandMode(): DrawerExpandModeT | undefined {
		return this.uiDrawerSpec.expandMode;
	}

	get position(): DrawerPositionT | undefined {
		return this.uiDrawerSpec.position;
	}

	get expanded(): boolean | undefined {
		return this.uiDrawerSpec.expanded;
	}

	get onItemSelected(): ((ctx: IEventProcessingContext, item: IUIDrawerItem) => void) | undefined {
		return this.uiDrawerSpec.onItemSelected;
	}
}

export class PDRelationTabSpec extends UIContainerSpec {
	private _content: UIPanelSpec;
	private _property: string;
	private _objCreator: new () => PDObject;

	constructor(spec: Layout.IPDRelationTabSpec, parent: IContainerLike) {
		super(spec, parent);
		this._widget = Layout.WidgetET.RelationTab;
		this.create();
	}

	protected create() {
		super.create();

		if (this.pdRelationTabSpec.items || this.pdRelationTabSpec.layout) {
			throw Error('Invalid PDRelationTabSpec.');
		}

		this._property = this.pdRelationTabSpec.property;

		if (this.pdRelationTabSpec.content) {
			this._content = new UIPanelSpec(this.pdRelationTabSpec.content, this);
		}
		if (this.pdRelationTabSpec.objCreator) {
			this._objCreator = this.pdRelationTabSpec.objCreator;
		}
	}

	private get pdRelationTabSpec(): Layout.IPDRelationTabSpec {
		return <Layout.IPDRelationTabSpec>this.spec;
	}

	get content(): UIPanelSpec | undefined {
		return this._content;
	}

	get property(): string {
		return this._property;
	}

	get objCreator(): new () => PDObject | undefined  {
		return this._objCreator;
	}

	get allItems(): UIItemSpec[] {
		let list: UIItemSpec[] = [];
		if (this.content) {
			list.push(this.content);
			let childs = this.content.allItems;
			list.push(...childs);
		}
		return list;
	}
}


//-------------------------------------------------------------------------------------------------
// Layout
//-------------------------------------------------------------------------------------------------

export abstract class LayoutSpec {
	static createLayoutSpec(layout: Layout.ILayoutSpec): LayoutSpec {
		switch (layout.type) {
			case Layout.LayoutTypeET.Grid:
				return new GridLayoutSpec(<Layout.IGridLayoutSpec>layout);
			case Layout.LayoutTypeET.Flex:
				return new FlexLayoutSpec(<Layout.IFlexLayoutSpec>layout);
		}
		throw Error(`Unknown layout type ${layout.type}`);
	}

	protected spec: Layout.ILayoutSpec;

	constructor(spec: Layout.ILayoutSpec) {
		this.spec = spec;
	}

	protected create() {
	}
}

export class GridLayoutSpec extends LayoutSpec {
	private _columns?: Layout.IColumnSpec[];
	private _rows?: Layout.IRowSpec[];

	constructor(spec: Layout.IGridLayoutSpec) {
		super(spec);
		this.create();
	}

	get gridLayoutSpec(): Layout.IGridLayoutSpec {
		return <Layout.IGridLayoutSpec>this.spec;
	}

	get columns(): Layout.IColumnSpec[] | undefined {
		return this._columns;
	}

	get rows(): Layout.IRowSpec[] | undefined {
		return this._rows;
	}

	protected create() {
		super.create();
		this._columns = this.gridLayoutSpec.columns;
		this._rows = this.gridLayoutSpec.rows;
	}
}

export class FlexLayoutSpec extends LayoutSpec {
	private _direction: Layout.FlexDirectionET = Layout.FlexDirectionET.Row;
	private _wrap: Layout.FlexWrapET | string;
	private _justifyContent: Layout.FlexJustifyContentET | string;
	private _alignItems: Layout.FlexAlignET | string;
	private _alignContent: Layout.FlexAlignContentET | string;
	//private _alignSelf: Layout.FlexAlignSelfET | string;
	//private _flex: Layout.FlexFlexET | string;

	constructor(spec: Layout.IFlexLayoutSpec) {
		super(spec);
		this.create();
	}

	get flexLayoutSpec(): Layout.IFlexLayoutSpec {
		return <Layout.IFlexLayoutSpec>this.spec;
	}

	protected create() {
		super.create();

		if (this.flexLayoutSpec.direction) {
			this._direction = this.flexLayoutSpec.direction;
		}
		if (this.flexLayoutSpec.wrap) {
			this._wrap = this.flexLayoutSpec.wrap;
		}
		if (this.flexLayoutSpec.justifyContent) {
			this._justifyContent = this.flexLayoutSpec.justifyContent;
		}
		if (this.flexLayoutSpec.alignItems) {
			this._alignItems = this.flexLayoutSpec.alignItems;
		}
		if (this.flexLayoutSpec.alignContent) {
			this._alignContent = this.flexLayoutSpec.alignContent;
		}
		/*if (this.flexLayoutSpec.alignSelf) {
			this._alignSelf = this.flexLayoutSpec.alignSelf;
		}
		if (this.flexLayoutSpec.flex) {
			this._flex = this.flexLayoutSpec.flex;
		}*/
	}

	get direction(): Layout.FlexDirectionET {
		return this._direction;
	}

	get wrap(): string {
		return this._wrap;
	}

	get justifyContent(): string {
		return this._justifyContent;
	}

	get alignItems(): string {
		return this._alignItems;
	}

	get alignContent(): string {
		return this._alignContent;
	}

	/*get alignSelf(): string {
		return this._alignSelf;
	}*/

	/*get flex(): string {
		return this._flex;
	}*/
}

export abstract class UIItemPlacement {
	static createUIItemPlacement(layout: LayoutSpec, placement: Layout.IUIItemPlacement): UIItemPlacement {
		if (layout instanceof GridLayoutSpec) {
			return new GridItemPlacement(<Layout.IGridItemPlacement>placement);
		}
		if (layout instanceof FlexLayoutSpec) {
			return new FlexItemPlacement(<Layout.IFlexItemPlacement>placement);
		}
	}

	protected placement: Layout.IUIItemPlacement;

	constructor(placement: Layout.IUIItemPlacement) {
		this.placement = placement;
	}

	protected create() {
	}
}

export class GridItemPlacement extends UIItemPlacement {
	private _row?: number;
	private _col?: number;
	private _colspan?: number;
	private _rowspan?: number;

	constructor(placement: Layout.IGridItemPlacement) {
		super(placement);
		this.create();
	}

	protected create() {
		super.create();

		if (this.gridItemPlacement) {
			if (this.gridItemPlacement.row) {
				this._row = this.gridItemPlacement.row;
			}
			if (this.gridItemPlacement.col) {
				this._col = this.gridItemPlacement.col;
			}
			if (this.gridItemPlacement.rowspan) {
				this._rowspan = this.gridItemPlacement.rowspan;
			}
			if (this.gridItemPlacement.colspan) {
				this._colspan = this.gridItemPlacement.colspan;
			}
		}
	}

	get gridItemPlacement(): Layout.IGridItemPlacement {
		return <Layout.IGridItemPlacement>this.placement;
	}

	get row(): number {
		return this._row;
	}

	get col(): number {
		return this._col;
	}

	get colspan(): number {
		return this._colspan;
	}

	get rowspan(): number {
		return this._rowspan;
	}
}

export class FlexItemPlacement extends UIItemPlacement {
	private _flex: string;
	private _align: Layout.FlexAlignET;

	constructor(placement: Layout.IFlexItemPlacement) {
		super(placement);
		this.create();
	}

	protected create() {
		super.create();

		if (this.flexItemPlacement) {
			if (this.flexItemPlacement.flex) {
				this._flex = this.flexItemPlacement.flex;
			}
			if (this.flexItemPlacement.align) {
				this._align = this.flexItemPlacement.align;
			}
		}
	}

	private get flexItemPlacement(): Layout.IFlexItemPlacement {
		return <Layout.IFlexItemPlacement>this.placement;
	}

	get flex(): string {
		return this._flex;
	}

	get align(): string {
		return this._align;
	}
}


//-------------------------------------------------------------------------------------------------
// Widgets
//-------------------------------------------------------------------------------------------------

export abstract class WidgetInfo {
	static createWidgetInfo(spec: Layout.IWidgetInfo, type: Layout.WidgetET): WidgetInfo {
		switch (type) {
			case Layout.WidgetET.RadioButton:
				return new RadioButtonWidgetInfo(<Layout.IRadioButtonWidgetInfo>spec);
			case Layout.WidgetET.DateTimeField:
				return new DateTimeFieldWidgetInfo(<Layout.IDateTimeFieldWidgetInfo>spec);
			case Layout.WidgetET.TextField:
				return new TextFieldWidgetInfo(<Layout.ITextFieldWidgetInfo>spec);
			case Layout.WidgetET.NumericTextField:
				return new NumericTextFieldWidgetInfo(<Layout.INumericTextFieldWidgetInfo>spec);
			case Layout.WidgetET.InfoField:
				return new InfoFieldWidgetInfo(<Layout.IInfoFieldWidgetInfo>spec);
			case Layout.WidgetET.DropDown:
				return new DropDownWidgetInfo(<Layout.IDropDownWidgetInfo>spec);
			case Layout.WidgetET.ComboBox:
				return new ComboBoxWidgetInfo(<Layout.IComboBoxWidgetInfo>spec);
			case Layout.WidgetET.InputSwitch:
				return new InputSwitchWidgetInfo(<Layout.IInputSwitchWidgetInfo>spec);
			case Layout.WidgetET.ObjectReference:
				return new ObjectReferenceWidgetInfo(<Layout.IObjectReferenceWidgetInfo>spec);
			case Layout.WidgetET.RelationTab:
				return new RelationTabWidgetInfo(<Layout.IRelationTabWidgetInfo>spec);
			case Layout.WidgetET.MultiSelect:
				return new MultiSelectRelationWidgetInfo(<Layout.IMultiSelectRelationWidgetInfo>spec);
			//case Layout.WidgetET.RelationList:
			//	return new RelationListWidgetInfo(<Layout.IRelationListWidgetInfo<T>>spec);
			case Layout.WidgetET.Accordion:
				return new AccordionWidgetInfo(<Layout.IAccordionWidgetInfo>spec);
			case Layout.WidgetET.Label:
				return new LabelWidgetInfo(<Layout.ILabelWidgetInfo>spec);
			case Layout.WidgetET.Button:
				return new ButtonWidgetInfo(<Layout.IButtonWidgetInfo>spec);
			case Layout.WidgetET.Checkbox:
				return new CheckboxWidgetInfo(<Layout.ICheckboxWidgetInfo>spec);
			case Layout.WidgetET.FileUpload:
				return new FileUploadWidgetInfo(<Layout.IFileUploadWidgetInfo>spec);
			default:
				throw Error(`Unknown widget info for type ${type}`);
		}
	}

	private _guiReadonly: boolean = false;
	protected widgetInfo: Layout.IWidgetInfo;

	constructor(widgetInfo: Layout.IWidgetInfo) {
		this.widgetInfo = widgetInfo;
	}

	protected create() {
		if (this.widgetInfo.guiReadonly) {
			this._guiReadonly = this.widgetInfo.guiReadonly;
		}
	}

	get guiReadonly(): boolean {
		return this._guiReadonly;
	}
}

export class LabelWidgetInfo extends WidgetInfo {
	private _label: string;
	private _labelId: string;
	
	constructor(widgetInfo: Layout.ILabelWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
		
		if (this.labelWidgetInfo.label) {
			this._label = this.labelWidgetInfo.label;
		}
		if (this.labelWidgetInfo.labelId) {
			this._labelId = this.labelWidgetInfo.labelId;
		}
	}

	private get labelWidgetInfo(): Layout.ILabelWidgetInfo {
		return <Layout.ILabelWidgetInfo>this.widgetInfo;
	}

	get label(): string | undefined {
		return this._label;
	}

	get labelId(): string | undefined {
		return this._labelId;
	}

	get styleSpec(): Layout.IStyleSpec | undefined {
		return this.labelWidgetInfo.styleSpec;
	}
}

export class ButtonWidgetInfo extends WidgetInfo {
	private _label: string;
	private _labelId: string;
	private _iconClass: string;

	constructor(widgetInfo: Layout.IButtonWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();

		if (this.buttonWidgetInfo.label) {
			this._label = this.buttonWidgetInfo.label;
		}
		if (this.buttonWidgetInfo.labelId) {
			this._labelId = this.buttonWidgetInfo.labelId;
		}
		if (this.buttonWidgetInfo.iconClass) {
			this._iconClass = this.buttonWidgetInfo.iconClass;
		}
	}

	private get buttonWidgetInfo(): Layout.IButtonWidgetInfo {
		return <Layout.IButtonWidgetInfo>this.widgetInfo;
	}

	get label(): string | undefined {
		return this._label;
	}

	get labelId(): string | undefined {
		return this._labelId;
	}

	get iconClass(): string | undefined {
		return this._iconClass;
	}
}

export class CheckboxWidgetInfo extends WidgetInfo {
	constructor(widgetInfo: Layout.ICheckboxWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	private get checkboxWidgetInfo(): Layout.ICheckboxWidgetInfo {
		return <Layout.ICheckboxWidgetInfo>this.widgetInfo;
	}

	get requiredTrue(): boolean {
		return this.checkboxWidgetInfo.requiredTrue === true;
	}
}

export class RadioButtonWidgetInfo extends WidgetInfo {
	private _value: number;
	private _label: string;
	private _labelId: string;
	private _bulletLocation: Layout.RadioButtonBulletLocationET;
	
	constructor(widgetInfo: Layout.IRadioButtonWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
		this._value = this.radioButtonWidgetInfo.value;
		if (this.radioButtonWidgetInfo.label) {
			this._label = this.radioButtonWidgetInfo.label;
		}
		if (this.radioButtonWidgetInfo.labelId) {
			this._labelId = this.radioButtonWidgetInfo.labelId;
		}
		if (this.radioButtonWidgetInfo.bulletLocation !== undefined) {
			this._bulletLocation = this.radioButtonWidgetInfo.bulletLocation;
		}
	}

	private get radioButtonWidgetInfo(): Layout.IRadioButtonWidgetInfo {
		return <Layout.IRadioButtonWidgetInfo>this.widgetInfo;
	}

	get value(): number {
		return this._value;
	}

	get label(): string | undefined {
		return this._label;
	}

	get labelId(): string | undefined {
		return this._labelId;
	}

	get bulletLocation(): Layout.RadioButtonBulletLocationET | undefined {
		return this._bulletLocation;
	}
}

export abstract class LabeledControlWidgetInfo extends WidgetInfo {
	private _prefix?: string;
	private _prefixWidth?: string;
	private _labelVisibility: VisibilityET = VisibilityET.Visible;
	private _labelPosition: LabelPositionET = LabelPositionET.Top;
	private _toolbar: ToolbarSpec;

	constructor(widgetInfo: Layout.ILabeledControlWidgetInfo) {
		super(widgetInfo);
	}

	protected create() {
		super.create();
		if (this.labeledControlWidgetInfo.prefix) {
			this._prefix = this.labeledControlWidgetInfo.prefix;
		}
		if (this.labeledControlWidgetInfo.prefixWidth) {
			this._prefixWidth = this.labeledControlWidgetInfo.prefixWidth;
		}
		if (this.labeledControlWidgetInfo.labelVisibility) {
			this._labelVisibility = this.labeledControlWidgetInfo.labelVisibility;
		}
		if (this.labeledControlWidgetInfo.labelPosition) {
			this._labelPosition = this.labeledControlWidgetInfo.labelPosition;
		}
		if (this.labeledControlWidgetInfo.toolbar) {
			this._toolbar = new ToolbarSpec(this.labeledControlWidgetInfo.toolbar);
		}
	}

	get labeledControlWidgetInfo(): Layout.ILabeledControlWidgetInfo {
		return <Layout.ILabeledControlWidgetInfo>this.widgetInfo;
	}

	get prefix(): string | undefined {
		return this._prefix;
	}

	get prefixWidth(): string | undefined {
		return this._prefixWidth;
	}

	get labelVisibility(): VisibilityET {
		return this._labelVisibility;
	}

	get labelPosition(): LabelPositionET {
		return this._labelPosition;
	}

	get toolbar(): ToolbarSpec | undefined {
		return this._toolbar;
	}

	get wrapLabel(): boolean {
		return this.labeledControlWidgetInfo.wrapLabel !== false;
	}

	get customLabel(): string | undefined {
		return this.labeledControlWidgetInfo.customLabel;
	}

	get customLabelId(): string | undefined {
		return this.labeledControlWidgetInfo.customLabelId;
	}

	get useCustomLabel(): boolean {
		return this.labeledControlWidgetInfo.useCustomLabel !== false;
	}
}

export class TextFieldWidgetInfo extends LabeledControlWidgetInfo {
	private _multiline: boolean = false;
	private _rows?: number;
	private _stretch: boolean = false;

	constructor(widgetInfo: Layout.ITextFieldWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
		if (this.textFieldWidgetInfo.rows) {
			this._rows = this.textFieldWidgetInfo.rows;
		}
		if (this.textFieldWidgetInfo.multiline) {
			this._multiline = this.textFieldWidgetInfo.multiline;
		}
		if (this.textFieldWidgetInfo.stretch) {
			this._stretch = this.textFieldWidgetInfo.stretch;
		}
	}

	get textFieldWidgetInfo(): Layout.ITextFieldWidgetInfo {
		return <Layout.ITextFieldWidgetInfo>this.widgetInfo;
	}

	get rows(): number | undefined {
		return this._rows;
	}

	get multiline(): boolean {
		return this._multiline;
	}

	get stretch(): boolean {
		return this._stretch;
	}
}

export class NumericTextFieldWidgetInfo extends LabeledControlWidgetInfo {
	private _step: number;
	private _decimals: number;
	private _min: number;
	private _max: number;
	private _showSpinners: boolean = true;
	private _format: string | any;

	constructor(widgetInfo: Layout.INumericTextFieldWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
		if (this.numericTextFieldWidgetInfo.step) {
			this._step = this.numericTextFieldWidgetInfo.step;
		}
		if (this.numericTextFieldWidgetInfo.decimals) {
			this._decimals = this.numericTextFieldWidgetInfo.decimals;
		}
		if (this.numericTextFieldWidgetInfo.min) {
			this._min = this.numericTextFieldWidgetInfo.min;
		}
		if (this.numericTextFieldWidgetInfo.max) {
			this._max = this.numericTextFieldWidgetInfo.max;
		}
		if (this.numericTextFieldWidgetInfo.showSpinners === true || this.numericTextFieldWidgetInfo.showSpinners === false) {
			this._showSpinners = this.numericTextFieldWidgetInfo.showSpinners;
		}
		if (this.numericTextFieldWidgetInfo.format) {
			this._format = this.numericTextFieldWidgetInfo.format;
		}
	}

	get numericTextFieldWidgetInfo(): Layout.INumericTextFieldWidgetInfo {
		return <Layout.INumericTextFieldWidgetInfo>this.widgetInfo;
	}

	get step(): number | undefined {
		return this._step;
	}

	get decimals(): number | undefined {
		return this._decimals;
	}

	get min(): number | undefined {
		return this._min;
	}

	get max(): number | undefined {
		return this._max;
	}

	get showSpinners(): boolean {
		return this._showSpinners;
	}

	get format(): string | any | undefined {
		return this._format;
	}
}

export class DateTimeFieldWidgetInfo extends LabeledControlWidgetInfo {
	constructor(widgetInfo: Layout.IDateTimeFieldWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	get dateTimeFieldWidgetInfo(): Layout.IDateTimeFieldWidgetInfo {
		return <Layout.IDateTimeFieldWidgetInfo>this.widgetInfo;
	}

	get showNavigationBar(): boolean | undefined {
		return this.dateTimeFieldWidgetInfo.showNavigationBar;
	}
}

export class DropDownWidgetInfo extends LabeledControlWidgetInfo {
	constructor(widgetInfo: Layout.IDropDownWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	get dropDownWidgetInfo(): Layout.IDropDownWidgetInfo {
		return <Layout.IDropDownWidgetInfo>this.widgetInfo;
	}
}

export class ComboBoxWidgetInfo extends LabeledControlWidgetInfo {
	private _active: boolean = true;
	private _displayTemplate: string;
	private _valueTemplate: string;
	private _className: string;
	private _choiceFilterExpr: string;
	private _choiceSortExpr: string;

	constructor(widgetInfo: Layout.IComboBoxWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();

		if (this.comboBoxWidgetInfo.active !== undefined) {
			this._active = this.comboBoxWidgetInfo.active;
		}
		if (this.comboBoxWidgetInfo.displayTemplate) {
			this._displayTemplate = this.comboBoxWidgetInfo.displayTemplate;
		}
		if (this.comboBoxWidgetInfo.valueTemplate) {
			this._valueTemplate = this.comboBoxWidgetInfo.valueTemplate;
		}
		if (this.comboBoxWidgetInfo.className) {
			this._className = this.comboBoxWidgetInfo.className;
		}
		if (this.comboBoxWidgetInfo.choiceFilterExpr) {
			this._choiceFilterExpr = this.comboBoxWidgetInfo.choiceFilterExpr;
		}
		if (this.comboBoxWidgetInfo.choiceSortExpr) {
			this._choiceSortExpr = this.comboBoxWidgetInfo.choiceSortExpr;
		}
	}

	private get comboBoxWidgetInfo(): Layout.IComboBoxWidgetInfo {
		return <Layout.IComboBoxWidgetInfo>this.widgetInfo;
	}

	get active(): boolean {
		return this._active;
	}

	get className(): string | undefined {
		return this._className;
	}

	get valueTemplate(): string | undefined {
		return this._valueTemplate;
	}

	get displayTemplate(): string | undefined {
		return this._displayTemplate;
	}

	get choiceFilterExpr(): string | undefined {
		return this._choiceFilterExpr;
	}

	get choiceSortExpr(): string | undefined {
		return this._choiceSortExpr;
	}
}

export class InputSwitchWidgetInfo extends LabeledControlWidgetInfo {
	constructor(widgetInfo: Layout.IInputSwitchWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	private get inputSwitchWidgetInfo(): Layout.IInputSwitchWidgetInfo {
		return <Layout.IInputSwitchWidgetInfo>this.widgetInfo;
	}
}

export class FileUploadWidgetInfo extends LabeledControlWidgetInfo {
	constructor(widgetInfo: Layout.IFileUploadWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	private get fileUploadWidgetInfo(): Layout.IFileUploadWidgetInfo {
		return <Layout.IFileUploadWidgetInfo>this.widgetInfo;
	}

	get maxFiles(): number | undefined {
		return this.fileUploadWidgetInfo.maxFiles;
	}

	get allowedFileTypes(): string[] | undefined {
		return this.fileUploadWidgetInfo.allowedFileTypes && this.fileUploadWidgetInfo.allowedFileTypes.length > 0 ?
			this.fileUploadWidgetInfo.allowedFileTypes : undefined;
	}
}

export abstract class RelationControlWidgetInfo extends LabeledControlWidgetInfo {
	private _active: boolean = true;
	private _newAction: boolean = false;
	private _editAction: boolean = false;
	private _deleteAction: boolean = false;
	private _selectAction: boolean = false;
	private _disconnectAction: boolean = false;

	constructor(widgetInfo: Layout.IRelationControlWidgetInfo) {
		super(widgetInfo);
	}

	protected create() {
		super.create();
		if (this.relationControlWidgetInfo.active != undefined) {
			this._active = this.relationControlWidgetInfo.active;
		}
		if (this.relationControlWidgetInfo.newAction) {
			this._newAction = this.relationControlWidgetInfo.newAction;
		}
		if (this.relationControlWidgetInfo.editAction) {
			this._editAction = this.relationControlWidgetInfo.editAction;
		}
		if (this.relationControlWidgetInfo.deleteAction) {
			this._deleteAction = this.relationControlWidgetInfo.deleteAction;
		}
		if (this.relationControlWidgetInfo.selectAction) {
			this._selectAction = this.relationControlWidgetInfo.selectAction;
		}
		if (this.relationControlWidgetInfo.disconnectAction) {
			this._disconnectAction = this.relationControlWidgetInfo.disconnectAction;
		}
	}

	private get relationControlWidgetInfo(): Layout.IRelationControlWidgetInfo {
		return <Layout.IRelationControlWidgetInfo>this.widgetInfo;
	}

	get active(): boolean {
		return this._active;
	}

	get newAction(): boolean {
		return this._newAction;
	}

	get editAction(): boolean {
		return this._editAction;
	}

	get deleteAction(): boolean {
		return this._deleteAction;
	}

	get selectAction(): boolean {
		return this._selectAction;
	}

	get disconnectAction(): boolean {
		return this._disconnectAction;
	}

	get editingSpec(): Layout.PDObjectEditingSpec | undefined {
		return this.relationControlWidgetInfo.editingSpec;
	}
}

/*export class RelationListWidgetInfo<T extends PDObject> extends RelationControlWidgetInfo {
	private _objCreator: new () => T;

	constructor(widgetInfo: Layout.IRelationListWidgetInfo<T>) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
		this._objCreator = this.relationListWidgetInfo.objCreator;
	}

	get relationListWidgetInfo(): Layout.IRelationListWidgetInfo<T> {
		return <Layout.IRelationListWidgetInfo<T>>this.widgetInfo;
	}

	get objCreator(): new () => T {
		return this._objCreator;
	}
}*/

export class ObjectReferenceWidgetInfo extends RelationControlWidgetInfo {
	private _noSelectionItemText: string;

	constructor(widgetInfo: Layout.IObjectReferenceWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();

		if (this.objectReferenceWidgetInfo.noSelectionItemText) {
			this._noSelectionItemText = this.objectReferenceWidgetInfo.noSelectionItemText;
		}
	}

	private get objectReferenceWidgetInfo(): Layout.IObjectReferenceWidgetInfo {
		return <Layout.IObjectReferenceWidgetInfo>this.widgetInfo;
	}

	get choiceSpec(): Layout.IPDChoiceSpec | Layout.IPDChoiceSpec[] | undefined {
		return this.objectReferenceWidgetInfo.choiceSpec;
	}

	get noSelectionItemText(): string | undefined {
		return this._noSelectionItemText;
	}

	get filteringEnabled(): boolean {
		return this.objectReferenceWidgetInfo.filteringEnabled === true;
	}

	get type(): Layout.ObjectReferenceTypeET {
		return this.objectReferenceWidgetInfo.type ? this.objectReferenceWidgetInfo.type : Layout.ObjectReferenceTypeET.DropDownList;
	}

	get selectionListSpec(): Layout.IPDObjectSelectionListSpec {
		return this.objectReferenceWidgetInfo.selectionListSpec;
	}
}

export class RelationTabWidgetInfo extends RelationControlWidgetInfo {
	private _relationInfo: string;

	private _tabPanelStyle: ICssStyle;

	constructor(widgetInfo: Layout.IRelationTabWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
		//this._relationInfo = this.relationTabWidgetInfo.relationInfo;
		if (this.relationTabWidgetInfo.tabPanelStyle) {
			this._tabPanelStyle = this.relationTabWidgetInfo.tabPanelStyle;
		}
	}

	private get relationTabWidgetInfo(): Layout.IRelationTabWidgetInfo {
		return <Layout.IRelationTabWidgetInfo>this.widgetInfo;
	}

	/*get relationInfo(): string {
		return this._relationInfo;
	}*/

	get tabPanelStyle(): ICssStyle {
		return this._tabPanelStyle;
	}

	get maxTabCount(): number | undefined {
		if (Number.isInteger(this.relationTabWidgetInfo.maxTabCount) && this.relationTabWidgetInfo.maxTabCount > 0) {
			return this.relationTabWidgetInfo.maxTabCount;
		}
		return undefined;
	}

	get tabHeaderProvider(): ((tabIndex: number, relObj: PDObject, localizationService: ILocalizationService) =>Layout.IRelationTabHeader) | undefined {
		return this.relationTabWidgetInfo.tabHeaderProvider;
	}

	get canCreateTab(): boolean {
		return this.relationTabWidgetInfo.canCreateTab !== false;
	}

	get canDeleteTab(): boolean {
		return this.relationTabWidgetInfo.canDeleteTab !== false;
	}
}

export class MultiSelectRelationWidgetInfo extends RelationControlWidgetInfo {
	constructor(widgetInfo: Layout.IMultiSelectRelationWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
	}

	private get multiSelectRelationWidgetInfo(): Layout.IMultiSelectRelationWidgetInfo {
		return <Layout.IMultiSelectRelationWidgetInfo>this.widgetInfo;
	}

	get choiceSpec(): Layout.IPDChoiceSpec | Layout.IPDChoiceSpec[] | undefined {
		return this.multiSelectRelationWidgetInfo.choiceSpec;
	}

	get type(): Layout.MultiSelectRelationWidgetTypeET {
		return this.multiSelectRelationWidgetInfo.type ? this.multiSelectRelationWidgetInfo.type : Layout.MultiSelectRelationWidgetTypeET.DropDownList;
	}

	get selectionListSpec(): Layout.IPDObjectSelectionListSpec {
		return this.multiSelectRelationWidgetInfo.selectionListSpec;
	}
}

//
export class AccordionWidgetInfo extends WidgetInfo {
	private _multiple: boolean = false;

	constructor(widgetInfo: Layout.IAccordionWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();

		if (this.accordionWidgetInfo.multiple) {
			this._multiple = this.accordionWidgetInfo.multiple;
		}
	}

	private get accordionWidgetInfo(): Layout.IAccordionWidgetInfo {
		return <Layout.IAccordionWidgetInfo>this.widgetInfo;
	}

	get multiple(): boolean {
		return this._multiple;
	}
}

export class InfoFieldWidgetInfo extends LabeledControlWidgetInfo {
	private _template: string;

	constructor(widgetInfo: Layout.IInfoFieldWidgetInfo) {
		super(widgetInfo);
		this.create();
	}

	protected create() {
		super.create();
		if (this.inputFielhWidgetInfo.template) {
			this._template = this.inputFielhWidgetInfo.template;
		}
	}

	private get inputFielhWidgetInfo(): Layout.IInfoFieldWidgetInfo {
		return <Layout.IInfoFieldWidgetInfo>this.widgetInfo;
	}

	get template(): string {
		return this._template;
	}
}

