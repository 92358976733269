import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
//import { SafeStyle } from '@angular/platform-browser';
import { PDObject, ShortDescriptionFormatET, IPDComponent } from '@otris/ng-core-shared';

// [style]="style | safeStyle"
@Component({
	selector: 'otris-pd-short-description',
	template: `
		<!--<ng-template #template let-anchor>
			<div class="otris-pd-short-description-tooltip-container">{{ shortDescription }}</div>
		</ng-template>-->

		<ng-template #template let-anchor>
			<ng-container [ngSwitch]="format | async">
				<div *ngSwitchCase="shortDescriptionFormatET.Html" class="otris-pd-short-description-tooltip-container"
					[innerHtml]="pdComponent.shortDescription | safeHtml">
				</div>
				<div *ngSwitchCase="shortDescriptionFormatET.Text" class="otris-pd-short-description-tooltip-container">{{ pdComponent.shortDescription }}</div>
			</ng-container>
		</ng-template>

		<div class="root-container" kendoTooltip tooltipClass="otris-tooltip" [tooltipTemplate]="template" filter="span" [style.color]="imageColor">
			<span class="image fa fa-fw fa-info-circle fa-lg"></span>
		</div>
	`,
	styles: [`
		:host {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.root-container {
			margin-left: 0.5em;
		}
		.image {
			cursor: help;
		}
		/*.image:hover { 
			opacity: 1;
		}*/
	`]
})
export class PDShortDescriptionComponent {

	//@Input() shortDescription: string;

	@Input() pdComponent: IPDComponent;

	@Input() highlighted: boolean = false;

	@Input() pdObject: PDObject;

	shortDescriptionFormatET = ShortDescriptionFormatET;

	//@Input() format: ShortDescritionFormatET;

	//@Input() style: string;

	/*get safeStyle(): SafeStyle {
		if (this.format !== ShortDescritionFormatET.Html || !this.style) {
			return undefined;
		}
		let safeStylePipe = <SafeStylePipe>ServiceLocator.injector.get(SafeStylePipe);
		return safeStylePipe.transform(this.style);
	}*/

	get imageColor(): string {
		return this.highlighted ? 'dodgerblue' : 'lightgray';
	}

	get format(): Observable<ShortDescriptionFormatET> {
		return this.pdObject.metaData.getShortDescriptionFormat(this.pdComponent.propertyName);
	}

	constructor() {
	}

}
