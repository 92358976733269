import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UITemplateDirective } from './directives/ui-template.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeStylePipe } from './pipes/safe-style.pipe';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		UITemplateDirective,
		SafeHtmlPipe,
		SafeStylePipe
	],
	exports: [
		UITemplateDirective,
		SafeHtmlPipe,
		SafeStylePipe
	],
	providers: [
		SafeStylePipe
	]
})
export class OtrisNgCoreSharedModule { }
