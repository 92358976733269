import { Input, Component, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PDObject, ComponentTypeET, IFormStatus } from '@otris/ng-core-shared';
import { UIAbstractComponent } from '../ui-abstract/ui-abstract.component';
import { UIContentSpec } from '../../model/pd-layout';
import { FormHandlerService } from '../../services/form-handler.service';

@Component({
	selector: 'otris-ui-content',
	template: `
		<ng-container *ngTemplateOutlet="template"></ng-container>
	`,
	host: {'class': 'otris-ui-content'},
	styles: []
})
export class UIContentComponent extends UIAbstractComponent {
	@Input() pdObject: PDObject;

	get template(): TemplateRef<any> {
		let spec = this.uiContentSpec;
		return spec ? this.formHandler.getUITemplate(spec.template) : undefined;
	}

	get uiContentSpec(): UIContentSpec {
		if (this.uiItemSpec instanceof UIContentSpec) {
			return (<UIContentSpec>this.uiItemSpec);
		}
		return undefined;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	getFormControlStatus(): IFormStatus {
		return <IFormStatus>{ pristine: true, touched: false, valid: true };
	}

	//
	// IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.Undefined;
	}

	get isEnabled(): boolean {
		return true;
	}

	get isDisabled(): boolean {
		return false;
	}

	disable(flag: boolean): void {}

	reset(): void {}
}
