import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { UIAbstractComponent, ButtonWidgetInfo, FormHandlerService } from '@otris/ng-core';
import { ComponentTypeET, PDObject, IFormStatus } from '@otris/ng-core-shared';

@Component({
	selector: 'otris-pd-button',
	template: `
		<button kendoButton [primary]="primary" [type]="type" [iconClass]="iconClass" [disabled]="!isEnabled"
			(click)="onClick(id)" class="button">{{label}}</button>
	`,
	styles: [`
		.button {
			width: 100%;
			height: 100%;
		}
	`]
})
export class PDButtonComponent extends UIAbstractComponent implements OnInit {
	//@Input() pdObject: PDObject;

	@Input() label: string;

	@Input() primary: boolean = false;

	@Input()
	set pdObject(val: PDObject) {
		if (val && val != this._pdObject) {
			this.onPDObjectChanging();
			this._pdObject = val;
			this.onPDObjectChanged();
		}
	}

	get pdObject(): PDObject {
		return this._pdObject;
	}

	private _pdObject: PDObject;

	/*@Input()
	set label(val: string) {
		this._label = 
	}

	get label(): string {
		return this._label;
	}

	private _label: string;*/

	@Input() iconClass: string;

	@Input() type: string = "button";

	//@Output() buttonClick = new EventEmitter<string>();

	get buttonWidgetInfo(): ButtonWidgetInfo {
		if (this.widgetInfo instanceof ButtonWidgetInfo) {
			return <ButtonWidgetInfo>this.widgetInfo;
		}
		return undefined;
	}

	private _click: Subject<void> = new Subject();

	get click(): Observable<void> {
		return this._click.asObservable();
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();
		let wi = this.buttonWidgetInfo;
		if (wi) {
			if (wi.iconClass) {
				this.iconClass = wi.iconClass;
			}

			/*if (wi.labelId && this.pdObject) {
				this.label = this.pdObject.metaData.getStringFromId(wi.labelId);
			}
			else if (wi.label) {
				this.label= wi.label;
			}*/
		}
	}

	getFormControlStatus(): IFormStatus {
		return <IFormStatus>{ pristine: true, touched: false, valid: true };
	}

	onClick(id: string): void {
		//this.buttonClick.emit(id);
		this._click.next();
	}

	protected onPDObjectChanging() {
	}

	protected onPDObjectChanged() {
		if (!this._pdObject) {
			return;
		}
		let wi = this.buttonWidgetInfo;
		if (wi) {
			if (wi.labelId && this.pdObject) {
				this.pdObject.metaData.getStringFromId(wi.labelId).subscribe(res => this.label = res);
			}
			else if (wi.label) {
				this.label = wi.label;
			}
		}
		//this._pdObject.metaData.getPropertyErgname(this.propertyName).subscribe(res => this._label = res);
		//this._pdObject.metaData.getShortDescription(this.propertyName).subscribe(res => this._shortDescription = res);
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.Undefined;
	}

	get isEnabled(): boolean {
		return !this._disabled;
	}

	get isDisabled(): boolean {
		return this._disabled;
	}

	private _disabled: boolean = false;

	disable(flag: boolean): void {
		this._disabled = flag;
	}

	reset(): void {
	}
}
