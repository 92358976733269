import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { IRadioButtonComponent, IEnumerationItem } from '@otris/ng-core-shared';

@Injectable()
export class PDRadioButtonGroupService {

	get radioButtons(): IRadioButtonComponent[] {
		return this._radioButtons;
	}

	private _radioButtons: IRadioButtonComponent[] = [];
	
	private _selectionChanged: Subject<IRadioButtonComponent> = new Subject();

	get selectionChanged(): Observable<IRadioButtonComponent> {
		return this._selectionChanged.asObservable();
	}

	private _groupName: string;

	get groupName(): string {
		return this._groupName;
	}

	set groupName(name: string) {
		this._groupName = name;
	}

	setSelection(sel: IRadioButtonComponent | undefined) {
		if (sel) {
			if (this._radioButtons.includes(sel)) {
				sel.checked = true;
			}
		}
		else {
			this._radioButtons.forEach(rb => rb.checked = false);
		}
	}
		
	setSelectionFromValue(sel: IEnumerationItem | boolean | undefined) {
		this.setSelection(this._radioButtons.find(rb => rb.value === sel));
	}

	registerRadioButton(radioButton: IRadioButtonComponent) {
		// todo: prüfen, ob unsubscribe notwendig. Wohl aber eher nicht, weil Child-Component
		this._radioButtons.push(radioButton);
		radioButton.checkedChanged.subscribe(rb => {
			//this._radioButtons.filter(rb2 => rb2.id != rb.id).forEach(rb => rb.onUncheck());

			// falls ein RadioButton selektiert wurde alle anderen deselektieren
			if (rb.checked) {
				this._radioButtons.filter(r => r.id != rb.id).forEach(r => r.checked = false);
			}
			this._selectionChanged.next(rb)
		});
	}
}
