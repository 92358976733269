import { Input, Component, ContentChildren, QueryList, HostBinding } from '@angular/core';
import { AppBarItemComponent, AppBarItemTypeET } from './app-bar-item.component';
//import { , AppBarItemTypeET } from './app-bar-item.directive';

@Component({
	selector: 'otris-app-bar',
	template: `
		<kendo-appbar position="top" [themeColor]="themeColor">

			<ng-container *ngFor="let item of appBarItems">
				<ng-container [ngSwitch]="item.itemType">
					<kendo-appbar-spacer *ngSwitchCase="appBarItemTypeET.Spacer">
					</kendo-appbar-spacer>
					<span *ngSwitchCase="appBarItemTypeET.Separator" class="k-appbar-separator"></span>
					<kendo-appbar-section *ngSwitchCase="appBarItemTypeET.Section">
						<ng-container *ngTemplateOutlet="item.content"></ng-container>
					</kendo-appbar-section>
				</ng-container>
			</ng-container>

			<!--
			<ng-container *ngFor="let item of appBarItems">
				<ng-container [ngSwitch]="item.type">
					<kendo-appbar-spacer *ngSwitchCase="appBarItemTypeET.Spacer">
					</kendo-appbar-spacer>
					<kendo-appbar-section *ngSwitchCase="appBarItemTypeET.Section">
						<ng-container *ngTemplateOutlet="item.templateRef"></ng-container>
					</kendo-appbar-section>
				</ng-container>
			</ng-container>
			-->
		</kendo-appbar>
	`,
	styles: []
})
export class AppBarComponent {

	@Input() themeColor: 'light' | 'dark' | 'inherit' = 'light';

	@HostBinding('class') class = 'otris-app-bar';

	//@ContentChildren(AppBarItemDirective) appBarItems: AppBarItemDirective[];

	@ContentChildren(AppBarItemComponent) appBarItems: QueryList<AppBarItemComponent>;

	appBarItemTypeET = AppBarItemTypeET;

	constructor() { }
}
