
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { TypeET } from './types';
//import { ServiceLocator } from '../services/service-locator';
import { AbstractEnumeration, IEnumerationItem } from './abstract-enumeration';
import { IPDClass, IPDClassToken } from './i-pd-class';
import { getJsonProperty } from '../decorators/json-property.decorator';
import { IMetaDataService, IMetaObjectFactory } from '../services/meta-data.service';
import { DefaultPDObjectMeta } from './default-pd-object-meta';
import { IPDObjectMeta } from './pd-object-meta';
import { ServiceLocator } from '../services/service-locator';

export interface IPDObjectId {
	low: number;
	high: number;
	oid: string;
}

export interface IPDObjectRaw {
	oid: string;
	[propName: string]: any;
	callOperation(...args: any[]): any;
	getExtent(...args: any[]): any;
}

// Neu


export class EmptyPDObjectRaw implements IPDObjectRaw {
	get oid(): string {
		return undefined;
	}

	callOperation() { }
	getExtent() { }
}

export abstract class PDObject {

	get objectId(): IPDObjectId {
		return this._objectId
	}

	private _objectId: IPDObjectId;

	abstract get pdObjectRaw(): IPDObjectRaw;

	get metaData(): IPDObjectMeta {
		return this._metaData;
	}

	private _metaData: IPDObjectMeta;

	constructor(_objectId: string | { low: number, high: number } | undefined, metaData?: IPDObjectMeta) {
		//this._metaData = ServiceLocator.injector.get(IMetaObjectFactory).createMetaObject(this)
		this._metaData = metaData ? metaData : this.getMetaDataObject();

		if (_objectId) {
			let oidLow, oidHigh;
			if (typeof _objectId === 'string') {
				let pos = _objectId.indexOf(':');
				if (pos > 0) {
					oidHigh = _objectId.substr(0, pos);
					oidLow = _objectId.substr(pos + 1);
				}
			}
			else {
				oidLow = _objectId.low;
				oidHigh = _objectId.high;
			}
			this._objectId = <IPDObjectId>{ low: oidLow, high: oidHigh, oid: oidHigh.toString() + ':' + oidLow.toString() };
		}
	}

	toJson(): string {
		return JSON.stringify(this.pdObjectRaw, (k, v) => this.jsonReplacer(k, v));
	}

	/*toJSON(): string {
		return this.toJson();
	}*/

	update(obj: any): void {
		/*if (this.pdObjectRaw.adaptRawObject) {
			this.pdObjectRaw.adaptRawObject(obj);
		}*/

		this.adaptRawObject(obj);
		Object.assign(this.pdObjectRaw, obj);
	}

	/**
	 * Liefert ein Attribute welches aus dem pdObjectRaw stammt.
	 */
	protected getPropertyValue(propName: string): any {
		return this.pdObjectRaw[propName];
	}

	getStatus(template: string): string {
		if (!template) {
			return '<n.a.>';
		}
		let datePipe = <DatePipe>ServiceLocator.injector.get(DatePipe);
		//let abstractEnumPipe = <AbstractEnumerationPipe>ServiceLocator.injector.get(AbstractEnumerationPipe);
		//let structurePipe = <StructurePipe>ServiceLocator.injector.get(StructurePipe);
		let regExp = /%a((\w*\.)*\w*)%/;
		let result: RegExpExecArray;
		while ((result = regExp.exec(template)) !== null) {
			// let obj = this.pdObjectRaw;
			let propName: string = result[1];
			/*if (propName.includes(".")) {
				// Child-Objekte berücksichtigen
				let objProps = propName.split(".");
				for (let i = 0; i < objProps.length; i++) {
					if (i == objProps.length - 1) {
						propName = objProps[i];
					}
					else {
						obj = obj[objProps[i]];
						if (!obj) {
							template = template.replace(result[0], '');
							continue;
						}
					}
				}
			}*/

			let type = this.metaData.getPropertyType(propName);
			let propValue;
			switch (type) {
				case TypeET.Date:
					propValue = datePipe.transform(this.getPropertyValue(propName), 'dd. MMM yyyy');
					break;
				case TypeET.Time:
					propValue = datePipe.transform(this.getPropertyValue(propName), 'HH:mm:ss');
					break;
				case TypeET.DateTime:
					propValue = datePipe.transform(this.getPropertyValue(propName), 'dd. MMM yyyy HH:mm:ss');
					break;
				/*case TypeET.Enum:
					propValue = abstractEnumPipe.transform(this[propName]);
					break;
				case TypeET.Structure:
					propValue = structurePipe.transform(this[propName]);
					break;*/
				default:
					propValue = this.getPropertyValue(propName);
			}
			template = template.replace(result[0], propValue);
		}
		return template;
	}

	protected getMetaDataObject(): IPDObjectMeta {
		return DefaultPDObjectMeta.instance;
	}

	protected adaptRawObject(rawObj: any): void {
		let pdClass = <IPDClass>ServiceLocator.injector.get(IPDClassToken);
		for (let prop in rawObj) {
			if (rawObj.hasOwnProperty(prop)) {
				switch (this.metaData.getPropertyType(prop)) {
					case TypeET.RelationToNEditable:
						{
							if (Array.isArray(rawObj[prop])) {
								let relObjs: CustomPDObject[] = [];
								for (let ro of rawObj[prop]) {
									if (ro.hasOwnProperty('_className')) {
										let newObj = pdClass.createInstance(ro['_className'], ro['_objectId']);
										newObj.update(ro);
										relObjs.push(newObj);
									}
								}
								rawObj[prop] = relObjs;
							}
							break;
						}

					/*case TypeET.RelationToN:
						{
							if (Array.isArray(rawObj[prop])) {
								let relObjs: PDObject[] = [];
								for (let ro of rawObj[prop]) {
									if (ro.hasOwnProperty('_className')) {
										let newObj = pdClass.createInstance(ro['_className'], ro['_objectId']);
										newObj.update(ro);
										relObjs.push(newObj);
									}
								}
								rawObj[prop] = relObjs;
							}
							break;
						}*/
				}
			}
		}
	}

	jsonReplacer(key: string, value: any): any {
		if (value instanceof PDObject) {
			let obj = {};
			for (let prop in value) {
				if (prop === '_metaData' || prop === '_resetCallbackSubject$' || prop === '_resetCallback$' || !value.hasOwnProperty(prop)) {
					continue;
				}
				let valueAdapted = value[prop]; // this durch value ersetzt
				if (prop === '_objectId') {
					valueAdapted = valueAdapted ? (<IPDObjectId>valueAdapted).oid : undefined;
				}
				else {
					let propAdapted = prop.startsWith('_') ? prop.substr(1) : prop;
					let type = value.metaData.getPropertyType(propAdapted); // this durch value ersetzt
					switch (type) {
						case TypeET.RelationTo1:
							{
								if (!(valueAdapted instanceof CustomPDObject)) {
									let relObj = <PDObject>valueAdapted;
									valueAdapted = (relObj && relObj.objectId) ? relObj.objectId.oid : null;
								}
								break;
							}
						case TypeET.RelationToN:
							{
								let relObjs = <PDObject[]>valueAdapted;
								if (relObjs) {
									valueAdapted = relObjs.map(o => {
										if (o instanceof CustomPDObject) {
											return o;
										}
										return o.objectId ? o.objectId.oid : null
									});
									break;
								}
								valueAdapted = [];
								break;
								// valueAdapted = relObjs ? relObjs.map(o => o.objectId ? o.objectId.oid : null) : [];
								// break;
							}
						case TypeET.RelationToNEditable:
							{
								/*let relObjs = <CustomPDObject[]>valueAdapted;
								let res = [];
								// Wo ist der Sinn?  
								relObjs.forEach(ro => res.push(ro));
								valueAdapted = res;*/
								break;
							}
						case TypeET.Enum:
							{
								let e = <AbstractEnumeration>valueAdapted;
								if (!Array.isArray(e.value)) {
									let enumItem = <IEnumerationItem>e.value;
									valueAdapted = enumItem ? enumItem.enumConst : -1;
								}
								break;
							}
						case TypeET.Date:
							{
								let d = value.pdObjectRaw[prop]; // this durch value ersetzt
								if (d instanceof Date) {
									let month = (d.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let day = d.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									valueAdapted = d.getFullYear().toString() + "-" + month + "-" + day; // + "T00:00:00.000Z";
								}
								else {
									valueAdapted = null;
								}
								break;
							}
						case TypeET.Time:
							{
								let d = value.pdObjectRaw[prop]; // this durch value ersetzt
								if (d instanceof Date) {
									let hours = d.getHours().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let minutes = d.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let seconds = d.getSeconds().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let millis = d.getMilliseconds().toLocaleString(undefined, { minimumIntegerDigits: 3 });
									valueAdapted = + hours + ':' + minutes + ':' + seconds + '.' + millis;
								}
								else {
									valueAdapted = null;
								}
								break;
							}
						case TypeET.DateTime:
							{
								let d = value.pdObjectRaw[prop]; // this durch value ersetzt
								if (d instanceof Date) {
									let month = (d.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let day = d.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let hours = d.getHours().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let minutes = d.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let seconds = d.getSeconds().toLocaleString(undefined, { minimumIntegerDigits: 2 });
									let millis = d.getMilliseconds().toLocaleString(undefined, { minimumIntegerDigits: 3 });
									valueAdapted = d.getFullYear().toString() + "-" + month + "-" + day + 'T' + hours + ':' + minutes + ':'
										':' + seconds + '.' + millis;
								}
								else {
									valueAdapted = null;
								}
								break;
							}
					}
				}

				let jsonProp = getJsonProperty(value, prop); // this durch value ersetzt
				if (typeof(jsonProp) === 'string') {
					obj[jsonProp] = valueAdapted !== undefined ? valueAdapted : null;
				}
				else {
					obj[prop] = valueAdapted !== undefined ? valueAdapted : null;
				}
			}
			return obj;
		}
		return value;

		/*if (key === '') {
			return value;
		}
		if (key === '_metaData') {
			return undefined;
		}*/

		/*if (key === '_objectId') {
			return value ? (<IPDObjectId>value).oid : undefined;
		}

		if (key.startsWith('_')) {
			key = key.substr(1);
		}
		let type = this.metaData.getPropertyType(key);
		switch (type) {
			case TypeET.RelationTo1:
				{
					let relObj = <PDObject>value;
					return (relObj && relObj.objectId) ? relObj.objectId.oid : null;
				}
			case TypeET.RelationToN:
				{
					let relObjs = <PDObject[]>value;
					return relObjs ? relObjs.map(o => o.objectId ? o.objectId.oid : null) : [];
				}
			case TypeET.RelationToNEditable:
				{
					let relObjs = <CustomPDObject[]>value;
					let res = [];
					relObjs.forEach(ro => res.push(ro));
					return res;
				}
			case TypeET.Enum:
				{
					let e = <AbstractEnumeration>value;
					if (!Array.isArray(e.value)) {
						let enumItem = <IEnumerationItem>e.value;
						return enumItem ? enumItem.enumConst : -1;
					}
					break;
				}
			case TypeET.Date:
				{
					let d = this.pdObjectRaw[key];
					if (d instanceof Date) {
						let month = (d.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
						let day = d.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });
						return d.getFullYear().toString() + "-" + month + "-" + day + "T00:00:00.000Z";
					}
					return null;
				}
		}

		return value;*/
	}
}

export abstract class CustomPDObject extends PDObject {

	private _resetCallbackSubject$: Subject<CustomPDObject> = new Subject<CustomPDObject>();

	private _resetCallback$: Observable<CustomPDObject>;

	get resetCallback$(): Observable<CustomPDObject> {
		if (!this._resetCallback$) {
			this._resetCallback$ = this._resetCallbackSubject$.asObservable();
		}
		return this._resetCallback$;
	}

	protected static _objectCounter: number = 0;

	get oid(): string | undefined {
		return this.objectId ? this.objectId.oid : undefined;
	}

	get pdObjectRaw(): IPDObjectRaw {
		return this;
	}

	private _className: string;

	get isNew(): boolean {
		return this._isNew;
	}

	// _metaData: IPDObjectMeta
	constructor(_objectId: string | { low: number, high: number } | undefined, private _isNew: boolean = true/*, metaData?: IPDObjectMeta*/) {
		super(_objectId);
		this._className = this.getClassName();
		CustomPDObject._objectCounter++;
		this.reset();
	}

	protected getMetaDataObject(): IPDObjectMeta {
		return ServiceLocator.injector.get(IMetaDataService).getMetaObject(this.getClassName());
	}

	abstract getClassName(): string;

	reset(): void {
		this.metaData.propertyNames.forEach(prop => this[prop] = undefined);
		this._resetCallbackSubject$.next(this);
	}

	clone(): CustomPDObject {
		let pdClass = ServiceLocator.injector.get(IPDClassToken);
		let newObj: any = pdClass.createInstance(this._className, this.oid, this.isNew);

		for (let prop in this) {
			if (!this.hasOwnProperty(prop)) {
				continue;
			}

			let propAdapted = prop.startsWith('_') ? prop.substr(1) : prop;
			let value: any = this[prop];
			let type = this.metaData.getPropertyType(propAdapted);
			switch (type) {
				case TypeET.RelationTo1:
					if (value instanceof CustomPDObject) {
						value = (value as CustomPDObject).clone()
					}
					break;

				case TypeET.RelationToN:
					if (Array.isArray(value)) {
						let relObjs = <PDObject[]>value;
						value = relObjs.map(o => o instanceof CustomPDObject ? o.clone() : o);
					}
					break;

				case TypeET.RelationToNEditable:
					if (Array.isArray(value)) {
						let relObjs = <CustomPDObject[]>value;
						value = relObjs.map(o => o.clone());
					}
					break;
			}
			newObj[prop] = value;
		}
		return newObj;
	}

	callOperation() { }
	getExtent() { }
}

export abstract class PDObjectRawWrapper extends PDObject {
	constructor(private _pdObjectRaw: IPDObjectRaw) {
		super(_pdObjectRaw.oid);
	}

	get pdObjectRaw(): IPDObjectRaw {
		return this._pdObjectRaw;
	}

	protected getPropertyValue(propName: string): any {
		if (this.hasOwnProperty(propName)) {
			return this[propName];
		}
		return super.getPropertyValue(propName);
	}
}

