import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AbstractControl, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { ICssStyle, IToolBarItemSpec } from '@otris/ng-core-shared';

export enum TextBoxTypeET {
	Text = 'text',
	Password = 'password'
}

@Component({
	selector: 'otris-text-box',
	template: `
		<div class="main-container">
			<div class="label-container" [ngStyle]="labelStyle" *ngIf="!!label">
				<span>{{label}}</span>
			</div>
			<div class="input-container">
				<input class="textbox k-textbox" kendoTextBox [type]="type" [placeholder]="placeholder" [readonly]="readonly"
					[formControl]="ctrl" (keydown)="onKeyDown($event)"/>
				<otris-tool-bar *ngIf="toolbarItems" class="toolbar" (buttonClick)="toolbarButtonClick.emit($event)" [itemSpecs]="toolbarItems">
				</otris-tool-bar>
			</div>
		</div>
	`,
	styles: [`
		.main-container {
			display: flex;
			height: 100%;
			flex-direction: column;
		}
		.label-container {
			margin-bottom: 0.5em;
			height: 1.2em;
			white-space: nowrap;
		}
		.input-container {
			display: flex;
			align-items: center;
		}
		.textbox {
			width: 100%;
		}
		.toolbar {
		}
	`]
})
export class TextBoxComponent implements OnInit {

	@Input() readonly: boolean = false;

	@Input() formGroup: FormGroup;

	@Input() propertyName: string;

	private _disabled = false;

	get disabled(): boolean {
		return this._disabled;
	}

	@Input() set disabled(val: boolean) {
		if (val != this._disabled) {
			this._disabled = val;
			if (this.ctrl) {
				if (val) {
					this.ctrl.disable();
				}
				else {
					this.ctrl.enable();
				}
			}
		}
	}

	private _value: string = '';

	@Input()
	get value(): string {
		return this._value;
	}

	set value(val: string) {
		if (val != this._value) {
			this._value = val;
			this.valueChange.emit(val);
			let c = this.ctrl;
			if (c) {
				c.setValue(val);
			}
		}
	}

	@Output() valueChange = new EventEmitter<string>();

	@Output() keyDown = new EventEmitter<string>();

	@Input() label: string;

	@Input() toolbarItems: IToolBarItemSpec[];

	@Input() placeholder: string = '';

	@Input() labelStyle: ICssStyle;

	@Input() type: TextBoxTypeET = TextBoxTypeET.Text;

	@Input() get required(): boolean {
		return this._required;
	}

	set required(val: boolean) {
		this._required = val;
		this.updateValidators()
	}

	private _required: boolean = false;

	@Output() toolbarButtonClick = new EventEmitter<IToolBarItemSpec>();

	@Input() validators: ValidatorFn | ValidatorFn[];

	@Output() validChange = new EventEmitter<boolean>();

	get ctrl(): AbstractControl {
		if (this._uncoupledCtrl) {
			return this._uncoupledCtrl;
		}
		return (this.formGroup && this.propertyName) ? this.formGroup.controls[this.propertyName] : undefined;
	}

	private _uncoupledCtrl: FormControl;

	constructor() { }

	ngOnInit() {
		if (this.formGroup && this.propertyName) {
			let ctrl = new FormControl(this._value);
			this.formGroup.addControl(this.propertyName, ctrl);
			this.updateValidators();
		}
		else {
			this._uncoupledCtrl = new FormControl(this._value);
		}

		if (this.validators) {
			this.ctrl.setValidators(this.validators);
		}

		if (this._disabled) {
			this.ctrl.disable();
		}

		this.ctrl.valueChanges.subscribe(v => this.value = v);
		this.ctrl.statusChanges.subscribe(stat => this.validChange.emit(this.ctrl.valid));
	}

	onKeyDown(evt: KeyboardEvent) {
		this.keyDown.emit(evt.key);
	}

	private updateValidators() {
		if (!this.formGroup) {
			return;
		}
		let ctrl = this.formGroup.controls[this.propertyName];
		if (ctrl) {
			ctrl.clearValidators();
			if (this.required) {
				ctrl.setValidators([Validators.required]);
			}
		}
	}
}
