import { ICssStyle } from './types';

export enum ToolBarItemTypeET {
	Button,
	DropDownButton
}

export enum EnabledWhenET {
	Always,
	ItemsSelected,
	SingleItemSelected,
	MultipleItemsSelected,
}

export interface IToolBarItemResult {
	id?: string;
	tag?: any;
}

export interface IToolBarItemSpec {
	id?: string;
	type: ToolBarItemTypeET;
	disabled?: boolean;
	enabledWhen?: EnabledWhenET;
	hidden?: boolean;
	tag?: any;
	shortDescription?: string;
	shortDescriptionId?: string;
	text?: string;
	textId?: string;
};

export interface IToolBarButtonSpec extends IToolBarItemSpec {
	//text?: string;
	//textId?: string;
	iconClass?: string;
	style?: ICssStyle;
};

export interface IToolBarDropDownButtonSpec extends IToolBarItemSpec {
	//text?: string;
	//textId?: string;
	iconClass?: string;
	items: IToolBarDropDownButtonItem[];
	style?: ICssStyle;
}

export interface IToolBarDropDownButtonItem {
	id?: string;
	text?: string;
	textId?: string;
	tag?: any;
}

export interface IToolbarSpec {
	items?: IToolBarItemSpec[];
}

export class ToolbarSpec {
	private _items: IToolBarItemSpec[] = [];

	constructor(spec: IToolbarSpec) {
		if (spec.items) {
			this._items = spec.items;
		}
	}

	get items(): IToolBarItemSpec[] {
		return this._items;
	}
}
