import { Injectable } from '@angular/core';
import { CustomPDObject, IPDClass, AbstractEnumeration } from '@otris/ng-core-shared';

@Injectable()
export class PDClassService implements IPDClass {
	createInstance(className: string, oid?: string, isNew?: boolean): CustomPDObject {
		throw new Error(`Not implemented`);
	}

	createEnumInstance(className: string, propName: string, value: number): AbstractEnumeration | undefined {
		throw new Error(`Not implemented`);
	}
}
