import { Inject, Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DefaultPDObjectMeta } from '../model/default-pd-object-meta';
import { IPDObjectMeta, IClassMetaData, IPropertyMetaData, IStringPropertyMetaData, IRelationMetaData } from '../model/pd-object-meta';
import { ShortDescriptionFormatET, TypeET } from '../model/types';
import { ILocalizationService, ILocalizationServiceToken } from './localization.service';

@Injectable()
export abstract class IMetaObjectFactory {
	abstract createMetaObject(className: string): IPDObjectMeta | undefined;
}

export type DefaultShortDescriptionFormat = () => Observable<ShortDescriptionFormatET>;

@Injectable()
export class MetaDataService {

	private _metaDataMap = new Map<string, IClassMetaData>();

	private _metaDataChangedSubject$ = new Subject<string>();

	private _metaObjects = new Map<string, IPDObjectMeta>();

	private _defaultShortDescriptionFormatCallback: DefaultShortDescriptionFormat;

	constructor(private _metaObjectFactory: IMetaObjectFactory,
		@Inject(ILocalizationServiceToken) private localizationService: ILocalizationService) {}

	getMetaDataChanged$(className: string): Observable<IClassMetaData | undefined> {
		return this._metaDataChangedSubject$.pipe(
			filter(res => res === className),
			map(() => this._metaDataMap.has(className) ? this._metaDataMap.get(className) : undefined )
		);
	}

	addMetaData(metaData: IClassMetaData): void {
		if (!metaData.className) {
			throw new Error(`No className in IClassMetaData object.`);
		}
		/*if (!this._metaDataMap.has(metaData.className)) {
			this._metaDataMap.set(metaData.className, metaData)
		}
		else {
			this.updateClassMetaData(metaData);
		}*/
		let updatedClasses = [];
		this.updateClassMetaData(metaData, updatedClasses);
		updatedClasses.forEach(cls => this._metaDataChangedSubject$.next(cls));
	}

	/*removeMetaData(className: string): void {
		if (!this._metaDataMap.has(className)) {
			throw new Error(`No meta data for class ${className} registered.`);
		}
		this._metaDataMap.delete(className);
		this._metaDataChangedSubject$.next(className);
	}*/

	clearMetaData(): void {
		let classes = Array.from(this._metaDataMap.keys());
		this._metaDataMap.clear();
		classes.forEach(cls => this._metaDataChangedSubject$.next(cls));
	}

	getMetaData(className: string): IClassMetaData | undefined {
		return this._metaDataMap.has(className) ? this._metaDataMap.get(className) : undefined;
	}

	getMetaObject(className: string): IPDObjectMeta {
		if (this._metaObjects.has(className)) {
			return this._metaObjects.get(className);
		}

		let metaObj = this._metaObjectFactory.createMetaObject(className);
		if (metaObj) {
			this._metaObjects.set(className, metaObj);
			return metaObj;
		}

		return DefaultPDObjectMeta.instance;
	}

	setMetaObject(className: string, metaObj: IPDObjectMeta): void {
		this._metaObjects.set(className, metaObj);
	}

	getClassErgName(cls: string): Observable<string> {
		//let localizationService: ILocalizationService = ServiceLocator.injector.get(ILocalizationServiceToken);
		//let metaDataService = ServiceLocator.injector.get(IMetaDataService);
		let metaData = this.getMetaData(cls);
		if (metaData && metaData.classErgName) {
			let ergName = metaData.classErgName.find(n => n[0] == this.localizationService.currentLanguage.code);
			if (ergName) {
				return of(ergName[1]);
			}
		}
		return this.localizationService.getClassErgName(cls);
	}

	getEnumLiteralErgName(enumName: string, literal: number): Observable<string> {
		//let enumName = typeof name === 'string' ? name : (<new () => T>name).name;
		return this.localizationService.getEnumLiteralErgName(enumName, literal);
	}

	getDefaultShortDescriptionFormat(): Observable<ShortDescriptionFormatET> {
		if (!this._defaultShortDescriptionFormatCallback) {
			throw new Error('DefaultShortDescriptionFormatCallback not set.');
		}

		return this._defaultShortDescriptionFormatCallback();
		//return this.privacyBackendService.getDefaultShortDescriptionFormat();
	}

	setDefaultShortDescriptionFormatCallback(cb: DefaultShortDescriptionFormat): void {
		this._defaultShortDescriptionFormatCallback = cb;
	}

	getRelationClass(basisClass: string, role: string): string | undefined {
		if (!this._metaDataMap.has(basisClass)) {
			return undefined;
		}

		let clsMeta = this._metaDataMap.get(basisClass);
		if (!clsMeta.relations) {
			return undefined;
		}

		let relMeta = clsMeta.relations.find(r => r.relationName === role);
		return relMeta ? relMeta.className : undefined;
	}

	private updateClassMetaData(newMetaData: IClassMetaData, updatedClasses: string[]): void {

		if  (!updatedClasses.includes(newMetaData.className)) {
			updatedClasses.push(newMetaData.className);
		}
		let current = this._metaDataMap.get(newMetaData.className);
		if (!current) {
			current = <IClassMetaData>{
				className: newMetaData.className
			}
			this._metaDataMap.set(newMetaData.className, current);
		}
		if (newMetaData.classErgName) {
			current.classErgName = newMetaData.classErgName;
		}
		if (newMetaData.properties) {
			this.updatePropertyMetaData(newMetaData.properties, current);
		}
		if (newMetaData.relations) {
			this.updateRelationMetaData(newMetaData.relations, current, updatedClasses);
		}
	}

	private updatePropertyMetaData(props: IPropertyMetaData[], current: IClassMetaData): void {
		if (!props || props.length == 0) {
			return;
		}
		if (!current.properties || current.properties.length == 0) {
			current.properties = props;
			return;
		}
		let currentProps = current.properties.reduce((map, prop) => map.set(prop.name, prop), new Map<string, IPropertyMetaData>());
		props.forEach(prop => {
			if (prop.accessRights && prop.accessRights.write === false) {
				prop.mandatory = false;
			}

			if (prop.name.startsWith('_')) {
				prop.name = prop.name.substr(1);
			}
			
			if (!currentProps.has(prop.name)) {
				current.properties.push(prop);
			}
			else {
				let currentProp = currentProps.get(prop.name);
				if (prop.accessRights) {
					currentProp.accessRights = prop.accessRights;
				}
				if (prop.mandatory !== undefined) {
					currentProp.mandatory = prop.mandatory;
				}
				if (prop.label) {
					currentProp.label = prop.label;
				}
				if (prop.shortDescription) {
					currentProp.shortDescription = prop.shortDescription;
				}
				if (prop.type == TypeET.String) {
					let stringProp: IStringPropertyMetaData = prop;
					if (stringProp.maxLength !== undefined) {
						(<IStringPropertyMetaData>currentProp).maxLength = stringProp.maxLength;
					}
				}
			}
		});
	}

	private updateRelationMetaData(relations: IRelationMetaData[], current: IClassMetaData, updatedClasses: string[]): void {
		if (!relations || relations.length == 0) {
			return;
		}
		if (!current.relations) {
			current.relations = [];
		}
		let currentRels = current.relations.reduce((map, rel) => map.set(rel.relationName, rel), new Map<string, IRelationMetaData>());
		relations.forEach(rel => {
			if (rel.accessRights && rel.accessRights.change === false) {
				rel.mandatory = false;
			}

			if (rel.className) {
				this.updateClassMetaData(rel, updatedClasses);
			}
			if (!currentRels.has(rel.relationName)) {
				let relNew = Object.assign(<IRelationMetaData>{}, rel);
				relNew.relations = undefined;
				relNew.properties = undefined;
				current.relations.push(relNew);
			}
			else {
				let currentRel = currentRels.get(rel.relationName);
				if (rel.accessRights) {
					currentRel.accessRights = rel.accessRights;
				}
			}
		});
	}
}

@Injectable({ providedIn: 'root', useClass: MetaDataService})
export abstract class IMetaDataService {
	abstract getMetaDataChanged$(className: string): Observable<IClassMetaData | undefined>;

	abstract addMetaData(metaData: IClassMetaData): void;

	//abstract removeMetaData(className: string): void;

	abstract clearMetaData(): void;

	abstract getMetaData(className: string): IClassMetaData | undefined;

	abstract getMetaObject(className: string): IPDObjectMeta;

	abstract setMetaObject(className: string, metaObj: IPDObjectMeta): void;

	abstract getClassErgName(cls: string): Observable<string>;

	abstract getDefaultShortDescriptionFormat(): Observable<ShortDescriptionFormatET>;

	abstract getEnumLiteralErgName(enumName: string, literal: number): Observable<string>;

	abstract setDefaultShortDescriptionFormatCallback(cb: DefaultShortDescriptionFormat): void;

	abstract getRelationClass(basisClass: string, role: string): string | undefined;
}
