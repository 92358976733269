import { Component, Input, Output, EventEmitter, ViewChildren, ChangeDetectorRef, Inject, QueryList, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { forkJoin, Subject, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RelationTabWidgetInfo, PDRelationTabSpec, UIAbstractComponent, FormHandlerService } from '@otris/ng-core';
import {
	ComponentTypeET, PDObject, IToolBarItemSpec, IToolBarButtonSpec,
	ToolBarItemTypeET, IInteractionService, IInteractionServiceToken, IToolBarItemResult, DialogResultET, IRelationContext,
	IComponent, ICssStyle, CustomPDObject, IFormStatus, IPDRelationTabComponent, CreateRelationObjectCallback,
	ILocalizationService, ILocalizationServiceToken
} from '@otris/ng-core-shared';
// Das darf man wegen Rekursion nicht importieren!
//import { PDPanelComponent } from '../pd-panel/pd-panel.component';

enum ToolBarButtonIdET {
	New = 'idNew',
	Delete = 'idDelete'
}

interface IRelationObjectSpec {
	id: string;
	obj: PDObject;
	formGroup: FormGroup;
}

@Component({
	selector: 'otris-pd-relation-tab',
	template: `
		<div class="root-container">
			<div class="header">
				<div [ngClass]="{'otris-pd-relation-tab-selected-tab-item': relObj===selectedObject,
					'otris-pd-relation-tab-unselected-tab-item': relObj!==selectedObject,
					'tab-item': isEnabled, 'tab-item-disabled': !isEnabled}"
					*ngFor="let relObj of relationObjects; index as i" (click)="onTabItemClick(relObj)">
					<div class="tab-item-text" kendoTooltip filter="span" [showOn]="(hasTabHeaderShortInfo(relObj, i) | async) ? 'hover' : 'none'">
						<span [title]="getTabHeaderShortInfo(relObj, i) | async">{{getTabHeaderText(relObj, i) | async}}</span>
					</div>
				</div>
				<div style="margin-left: 0.5em;"></div>
				<otris-pd-short-description [highlighted]="mouseIsOver" *ngIf="isEnabled && hasShortDescription" [pdObject]="pdObject" [pdComponent]="this">
				</otris-pd-short-description>
				
				<otris-pd-error-indicator *ngIf="isEnabled && relationFormArray && relationFormArray.errors"
					[relatedFormControl]="relationFormArray" [propertyName]="propertyName" [pdObject]="pdObject">
				</otris-pd-error-indicator>
				<!--otris-pd-mandatory-indicator *ngIf="mandatoryIndicatorVisible">
				</otris-pd-mandatory-indicator-->
				<otris-tool-bar class="toolbar" (buttonClick)="onToolBarButtonClick($event)" [itemSpecs]="toolbarItems">
				</otris-tool-bar>
			</div>
			<div class="panel-container otris-pd-relation-tab-panel-container" *ngIf="pdRelationTabSpec.content" [ngStyle]="panelContainerStyle">
				<otris-pd-panel #panel *ngFor="let relObjSpec of relationObjectSpecs" [ngClass]="{'panel-hidden': relObjSpec.obj!==selectedObject}"
					class="panel" [uiItemSpec]="pdRelationTabSpec.content" [formGroup]="relObjSpec.formGroup" [pdObject]="relObjSpec.obj"
					[relationContext]="getRelationContext(relObjSpec)">
				</otris-pd-panel>
				<div *ngIf="relationObjectSpecs.length == 0" class="no-tabs-message">
					<span>{{noTabsMessage | async}}</span>
				</div>
			</div>
		</div>
	`,
	styles: [`
		:host {
			overflow-y: hidden; /* Chrome bugfix */
		}

		.root-container {
			display: flex;
			flex-direction: column;

			height: 100%;
		}
		.header {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			/*overflow: auto;*/
			border-bottom: 1px solid darkgrey;
		}
		.tab-item {
			cursor: pointer;
		}
		.tab-item:hover {
			background-color: gainsboro;
		}
		.tab-item-disabled {
			color: grey;
		}
		.tab-item-text {
			margin: 0.5em 1em;
			user-select: none;
		}
		/*.selected-tab-item {
			border-bottom: 0.25em solid #ff6358;
		}*/
		.toolbar {
			margin: 0 0 0 auto;
		}
		.panel-container {
			flex: 1 1 auto;
			/* display: grid; IE11 Fix and center fix */ 
			display: flex;
			justify-content: center;
			align-items: center;

			margin-top: 0.5em;
			padding: 0.5em;
		}
		.panel {
			/*grid-row: 1;
			grid-column: 1;*/
			flex: 1;
			align-self: stretch;
		}
		.panel-hidden {
			display: none;
		}
		.no-tabs-message {
			/* margin: auto; IE11 Fix and center fix */
			color: darkgrey;
			font-size: 2em;
		}
	`]
})
export class PDRelationTabComponent extends UIAbstractComponent implements IPDRelationTabComponent {

	@Input()
	set pdObject(val: PDObject) {
		if (val && val != this._pdObject) {
			this.onPDObjectChanging();
			this._pdObject = val;
			this.onPDObjectChanged();
		}
	}

	get pdObject(): PDObject {
		return this._pdObject;
	}

	private _pdObject: PDObject;

	@Input() objCreator: new () => PDObject;

	@Input()
	set maxTabCount(count: number) {
		if (Number.isInteger(count) && count > 0) {
			this._maxTabCount = count;
		}
	}

	get maxTabCount(): number {
		return this._maxTabCount;
	}

	private _maxTabCount: number = 10;

	@Output() relationObjectCreated = new EventEmitter<string>();

	@Output() relationObjectDeleting = new EventEmitter<PDObject>();

	@Output() relationObjectDeleted = new EventEmitter();

	@Output() selectedItemChanged = new EventEmitter<string>();

	@ViewChildren('panel') panelComponents: QueryList<any>; // todo: IPDPanelComponent

	@HostListener('mouseenter')
	onMouseEnter() {
		this.mouseIsOver = true;
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.mouseIsOver = false;
	}

	mouseIsOver: boolean = false;

	toolbarItems: IToolBarItemSpec[];

	private _relationObjectCreatedSubject$: Subject<string> = new Subject();

	private _relationObjectCreated$: Observable<string>;

	get relationObjectCreated$(): Observable<string> {
		if (!this._relationObjectCreated$) {
			this._relationObjectCreated$ = this._relationObjectCreatedSubject$.asObservable();
		}
		return this._relationObjectCreated$;
	}

	private _relationObjectDeletingSubject$: Subject<PDObject> = new Subject();

	private _relationObjectDeleting$: Observable<PDObject>;

	get relationObjectDeleting$(): Observable<PDObject> {
		if (!this._relationObjectDeleting$) {
			this._relationObjectDeleting$ = this._relationObjectDeletingSubject$.asObservable();
		}
		return this._relationObjectDeleting$;
	}

	private _relationObjectDeletedSubject$: Subject<void> = new Subject();

	private _relationObjectDeleted$: Observable<void>;

	get relationObjectDeleted$(): Observable<void> {
		if (!this._relationObjectDeleted$) {
			this._relationObjectDeleted$ = this._relationObjectDeletedSubject$.asObservable();
		}
		return this._relationObjectDeleted$;
	}

	private _selectedItemChangedSubject$: Subject<string> = new Subject();

	private _selectedItemChanged$: Observable<string>;

	get selectedItemChanged$(): Observable<string> {
		if (!this._selectedItemChanged$) {
			this._selectedItemChanged$ = this._selectedItemChangedSubject$.asObservable();
		}
		return this._selectedItemChanged$;
	}

	private _relationFormArray: FormArray;

	get relationFormArray(): FormArray {
		return this._relationFormArray;
	}

	get relationObjectSpecs(): IRelationObjectSpec[] {
		return this._relationObjectSpecs;
	}

	private _relationObjectSpecs: IRelationObjectSpec[] = [];

	private _relationObjects: PDObject[] = [];

	get relationObjects(): PDObject[] {
		return this._relationObjects;
	}

	private _selectedObject: PDObject;

	get selectedObject(): PDObject {
		return this._selectedObject;
	}

	private setSelectedObject(obj: PDObject) {
		if (this._selectedObject !== obj) {
			if (!obj) {
				this._selectedObject = undefined;
				this.updateToolbar();
			}
			else {
				let spec = this._relationObjectSpecs.find(s => s.obj === obj);
				if (!spec) {
					return;
				}
				this._selectedObject = obj;
				this.updateToolbar();
				this.selectedItemChanged.emit(spec.id);
				this._selectedItemChangedSubject$.next(spec.id);
			}
			/*this.updateToolbar();
			if (this._selectedObject) {
				this.selectedItemChanged.emit(spec.id);
				this._selectedItemChangedSubject$.next(spec.id);
			}*/
		}
	}

	get relationTabWidgetInfo(): RelationTabWidgetInfo {
		return this.widgetInfo instanceof RelationTabWidgetInfo ? <RelationTabWidgetInfo>this.widgetInfo : undefined;
	}

	get pdRelationTabSpec(): PDRelationTabSpec {
		return this.uiItemSpec instanceof PDRelationTabSpec ? <PDRelationTabSpec>this.uiItemSpec : undefined;
	}

	set createRelationObjectCallback(cb: CreateRelationObjectCallback) {
		this._createRelationObjectCallback = cb;
	}

	private _createRelationObjectCallback: CreateRelationObjectCallback;

	get panelContainerStyle(): ICssStyle | undefined {
		let wi = this.relationTabWidgetInfo;
		return wi ? wi.tabPanelStyle : undefined;
	}

	get shortDescription(): string | undefined {
		return this._shortDescription;
	}

	private _shortDescription: string;

	get hasShortDescription(): boolean {
		if (this.pdObject && this.pdRelationTabSpec) {
			return this.pdObject.metaData.hasShortDescription(this.pdRelationTabSpec.property);
		}
		return false;
	}

	get mandatoryIndicatorVisible(): boolean {
		return this.isRequired && this.isEnabled && !this.isReadonly && !this.getFormControlStatus().valid;
	}


	noTabsMessage = this.localizationService.getStringWithUpdates('system.kendo-ui.components.pd-relation-tab.no-tab-message');

	private get canCreateTab(): boolean {
		let wi = this.relationTabWidgetInfo;
		return !wi || wi.canCreateTab;	
	}

	private get canDeleteTab(): boolean {
		let wi = this.relationTabWidgetInfo;
		return !wi || wi.canDeleteTab;	
	}

	private _metaDataChangedSubscription: Subscription;

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, private changeDetector: ChangeDetectorRef,
		@Inject(ILocalizationServiceToken) private localizationService: ILocalizationService,
		@Inject(IInteractionServiceToken) private interactionService: IInteractionService) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();
		this.toolbarItems = [];
		//let relAccessRights = this.pdObject.metaData.getRelationMeta(this.propertyName)?.accessRights;
		if (this.canCreateTab) {
			/*this._tbItemCreate = {
				id: ToolBarButtonIdET.New, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-plus',
				shortDescriptionId: 'system.kendo-ui.components.pd-relation-tab.tool-bar-button-new'
				//disabled: (relAccessRights && relAccessRights.create !== undefined) ? !relAccessRights.create : undefined
			};
			this.toolbarItems.push(this._tbItemCreate);*/
			this.toolbarItems.push(
				<IToolBarButtonSpec>{
					id: ToolBarButtonIdET.New, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-plus',
					shortDescriptionId: 'system.kendo-ui.components.pd-relation-tab.tool-bar-button-new'
				}
			);
		}
		if (this.canDeleteTab) {
			/*this._tbItemDelete = {
				id: ToolBarButtonIdET.Delete, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-times',
				shortDescriptionId: 'system.kendo-ui.components.pd-relation-tab.tool-bar-button-delete'
				//disabled: (relAccessRights && relAccessRights.delete !== undefined) ? !relAccessRights.delete : undefined
			};
			this.toolbarItems.push(this._tbItemDelete);*/
			this.toolbarItems.push(
				<IToolBarButtonSpec>{
					id: ToolBarButtonIdET.Delete, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-times',
					shortDescriptionId: 'system.kendo-ui.components.pd-relation-tab.tool-bar-button-delete'
				}
			);
		}
	
		if (this.pdRelationTabSpec) {
			if (this.pdRelationTabSpec.objCreator) {
				this.objCreator = this.pdRelationTabSpec.objCreator;
			}
		}

		if (this.relationTabWidgetInfo && this.relationTabWidgetInfo.maxTabCount !== undefined) {
			this.maxTabCount = this.relationTabWidgetInfo.maxTabCount;
		}

		/*if (!this.styleMarginRight) {
			this.styleMarginRight = "1em";
		}*/

		/*if (!this.styleMarginBottom) {  // !this.styleMargin
			this.styleMarginBottom = "1em";
		}*/

		/*if (Array.isArray(this.pdObject.pdObjectRaw[this.pdRelationTabSpec.property])) {
			let relObjs: Array<PDObject> = this.pdObject.pdObjectRaw[this.pdRelationTabSpec.property];
			this._relationObjects = relObjs; // todo: Typecheck ob PDObject
			this._relationFormGroup = new FormGroup({});
			this.formGroup.addControl(this.pdRelationTabSpec.property, this._relationFormGroup);
			let index = 0;
			for (let relObj of this._relationObjects) {
				let relObjFormGroup = new FormGroup({});
				this._relationFormGroup.addControl(index.toString(), relObjFormGroup);
				this._relationObjectSpecs.push({ id: index.toString(), obj: relObj, formGroup: relObjFormGroup });
				index++;
			}
		}*/
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		if (this._relationObjects.length > 0) {
			this.setSelectedObject(this._relationObjects[0]);
		}
		this.updateToolbar();
	}

	ngOnDestroy() {
		if (this._metaDataChangedSubscription) {
			this._metaDataChangedSubscription.unsubscribe();
		}
		super.ngOnDestroy();
	}

	getFormControlStatus(): IFormStatus {
		let stat = <IFormStatus>{ pristine: true, touched: false, valid: true };
		if (this.panelComponents) {
			this.panelComponents.forEach(pc => {
				let panelStat = pc.getFormControlStatus();
				if (!panelStat.pristine) {
					stat.pristine = false;
				}
				if (panelStat.touched) {
					stat.touched = true;
				}
				if (!panelStat.valid) {
					stat.valid = false;
				}
			});
		}
		return stat;
	}

	getTabHeaderText(relObj: PDObject, index: number): Observable<string> {
		let wi = this.relationTabWidgetInfo;
		if (!wi || !wi.tabHeaderProvider) {
			return of(index.toString());
		}
		let header = wi.tabHeaderProvider(index, relObj, this.localizationService);
		return header.text$.pipe(
			map(text => {
				if (Number.isInteger(header.maxVisibleTextLength) && header.maxVisibleTextLength > 3 && text.length > header.maxVisibleTextLength) {
					text = text.substr(0, header.maxVisibleTextLength - 3) + '...';
				}
				return text;
			})
		);
		// todo: ggf. weg wegen tabHeaderProvider
		/*let relInfo = wi.relationInfo;
		relInfo = relInfo.replace('%relationIndex%', (index + 1).toString());
		return of(relObj.getStatus(relInfo));*/
	}

	getTabHeaderShortInfo(relObj: PDObject, index: number): Observable<string | undefined> {
		let wi = this.relationTabWidgetInfo;
		if (!wi || !wi.tabHeaderProvider) {
			return of(undefined);
		}
		let header = wi.tabHeaderProvider(index, relObj, this.localizationService);
		return header.text$.pipe(
			map(text => {
				if (Number.isInteger(header.maxVisibleTextLength) && header.maxVisibleTextLength > 3 && text.length > header.maxVisibleTextLength) {
					return text;
				}
				return undefined;
			})
		);
	}

	hasTabHeaderShortInfo(relObj: PDObject, index: number): Observable<boolean> {
		return this.getTabHeaderShortInfo(relObj, index).pipe(
			map(info => !!info)
		);
	}

	getRelationContext(relObjSpec: IRelationObjectSpec): IRelationContext {		
		return { source: relObjSpec.obj, path: this.relationPath, isMultiple: true, index: relObjSpec.id };
	}

	getComponent<T extends IComponent>(id: string, type?: ComponentTypeET): T {
		if (this.selectedObject) {
			let activeSpec = this.relationObjectSpecs.find(spec => spec.obj == this.selectedObject);
			if (activeSpec) {
				let idAdapted = this.relationPath + '.' + activeSpec.id + '.' + id;
				return <T>this.formHandler.getComponent(idAdapted, type);
			}
		}
		return undefined;
	}

	getComponentFromTab<T extends IComponent>(tabId: string, id: string, type?: ComponentTypeET): T {
		let spec = this.relationObjectSpecs.find(s => s.id === tabId);
		if (spec) {
			let idAdapted = this.relationPath + '.' + spec.id + '.' + id;
			return <T>this.formHandler.getComponent(idAdapted, type);
		}
		return undefined;
	}

	getComponentFromAllTabs<T extends IComponent>(id: string, type?: ComponentTypeET): T[] {
		let comps = [];
		this.relationObjectSpecs.forEach(spec => {
			let idAdapted = this.relationPath + '.' + spec.id + '.' + id;
			comps.push(this.formHandler.getComponent(idAdapted, type));
		});
		return comps;
	}

	getTabIdForComponent(comp: IComponent): string | undefined {
		if (comp.id.startsWith(this.relationPath)) {
			let parts = comp.id.split('.');
			if (parts.length >= 3) {
				let tabId = parts[parts.length - 2];
				if (this.relationObjectSpecs.find(s => s.id === tabId)) {
					return tabId;
				}
			}
		}
		return undefined;
	}

	removeTab(tabId: string): boolean {
		let spec = this.relationObjectSpecs.find(s => s.id === tabId);
		if (spec) {
			this.deleteRelationObjectImpl(spec.obj);
			return true;
		}
		return false;
	}

	removeAllTabs(): void {
		this.relationObjectSpecs.forEach(spec => {
			this.deleteRelationObjectImpl(spec.obj);
		});
	}

	createNewTab(): string {
		return this.createRelationObject();
	}

	recreateTabs(): void {
		this.resetRelationObjects();
	}

	getTabPanel(obj: PDObject): any { // todo: IPDPanelComponent
		//return this._relationObjectSpecs.find(spec => spec.obj === obj);
		return this.panelComponents ? this.panelComponents.find(c => c.pdObject === obj) : undefined;
	}

	onTabItemClick(item: PDObject) {
		if (!this._disabled) {
			this.setSelectedObject(item);
		}
	}

	onToolBarButtonClick(item: IToolBarItemResult) {
		switch (item.id) {
			case ToolBarButtonIdET.New:
				this.createRelationObject();
				break;
			case ToolBarButtonIdET.Delete:
				this.deleteRelationObject();
				break;
		}
	}

	protected onLanguageChanged() {
		super.onLanguageChanged();
		if (this.pdRelationTabSpec && this.pdRelationTabSpec.property) {
			this._pdObject.metaData.getShortDescription(this.pdRelationTabSpec.property).subscribe(res => this._shortDescription = res);
		}
	}

	protected onPDObjectChanging() {
		if (this._metaDataChangedSubscription) {
			this._metaDataChangedSubscription.unsubscribe();
			this._metaDataChangedSubscription = undefined;
		}
	}

	protected onPDObjectChanged() {
		let mandatory = this.pdRelationTabSpec ? this.pdObject.metaData.isMandatory(this.pdRelationTabSpec.property) : undefined;
		this._isMandatoryModel = mandatory !== undefined ? mandatory : false;
		this.resetRelationObjects();
		this._metaDataChangedSubscription = this.pdObject.metaData.metaDataChanged$.subscribe(
			() => this.updateToolbar()
		);
	}

	private resetRelationObjects(): void {
		if (this._relationFormArray) {
			while (this._relationFormArray.length > 0) {
				this._relationFormArray.removeAt(0);
			}
			if (this.formGroup.get(this.pdRelationTabSpec.property)) {
				this.formGroup.removeControl(this.pdRelationTabSpec.property);
			}
		}
		this._relationObjectSpecs = [];
		this._relationObjects = [];
		this.changeDetector.detectChanges();
		if (this.pdRelationTabSpec && this.pdRelationTabSpec.property &&
			Array.isArray(this.pdObject.pdObjectRaw[this.pdRelationTabSpec.property])) {
			let relObjs: Array<PDObject> = this.pdObject.pdObjectRaw[this.pdRelationTabSpec.property];
			this._relationObjects = [...relObjs];
			this._relationFormArray = new FormArray([], this.isRequired ? Validators.required : undefined);
			if (this.pdObject.metaData.isMandatory(this.pdRelationTabSpec.property)) {
				this._relationFormArray.setValidators(Validators.required);
			}
			this.formGroup.addControl(this.pdRelationTabSpec.property, this._relationFormArray);
			let index = 0;
			for (let relObj of this._relationObjects) {
				let relObjFormGroup = new FormGroup({ _className: new FormControl((<CustomPDObject>relObj).getClassName()) });
				this._relationFormArray.push(relObjFormGroup);
				this._relationObjectSpecs.push({ id: index.toString(), obj: relObj, formGroup: relObjFormGroup });
				index++;
			}
			this.changeDetector.detectChanges();
			for (let relObj of this._relationObjects) {
				this.setSelectedObject(relObj);
				//let panel = this.getTabPanel(relObj);
				let spec = this._relationObjectSpecs.find(s => s.obj === relObj);
				this.relationObjectCreated.emit(spec.id);
				this._relationObjectCreatedSubject$.next(spec.id);
			}
			if (this._relationObjects.length > 0) {
				this.setSelectedObject(this._relationObjects[0]);
			}
		}
		this.updateToolbar();
	}

	private get relationPath(): string {
		return this.relationContext ? this.relationContext.path + '.' + this.pdRelationTabSpec.property : this.pdRelationTabSpec.property;
	}

	private createRelationObject(): string {
		if (!this._createRelationObjectCallback && !this.objCreator) {
			throw new Error('createRelationObjectCallback and objCreator are undefined');
		}

		let index = 0;
		while (this._relationObjectSpecs.find(item => item.id === index.toString())) {
			index++;
		}
		let relObj = this._createRelationObjectCallback ? this._createRelationObjectCallback() : new this.objCreator();
		this._relationObjects.push(relObj);
		let relObjFormGroup = new FormGroup({ _className: new FormControl((<CustomPDObject>relObj).getClassName()) });
		this._relationFormArray.push(relObjFormGroup);
		let tabId = index.toString();
		this._relationObjectSpecs.push({ id: tabId, obj: relObj, formGroup: relObjFormGroup });
		this.changeDetector.detectChanges();
		this.setSelectedObject(relObj);
		this.relationObjectCreated.emit(tabId);
		this._relationObjectCreatedSubject$.next(tabId);
		this.updateToolbar();
		return tabId;
	}

	private deleteRelationObject(): void {
		forkJoin(
			[
				this.localizationService.getSystemString('kendo-ui.components.pd-relation-tab.remove-record-title'),
				this.localizationService.getSystemString('kendo-ui.components.pd-relation-tab.remove-record')
			]
		).pipe(
			switchMap(res => this.interactionService.showConfirmMessage(res[0], res[1])),
			tap(res => {
				if (res === DialogResultET.Ok) {
					this.deleteRelationObjectImpl(this._selectedObject);
				}
			})
		).subscribe();
	}

	private deleteRelationObjectImpl(relObj: PDObject): void {
		let index = this._relationObjects.indexOf(relObj);
		if (index >= 0) {
			this.relationObjectDeleting.emit(relObj);
			this._relationObjectDeletingSubject$.next(relObj);
			this._relationObjects.splice(index, 1);
			this._relationFormArray.removeAt(index);
			this._relationObjectSpecs.splice(index, 1);
			if (relObj === this._selectedObject) {
				this.setSelectedObject(this._relationObjects.length > 0 ? this._relationObjects[0] : undefined);
			}
			this.relationObjectDeleted.emit();
			this._relationObjectDeletedSubject$.next();
			this.updateToolbar();
		}
	}

	private updateToolbar(): void {
		if (!this.toolbarItems) {
			return;
		}
		for (let tbItem of this.toolbarItems) {
			tbItem.disabled = this._disabled;
		}
		if (!this._disabled) {
			let relAccessRights = this.pdObject.metaData.getRelationMeta(this.propertyName)?.accessRights;
			if (this.canCreateTab) {
				let tbItemNew = this.toolbarItems.find(item => item.id == ToolBarButtonIdET.New);
				if (this._relationObjects.length >= this.maxTabCount) {
					tbItemNew.disabled = true;	
				}
				else {
					tbItemNew.disabled = (relAccessRights && relAccessRights.create !== undefined) ? !relAccessRights.create : undefined;
				}
			}
			if (this.canDeleteTab) {
				let tbItemDelete = this.toolbarItems.find(item => item.id == ToolBarButtonIdET.Delete);
				if (!this._selectedObject) {
					tbItemDelete.disabled = true;
				}
				else {
					tbItemDelete.disabled = (relAccessRights && relAccessRights.delete !== undefined) ? !relAccessRights.delete : undefined;
				}
			}
		}
	}

	public getId(): string | undefined {
		/*let property = this.pdRelationTabSpec.property;
		if (property) {
			if (this.relationContext) {
				let ctrlId = this.relationContext.path;
				return this.relationContext.isMultiple ?
					this.relationContext.path + '.' + this.relationContext.index + '.' + property :
					this.relationContext.path + '.' + property;
			}
			return property;
		}
		return this.uiItemSpec.id;*/
		if (this.pdRelationTabSpec) {
			return this.getIdFromPropertyName(this.pdRelationTabSpec.property);
		}
		return super.getId();
	}

	protected hasId(): boolean {
		if (this.pdRelationTabSpec && !!this.pdRelationTabSpec.property) {
			return true;
		}
		return super.hasId();
	}

	//
	// IComponent overrides
	//

	/*get id(): string | undefined {
		let property = this.pdRelationTabSpec.property;
		if (property) {
			if (this.relationContext) {
				let ctrlId = this.relationContext.path;
				return this.relationContext.isMultiple ?
					this.relationContext.path + '.' + this.relationContext.index + '.' + property :
					this.relationContext.path + '.' + property;
			}
			return property;
		}
		return this.uiItemSpec.id;
	}*/

	get componentType(): ComponentTypeET {
		return ComponentTypeET.RelationTab;
	}

	get isEnabled(): boolean {
		return !this._disabled;
	}

	get isDisabled(): boolean {
		return this._disabled;
	}

	private _disabled: boolean = false;

	disable(flag: boolean): void {
		if (this._disabled != flag) {
			this._disabled = flag;
			if (this.panelComponents) {
				this.panelComponents.forEach(comp => comp.disable(flag));
			}
			this.updateToolbar();
		}
	}

	reset(): void {
		/*while (this._relationFormArray.length > 0) {
			this._relationFormArray.removeAt(0);
		}
		if (this.formGroup.get(this.pdRelationTabSpec.property)) {
			this.formGroup.removeControl(this.pdRelationTabSpec.property);
		}*/

		//this._relationObjects = [];
		//this._relationObjectSpecs = [];
		this._selectedObject = undefined;
		this.resetRelationObjects();
	}

	//
	// IPDComponent overrides
	//

	get label(): string {
		return "";
	}

	get customLabel(): string {
		return "";
	}

	get useCustomLabel(): boolean {
		return this._useCustomLabel;
	}

	set useCustomLabel(value: boolean) {
		this._useCustomLabel = value;
	}
	private _useCustomLabel = true;
	
	get propertyName(): string {
		return this.pdRelationTabSpec ? this.pdRelationTabSpec.property : undefined;
	}

	set customReadonly(flag: boolean) {}

	get customReadonly(): boolean {
		return false;
	}

	get isReadonly(): boolean {
		return false;
	}

	get control(): AbstractControl {
		return undefined; // todo
	}
}
