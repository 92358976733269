import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PDComponent, LabelWidgetInfo, FormHandlerService } from "@otris/ng-core";
import { ComponentTypeET, ICssStyle } from '@otris/ng-core-shared';

@Component({
	selector: 'otris-pd-label',
	template: `
		<div [ngStyle]="customStyle">{{staticLabel}}</div>
	`,
	styles: [`
		/*:host {
			display: flex; /* IE11 hack, da sonst der Text nicht umbrochen wird */
		}*/

		/*label {
			flex-basis: 100%; /* IE11 hack, siehe display oben */
		}*/
	`]
})
export class PDLabelComponent extends PDComponent {

	//private _staticLabel: string;

	@Input() staticLabel: string;

	private _customStyle: ICssStyle;

	@Input() set customStyle(style: ICssStyle) {
		this._customStyle = style;
	}

	get customStyle(): ICssStyle {
		return this._customStyle;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();
		this.staticLabel = this.label;
		let wi = this.labelWidgetInfo;
		if (wi) {
			if (wi.labelId && this.pdObject) {
				this.pdObject.metaData.getStringFromId(wi.labelId).subscribe(res => this.staticLabel = res);
			}
			else if (this.labelWidgetInfo.label) {
				this.staticLabel = wi.label;
			}
			if (wi.styleSpec && !this.customStyle) {
				this.customStyle = wi.styleSpec.style;
			}
		}
	}

	protected onLanguageChanged() {
		super.onLanguageChanged();
		if (this.labelWidgetInfo && this.labelWidgetInfo.labelId && this.pdObject) {
			this.pdObject.metaData.getStringFromId(this.labelWidgetInfo.labelId).subscribe(res => this.staticLabel = res);
		}
	}

	/*get staticLabel(): string {
		if (this.labelWidgetInfo) {
			if (this.labelWidgetInfo.labelId && this.pdObject) {
				return this.pdObject.metaData.getStringFromId(this.labelWidgetInfo.labelId);
			}
			else if (this.labelWidgetInfo.label) {
				return this.labelWidgetInfo.label;
			}
		}		

		return this.label;
	}*/

	private get labelWidgetInfo() {
		return this.pdItemSpec.widgetInfo instanceof LabelWidgetInfo ?
			<LabelWidgetInfo>this.pdItemSpec.widgetInfo : undefined;
	}

	//----------------------------------------------------------------------------------------------
	// IComponent
	//----------------------------------------------------------------------------------------------

	get componentType(): ComponentTypeET {
		return ComponentTypeET.Undefined;
	}
}
