import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

// Kendo UI
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { GridModule } from '@progress/kendo-angular-grid';
import { PopupModule } from '@progress/kendo-angular-popup'
import { UploadsModule } from '@progress/kendo-angular-upload';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { NavigationModule } from '@progress/kendo-angular-navigation';

//import { LoadingModule, ANIMATION_TYPES  } from 'ngx-loading';
//import { NgxSpinnerModule } from 'ngx-spinner';

import { IInteractionServiceToken, INotificationServiceToken } from '@otris/ng-core-shared';
import { OtrisNgCoreModule, IFileSystemService } from '@otris/ng-core';

import { PDInfoFieldComponent } from './components/pd-info-field/pd-info-field.component';
import { PDLabeledControlFrameComponent } from './components/pd-labeled-control-frame/pd-labeled-control-frame.component';
import { PDTextFieldComponent } from './components/pd-text-field/pd-text-field.component'
import { PDObjectReferenceComponent } from './components/pd-object-reference/pd-object-reference.component';
import { PDDateTimeFieldComponent } from './components/pd-date-time-field/pd-date-time-field.component';
import { ToolBarComponent } from './components/tool-bar/tool-bar.component';
import { DropDownToolBarButtonComponent } from './components/tool-bar/drop-down-tool-bar-button.component';
import { PDMultiSelectComponent } from './components/pd-multi-select/pd-multi-select.component';
import { PDCheckboxComponent } from './components/pd-checkbox/pd-checkbox.component';
import { PDInputSwitchComponent } from './components/pd-input-switch/pd-input-switch.component';
import { PDTabViewComponent } from './components/pd-tab-view/pd-tab-view.component';
import { PDPanelComponent } from './components/pd-panel/pd-panel.component';
import { PDGroupboxComponent } from './components/pd-groupbox/pd-groupbox.component';
import { PDGroupboxHeaderDirective } from './components/pd-groupbox/pd-groupbox-header.directive';
import { PDDropDownComponent } from './components/pd-drop-down/pd-drop-down.component';
import { PDNumericTextFieldComponent } from './components/pd-numeric-text-field/pd-numeric-text-field.component';
import { PDRadioButtonComponent } from './components/pd-radio-button/pd-radio-button.component';
import { PDAutoCompleteComponent } from './components/pd-auto-complete/pd-auto-complete.component';
import { PDRadioButtonGroupComponent } from './components/pd-radio-button-group/pd-radio-button-group.component';
import { KendoDialogContainerComponent } from './components/kendo-dialog-container/kendo-dialog-container.component';
import { ButtonComponent } from './components/button/button.component';
import { PDFormComponent } from './components/pd-form/pd-form.component';
import { PDPreviewComponent } from './components/pd-preview/pd-preview.component';
import { PDLabelComponent } from './components/pd-label/pd-label.component';
import { PDRelationTabComponent } from './components/pd-relation-tab/pd-relation-tab.component';
import { PDComboBoxComponent } from './components/pd-combo-box/pd-combo-box.component';
import { PDListComponent } from './components/pd-list/pd-list.component';
import { PDObjectSelectionComponent } from './components/pd-object-selection/pd-object-selection.component';
import { PDButtonComponent } from './components/pd-button/pd-button.component';
import { TypeETToKendoGridFilterPipe } from './pipes/typeET-to-kendo-grid-filter';
import { PopupOutletComponent } from './components/popup-outlet/popup-outlet.component';
import { SplitterComponent } from './components/splitter/splitter.component';
import { SplitterPaneDirective } from './directives/splitter-pane.directive';
import { KendoLocalizationHandlerComponent } from './components/kendo-localization-handler/kendo-localization-handler.component';
import { PDShortDescriptionComponent } from './components/pd-short-description/pd-short-description.component';
import { PDObjectEditComponent } from  './components/pd-object-edit/pd-object-edit.component';
import { PDMandatoryIndicatorComponent } from './components/pd-mandatory-indicator/pd-mandatory-indicator.component';
import { PDFileUploadComponent } from './components/pd-file-upload/pd-file-upload.component';
import { InteractionService } from './services/interaction.service';
import { NotificationService } from './services/notification.service';
import { KendoFileSystemService } from './services/kendo-file-system.service';
import { PDErrorIndicatorComponent } from './components/pd-error-indicator/pd-error-indicator.component';

import '@progress/kendo-angular-intl/locales/de/all';
import '@progress/kendo-angular-intl/locales/en/all';
import { KendoFileSelectionComponent } from './components/kendo-file-selection/kendo-file-selection.component';
import { PDDrawerComponent } from './components/pd-drawer/pd-drawer.component';
import { PDDrawerItemComponent } from './components/pd-drawer/pd-drawer-item.component';
import { TextBoxComponent } from './components/text-box/text-box.component';
import { AppBarComponent } from './components/app-bar/app-bar.component';
import { AppBarItemComponent } from './components/app-bar/app-bar-item.component';
import { ReadonlyTemplateContainerComponent } from './components/readonly-template-container/readonly-template-container.component';
import { ReadonlyTemplateDirective } from './components/readonly-template-container/readonly-template.directive';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		ButtonsModule,
		InputsModule,
		DropDownsModule,
		DateInputsModule,
		ToolBarModule,
		LayoutModule,
		DialogsModule,
		TooltipModule,
		GridModule,
		PopupModule,
		UploadsModule,
		NotificationModule,
		NavigationModule,
		OtrisNgCoreModule,
		//NgxSpinnerModule,
		/*LoadingModule.forRoot({
			animationType: ANIMATION_TYPES.threeBounce,  // circle, threeBounce
			backdropBackgroundColour: 'rgba(0,0,0,0.5)', 
			//backdropBorderRadius: '3em',
			primaryColour: 'rgba(255,99,71,0.2)', 
			secondaryColour: 'rgba(255,99,71,0.5)', 
			tertiaryColour: 'rgba(255,99,71,1)'
		})*/
	],
	declarations: [
		PDLabeledControlFrameComponent,
		PDTextFieldComponent,
		PDInfoFieldComponent,
		PDObjectReferenceComponent,
		PDDateTimeFieldComponent,
		ToolBarComponent,
		DropDownToolBarButtonComponent,
		PDMultiSelectComponent,
		PDCheckboxComponent,
		PDInputSwitchComponent,
		PDTabViewComponent,
		PDPanelComponent,
		PDFileUploadComponent,
		PDGroupboxComponent,
		PDGroupboxHeaderDirective,
		PDDropDownComponent,
		PDComboBoxComponent,
		PDNumericTextFieldComponent,
		PDRadioButtonComponent,
		PDAutoCompleteComponent,
		PDRadioButtonGroupComponent,
		KendoDialogContainerComponent,
		ButtonComponent,
		PDFormComponent,
		PDPreviewComponent,
		PDLabelComponent,
		PDRelationTabComponent,
		PDObjectSelectionComponent,
		PDListComponent,
		PDButtonComponent,
		PopupOutletComponent,
		TypeETToKendoGridFilterPipe,
		SplitterComponent,
		SplitterPaneDirective,
		KendoLocalizationHandlerComponent,
		PDShortDescriptionComponent,
		PDObjectEditComponent,
		PDMandatoryIndicatorComponent,
		PDErrorIndicatorComponent,
		KendoFileSelectionComponent,
		PDDrawerComponent,
		PDDrawerItemComponent,
		TextBoxComponent,
		AppBarComponent,
		AppBarItemComponent,
		ReadonlyTemplateContainerComponent,
		ReadonlyTemplateDirective
	],
	exports: [
		PDLabeledControlFrameComponent,
		PDTextFieldComponent,
		PDInfoFieldComponent,
		PDObjectReferenceComponent,
		PDDateTimeFieldComponent,
		ToolBarComponent,
		DropDownToolBarButtonComponent,
		PDMultiSelectComponent,
		PDCheckboxComponent,
		PDInputSwitchComponent,
		PDTabViewComponent,
		PDPanelComponent,
		PDFileUploadComponent,
		PDGroupboxComponent,
		PDGroupboxHeaderDirective,
		PDDropDownComponent,
		PDComboBoxComponent,
		PDNumericTextFieldComponent,
		PDRadioButtonComponent,
		PDAutoCompleteComponent,
		PDRadioButtonGroupComponent,
		KendoDialogContainerComponent,
		ButtonComponent,
		PDFormComponent,
		PDPreviewComponent,
		PDLabelComponent,
		PDRelationTabComponent,
		PDObjectSelectionComponent,
		PDListComponent,
		PDButtonComponent,
		PopupOutletComponent,
		TypeETToKendoGridFilterPipe,
		SplitterComponent,
		SplitterPaneDirective,
		KendoLocalizationHandlerComponent,
		PDShortDescriptionComponent,
		PDObjectEditComponent,
		//LoadingModule,
		//NgxSpinnerModule,
		OtrisNgCoreModule,
		PDMandatoryIndicatorComponent,
		PDErrorIndicatorComponent,
		KendoFileSelectionComponent,
		PDDrawerComponent,
		TextBoxComponent,
		AppBarComponent,
		AppBarItemComponent,
		ReadonlyTemplateContainerComponent,
		ReadonlyTemplateDirective
	],
	entryComponents: [
		PDObjectSelectionComponent
	],
	providers: [
		// todo: providedIn Mechanismus benutzen, wahrscheinlich ist dafür hier eine abstrakte Klasse notwendig
		{ provide: IInteractionServiceToken, useClass: InteractionService },
		{ provide: INotificationServiceToken, useClass: NotificationService },
		{ provide: IFileSystemService, useClass: KendoFileSystemService }
	]
	/*schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	]*/
})
export class OtrisKendoUIModule { }
