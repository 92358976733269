import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PDItemSpec, FlexItemPlacement, TextFieldWidgetInfo, PDLabeledControlComponent, FormHandlerService } from '@otris/ng-core';
import { ComponentTypeET, ITextFieldComponent } from '@otris/ng-core-shared';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

// [attr.readonly]="isReadonly"
// [readonly]="isReadonly"
// [readonly]="isReadonly"
// [label]="label" [toolbarSpec]="toolbarSpec" [isRequired]="isRequired" [enabled]="isEnabled" [shortDescription] = "shortDescription"
@Component({
	selector: 'otris-pd-text-field',
	template: `
		<otris-pd-labeled-control-frame [labeledControl]="this" (toolBarButtonClick)="onToolBarButtonClick($event)" propagateClickEvent="true" (labelClick)="onLabelClick()" [relatedFormControl]="this.control" [pdObject]="pdObject">
			<ng-container *ngIf="!isReadonly; else readonly">
				<textarea #textArea *ngIf="multiline; else multiline_else" kendoTextArea class="textarea" [ngClass]="{'textarea-stretch': textFieldWidgetInfo?.stretch}"
					[formControl]="formGroup.controls[propertyName]" [rows]="textAreaRows" [readonly]="isReadonly">
				</textarea>
				<ng-template #multiline_else>
					<div class="input-container" kendoTooltip filter="span">
						<input #input class="k-textbox input-ctrl" [formControl]="formGroup.controls[propertyName]" [readonly]="isReadonly"/>
					</div>
				</ng-template>
			</ng-container>
			
			<ng-template #readonly>
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container> <!-- todo -->
				<!--div style="background-color: grey; color: red;">{{text}}</div-->
			</ng-template>
		</otris-pd-labeled-control-frame>
	`,
	styles: [`
		:host {
			display: flex;
		}
		.errorImage {
			margin: auto 0 auto 3px;
			color: red;
		}
		.textarea {
			align-self: flex-start; /* 100p fix */
			width: 100%;
			resize: none;
		}
		.textarea-stretch {
			/* flex: 1 1 auto; 100p fix */
			align-self: stretch;
		}
		.input-container {
			display: flex;
			flex: 1 1 auto;
		}
		.input-ctrl {
			flex: 1 1 auto;
		}
	`]
})
export class PDTextFieldComponent extends PDLabeledControlComponent implements ITextFieldComponent {
	@ViewChild('input') inputElement: ElementRef;
	@ViewChild('textArea') textAreaElement: ElementRef;

	multiline: boolean = false;

	textAreaRows: number = 2;

	get textFieldWidgetInfo() {
		return this.pdItemSpec.widgetInfo instanceof TextFieldWidgetInfo ?
			<TextFieldWidgetInfo>this.pdItemSpec.widgetInfo : undefined;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();
		this._valueChangesSubject$ = new BehaviorSubject(this.control ? this.control.value : undefined);
		let widgetInfo = this.textFieldWidgetInfo;
		if (widgetInfo && widgetInfo.multiline) {
			this.multiline = true;
			if (widgetInfo.rows) {
				this.textAreaRows = widgetInfo.rows;
			}
		}
	}

	onLabelClick() {
		if (this.inputElement) {
			this.inputElement.nativeElement.focus();
		}
		else if (this.textAreaElement) {
			this.textAreaElement.nativeElement.focus();
		}
	}

	protected onReadonlyChanged(): void {
		// if(!this.multiline) {
		// 	super.onReadonlyChanged();
		// }
	}

	protected onControlValueChanges(val: any) {
		super.onControlValueChanges(val);
		if (this._valueChangesSubject$) {
			this._valueChangesSubject$.next(val);
		}
	}

	protected getCustomValidators(): Observable<ValidatorFn[] | undefined> {
		return this.pdObject.metaData.getMaxStringLength(this.propertyName).pipe(
			map(res => {
				if (Number.isInteger(res)) {
					return [Validators.maxLength(res)];
				}
				return undefined;
			})
		);
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.TextField;
	}

	//
	// Interface ITextFieldComponent
	//

	get text(): string {
		return this.control ? this.control.value : '';
	}

	set text(val: string) {
		if (!this.control) {
			return;
		}
		this.control.setValue(val);
		this.control.markAsDirty();
	}

	private _valueChangesSubject$: BehaviorSubject<string>; // = new Subject();

	private _valueChanges$: Observable<string>;

	get valueChanges$(): Observable<string> {
		if (!this._valueChanges$) {
			this._valueChanges$ = this._valueChangesSubject$.asObservable();
		}
		return this._valueChanges$;
	}
}
