import { Injectable, Inject, InjectionToken, LOCALE_ID, inject } from '@angular/core';
import { Observable, BehaviorSubject, forkJoin, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ErgName, LanguageCodeET } from '../model/types';

export interface ILanguage {
	code: LanguageCodeET;
	shortName: string;
}

export interface IAppModuleLocalizations {
	systemModuleLocs: ISystemModuleLocalizations[];
	appLocs: IAppLocalizations;
}

export interface ISystemModuleLocalizations {
	module: string;
	subModules?: ISystemModuleLocalizations[];
	services?: IServiceLocalizations[];
	components?: IComponentLocalizations[];
}

export interface IServiceLocalizations {
	service: string;
	items: IItemLocalization[];
}

export interface IComponentLocalizations {
	component: string;
	items: IItemLocalization[];
}

export interface IAppLocalizations {
	classes?: IClassLocalizations[];
	enums?: IEnumLocalizations[];
	strings?: {
		component: string;
		items?: {
			id: string;
			locs: IUIItemLocalization;
		}[];
	}[];
}

export interface IClassLocalizations {
	name: string;
	title?: ErgName;
	properties?: {
		name: string;
		locs: IUIItemLocalization;
	}[];
	strings?: IItemLocalization[];
}

export interface IEnumLocalizations {
	name: string;
	locs: IItemLocalization[];
}

export interface IItemLocalization {
	id: string;
	translations: ErgName;
}

export interface IUIItemLocalization {
	label?: ErgName;
	shortDescription?: ErgName;
}

/*export interface ITranslation {
	lang: string;
	trans: string;
}*/

export const ILocalizationServiceToken = new InjectionToken<ILocalizationService>(
	'ILocalizationServiceToken',
	{
		providedIn: 'root',
		factory: () => new LocalizationService(inject(TranslateService), inject(LOCALE_ID))
	}
);

@Injectable()
export abstract class ILocalizationService {
	abstract allLanguages: ILanguage[];
	abstract activatedLanguages: ILanguage[];
	abstract currentLanguage: ILanguage | undefined;
	abstract changeHandler: Observable<ILanguage>;
	abstract setCurrentLanguage(lang: LanguageCodeET): void;
	abstract setActivatedLanguages(langs: LanguageCodeET[], defaultLang: LanguageCodeET): void;
	abstract getEnumLiteralErgName(enumName: string, literal: number): Observable<string>;
	abstract getPropertyLabel(cls: string, prop: string): Observable<string>;
	abstract getPropertyShortDescription(cls: string, prop: string): Observable<string>;

	/**
	 * Liefert die Übersetzung von Klassen-Strings aus - Schema: "app.class.${cls}.strings.${id}"
	 * 
	 * @param cls Klasse - Beispiel: "VerfahrenGemeldet", "AVContainer"
	 * @param id String id
	 */
	abstract getClassString(cls: string, id: string): Observable<string>;

		/**
	 * Liefert die Übersetzung von Klassen-Strings aus - Schema: "app.class.${cls}.strings.${id}"
	 * und reagiert auf Sprachänderungen.
	 * 
	 * @param cls Klasse - Beispiel: "VerfahrenGemeldet", "AVContainer"
	 * @param id String id
	 */
	abstract getClassStringWithUpdate(cls: string, id: string): Observable<string>;

	abstract getClassErgName(cls: string): Observable<string>;

	/**
	 * Liefert die Übersetzung von einem system string (ng-core, privacy-ng-lib Ebene).
	 * 
	 * @param id i18n Pfad ohne 'system.'
	 * @param paramsObj Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getSystemString(id: string, paramsObj?: Object): Observable<string>;
	
	/**
	 * Liefert die Übersetzung von einem system string (ng-core, privacy-ng-lib Ebene).
	 * Reagiert zusätzlich auf Sprachänderung.
	 * 
	 * @param id i18n Pfad ohne 'system.'
	 * @param paramsObj Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getSystemStringWithUpdates(ids: string, paramsObjs?: Object): Observable<string>;

	/**
	 * Liefert die Übersetzungen von system strings (ng-core, privacy-ng-lib Ebene).
	 * Wobei die Reihenfolge der jeweiligen Argumenten passen müssen.
	 * (paramsObj[2] für ids[2] etc.)
	 * 
	 * @param id i18n Pfad ohne 'system.'
	 * @param paramsObj Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getSystemStrings(ids: string[], paramsObjs?: Object[]): Observable<string[]>;

	abstract getString(id: string, paramsObj?: Object): Observable<string>;

	/**
	 * Liefert die Übersetzung von strings mit optionaler paramsObj.
	 * Wobei die Reihenfolge der jeweiligen Argumenten passen müssen.
	 * (paramsObj[2] für ids[2] etc.)
	 * 
	 * @param ids Array mit vollständigen i18n Pfad
	 * @param paramsObjs Array mit Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getStrings(ids: string[], paramsObjs?: Object[]): Observable<string[]>;

	/**
	 * Liefert wie gewöhnlich ein übersetzen String. Allerdings reagiert dieser
	 * auch auf Sprachänderungen, mithilfe der changeHandler Funktion.
	 * FIXME: id param kann ja nur zu falsch-benutzung führen
	 * 
	 * @param id Vollständigen i18n Pfad OHNE '.strings.'
	 * @param paramsObjs Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getStringWithUpdates(id: string, paramsObjs?: Object): Observable<string>;

	abstract get activatedLanguagesChanged$(): Observable<LanguageCodeET[]>;

	abstract set appModuleLocalizations(value: IAppModuleLocalizations);

	/**
	 * Liefert die Übersetzung eines string mit optionaler paramsObj.
	 * 
	 * @param id Vollständiger i18n Pfad
	 * @param paramsObjs Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getStringFromId(id: string): Observable<string>;

		/**
	 * Liefert die Übersetzung eines string mit optionaler paramsObj. Allerdings 
	 * reagiert dieser auch auf Sprachänderungen, mithilfe der changeHandler Funktion.
	 * 
	 * @param id Vollständiger i18n Pfad
	 * @param paramsObjs Zusatzparameter für den String, z.B. json: "Hallo, {name}"  object: {"name": user.name}
	 */
	abstract getStringFromIdWithUpdates(id: string): Observable<string>;
}

@Injectable()
export class LocalizationService implements ILocalizationService {

	private _changeHandler: BehaviorSubject<ILanguage> = new BehaviorSubject<ILanguage>(undefined);

	private _allLanguages: Map<LanguageCodeET, ILanguage> = new Map<LanguageCodeET, ILanguage>();

	private _activatedLanguages: LanguageCodeET[] = null;

	get allLanguages(): ILanguage[] {
		return Array.from(this._allLanguages.values());
	}

	get activatedLanguages(): ILanguage[] {
		if (this._activatedLanguages !== null) {
			return [...this._allLanguages.values()].filter(l => this._activatedLanguages.includes(l.code));
		}
		return [];
	}

	private _currentLanguage: LanguageCodeET;
	get currentLanguage(): ILanguage | undefined {
		return this._allLanguages.get(this._currentLanguage);
	}

	get changeHandler(): Observable<ILanguage> {
		return this._changeHandler.asObservable();
	}

	private _activatedLanguagesChanged$: Observable<LanguageCodeET[]>;

	private _activatedLanguagesChangedSubject$ = new Subject<LanguageCodeET[]>();

	private _appModuleLocalizations: IAppModuleLocalizations;


	constructor(private translate: TranslateService, @Inject(LOCALE_ID) public localeId: string) {
		this._allLanguages.set(LanguageCodeET.de, <ILanguage>{ code: LanguageCodeET.de, shortName: 'de' });
		this._allLanguages.set(LanguageCodeET.en, <ILanguage>{ code: LanguageCodeET.en, shortName: 'en' });
		//this._allLanguages.set(LanguageCodeET.fr, <ILanguage>{ code: LanguageCodeET.fr, shortName: 'fr' });

		let defaultLang = LanguageCodeET.de;
		let browserLang = navigator.language;
		let pos = browserLang.indexOf('-');
		if (pos > 0) {
			browserLang = browserLang.substr(0, pos);
			let lang = Array.from(this._allLanguages.values()).find(l => l.shortName === browserLang);
			if (lang) {
				defaultLang = lang.code
			}
		}
		this.setActivatedLanguages(Array.from(this._allLanguages.keys()), defaultLang);
	}

	setActivatedLanguages(langs: LanguageCodeET[], defaultLang: LanguageCodeET): void {
		if (langs.length == 0) {
			throw new Error('Error in LocalizationService.setActivatedLanguages(). Details: No languages specified.');
		}
		if (!langs.includes(defaultLang)) {
			defaultLang = langs[0];
		}
		this._activatedLanguages = []; 
		for (let lang of langs) {
			if (!this._allLanguages.has(lang)) {
				throw new Error('Error in LocalizationService.setActivatedLanguages(). Details: Unknown language.');
			}
			this._activatedLanguages.push(lang);
		}

		this.translate.setDefaultLang(this.activatedLanguages.find(l => l.code === defaultLang).shortName);
		let newLangs = [];
		this.activatedLanguages.filter(l => l.code !== defaultLang).map(l => l.shortName).forEach(l => {
			if (!this.translate.langs.includes(l)) {
				newLangs.push(l);
			}
		});
		if (newLangs.length > 0) {
			this.translate.addLangs(newLangs);
		}
		this.setCurrentLanguage(defaultLang);
		this._activatedLanguagesChangedSubject$.next(this._activatedLanguages);
	}

	setCurrentLanguage(langCode: LanguageCodeET): void {
		if (langCode != this._currentLanguage) {
			if (this._activatedLanguages.includes(langCode)) {
				let lang = this._allLanguages.get(langCode);
				this._currentLanguage = langCode;
				this.localeId = lang.shortName;
				this.translate.use(lang.shortName).subscribe(
					() => {
						this._changeHandler.next(lang);
					}
				);
			}
			else {
				throw new Error('Error in LocalizationService.setCurrentLanguage(). Details: Invalid language code.');
			}
		}
	}

	getPropertyLabel(cls: string, prop: string): Observable<string> {
		if (!cls || !prop) {
			return of('');
		}
		//return this.translate.get(`app.class.${cls}.properties.${prop}.label`).map(res => <string>res);
		return this.getTranslation(`app.class.${cls}.properties.${prop}.label`);
	}

	getPropertyShortDescription(cls: string, prop: string): Observable<string> {
		if (!cls || !prop) {
			return of('');
		}
		//return this.translate.get(`app.class.${cls}.properties.${prop}.short-description`).map(res => <string>res);
		return this.getTranslation(`app.class.${cls}.properties.${prop}.short-description`);
	}

	getEnumLiteralErgName(enumName: string, literal: number): Observable<string> {
		if (!enumName) {
			return of('');
		}
		let path = 'app.enum.' + enumName + '.' + literal.toString();
		//return this.translate.get(path).map(res => <string>res);
		return this.getTranslation(path);
	}

	getClassString(cls: string, id: string): Observable<string> {
		if (!cls || !id) {
			return of('');
		}
		//return this.translate.get(`app.class.${cls}.strings.${id}`).map(res => <string>res);
		return this.getTranslation(`app.class.${cls}.strings.${id}`);
	}

	getClassStringWithUpdate(cls: string, id: string): Observable<string> {
		if (!cls || !id) {
			return of('');
		}
		return this.changeHandler.pipe(
			switchMap(() => this.getTranslation(`app.class.${cls}.strings.${id}`))
		)
	}

	getClassErgName(cls: string): Observable<string> {
		if (!cls) {
			return of('');
		}
		return this.getTranslation(`app.class.${cls}.title`).pipe(map(res => <string>res));
	}

	getSystemStrings(ids: string[], paramsObjs?: Object[]): Observable<string[]> {
		let observableBatch: Observable<string>[] = [];

		for (let i = 0; i < ids.length; i++) {
			if (paramsObjs && paramsObjs[i]) {
				//observableBatch.push(this.translate.get(`system.${ids[i]}`, paramsObjs[i]));
				observableBatch.push(this.getTranslation(`system.${ids[i]}`, paramsObjs[i]));
			} else {
				observableBatch.push(this.getTranslation(`system.${ids[i]}`));
			}
		}

		return forkJoin(
			observableBatch
		)
	}

	getSystemString(id: string, paramsObj?: Object): Observable<string> {
		if (!id) {
			return of('');
		}
		let path = 'system.' + id; 
		//return this.translate.get(path, paramsObj).map(res => <string>res);
		return this.getTranslation(path, paramsObj);
	}

	getSystemStringWithUpdates(id: string, paramsObjs?: Object): Observable<string> {
		return this.changeHandler.pipe(
			switchMap(() => this.getSystemString(id, paramsObjs))
		);
	}

	getString(id: string, paramsObj?: Object): Observable<string> {
		if (!id) {
			return of('');
		}
		if (id.startsWith('system.')) {
			return this.getSystemString(id.substr(7), paramsObj);
		}
		if (id.startsWith('app.')) {
			let idRest = id.substr(4);
			if (idRest.startsWith('class.')) {
				let pos = idRest.indexOf('.', 6);
				if (pos > 0) {
					let cls = idRest.substring(6, pos);
					pos = idRest.indexOf('.strings.');
					if (pos > 0) {
						let str = idRest.substr(pos + 9);
						return this.getClassString(cls, str);
						//return this.getClassString(idRest.substring(6, pos), idRest.substr(pos + 1));
					}
				}
			}
			else if (idRest.startsWith('strings.')) {
				return this.getTranslation(id, paramsObj);
				//return this.translate.get(id, paramsObj).map(res => <string>res);
			}
		}
		return of(id);
	}

	getStrings(ids: string[], paramsObjs?: Object[]): Observable<string[]> {

		let observableBatch: Observable<string>[] = [];

		for(let i = 0; i < ids.length; i++) {
			if (paramsObjs && paramsObjs[i]) {
				observableBatch.push(this.getTranslation(ids[i], paramsObjs[i]));
			} else {
				observableBatch.push(this.getTranslation(ids[i]));
			}
		}

		return forkJoin(
			observableBatch
		)
	}

	getStringWithUpdates(id: string, paramsObjs?: Object): Observable<string> {
		return this.changeHandler.pipe(
			switchMap(() => this.getString(id, paramsObjs))
		);
	}

	getStringFromId(id: string, paramsObjs?: Object[]): Observable<string> {
		return this.getTranslation(id, paramsObjs);
	}

	getStringFromIdWithUpdates(id: string, paramsObjs?: Object[]): Observable<string> {
		return this.changeHandler.pipe(
			switchMap(() => this.getTranslation(id, paramsObjs))
		);
	}

	get activatedLanguagesChanged$(): Observable<LanguageCodeET[]> {
		if (!this._activatedLanguagesChanged$) {
			this._activatedLanguagesChanged$ = this._activatedLanguagesChangedSubject$.asObservable();
		}
		return this._activatedLanguagesChanged$;
	}

	set appModuleLocalizations(appModuleLocs: IAppModuleLocalizations) {
		this._appModuleLocalizations = appModuleLocs;
	}

	private getTranslation(id: string, paramsObj?: Object): Observable<string> {
		let translate = (translations?: ErgName) => {
			if (translations) {
				let trans = translations.find(t => t[0] === this.currentLanguage.shortName);
				if (trans) {
					if (paramsObj) {
						Object.keys(paramsObj).forEach(k => {
							//let regExp = new RegExp('{{' + k + '}}', 'g');
							let regExp = new RegExp(`{{${k}}}`, 'g');
							return of(trans[1].replace(regExp, paramsObj[k]));
						});
					}
					return of(trans[1]);
				}
			}
			return this.translate.get(id, paramsObj).pipe(map(res => <string>res));
		};

		let translateSystemModuleItem = (systemModuleLoc: ISystemModuleLocalizations, parts: string[]) => {
			if (parts.length >= 3) {
				let category = parts[0];

				// Submodul
				if (category !== 'components' && category !== 'services') {
					if (systemModuleLoc.subModules) {
						let subModuleLoc = systemModuleLoc.subModules.find(m => m.module === category);
						if (subModuleLoc) {
							return translateSystemModuleItem(subModuleLoc, parts.slice(1));
						}
					}
					return translate();
				}

				let partName = parts[1];
				let itemName = parts.slice(2).reduce((res, cur) => {
					if (res.length > 0) {
						res += '.';
					}
					res += cur;
					return res;
				}, '');
				switch (category) {
					case 'components':
						if (systemModuleLoc.components) {
							let comp = systemModuleLoc.components.find(c => c.component === partName);
							if (comp && comp.items) {
								let item = comp.items.find(i => i.id === itemName)
								return translate(item ? item.translations : undefined);
							}
						}
						break;

					case 'services':
						if (systemModuleLoc.services) {
							let service = systemModuleLoc.services.find(item => item.service === partName);
							if (service && service.items) {
								let item = service.items.find(i => i.id === itemName)
								return translate(item ? item.translations : undefined);
							}												
						}
						break;
				}
			}
			return translate();
		};

		if (this._appModuleLocalizations) {
			let parts = id.split('.');
			if (parts.length > 0) {
				switch (parts[0]) {
					case 'system':
						if (parts.length >= 5 && this._appModuleLocalizations.systemModuleLocs) {
							let module = parts[1];
							let systemModuleLoc: ISystemModuleLocalizations = this._appModuleLocalizations.systemModuleLocs.find(m => m.module === module);
							if (systemModuleLoc) {
								return translateSystemModuleItem(systemModuleLoc, parts.slice(2));
							}
						}
						/*if (parts.length >= 5) {
							let module = parts[1];
							let category = parts[2];
							let partName = parts[3]
							let itemName = parts[4];
							if (this._appModuleLocalizations.systemModuleLocs) {
								let systemModuleLoc: ISystemModuleLocalizations = this._appModuleLocalizations.systemModuleLocs.find(m => m.module === module);
								if (systemModuleLoc) {									
									if (category === 'components' && systemModuleLoc.components) {
										let comp = systemModuleLoc.components.find(c => c.component === partName);
										if (comp && comp.items) {
											let item = comp.items.find(i => i.id === itemName)
											return translate(item ? item.translations : undefined);
										}
									}
									if (category === 'services' && systemModuleLoc.services) {
										let service = systemModuleLoc.services.find(item => item.service === partName);
										if (service && service.items) {
											let item = service.items.find(i => i.id === itemName)
											return translate(item ? item.translations : undefined);
										}
									}
								}
							}
						}*/
						break;

					case 'app':
						if (this._appModuleLocalizations.appLocs && parts.length >= 3) {
							switch (parts[1]) {
								case 'class':
									{
										let classes = this._appModuleLocalizations.appLocs.classes;
										let classLoc = classes ? classes.find(c => c.name === parts[2]) : undefined;
										if (classLoc && parts.length >= 4) {
											switch (parts[3]) {
												case 'title':
													return translate(classLoc.title);

												case 'properties':
													if (classLoc.properties  && parts.length >= 6) {
														let prop = classLoc.properties.find(p => p.name === parts[4]);
														if (prop && prop.locs) {
															switch (parts[5]) {
																case 'label':
																	return translate(prop.locs.label);

																case 'short-description':
																	return translate(prop.locs.shortDescription);
															}
														}
													}
													break;

												case 'strings':
													if (classLoc.strings && parts.length >= 5) {
														let str = classLoc.strings.find(s => s.id === parts[4]);
														return translate(str ? str.translations : undefined);
													}
													break;

											}
										}
										break;
									}

								case 'enum':
									{
										let enumLocs = this._appModuleLocalizations.appLocs.enums;
										if (enumLocs && parts.length >= 4) {
											let enumLoc = enumLocs.find(e => e.name === parts[2]);
											let locs = (enumLoc && enumLoc.locs) ? enumLoc.locs.find(l => l.id === parts[3]) : undefined;
											return translate(locs ? locs.translations : undefined);
										}
										break;
									}

								case 'strings':
									{
										let strings = this._appModuleLocalizations.appLocs.strings;
										if (strings && parts.length >= 5) {
											let str = strings.find(s => s.component === parts[2]);
											let item = (str && str.items) ? str.items.find(s => s.id === parts[3]) : undefined;
											if (item && item.locs) {
												switch (parts[4]) {
													case 'label':
														return translate(item.locs.label);
													case 'short-description':
														return translate(item.locs.shortDescription);
												}
											}
										}
										break;
									}
							}
						}					
				}
			}
		}

		return translate();
		//return this.translate.get(id, paramsObj).map(res => <string>res);
	}
}
