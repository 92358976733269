import { InjectionToken, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export const IAsyncActionManagerServiceToken = new InjectionToken<IAsyncActionManagerService>(
	'IAsyncActionManagerToken',
	{
		providedIn: 'root',
		factory: () => new AsyncActionManagerService()
	}
);

export abstract class IAsyncActionManagerService {
	abstract get monitoringEnabled(): boolean;
	abstract set monitoringEnabled(flag: boolean);
	abstract notifyActionStarted(action: Observable<any>): void;
	abstract notifyActionFinished(action: Observable<any>): void;
	abstract get hasActionsInProgress(): boolean;
	abstract get actionsInProgress(): Observable<any>[];
	abstract get actionFinished$(): Observable<Observable<any>>; 
}

@Injectable()
class AsyncActionManagerService implements IAsyncActionManagerService {
	private _actions: Observable<any>[] = [];

	private _actionFinishedSubject$: Subject<any> = new Subject();

	private _actionFinished$: Observable<any>;

	private _monitoringEnabled = false;

	get monitoringEnabled(): boolean {
		return this._monitoringEnabled;
	}

	set monitoringEnabled(flag: boolean) {
		this._monitoringEnabled = flag;
	}

	notifyActionStarted(action: Observable<any>): void {
		if (!this._monitoringEnabled) {
			return;
		}
		if (this._actions.includes(action)) {
			throw new Error('AsyncActionManagerService.notifyActionFinished(): action already registered');
		}

		this._actions.push(action);
	}

	notifyActionFinished(action: Observable<any>): void {
		//if (!this._monitoringEnabled) {
		//	return;
		//}
		let index = this._actions.indexOf(action);
		if (index == -1) {
			//throw new Error('AsyncActionManagerService.notifyActionFinished(): action not found');
			return;
		}
		this._actions.splice(index, 1);
		this._actionFinishedSubject$.next(action);
	}

	get hasActionsInProgress(): boolean {
		return this._actions.length > 0;
	}

	get actionsInProgress(): Observable<any>[] {
		return [...this._actions];
	}

	get actionFinished$(): Observable<Observable<any>> {
		if (!this._actionFinished$) {
			this._actionFinished$ = this._actionFinishedSubject$.asObservable();
		}
		return this._actionFinished$;
	}
}
