import { Component, ElementRef, EventEmitter, HostListener, Injector, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	ComponentTypeET, IDrawerComponent, IFormStatus, PDObject, IUIDrawerItem, DrawerModeET, DrawerExpandModeT, DrawerPositionT
} from '@otris/ng-core-shared';
import {
	FormHandlerService, UIAbstractComponent, UIDrawerSpec, UIDrawerItemSpec, IEventProcessingContext, EventProcessingContextManagerService
} from '@otris/ng-core';

// { type: 'slide', direction: position=='start' ?  'right' : 'left', duration: 25 }
// (anchorViewportLeave)="expanded=false" (positionChange)="onPopupPositionChange()"
@Component({
	selector: 'otris-pd-drawer',
	template: `
		<div #rootContainer class="root-container" [style.flex-direction]="position=='start' ? 'row' : 'row-reverse'">
			<ng-container *ngIf="expandMode=='push' || !expanded; else itemListPlaceholder">
				<ng-container *ngTemplateOutlet="drawerItemList"></ng-container>
			</ng-container>
			<div class="content-container otris-pd-drawer-content">
				<otris-pd-panel #itemPanel class="pd-panel" *ngFor="let item of drawerItems; index as i" [ngClass]="{'panel-hidden': selectedIndex!==i}"
					[uiItemSpec]="item.content" [formGroup]="formGroup" [pdObject]="pdObject"
					[relationContext]="relationContext">
				</otris-pd-panel>
			</div>
		</div>

		<kendo-popup *ngIf="this.expandMode=='overlay' && this.expanded" [anchor]="rootContainer"
			[animate]="false" [popupClass]="'otris-overlay'" 
			[anchorAlign]="{ horizontal: position=='start' ? 'left' : 'right', vertical: 'top' }"
			[popupAlign]="{ horizontal: position=='start' ? 'left' : 'right', vertical: 'top' }">
			<div class='popup-root'
				[style.flex-direction]="position=='start' ? 'row' : 'row-reverse'"
				[style.height.px]="rootContainerHeight"
				[style.width.px]="rootContainerWidth">
				<ng-container *ngTemplateOutlet="drawerItemList"></ng-container>
			</div>
		</kendo-popup>

		<ng-template #drawerItemList>
			<ul *ngIf="drawerItemsVisible" class="item-list" [style.margin]="position=='start' ? '0 0.5em 0 0' : '0 0 0 0.5em'"
				(mouseenter)="onItemListMouseEnter()" (mouseleave)="onItemListMouseLeave()">
				<otris-pd-drawer-item *ngFor="let item of drawerItems" [drawerItem]="item.item"
					[mini]="mode==drawerModeET.Compact || mode==drawerModeET.CompactAutoExpand" [disabled]="isDisabled"
					[expanded]="expanded" (itemSelected)="onItemSelected($event)">
				</otris-pd-drawer-item>
			</ul>
		</ng-template>

		<ng-template #itemListPlaceholder>
			<ul *ngIf="drawerItemsVisible" class="item-list">
				<otris-pd-drawer-item *ngFor="let item of drawerItems" [drawerItem]="item.item" [mini]="true">
				</otris-pd-drawer-item>
			</ul>
		</ng-template>
	`,
	styles: [`
		:host {
		}
		.root-container {
			width: 100%;
			height: 100%;
			display: flex;
		}
		.item-list {
			list-style: none;
			margin: 0 0.5em 0 0;
			padding: 0;
			background-color: white;
		}
		.content-container {
			display: flex;
			flex: 1;
			background-color: gainsboro;
			padding: 0 1em;
		}
		.pd-panel {
			flex: 1;
		}
		.panel-hidden {
			display: none;
		}
		.popup-root {
			display: flex;
		}
	`]
})
export class PDDrawerComponent extends UIAbstractComponent implements IDrawerComponent {

	/*@HostListener('document:click', ['$event'])
	public documentClick(event: any): void {
		 if (!this.contains(event.target)) {
			//this.showPopup = false;
			this.expanded = false;
		 }
	}*/
	
	@Input() pdObject: PDObject;

	@Input() mode = DrawerModeET.Normal;

	@Input() expanded = false;

	@Input() expandMode: DrawerExpandModeT = 'push';

	@Input() position: DrawerPositionT = 'start';

	@Output() itemSelected = new EventEmitter<IUIDrawerItem>();

	drawerModeET = DrawerModeET;

	@ViewChild('rootContainer') rootContainer: ElementRef;

	@ViewChildren('itemPanel') itemPanels: QueryList<any>; // todo: IPDPanelComponent

	get drawerItems(): UIDrawerItemSpec[] {
		return this.uiDrawerSpec.drawerItems;
	}

	get drawerItemsVisible(): boolean {
		return this.mode != DrawerModeET.Normal || this.expanded;
	}

	get selectedItem(): IUIDrawerItem | undefined {
		let item = this.drawerItems.find(i => i.item.selected);
		return item ? item.item : undefined;
	}

	get selectedIndex(): number {
		return this.drawerItems.findIndex(i => i.item.selected);
	}

	get rootContainerHeight(): number {
		if (this.rootContainer && this.rootContainer.nativeElement) {
			return this.rootContainer.nativeElement.offsetHeight;
		}
		return 0;
	}

	get rootContainerWidth(): number {
		if (this.rootContainer && this.rootContainer.nativeElement) {
			return this.rootContainer.nativeElement.offsetWidth;
		}
		return 0;
	}

	private get uiDrawerSpec(): UIDrawerSpec {
		return this.uiItemSpec as UIDrawerSpec;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, private injector: Injector) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();
		if (this.uiDrawerSpec.mode) {
			this.mode = this.uiDrawerSpec.mode;
		}
		if (this.uiDrawerSpec.expandMode) {
			this.expandMode = this.uiDrawerSpec.expandMode;
		}
		if (this.uiDrawerSpec.position) {
			this.position = this.uiDrawerSpec.position;
		}
		if (this.uiDrawerSpec.expanded !== undefined && this.mode != DrawerModeET.CompactAutoExpand) {
			this.expanded = this.uiDrawerSpec.expanded;
		}
	}

	ngAfterViewInit() {		
		super.ngAfterViewInit();
		let selItem = this.selectedItem;
		if (selItem) {
			this.emitItemSelectedEvent(selItem);
		}
	}

	getFormControlStatus(): IFormStatus {
		let stat = <IFormStatus>{ pristine: true, touched: false, valid: true };
		if (this.itemPanels) {
			this.itemPanels.forEach(pc => {
				let panelStat = pc.getFormControlStatus();
				if (!panelStat.pristine) {
					stat.pristine = false;
				}
				if (panelStat.touched) {
					stat.touched = true;
				}
				if (!panelStat.valid) {
					stat.valid = false;
				}
			});
		}
		return stat;
	}

	/*private contains(target: any): boolean {
		return this.rootContainer.nativeElement.contains(target) ||
			 (this.popup ? this.popup.nativeElement.contains(target) : false);
	}*/

	onItemListMouseEnter(): void {
		//this.showPopup = true;
		if (this.mode == DrawerModeET.CompactAutoExpand) {
			this.expanded = true;
		}
	}

	onItemListMouseLeave(): void {
		if (this.mode == DrawerModeET.CompactAutoExpand) {
			this.expanded = false;
		}
	}

	onItemSelected(item: IUIDrawerItem) {
		if (item != this.selectedItem) {
			this.drawerItems.filter(i => i.item.selected === true)
				.forEach(i => {
					//if (i.item != item) {
						i.item.selected = false;
					//}
				});
			item.selected = true;
			this.emitItemSelectedEvent(item);
		}
	}

	// Todo: Nach UIAbstractComponent
	protected createEventProcessingContext(): IEventProcessingContext {
		let ctx = super.createEventProcessingContext();
		let service = this.injector.get<EventProcessingContextManagerService>(EventProcessingContextManagerService);
		if (service) {
			ctx.customContext = service.customContext;
		}
		return ctx;
	}

	private emitItemSelectedEvent(item: IUIDrawerItem): void {
		this.itemSelected.emit(item);
		if (this.uiDrawerSpec.onItemSelected) {
			let ctx = this.createEventProcessingContext();
			this.uiDrawerSpec.onItemSelected(ctx, item);
		}
	}

	//----------------------------------------------------------------------------------------------
	// IDrawerComponent
	//----------------------------------------------------------------------------------------------

	get componentType(): ComponentTypeET {
		return ComponentTypeET.Drawer;
	}

	get isEnabled(): boolean {
		return !this._disabled;
	}

	get isDisabled(): boolean {
		return this._disabled;
	}

	private _disabled: boolean = false;

	disable(flag: boolean): void {
		if (this._disabled != flag) {
			this._disabled = flag;
			this.itemPanels.forEach(p => p.disable(flag));
		}
	}

	reset(): void {
	}
}
