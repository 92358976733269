import { Directive, Input, TemplateRef } from '@angular/core';

/*@Directive({
	selector: '[otrisPDGroupboxHeader]'
})*/
export abstract class UIAbstractDirective {

	get template(): TemplateRef<any> {
		return this._template;
	}

	abstract get id(): string;

	constructor(private _template: TemplateRef<any>) { }
}
