import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';

import { ILocalizationService, ILocalizationServiceToken, ICssStyle } from '@otris/ng-core-shared';
// Wird das noch gebraucht?
// (selectedChange)="onSelectedChange($event)"
// [routerLink]="routeInfo"
@Component({
	selector: 'otris-button',
	template: `
		<button kendoButton class="button" [primary]="primary" [type]="type" [toggleable]="toggleable" [iconClass]="icon" [look]="flat ? 'flat' : ''"
			[selected]="selected" [disabled]="disabled" [ngStyle]="buttonStyle" [ngClass]="buttonClass" (click)="onClick()"
			(selectedChange)="onSelectedChange($event)" kendoTooltip title="{{title}}" [routerLink]="routeInfo" queryParamsHandling="preserve">
			{{label}}
		</button>
	`,
	styles: [`
		.button {
			width: 100%;
			height: 100%;
		}
	`]
})
export class ButtonComponent implements OnInit {
	@Input() id: string;
	@Input() label: string;
	@Input() type: string = "button";
	@Input() primary: boolean = false;
	@Input() toggleable: boolean = false;
	@Input() selected: boolean = false;
	@Input() icon: string;
	@Input() flat: boolean = false;
	@Input() disabled: boolean = false;
	@Input() buttonStyle: ICssStyle;
	@Input() buttonClass: string | string[];
	@Input() routeInfo: string | any[];
	@Input() titleId: string;

	public title: string;

	/*@Input()
	get selected(): boolean {
		return this._selected
	}
	set selected(val: boolean) {
		if (this._selected !== val) {
			this._selected = val;
			this.selectedChanged2.emit([this.id, val]);
		}
	}
	private _selected: boolean = false;*/

	@Output() buttonClick = new EventEmitter<string>();
	@Output() selectedChanged = new EventEmitter<[string, boolean]>();

	constructor(
		@Inject(ILocalizationServiceToken) private localizationService: ILocalizationService,
	) { }

	ngOnInit() {
		if (this.titleId) {
			this.localizationService.changeHandler.pipe(
				switchMap(r => this.localizationService.getStringFromId(this.titleId).pipe(
					map(trans => this.title = trans))
				)
			).subscribe();
		}
	}

	onClick(): void {
		this.buttonClick.emit(this.id);
	}

	onSelectedChange(state: boolean) {
		this.selected = state;
		this.selectedChanged.emit([this.id, state]);
	}
}
