import { Input, Component, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'otris-ui-spinner',
	template: `
		<ngx-spinner [name]="spinnerId" [size]="size" [type]="type" [fullScreen]="fullScreen"
			[color]="color" [bdColor]='backdropColor'>
			<ng-content></ng-content>	
		</ngx-spinner>		
	`,
	styles: []
})
export class UISpinnerComponent implements OnDestroy {

	private _visible = false;

	@Input()
	set visible(flag: boolean) {
		if (flag != this._visible) {
			this._visible = flag;
			if (flag) {
				this._spinnerService.show(this.spinnerId);
			}
			else {
				this._spinnerService.hide(this.spinnerId);
			}
		}
	}

	get visible() {
		return this._visible;
	}

	@Input() size: 'small' | 'default' | 'medium' | 'large' = 'large';

	@Input() type = 'ball-spin-clockwise';

	@Input() fullScreen = true;

	@Input() color = '#fff';

	@Input() backdropColor = 'rgba(51, 51, 51, 1)';

	private _spinnerId: string;

	get spinnerId(): string {
		return this._spinnerId;
	}

	private static spinnerCounter = 0;

	constructor(private _spinnerService: NgxSpinnerService) {
		this._spinnerId = `ui-spinner-${++UISpinnerComponent.spinnerCounter}`;
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		if (this.visible) {
			this.visible = false;
		}
	}
}
