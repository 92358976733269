import { Component, OnInit, Input, ViewChild, ContentChild, HostBinding, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UIAbstractComponent, UIGroupboxSpec, GlobalTemplateProviderService, FormHandlerService } from '@otris/ng-core';
import { ComponentTypeET, PDObject, IGroupboxComponent, IFormStatus } from '@otris/ng-core-shared';
//import { PDPanelComponent } from '../pd-panel/pd-panel.component';
import { PDGroupboxHeaderDirective } from './pd-groupbox-header.directive';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'otris-pd-groupbox',
	template: `
		<div class="container" *ngIf="hasHeaderTemplate; else default">
			<div>
				<ng-container *ngTemplateOutlet="groupboxHeaderTemplate;context:headerContext"></ng-container>
			</div>
			<otris-pd-panel *ngIf="groupboxSpec.content" #panel class="panel" [uiItemSpec]="groupboxSpec.content" [formGroup]="formGroup" [pdObject]="pdObject">
			</otris-pd-panel>
		</div>

		<ng-template #default>
			<fieldset class="default-container">
				<legend>{{header}}</legend>
				<ng-template [ngIf]="groupboxSpec.content">
					<otris-pd-panel #panel [uiItemSpec]="groupboxSpec.content" [formGroup]="formGroup" [pdObject]="pdObject" [relationContext]="relationContext" class="panel"></otris-pd-panel>
				</ng-template>
			</fieldset>
		</ng-template>
	`,
	styles: [`
		:host {
			display: flex;
			/* flex-direction: column; IE11 mag das nicht. Ursprünglich für das word-break */
		}

		.container {
			/* height: 100%; 100p fix */
			display: flex;
			flex: 1 1 100%; /* 100p fix + IE11 text wrap fix */
			flex-direction: column;
		}
		.default-container {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;

			margin-bottom: 1em;
			/*display: flex; // TODO: Grund vom auskommentieren ist unbekannt, könnte ein IE11 Bug gewesen sein oder dergleichen
			flex-direction: column;
			margin-top: 0.5em;*/
		}
		.panel { 
			display: flex; /* 100p fix */

			flex: 1 1 auto;
			/* height: 100%; 100p fix */
		}

		/*.header {
			padding: 0.5em;
			margin-bottom: 0.5em;
			background-color: darkgrey;
		}*/
	`]
})
export class PDGroupboxComponent extends UIAbstractComponent implements IGroupboxComponent {

	@Input() pdObject: PDObject;

	@ViewChild('panel') panelComponent: any;

	@ContentChild(PDGroupboxHeaderDirective) pdGroupboxHeaderDirective: PDGroupboxHeaderDirective;

	private _groupboxHeaderTemplate: TemplateRef<any>;

	get groupboxHeaderTemplate(): TemplateRef<any> {
		return this.pdGroupboxHeaderDirective ? this.pdGroupboxHeaderDirective.template : this._groupboxHeaderTemplate;
	}

	get hasHeaderTemplate(): boolean {
		return !!this.groupboxSpec.headerTemplateId;
	}

	//@ViewChild('panel', { read: UIAbstractComponent }) panelComponent: UIAbstractComponent;
	//@ViewChild(PDPanelComponent) panelComponent: PDPanelComponent;

	//@HostBinding('class') cssClasses = 'otris-pd-groupbox';
	@HostBinding('class') cssClasses = 'otris-pd-groupbox';

	private subscription: Subscription;

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService/*, private templateProvider: GlobalTemplateProviderService*/) {
		super(router, route, formHandler);

		this.subscription = this.formHandler.uiTemplateRegistered$.subscribe(id => {
			if (id === this.groupboxSpec.headerTemplateId) {
				this.updateHeaderTemplate();
			}
		})
	}

	getFormControlStatus(): IFormStatus {
		if (this.panelComponent) {
			return (this.panelComponent as UIAbstractComponent).getFormControlStatus();
		}
		return <IFormStatus>{ pristine: true, touched: false, valid: true };
	}

	get groupboxSpec(): UIGroupboxSpec {
		if (!(this.uiItemSpec instanceof UIGroupboxSpec)) {
			throw Error('No UIGroupboxSpec assigned.');
		}
		return <UIGroupboxSpec>this.uiItemSpec;
	}

	get header(): string | undefined {

		/*if (this.groupboxSpec) {
			if (this.groupboxSpec.headerId && this.pdObject) {
				return this.pdObject.metaData.getStringFromId(this.groupboxSpec.headerId);
			}
			return this.groupboxSpec.header;
		}
		return undefined;*/
		return this._header;
	}

	private _header: string;

	get headerContext(): { header: string, groupBox: IGroupboxComponent, spec: UIGroupboxSpec } {
		return {
			header: this.header,
			groupBox: this,
			spec: this.groupboxSpec
		}
	}

	ngOnInit() {
		super.ngOnInit();
		/*if (this.groupboxSpec) {
			if (this.groupboxSpec.headerId && this.pdObject) {
				this.pdObject.metaData.getStringFromId(this.groupboxSpec.headerId).subscribe(res => this._header = res);
			}
			else {
				this._header = this.groupboxSpec.header;
			}
		}*/
		this.onLanguageChanged();
		this.updateHeaderTemplate();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		/*if (this.uiItemSpec.disabled) {
			this.disable(true);
		}*/
	}

	ngAfterContentInit() {
		super.ngAfterContentInit();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		super.ngOnDestroy();
	}

	protected onLanguageChanged() {
		super.onLanguageChanged();
		if (this.groupboxSpec) {
			if (this.groupboxSpec.headerId && this.pdObject) {
				this.pdObject.metaData.getStringFromId(this.groupboxSpec.headerId).subscribe(res => this._header = res);
			}
			else {
				this._header = this.groupboxSpec.header;
			}
		}

	}

	private updateHeaderTemplate() {
		if (this.groupboxSpec.headerTemplateId) {
			this._groupboxHeaderTemplate = this.formHandler.getUITemplate(this.groupboxSpec.headerTemplateId);
			//this._groupboxHeaderTemplate = template;
			/*if (template instanceof PDGroupboxHeaderDirective) {
				this.pdGroupboxHeaderDirective = template;
			}*/
		}
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.GroupBox;
	}

	get isEnabled(): boolean {
		return !this._disabled;
	}

	get isDisabled(): boolean {
		return this._disabled;
	}

	private _disabled: boolean = false;

	disable(flag: boolean): void {
		this._disabled = flag;
		if (this.panelComponent) {
			this.panelComponent.disable(flag);
		}
	}

	reset(): void {
		if (this.panelComponent) {
			this.panelComponent.reset();
		}
	}
}
