import { InjectionToken, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { PDObject } from './pd-object';
import { IPDListColumnInfo } from './types';

export const IInteractionServiceToken = new InjectionToken<IInteractionService>('IInteractionServiceToken');

//export let IInteractionServiceToken: InjectionToken<IInteractionService>;

export enum DialogResultET {
	Ok,
	Cancel,
	Yes,
	No
}

export interface ISelectPDObjectResult {
	selection?: PDObject;
}

export interface ISelectPDObjectsResult {
	selection: PDObject[];
}

export interface IActionButton {
	text: string;
	primary?: boolean;
	id: DialogResultET;
}

export interface IDialogSettings {
	width?: string;
	height?: string;
	minWidth?: string;
	maxWidth?: string;
	minHeight?: string;
	maxHeight?: string;
}

export interface ISelectObjectOptions extends IDialogSettings {
	filterExpr?: string;
	sortExpr?: string;
	columnInfos?: IPDListColumnInfo[];
	choiceObjects?: Observable<PDObject[]>;
}

export abstract class IInteractionService {
	abstract showOkMessage(title: string, message: string, settings?: IDialogSettings): Observable<void>;

	abstract showConfirmMessage(title: string, message: string, settings?: IDialogSettings): Observable<DialogResultET>;

	abstract showYesNoMessage(title: string, message: string, settings?: IDialogSettings): Observable<DialogResultET>;

	abstract selectPDObject(className: string, title: string, options?: ISelectObjectOptions, customContent?: TemplateRef<any>): Observable<ISelectPDObjectResult>;

	abstract selectPDObjects(className: string, title: string, options?: ISelectObjectOptions, customContent?: TemplateRef<any>): Observable<ISelectPDObjectsResult>;

	abstract showCustomMessage(title: string, buttons: IActionButton[], content: TemplateRef<any>): Observable<DialogResultET>;
}
