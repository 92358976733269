import { Input, Output, EventEmitter, ChangeDetectorRef, Inject, Directive } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
	IToolBarItemSpec, IToolBarButtonSpec, ToolBarItemTypeET, IToolBarItemResult, IToolBarDropDownButtonSpec, IToolBarDropDownButtonItem, ServiceLocator, IInteractionServiceToken, ILocalizationServiceToken
} from '@otris/ng-core-shared';
import { UIFormSpec, UIRootContainerSpec } from '../../model/pd-layout';
import { PDRootContainerComponent } from '../pd-root-container/pd-root-container.component';
import { IPopupManagerServiceToken, IPopupManagerService, PopupIdQueryParam } from '../../services/popup-manager.service';
import { FormHandlerService } from '../../services/form-handler.service';
import { MainUIService } from '../../services/main-ui.service';

enum ToolBarButtonIdET {
	Save = 'idSave',
	//Cancel = 'idCancel',
	Reset = 'idReset'
}

/*export interface ISavingArgs {
	data: any;
	cancel?: boolean;
}*/

@Directive()
export abstract class PDAbstractFormComponent extends PDRootContainerComponent {

	@Input() formSpec: UIFormSpec;

	private _busy: boolean = false;

	@Input()
	set busy(val: boolean) {
		this._busy = val;
		/*let spinnerSrv = this.spinnerService;
		if (val) {
			spinnerSrv.show(this.formId);
		}
		else {
			spinnerSrv.hide(this.formId);
		}*/
	}

	get busy(): boolean {
		return this._busy;
	}

	@Output() saveRequest = new EventEmitter<any>();

	//@Output() saving = new EventEmitter<ISavingArgs>();

	toolbarItems: IToolBarItemSpec[];

	private popupId: string;

	private _closed = false;

	get closed(): boolean {
		return this._closed;
	}

	get showToolbar(): boolean {
		return this.formSpec.showToolbar;
	}

	protected get rootContainerSpec(): UIRootContainerSpec {
		return this.formSpec;
	}

	/*private _formId: string;

	get formId(): string {
		return this._formId;
	}

	private static formCounter = 0;*/

	protected get spinnerService(): NgxSpinnerService {
		return ServiceLocator.injector.get(NgxSpinnerService);
	}

	private get mainUIService(): MainUIService {
		return ServiceLocator.injector.get(MainUIService); 
	}

	constructor(router: Router, route: ActivatedRoute,
		changeDetector: ChangeDetectorRef, formHandler: FormHandlerService,
		@Inject(IPopupManagerServiceToken) private popupManagerService: IPopupManagerService) {
		super(router, route, changeDetector, formHandler);

		//this._formId = `form_${++PDAbstractFormComponent.formCounter}`;
		let params: Params = this.route.snapshot.queryParams;
		if (!!params[PopupIdQueryParam]) {
			this.popupId = params[PopupIdQueryParam];
		}
	}

	ngOnInit() {
		super.ngOnInit();

		// TEST
		/*this.spinnerService.show(this.formId);
		setTimeout(() => {
			this.spinnerService.hide(this.formId);
		}, 5000);*/

		this.toolbarItems = [];
		let toolbarSpec = this.formSpec.toolbarSpec
		if (!toolbarSpec || toolbarSpec.save) {
			this.toolbarItems.push(<IToolBarButtonSpec>{
				id: ToolBarButtonIdET.Save, type: ToolBarItemTypeET.Button, disabled: true, iconClass: 'fa fa-lg fa-check',
				shortDescriptionId: 'system.ng-core.components.pd-abstract-form.tool-bar-button-save'
			});
		}
		if (!toolbarSpec || toolbarSpec.reset) {
			this.toolbarItems.push(<IToolBarButtonSpec>{
				id: ToolBarButtonIdET.Reset, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-eraser',
				shortDescriptionId: 'system.ng-core.components.pd-abstract-form.tool-bar-button-reset'
			});
		}
		if (!toolbarSpec || toolbarSpec.language) {
			// todo: aus Language-Service ermitteln!
			let langTBItems: IToolBarDropDownButtonItem[] = [];
			langTBItems.push(<IToolBarDropDownButtonItem>{ id: 'Language', text: 'de' });
			langTBItems.push(<IToolBarDropDownButtonItem>{ id: 'Language', text: 'en' });
			this.toolbarItems.push(<IToolBarButtonSpec>{
				id: 'Language', type: ToolBarItemTypeET.DropDownButton, items: langTBItems, text: 'todo',
				shortDescriptionId: 'app.strings.main-tool-bar.language'
			});
		}		

		this.subscription = this.theForm.statusChanges.subscribe(v => {
			if (!this.closed) {
				this.updateToolbarItems();
			}
		});
		this.subscription.add(this.theForm.valueChanges.subscribe(v => {
			if (!this.closed) {
				this.updateToolbarItems();
				this.formHandler.updateMandatoryStatus();
			}
		}));
		
	}

	onToolBarButtonClick(item: IToolBarItemResult) {
		switch (item.id) {
			case ToolBarButtonIdET.Save:
				this.save(true);
				break;
			case ToolBarButtonIdET.Reset:
				this.reset();
				break;
		}
	}

	// todo: Flag, ob das Formular auch geschlossen werden soll
	save(close: boolean) {
		let val = this.theForm.getRawValue();
		let saveImpl = () => {
			this.pdObject.update(val);
			this.saveRequest.emit(val);
			if (close) {
				this.closeForm();
			}
		};

		if (this.formSpec.savingHandler) {
			let interactionService = ServiceLocator.injector.get(IInteractionServiceToken);
			let localizationService = ServiceLocator.injector.get(ILocalizationServiceToken)
			this.formSpec.savingHandler(val, this.mainUIService, interactionService, localizationService).subscribe(res => {
				if (res === true) {
					saveImpl();
				}
			});
			return;
		}
		saveImpl();

		/*this.pdObject.update(val);
		this.saveRequest.emit(val);
		if (close) {
			this.closeForm();
		}*/
	}

	reset() {
		//this.theForm.reset();
		this.pdObject.reset();
		if (this.mainPanel) {
			this.mainPanel.reset();
		}
		this.theForm.markAsPristine();
		this.updateToolbarItems();
	}

	closeForm() {
		if (this.closed) {
			throw new Error('Form already closed.');
		}

		if (!!this.popupId) {
			this.popupManagerService.closePopup(this.popupId);
		}
		this._closed = true;
	}

	private getToolBarItem(id: ToolBarButtonIdET): IToolBarItemSpec {
		return this.toolbarItems.find(i => i.type == ToolBarItemTypeET.Button && (<IToolBarButtonSpec>i).id == id);
	}

	private updateToolbarItems() {
		this.getToolBarItem(ToolBarButtonIdET.Save).disabled = this.theForm.pristine || this.theForm.invalid;
		this.getToolBarItem(ToolBarButtonIdET.Reset).disabled = this.theForm.pristine;
	}
}
