import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { PDObject, IPDObjectId, IPDObjectRaw } from '../model/pd-object';

export const IPDAccessServiceToken = new InjectionToken<IPDAccessService>('IPDAccessService');

export interface ICallOperationResult<T extends PDObject> {
	result: number;
	outStrs: string | string[];
	outObjs: T | T[];
}

export interface IGetExtentByPageResult {
	itemCount: number;
	data: PDObject[];
}

/**
 * Service der die Kommunikation zum janus Server ermöglicht. Eine
 * Anmeldung an den Server wird vorher benötigt.
 */
export abstract class IPDAccessService {
	/**
	 * Anmeldung an den Janus Server
	 * @param login Benutzername
	 * @param pwd Passwort
	 */
	abstract changeUser(login: string, pwd: string): Observable<{msg: string, errorCode: number}>;

	/**
	 * Abmeldung des Benutzer
	 */
	abstract disconnect(): Observable<boolean>;

	abstract getObject(id: string, attrs?: string[]): Observable<PDObject>;

	abstract getObjects(ids: string[], attrs?: string[]): Observable<PDObject[]>;
	
	abstract getExtent(pdclass: string | number, filter?: string, sort?: string, attrs?: string[]): Observable<PDObject[]>;

	abstract getExtentByPage(pdclass: string | number, pageIndex: number, pageSize: number, ignoreErrors?: boolean,
		filter?: string, sort?: string, attrs?: string[]): Observable<IGetExtentByPageResult>;

	/**
	 * Anfrage an den janus Server, der die angebene Operation ausführen soll
	 * @param opName Janus Server-Operation
	 * @param inParams Zusätzliche Parameter die an den Server übergeben werden können
	 * @param inObjs Zusätzliche Objekte die an den Server übergeben werden können
	*/
	abstract callOperation(opName: string, inParams: string | string[], inObjs: IPDObjectId | IPDObjectId[]): Observable<ICallOperationResult<PDObject>>;

	abstract callOperationTyped<T extends PDObject>(opName: string, inParams: string | string[], inObjs: IPDObjectId | IPDObjectId[], pdObjectCreator: (rawObj: IPDObjectRaw) => T): Observable<ICallOperationResult<T>>;

	abstract getRelationObjects(objWrapper: PDObject, relationName: string, filter?: string, sort?: string, attrs?: string[]): Observable<PDObject[]>;

	abstract callObjectOperation(objWrapper: PDObject, opName: string, inParams: string | string[], inObjs: IPDObjectId | IPDObjectId[]): Observable<ICallOperationResult<PDObject>>;

	abstract downloadObjectDocument(objWrapper: PDObject, attr: string): void;
}
