import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, Inject, LOCALE_ID } from '@angular/core';
//import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	PrivacyNgLibModule, AppBaseModule, REQUIRED_PRIVACY_VERSION,
	PRIVACY_MODULE_TYPE, PrivacyModuleTypeET, IPrivacyNgModuleSettingsService, MetaObjectFactoryService, IPrivacyBackendServiceHookToken
} from '@otris/privacy-ng-lib';
//import { IFormSpecProvider, MainUIService } from '@otris/ng-core';
import { IPDClassToken, ILocalizationServiceToken, ILocalizationService, IMetaObjectFactory } from '@otris/ng-core-shared';
import { AppRoutingModule } from './app-routing.module';
import { PDClassService } from './services/pd-class.service';
//import { FormSpecProvider } from './services/form-spec-provider.service';
//import { MainFormComponent } from './components/main-form/main-form.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LandingPageSettingsService } from './services/landing-page-settings.service';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { PrivacyBackendServiceHook } from './services/privacy-backend-service-hook.service';
import { MainComponent } from './components/main/main.component';
import { AppComponent } from './components/app/app.component';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		MainComponent
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		PrivacyNgLibModule.forRoot(),
		HttpClientModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		ProgressBarModule
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'de' },
		{ provide: PRIVACY_MODULE_TYPE, useValue: PrivacyModuleTypeET.Custom },
		{ provide: IPDClassToken, useClass: PDClassService },
		//{ provide: IFormSpecProvider, useClass: FormSpecProvider },
		{ provide: IPrivacyNgModuleSettingsService, useClass: LandingPageSettingsService },
		{ provide: IMetaObjectFactory, useClass: MetaObjectFactoryService },
		{ provide: IPrivacyBackendServiceHookToken, useClass: PrivacyBackendServiceHook }
		//MainUIService
	],
	bootstrap: [AppComponent]
})
export class AppModule extends AppBaseModule {
	constructor(injector: Injector,
		@Inject(ILocalizationServiceToken) localizationService: ILocalizationService) {
		super(injector, localizationService);
	}
}
