import { Observable } from 'rxjs';
import {
	IToolbarSpec, PDObject, VisibilityET, LabelPositionET, ICssStyle, IPDListColumnInfo, IPDAccessService,
	IUIDrawerItem, DrawerModeET, DrawerExpandModeT, DrawerPositionT, IInteractionService
} from '@otris/ng-core-shared';
import { IEventProcessingContext } from './i-event-processing-context';
import { ILocalizationService } from '@otris/ng-core-shared';
import { MainUIService } from '../services/main-ui.service';

export enum WidgetET {	
	TextField,
	NumericTextField,
	DateTimeField,
	Checkbox,
	FileUpload,
	RadioButton,
	RadioButtonGroup,
	InputSwitch,
	DropDown,
	MultiSelect,
	AutoComplete,
	ComboBox,
	ObjectReference,
	RelationList,
	RelationTab,
	Panel,
	Groupbox,
	Accordion,
	AccordionTab,
	TabView,
	TabPanel,
	Drawer,
	Label,
	Button,
	ContentTemplate,
	InfoField
}

export enum LayoutTypeET {
	Grid,
	Flex
}

export enum ItemTypeET {
	PDItem,
	PDRadioButton,
	UIPanel,
	UIGroupbox,
	UIRadioButtonGroup,
	UIAccordion,
	UITabView,
	UIDrawer,
	PDRelationTab,
	UIContent
}

export enum FlexDirectionET {
	Row,
	Column
}

export enum FlexAlignSelfET {
	Stretch = 'stretch',
	Unset = 'unset'
}

/*export enum FlexFlexET {
		Stretch = '1 1 auto',
		Unset = 'unset'
}*/

export enum FlexWrapET {
	Wrap = 'wrap',
	NoWrap = 'nowrap',
	WrapReverse = 'wrap-reverse'
}

export enum FlexJustifyContentET {
	Start = 'start',
	Center = 'center',
	SpaceBetween = 'space-between',
	SpaceAround = 'space-around',
	SpaceEvenly = 'space-evenly'
}

export enum FlexAlignContentET {
	FlexStart = 'flex-start',
	FlexEnd = 'flex-end',
	Center = 'center',
	SpaceBetween = 'space-between',
	SpaceAround = 'space-around',
	Stretch = 'stretch',
	SpaceEvenly = 'space-evenly'
}

export enum FlexAlignET {
	FlexStart = 'flex-start',
	FlexEnd = 'flex-end',
	Center = 'center',
	Baseline = 'baseline',
	Stretch = 'stretch'
}

export interface IPDChoiceSpec {
	className: string;
	relationInfo?: string;
	relationInfoProvider?: (localizationService: ILocalizationService) => string;
	filterExpr?: string;
	sortExpr?: string;
	properties?: string[];
}

export interface IPDObjectSelectionListSpec {
	columns?: IPDListColumnInfo[];
	width?: string;
	height?: string;
	title?: string;
	titleId?: string;
	pdObjectProvider?: (pdAccess: IPDAccessService) => Observable<PDObject[]>;
}

export interface IStyleSpec {
	style: ICssStyle,
	theme?: string
}

export enum RadioButtonGroupDataTypeET {
	Boolean,
	Enum
}

export enum RadioButtonBulletLocationET {
	Start,
	Center,
	End
}

export enum ObjectReferenceTypeET {
	DropDownList,
	SelectionList
}

export enum MultiSelectRelationWidgetTypeET {
	DropDownList,
	SelectionList
}

/*export enum ShortDescritionFormatET {
	Text,
	Html
}*/

export interface IUIRootContainerSpec {
	header?: string;
	headerId?: string;
	content?: IUIPanelSpec;
	width?: string;
	minWidth?: string;
	maxWidth?: string;
	height?: string;
	minHeight?: string;
	maxHeight?: string;
	showHeader?: boolean;
	transparent?: boolean;
	// backgroundColor?: string; // Nur über themes bearbeitbar
	childItemMargin?: string;
	childItemMarginTop?: string;
	childItemMarginRight?: string;
	childItemMarginBottom?: string;
	childItemMarginLeft?: string;
	disableChildItemMarginsInContainer?: boolean;
}

export interface IUIFormToolbarSpec {
	save?: boolean;
	reset?: boolean;
	language?: boolean;
}

export interface IUIFormSpec extends IUIRootContainerSpec{
	showToolbar?: boolean;
	toolbarSpec?: IUIFormToolbarSpec;
	savingHandler?: (formData: any, mainUIService: MainUIService, interactionService: IInteractionService, localizationService: ILocalizationService) => Observable<boolean>;
}

export interface IUIPreviewSpec extends IUIRootContainerSpec {

}

export interface IUIItemSpec {
	id?: string;
	placement?: IUIItemPlacement;
	type: ItemTypeET;
	width?: string;
	height?: string;
	minWidth?: string;
	maxWidth?: string;
	margin?: string;
	marginLeft?: string;
	marginTop?: string;
	marginRight?: string;
	marginBottom?: string;
	padding?: string;
	paddingLeft?: string;
	paddingTop?: string;
	paddingRight?: string;
	paddingBottom?: string;
	pdClassContext?: string[];
	editContext?: string[];
	invalidEditContext?: string[];
	widgetInfo?: IWidgetInfo;
	disabled?: boolean;
	customData?: any;
	mandatoryCustomActivated?: boolean;
	visible?: boolean;
}

export interface IUIContentSpec extends IUIItemSpec {
	template: string;
}

export interface IUIContainerSpec extends IUIItemSpec {
	layout?: ILayoutSpec;
	items?: IUIItemSpec[];
	backgroundColor?: string;
	borderRadius?: string;
	childItemMargin?: string;
	childItemMarginTop?: string;
	childItemMarginRight?: string;
	childItemMarginBottom?: string;
	childItemMarginLeft?: string;
	disableChildItemMarginsInContainer?: boolean;
}

export interface IPDItemSpec extends IUIItemSpec {
	property?: string;
	widget: WidgetET;
	//shortDescriptionFormat?: ShortDescritionFormatET;
	//shortDescriptionStyle?: string;
	//mandatoryCustomActivated?: boolean;
	//widget?: WidgetET;
}

export interface IPDRadioButtonSpec extends IPDItemSpec {
	content?: IUIPanelSpec;
}

export interface IUIPanelSpec extends IUIContainerSpec {
	transparent?: boolean;
}

export interface IUIGroupboxSpec extends IUIContainerSpec {
	content?: IUIPanelSpec;
	header?: string;
	headerId?: string;
	headerTemplateId?: string;
}

export interface IUIRadioButtonGroupSpec extends IUIContainerSpec {
	dataType: RadioButtonGroupDataTypeET;
	content?: IUIPanelSpec;
	header?: string;
	property?: string;
	wrapLabel?: boolean;
}

export interface IUIAccordionSpec extends IUIContainerSpec {
	tabs: IUIAccordionTabSpec[];
}

export interface IUIAccordionTabSpec extends IUIContainerSpec {
	content?: IUIPanelSpec;
	header?: string;
}

export interface IUITabViewSpec extends IUIContainerSpec {
	panels: IUITabPanelSpec[];
}

export interface IUITabPanelSpec extends IUIContainerSpec {
	content?: IUIPanelSpec;
	header?: string;
	headerId?: string;
}

export interface IUIDrawerItemSpec {
	item: IUIDrawerItem;
	content: IUIPanelSpec;
}

export interface IUIDrawerSpec extends IUIContainerSpec {
	drawerItems: IUIDrawerItemSpec[];
	mode?: DrawerModeET;
	expandMode?: DrawerExpandModeT;
	position?: DrawerPositionT;
	expanded?: boolean;
	onItemSelected?: (ctx: IEventProcessingContext, item: IUIDrawerItem) => void;
}

export interface IPDRelationTabSpec extends IUIContainerSpec {
	property: string;
	content?: IUIPanelSpec;
	objCreator?: new () => PDObject;
}

export interface ILayoutSpec {
	type: LayoutTypeET
}

export interface IColumnSpec {
	width: string;
}

export interface IRowSpec {
	height: string;
}

export interface IGridLayoutSpec extends ILayoutSpec {
	columns?: IColumnSpec[];
	rows?: IRowSpec[];
}

export interface IFlexLayoutSpec extends ILayoutSpec {
	direction?: FlexDirectionET;
	wrap?: FlexWrapET | string;
	justifyContent?: FlexJustifyContentET | string;
	alignItems?: FlexAlignET | string;
	alignContent?: FlexAlignContentET | string;
	/**
	 * Override the align-items property of the parents flexbox align-items.
	 * @use Core.FlexAlignSelfET
	 */
	//alignSelf?: FlexAlignSelfET | string;
	/**
	 * Defines how the flexbox itself stretch inside of the parent flexbox.
	 * @use Core.FlexFlexET
	 */
	//flex?: FlexFlexET | string;
}

export interface IUIItemPlacement {

}

export interface IGridItemPlacement extends IUIItemPlacement {
	row?: number;
	col?: number;
	colspan?: number;
	rowspan?: number;
}

export interface IFlexItemPlacement {
	flex?: string;
	align?: FlexAlignET;
}

export interface IWidgetInfo {
	guiReadonly?: boolean;
}

export interface ILabelWidgetInfo extends IWidgetInfo {
	label?: string;
	labelId?: string;
	styleSpec?: IStyleSpec;
}

export interface IButtonWidgetInfo extends IWidgetInfo {
	label?: string;
	labelId?: string;
	iconClass?: string;
}

export interface IRadioButtonWidgetInfo extends IWidgetInfo {
	value: any;
	label?: string;
	labelId?: string;
	bulletLocation?: RadioButtonBulletLocationET;
}

export interface ILabeledControlWidgetInfo extends IWidgetInfo {
	prefix?: string;
	prefixWidth?: string;
	labelVisibility?: VisibilityET;
	labelPosition?: LabelPositionET;
	toolbar?: IToolbarSpec;
	wrapLabel?: boolean;
	useCustomLabel?: boolean;
	customLabel?: string;
	customLabelId?: string;
}

export interface ITextFieldWidgetInfo extends ILabeledControlWidgetInfo {
	multiline?: boolean;
	rows?: number;
	stretch?: boolean;
}

export interface INumericTextFieldWidgetInfo extends ILabeledControlWidgetInfo {
	step?: number;
	decimals?: number;
	min?: number;
	max?: number;
	showSpinners?: boolean;
	format?: string | any;
}

export interface IDateTimeFieldWidgetInfo extends ILabeledControlWidgetInfo {
	showNavigationBar?: boolean;
}

export interface IDropDownWidgetInfo extends ILabeledControlWidgetInfo {

}

export interface IComboBoxWidgetInfo extends ILabeledControlWidgetInfo {
	active?: boolean;
	displayTemplate?: string;
	valueTemplate?: string;
	className?: string;
	choiceFilterExpr?: string;
	choiceSortExpr?: string;
}

export interface IDetailFormHandling {
	outletName: string;
	url: any[];
}

export interface PDObjectEditingSpec {
	outletName: string;
	url: any[];
	className: string | string[];
	objectInfo: string;
}


export interface IRelationControlWidgetInfo extends ILabeledControlWidgetInfo {
	active?: boolean;
	newAction?: boolean;
	editAction?: boolean;
	deleteAction?: boolean;
	selectAction?: boolean;
	disconnectAction?: boolean;
	editingSpec?: PDObjectEditingSpec;

	//newActionFormHandling?: IDetailFormHandling;
	//newObjectClassname?: string;

}

export interface IInputSwitchWidgetInfo extends ILabeledControlWidgetInfo {

}

export interface ICheckboxWidgetInfo extends IWidgetInfo {
	requiredTrue?: boolean;
}

export interface IObjectReferenceWidgetInfo extends IRelationControlWidgetInfo {
	choiceSpec?: IPDChoiceSpec | IPDChoiceSpec[];
	noSelectionItemText?: string;
	filteringEnabled?: boolean;
	type?: ObjectReferenceTypeET;
	selectionListSpec?: IPDObjectSelectionListSpec;
}

export interface IRelationTabHeader {
	text$: Observable<string>;
	maxVisibleTextLength?: number;
}

export interface IRelationTabWidgetInfo extends IRelationControlWidgetInfo {
	//relationInfo: string; // todo: prüfen, ob das wg. tabHeaderProvider noch gebraucht wird
	tabPanelStyle: ICssStyle;
	maxTabCount?: number;
	tabHeaderProvider?: (tabIndex: number, relObj: PDObject, localizationService: ILocalizationService) => IRelationTabHeader;
	canCreateTab?: boolean;
	canDeleteTab?: boolean;
}

export interface IMultiSelectRelationWidgetInfo extends IRelationControlWidgetInfo {
	choiceSpec?: IPDChoiceSpec | IPDChoiceSpec[];
	type?: MultiSelectRelationWidgetTypeET;
	selectionListSpec?: IPDObjectSelectionListSpec;
}

export interface IAccordionWidgetInfo extends IWidgetInfo {
	multiple?: boolean;
}

export interface IInfoFieldWidgetInfo extends ILabeledControlWidgetInfo {
	template?: string;
}

export interface IFileUploadWidgetInfo extends ILabeledControlWidgetInfo {
	maxFiles?: number;
	allowedFileTypes?: string[];
}
