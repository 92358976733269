import { Component, Input, ViewChild, ElementRef, ViewContainerRef, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { SelectEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload'
import { PDLabeledControlComponent, FormHandlerService, FileUploadWidgetInfo } from '@otris/ng-core';
import {
	ComponentTypeET, IFileUploadComponent, INotificationService, INotificationInstance, INotificationServiceToken, IPDDocument,
	ILocalizationServiceToken, ILocalizationService
} from '@otris/ng-core-shared';
import { switchMap, tap, map } from 'rxjs/operators';

enum NotificationTypeET {
	TooManyFiles,
	InvalidType
}

@Component({
	selector: 'otris-pd-file-upload',
	template: `
		<otris-pd-labeled-control-frame [labeledControl]="this" (toolBarButtonClick)="onToolBarButtonClick($event)" propagateClickEvent="true" [pdObject]="pdObject" [relatedFormControl]="this.control">
			<div class="fileselect-container">
				<ng-container *ngIf="isChangeable; else readonly">
					<kendo-fileselect #fileSelect class="fileselect" [(ngModel)]="_selectedFiles" (select)="onSelect($event)"
						(remove)="onRemove($event)" [disabled]="isDisabled">
						<kendo-fileselect-messages select="{{selectLabel | async}}" dropFilesHere="{{dropzoneLabel | async}}" cancel="Tata">
						</kendo-fileselect-messages>
					</kendo-fileselect>
					<div #notificationContainer></div>
				</ng-container>
			</div>
		</otris-pd-labeled-control-frame>

		<ng-template #readonly>
			<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
		</ng-template> <!-- #readonly -->
	`,
	styles: [`
		.fileselect-container {
			flex: 1;
			display: flex;
			flex-direction: column;
		}
		.fileselect {
			flex: 1;
		}
	`]
})
export class PDFileUploadComponent extends PDLabeledControlComponent implements IFileUploadComponent {

	//@ViewChild('fileSelect') _fileSelectComponent: ElementRef;
	@ViewChild('fileSelect', {read: ViewContainerRef}) _fileSelectContainer: ViewContainerRef;
	//@ViewChild('notificationContainer', {read: ViewContainerRef}) _notificationContainer: ViewContainerRef;

	@Input() maxFiles: number = 1;

	// todo
	//@Input() maxSize: number;

	/*fileRestrictions: FileRestrictions = {
		allowedExtensions: ['.png']
	};*/

	_selectedFiles: FileInfo[] = [];

	get selectLabel(): Observable<string> {
		return this.localizationService.getSystemString('kendo-ui.components.pd-file-upload.select-label');
	}

	get dropzoneLabel(): Observable<string> {
		return this.localizationService.getSystemString('kendo-ui.components.pd-file-upload.dropzone-label');
	}

	private _currentNotification: INotificationInstance;

	private _currentNotificationType: NotificationTypeET;

	//private _notificationHideSubscription: Subscription;

	private _selectedDocumentsMap = new Map<string, IPDDocument>();

	private get allowedFileTypes(): string[] {
		let wi = this.fileUploadWidgetInfo;
		if (wi && wi.allowedFileTypes) {
			return wi.allowedFileTypes;
		}
		return [];
	}

	get selectedDocuments(): IPDDocument[] {
		return Array.from(this._selectedDocumentsMap.values());
	}

		/**
	 * Liefert die ergonomischen Namen der selektierten Objekte.
	 */
		getSelectedDocumentsErgNameString(): string {
			if (this.selectedDocuments) {
				return this.selectedDocuments.map(doc => doc.fileName).join(", ");
			}
			return "";
		}

	private get fileUploadWidgetInfo(): FileUploadWidgetInfo {
		if (this.pdItemSpec.widgetInfo instanceof FileUploadWidgetInfo) {
			return <FileUploadWidgetInfo>this.pdItemSpec.widgetInfo;
		}
		return undefined;
	}

	canChange: boolean;

	get isChangeable(): boolean {
		return this.isReadonly !== true && this.canChange !== false;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService,
		@Inject(INotificationServiceToken) private notificationService: INotificationService,
		@Inject(ILocalizationServiceToken) private localizationService: ILocalizationService) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();
		let wi = this.fileUploadWidgetInfo;
		if (wi) {
			if (wi.maxFiles && Number.isInteger(wi.maxFiles) && wi.maxFiles > 0) {
				this.maxFiles = wi.maxFiles;
			}
			//this.multiple = wi.multipleFiles;
		}		
	}

	onSelect(args: SelectEvent) {
		let newNotificationType: NotificationTypeET;
		let selectedFileCount = this._selectedFiles ? this._selectedFiles.length : 0;
		if (args.files.length + selectedFileCount > this.maxFiles) {
			newNotificationType = NotificationTypeET.TooManyFiles;
		}
		else if (this.allowedFileTypes.length > 0 && args.files.findIndex(f => !this.allowedFileTypes.includes(f.extension)) >= 0) {
			newNotificationType = NotificationTypeET.InvalidType;
		}

		if (newNotificationType !== undefined) {
			this.showNotification(newNotificationType).subscribe();
			args.preventDefault();
			return;
		}
		this.hideNotification().subscribe();
		this.addSelectedDocuments(args.files).subscribe();
	}

	onRemove(args: RemoveEvent) {
		this.hideNotification().subscribe();
		this.removeSelectedDocuments(args.files);
	}

	protected onPDObjectChanged() {
		super.onPDObjectChanged();
		this._selectedDocumentsMap.clear();
		this._selectedFiles = [];
		if (!this.pdObject) {
			this.updateControlValue();
			return;
		}
		let currentVal = this.pdObject.pdObjectRaw[this.propertyName];
		if (!currentVal) {
			return;
		}
		let docs: IPDDocument[] = Array.isArray(currentVal) ? currentVal : [currentVal];
		docs.forEach(doc => {
			this._selectedDocumentsMap.set(doc.fileName, doc);
			this._selectedFiles.push({ name: doc.fileName, size: doc.size });
		});
		this.updateControlValue();
	}

	private showNotification(type: NotificationTypeET): Observable<void> {
		let hideOp$: Observable<void> = of(undefined);
		if (this._currentNotification) {
			if (type === this._currentNotificationType) {
				return of(undefined);
			}
			hideOp$ = this.hideNotification();
		}

		let msgParams: any;
		let msgId: string;
		switch (type) {
			case NotificationTypeET.InvalidType:
				msgId = 'kendo-ui.components.pd-file-upload.invalid-file-type';
				msgParams = { value: this.allowedFileTypes.reduce((prev, cur) => prev ? prev + ', ' + cur : cur, undefined) };
				break;
			case NotificationTypeET.TooManyFiles:
				msgId = 'kendo-ui.components.pd-file-upload.too-many-files';
				msgParams = { value: this.maxFiles };
				break;
		}

		return hideOp$.pipe(
			switchMap(() => this.localizationService.getSystemString(msgId, msgParams)),
			tap(val => {
				this._currentNotificationType = type;
				this._currentNotification = this.notificationService.show({
					anchor: this._fileSelectContainer,
					content: val,
					type: 'error',
					position: { horizontal: 'center', vertical: 'top' },
					closable: true
				});
			}),
			switchMap(() => this._currentNotification.afterHide$),
			tap(() => {
				this._currentNotification = undefined;
				this._currentNotificationType = undefined;
			})
		);
	}

	private hideNotification(): Observable<void> {
		if (this._currentNotification) {
			/*if (this._notificationHideSubscription) {
				this._notificationHideSubscription.unsubscribe();
				this._notificationHideSubscription = undefined;
			}*/
			this._currentNotification.hide();
			return this._currentNotification.afterHide$.pipe(
				tap(() => {
					this._currentNotification = undefined;
					this._currentNotificationType = undefined;	
				})
			);
		}
		return of(undefined);
	}

	private addSelectedDocuments(files: FileInfo[]): Observable<void> {

		let checkedFile = files.filter(f => !this._selectedDocumentsMap.has(f.name));
		let readOps$ = checkedFile.map(f => {
			return new Observable<void>(sub => {
				let fileReader = new FileReader();
				fileReader.onload = () => {
					let doc = <IPDDocument>{ fileName: f.name, data: fileReader.result, size: f.size }; 
					if (!this._selectedDocumentsMap.has(doc.fileName)) {
						this._selectedDocumentsMap.set(doc.fileName, doc);
					}
					sub.next();
					sub.complete();
				};
				fileReader.readAsDataURL(f.rawFile);
			});
		});
		return forkJoin(readOps$).pipe(
			map(res => this.updateControlValue())
		);
	}

	private removeSelectedDocuments(files: FileInfo[]): void {
		files.forEach(f => {
			if (this._selectedDocumentsMap.has(f.name)) {
				this._selectedDocumentsMap.delete(f.name);
			}
		});
		this.updateControlValue();
	}

	private removeAllSelectedDocuments(): void {
		this._selectedDocumentsMap.clear();
		this._selectedFiles = [];
		this.updateControlValue();
	}

	private updateControlValue(): void {
		let ctrl = this.control;
		if (ctrl) {
			ctrl.setValue(this.maxFiles == 1 ? (this.selectedDocuments.length > 0 ? this.selectedDocuments[0] : undefined) : this.selectedDocuments);
		}
	}

	protected applyAccessRights(): void {
		super.applyAccessRights();
		let relAccessRights = this.pdObject.metaData.getRelationMeta(this.propertyName)?.accessRights;
		this.canChange = (relAccessRights && relAccessRights.change !== undefined) ? relAccessRights.change : undefined;
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.FileUpload;
	}

	reset(): void {
		super.reset();
		this.removeAllSelectedDocuments();
	}

}
