import { InjectionToken } from '@angular/core';
import { CustomPDObject } from './pd-object';
import { AbstractEnumeration } from './abstract-enumeration';

export const IPDClassToken = new InjectionToken<IPDClass>('IPDClassToken');

export interface IPDClass {
	createInstance(className: string, oid?: string, isNew?: boolean): CustomPDObject;
	createEnumInstance(className: string, propName: string, value: number): AbstractEnumeration | undefined;
}
