import { ComponentTypeET, IRelationContext, IFormStatus, IPDListColumnInfo, IEnumerationItem } from './types';
import { Observable } from 'rxjs';
import { PDObject } from '../model/pd-object';
import { ToolbarSpec, IToolBarItemResult } from '../model/i-tool-bar-item-spec';
import { LabelPositionET, VisibilityET } from '../model/types';
import { IUIDrawerItem } from './i-ui-drawer-item';
import { EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';

export interface IComponent {
	id: string | undefined;
	relationContext: IRelationContext;
	componentType: ComponentTypeET;
	isDisabled: boolean;
	isEnabled: boolean;
	isRequired: boolean;
	mandatoryCustom: boolean;
	mandatoryCustomActivated: boolean;
	customData: any;
	visible: boolean;
	disable(flag: boolean): void;
	reset(): void;
	getFormControlStatus(): IFormStatus;
}

/*export interface IContainerComponent extends IComponent {
	childItems: UIItemSpec[];
}*/

export interface IGroupboxComponent extends IComponent {
}

export interface IPDComponent extends IComponent {
	label: string;
	customLabel: string;
	useCustomLabel: boolean;
	propertyName: string;
	shortDescription: string | undefined;
	hasShortDescription: boolean;
	customReadonly: boolean;
	isReadonly: boolean;
	control: AbstractControl;
}

// Weg?
export interface IBooleanComponent extends IComponent {
	checked: boolean;
}

export interface IInputSwitchComponent  extends IPDComponent {
	valueChanged: Observable<boolean>;
	checked: boolean;
}

export interface ILabeledControlComponent extends IPDComponent {
	labelPosition: LabelPositionET;
	labelVisible: boolean;
	labelVisibility: VisibilityET;
	wrapLabel: boolean;
	toolbarSpec: ToolbarSpec | undefined;
	toolbarButtonClick: Observable<IToolBarItemResult>;
}

export interface ITextFieldComponent extends ILabeledControlComponent {
	text: string;
	valueChanges$: Observable<string>;
}

export interface INumericTextFieldComponent extends ILabeledControlComponent {
	value: number;
}

export interface IDateTimeFieldComponent extends ILabeledControlComponent {
	value: Date;
}

export interface IRadioButtonComponent extends IPDComponent {
	checkedChanged: Observable<IRadioButtonComponent>;
	value: IEnumerationItem | boolean;
	checked: boolean;
	//onUncheck(): void;
	check(): void;
}

export interface IRadioButtonGroupComponent extends IComponent {
	selection: IRadioButtonComponent | undefined;
	selectionChanged: Observable<IRadioButtonComponent>;
	setSelection(sel: IRadioButtonComponent | undefined): void;
	setSelectionFromValue(sel: IEnumerationItem | boolean | undefined): void;
}

export interface ITabViewComponent extends IComponent {
	disableTabPanel(index: number, flag: boolean): void;
	getTabPanelDisabled(index: number): boolean;
	hideTabPanel(index: number, flag: boolean): void;
	getTabPanelHidden(index: number): boolean;
}

export enum DrawerModeET {
	Normal,
	Compact,
	CompactAutoExpand
}

export enum MultiSelectValueTypeET {
	String,
	PDObject
}

export type DrawerExpandModeT = 'push' | 'overlay';

export type DrawerPositionT = 'start' | 'end';

export interface IDrawerComponent extends IComponent {
	mode: DrawerModeET;
	expandMode: DrawerExpandModeT;
	position: DrawerPositionT;
	selectedItem: IUIDrawerItem | undefined;
	selectedIndex: number;
	expanded: boolean;
	itemSelected: EventEmitter<IUIDrawerItem>;
}

// todo: entfernen
/*export interface IValueComponent extends IPDComponent {
	value: any;
	valueChanged: Observable<any>;
}*/

export interface ICustomChoiceProviderResult {
	data: Observable<PDObject[] | string[]>;
	className?: string;
	objectInfo?: string;
	title?: string;
	titleId?: string;
}

export interface IComboBoxComponent extends ILabeledControlComponent {
	active: boolean;
	customChoiceProvider: () => Observable<PDObject[]>;
	customChoiceFilterProvider: () => string;
	value: any;
	valueChanged: Observable<any>; // todo: typsicher machen
	updateChoice();
}

export interface IDropDownComponent extends ILabeledControlComponent {
	selectedItem: string | IEnumerationItem | undefined;
}

export interface IRelationComponent extends ILabeledControlComponent {
	active: boolean;
	customChoiceProvider: () => ICustomChoiceProviderResult[];
	customChoiceFilterProvider: () => string | Map<string, string>;
	selectionListColumnProvider: () => IPDListColumnInfo[];
}

export interface SelectionChangingArgs {
	cancel$?: Observable<boolean>;
	oldSelection: PDObject | undefined;
	newSelection: PDObject | undefined;
}

export interface IObjectReferenceComponent extends IRelationComponent {
	selection: PDObject | undefined;
	selectionChanged: Observable<PDObject | undefined | null>;
	selectionChanging$: Observable<SelectionChangingArgs>;
	choiceUpdated: Observable<void>;
	choiceObjects: PDObject[];
	updateChoice();
}

export interface IMultiSelectComponent extends IRelationComponent {
	valueType: MultiSelectValueTypeET;
	selection: (PDObject | string)[] | undefined;
	selectionChanged: Observable<PDObject[] | string[] | undefined>;
	choiceObjects: (PDObject | string)[];
	updateChoice();
}

export type CreateRelationObjectCallback = () => PDObject;

export interface IPDRelationTabComponent extends IPDComponent {

	relationObjectCreated$: Observable<string>;

	relationObjectDeleting$: Observable<PDObject>;

	relationObjectDeleted$: Observable<void>;

	selectedItemChanged$: Observable<string>;

	createRelationObjectCallback: CreateRelationObjectCallback;

	selectedObject: PDObject;

	relationObjects: PDObject[];

	getComponent<T extends IComponent>(id: string, type?: ComponentTypeET): T;

	getComponentFromTab<T extends IComponent>(tabId: string, id: string, type?: ComponentTypeET): T;

	getComponentFromAllTabs<T extends IComponent>(id: string, type?: ComponentTypeET): T[];

	getTabIdForComponent(comp: IComponent): string | undefined;

	removeTab(tabId: string): boolean;

	removeAllTabs(): void;

	createNewTab(): string;

	recreateTabs(): void;
}

export interface IFileUploadComponent extends ILabeledControlComponent {
	
}
