import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalCustomNavigationClient, MsalService } from '@azure/msal-angular';
import { RedirectRequest, EventType, EventMessage, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';

import { EMPTY, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IAzureADAuthProviderService } from '../../services/azure-ad-auth-provider.service';
import { ILoginService } from '../../../../model/i-login-service';



// *ngIf="!loggedIn"
@Component({
	selector: 'otris-azure-ad-login',
	template: `
	`,
	styles: [],
})
export class AzureADLoginComponent implements OnInit, OnDestroy {

	subscriptions: Subscription[] = [];

	private readonly _destroying$ = new Subject<void>();

	constructor(private _router: Router,
		private _route: ActivatedRoute,
		//@Inject(IErrorHandlerToken) private _errorHandler: IErrorHandler,
		//private _broadcastService: MsalBroadcastService,
		private _msalService: MsalService,
		private _azureADAuthProviderService: IAzureADAuthProviderService,
		private _loginService: ILoginService
	) {}

	ngOnInit() {

		//const customNavigationClient = new MsalCustomNavigationClient(this._msalService, this._router, this._location);
		//this._msalService.instance.setNavigationClient(customNavigationClient);

		//this._msalService.instance.getAllAccounts()

		/*this._broadcastService.msalSubject$.pipe(
			filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
			takeUntil(this._destroying$)
		).subscribe((result) => {
			console.log('Login success');
		});*/
	

		this._msalService.handleRedirectObservable().subscribe({
			next: (result: AuthenticationResult) => {
				if (result) {
					this._azureADAuthProviderService.payload = result.idTokenClaims;
					this._azureADAuthProviderService.isAuthenticated = true;
					this._loginService.login().pipe(
						switchMap(res => {
							if (res) {
								return this._loginService.handleSuccessfullLogin();
							}
							return EMPTY;
						})
					).subscribe();
				}
				else {
					this.login();
				}
			},
			error: (error) => console.log(error)
		 });
	}

	ngOnDestroy(): void {
		this._destroying$.next(null);
		this._destroying$.complete();  
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	private login() {
		let queryParams = '';
		let paramMap = this._route.snapshot.queryParamMap;
		paramMap.keys.forEach(k => {
			if (queryParams.length > 0) {
				queryParams += '%24';
			}
			queryParams += k + '=' + paramMap.get(k);
		});
		let request: RedirectRequest = {
			scopes: ['user.read', 'openid', 'profile'],
			state: queryParams
		};


		/*this._broadcastService.inProgress$.pipe(
      	filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe();*/

		this._msalService.loginRedirect(request);

	}
}
