import { Component, OnInit, Input, Directive, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { PDObject, IPDComponent, IFormStatus, PropertyAccessRightET, IMetaDataService, ServiceLocator, IComponent, RelationAccessRightET } from '@otris/ng-core-shared';
import { UIAbstractComponent } from '../ui-abstract/ui-abstract.component';
import { PDItemSpec } from '../../model/pd-layout';
import { FormHandlerService } from '../../services/form-handler.service';
import { GlobalTemplateProviderService } from '../../services/global-template-provider.service';

// export const PD_COMPONENT_READONLY_TEMPLATE_ID = 'PDComponentReadonlyTemplateId';

@Directive()
export abstract class PDComponent extends UIAbstractComponent implements IPDComponent {
	@Input()
	set pdObject(val: PDObject) {
		if (val && val != this._pdObject) {
			this.onPDObjectChanging();
			this._pdObject = val;
			this.onPDObjectChanged();
		}
	}

	get pdObject(): PDObject {
		return this._pdObject;
	}

	private _pdObject: PDObject;

	get propertyName(): string {
		return this._propertyName;
	}

	private _propertyName: string;

	private _isReadonlySystem: boolean = false;

	/**
	 * Ist die Komponente bearbeitbar?
	 * Für die Komponenten ObjectReferences und Multiselect gibt es einen
	 * weiteren Attribut "canChange", dieser sollte ggf. auch beachtet werden.
	 */
	get isReadonly(): boolean {
		return this._isReadonlySystem || this._customReadonly;
	}

	private _customReadonly: boolean;

	get customReadonly(): boolean {
		return this._customReadonly
	}

	set customReadonly(flag: boolean) {
		this._customReadonly = flag;
		this.onReadonlyChanged();
	}

	private setReadonlySystem(f: boolean) {
		if (this._isReadonlySystem != f) {
			this._isReadonlySystem = f;
			this.onReadonlyChanged();
		}
	}

	protected onReadonlyChanged(): void {
		let ctrl = this.control;
		if (ctrl) {
			if (this.isReadonly) {
				ctrl.disable();
			}
			else {
				ctrl.enable();
			}
		}
	}

	get control(): AbstractControl {
		return (this.propertyName && this.formGroup) ? this.formGroup.controls[this.propertyName] : undefined;
	}

	get shortDescription(): string | undefined {
		return this._shortDescription;
		/*if (this.pdObject) {
			return this.pdObject.metaData.getShortDescription(this.propertyName);
		}
		return undefined;*/
	}

	private _shortDescription: string;

	get hasShortDescription(): boolean {
		if (this.pdObject) {
			return this.pdObject.metaData.hasShortDescription(this.propertyName);
		}
		return false;
	}

	get label(): string {
		if(this._useCustomLabel) {
			return this._customLabel ? this._customLabel : this._label;
		} else {
			return this._label;
		}
		// return this._customLabel ? this._customLabel : this._label;

		//return (this.pdObject && this.propertyName) ? this.pdObject.metaData.getPropertyErgname(this.propertyName) : "n.a.";
	}

	private _label: string = "n.a.";

	set customLabel(val: string) {
		this._customLabel = val;
	}
	private _customLabel: string;
	
	get useCustomLabel(): boolean {
		return this._useCustomLabel;
	}

	set useCustomLabel(value: boolean) {
		this._useCustomLabel = value;
	}
	private _useCustomLabel = true;

	private _metaDataChangedSubscription: Subscription;

	protected get metaDataService(): IMetaDataService {
		return ServiceLocator.injector.get(IMetaDataService);
	}

	// private _readonlyTemplate: TemplateRef<any>;

	// get readonlyTemplate(): TemplateRef<any> | undefined {
	// 	return this._readonlyTemplate;
	// }

	// get readonlyTemplateContext(): { comp: IPDComponent } {
	// 	return { comp: this };
	// }

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();

		this.route.data.subscribe((data: { pdObject: PDObject }) => {
			if (data.pdObject) {
				this.pdObject = data.pdObject;
				//this.changeDetector.detectChanges();
			}
		});

		if (!(this.uiItemSpec instanceof PDItemSpec)) {
			throw Error(`uiItemSpec is not instanceof PDItemSpec.`);
		}

		if (this.pdItemSpec.property) {
			this._propertyName = this.pdItemSpec.property;
			// todo: Control nur dann erzeugen, falls es noch keins für dieses Property in der FormGroup gibt
			// ansonsten das exisitierende nehmen
			// RadioButtons referenzieren z.B. alle dasselbe FormControl
			let ctrl = new FormControl(); //this.pdObject.pdObjectRaw[this.propertyName]);
			if (this.formGroup.contains(this.propertyName)) {
				console.debug(`FormControl with name '${this.propertyName}' already exists in FormGroup. Will be overwritten with new control.`);
				this.formGroup.removeControl(this.propertyName);
			}
			this.formGroup.addControl(this.propertyName, ctrl);
			ctrl.valueChanges.subscribe(val => {
				this.onControlValueChanges(val);
			});
			this.onPDObjectChanged();
		}

		// let templateProviderService = ServiceLocator.injector.get(GlobalTemplateProviderService);
		// let directive = templateProviderService.getTemplate(PD_COMPONENT_READONLY_TEMPLATE_ID);
		// if (directive) {
		// 	this._readonlyTemplate = directive.template;
		// }
	}

	ngOnDestroy() {
		if (this.propertyName && this.formGroup.contains(this.propertyName)) {
			this.formGroup.removeControl(this.propertyName);
		}
		if (this._metaDataChangedSubscription) {
			this._metaDataChangedSubscription.unsubscribe();
		}
		super.ngOnDestroy();
	}

	getFormControlStatus(): IFormStatus {
		let stat = <IFormStatus>{ pristine: true, touched: false, valid: true };
		let ctrl = this.control;
		if (ctrl && !ctrl.disabled) {
			stat.pristine = ctrl.pristine;
			stat.touched = ctrl.touched;
			stat.valid = ctrl.valid;
		}
		return stat;
	}

	protected onControlValueChanges(val: any) {}

	protected get pdItemSpec(): PDItemSpec {
		return <PDItemSpec>this.uiItemSpec;
	}

	protected onPDObjectChanging() {
		if (this._metaDataChangedSubscription) {
			this._metaDataChangedSubscription.unsubscribe();
			this._metaDataChangedSubscription = undefined;
		}
	}

	protected onPDObjectChanged() {
		if (!this._pdObject) {
			return;
		}
		let ctrl = this.control;
		if (ctrl) {
			if (ctrl.value !== this.pdObject.pdObjectRaw[this.propertyName]) {
				ctrl.setValue(this.pdObject.pdObjectRaw[this.propertyName]);
			}

			/*let canWrite = this._pdObject.metaData.getPropertyAccessRight(PropertyAccessRightET.Write, this.propertyName);
			this.setReadonlySystem(canWrite === false || (this.widgetInfo && this.widgetInfo.guiReadonly));

			let mandatory = this.pdObject.metaData.isMandatory(this.propertyName);
			this._isMandatoryModel = mandatory !== undefined ? mandatory : false;
			if (this._isReadonlySystem) {
				this._mandatoryCustom = false;
			}
			this.updateValidators(ctrl);*/
			this.applyAccessRights();
		}

		this.onLanguageChanged();
		this._metaDataChangedSubscription = this.pdObject.metaData.metaDataChanged$.subscribe(
			() => this.onMetaDataChanged()
		);
	}

	protected onLanguageChanged() {
		super.onLanguageChanged();
		if (this.propertyName) {
			this._pdObject.metaData.getPropertyErgname(this.propertyName).subscribe(res => this._label = res);
			this._pdObject.metaData.getShortDescription(this.propertyName).subscribe(res => this._shortDescription = res);
		}
	}

	protected updateValidators(ctrl?: AbstractControl): void {
		if (!ctrl) {
			ctrl = this.control;
			if (!ctrl) {
				return;
			}
		}
		let validators = this.pdObject.metaData.getValidators(this.propertyName);
		if (this.mandatoryCustom) {
			if (!validators) {
				validators = [];
			}
			if (!validators.includes(Validators.required)) {
				validators.push(Validators.required);
			}
		}
		this.getCustomValidators().subscribe(
			res => {
				if (res) {
					if (!validators) {
						validators = [];
					}
					validators.push(...res);
				}
				ctrl.clearValidators();
				if (validators) {
					ctrl.setValidators(validators);
				}
				ctrl.updateValueAndValidity();
			}
		);
	}

	protected getCustomValidators(): Observable<ValidatorFn[] | undefined> {
		return of(undefined);
	}

	protected onMetaDataChanged(): void {
		this.applyAccessRights();
	}

	protected applyAccessRights(): void {
		if (!this.propertyName) {
			return;
		}
		let canWrite = this._pdObject.metaData.getPropertyAccessRight(PropertyAccessRightET.Write, this.propertyName);
		this.setReadonlySystem(canWrite === false || (this.widgetInfo && this.widgetInfo.guiReadonly));

		let mandatory = this.pdObject.metaData.isMandatory(this.propertyName);
		this._isMandatoryModel = mandatory !== undefined ? mandatory : false;
		if (this._isReadonlySystem) {
			this._mandatoryCustom = false;
		}
		this.updateValidators();
	}

	public getId(): string | undefined {
		if (this.pdItemSpec.id) {
			return this.pdItemSpec.id;
		}
		if (this.pdItemSpec.property) {
			return this.getIdFromPropertyName(this.pdItemSpec.property);
		}
		return undefined;
		//return this.pdItemSpec.id;
	}

	protected hasId(): boolean {
		return this.pdItemSpec && (!!this.pdItemSpec.id || !!this.pdItemSpec.property);
	}

	protected setMandatoryCustom(val: boolean): void {
		super.setMandatoryCustom(this._isReadonlySystem ? false : val);
	}

	//
	// IComponent
	//

	/*get id(): string | undefined {
		if (this.pdItemSpec.property) {
			if (this.relationContext) {
				let ctrlId = this.relationContext.path;
				return this.relationContext.isMultiple ?
					this.relationContext.path + '.' + this.relationContext.index + '.' + this.pdItemSpec.property :
					this.relationContext.path + '.' + this.pdItemSpec.property;
			}
			return this.pdItemSpec.property;
		}
		return this.pdItemSpec.id;
	}*/

	disable(flag: boolean): void {
		let ctrl = this.control;
		if (ctrl) {
			if (flag) {
				ctrl.disable();
			}
			else {
				ctrl.enable();
				this.onReadonlyChanged();
			}
		}
	}

	reset(): void {
		let ctrl = this.control;
		if (ctrl && this.pdObject && this.propertyName) {
			ctrl.reset(this.pdObject.pdObjectRaw[this.propertyName]);
		}

		// Test:
		this.customReadonly = false;
		//this.disable(false);
	}

	get isEnabled(): boolean {
		let ctrl = this.control;
		return ctrl ? ctrl.enabled : false;
	}

	get isDisabled(): boolean {
		return !this.isEnabled;
	}

	/*get mandatoryCustomActivated(): boolean {
		return this._mandatoryCustomActivated;
	}

	set mandatoryCustomActivated(val: boolean) {
		this._mandatoryCustomActivated = val;
	}

	protected _mandatoryCustomActivated: boolean = false;
	
	get mandatoryCustom(): boolean {
		return this._mandatoryCustom;
	}

	set mandatoryCustom(val: boolean) {
		if (this._mandatoryCustom != val) {
			this._mandatoryCustom = val;
			this.updateValidators();
		}
	}

	protected _mandatoryCustom: boolean = false;	*/

	/*set mandatoryCustomActivated(val: boolean) {
		this._mandatoryCustomActivated = val;
	}*/
}
