import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Observable, of } from 'rxjs';
import { map, switchMap } from "rxjs/operators";
import {
	PDComponent, RadioButtonWidgetInfo, UIRadioButtonGroupSpec, ItemTypeET, RadioButtonGroupDataTypeET, PDRadioButtonSpec, RadioButtonBulletLocationET,
	FormHandlerService
} from '@otris/ng-core';
import { ComponentTypeET, AbstractEnumeration, IEnumerationItem, ServiceLocator, ICssStyle, IRadioButtonComponent } from '@otris/ng-core-shared';
import { PDRadioButtonGroupService } from '../../services/pd-radio-button-group.service';

// checked
// (change)="onChange($event)"
@Component({
	selector: 'otris-pd-radio-button',
	template: `
		<ng-container *ngIf="!isReadonly; else readonly">
			<div class="content-container" *ngIf="radioButtonSpec?.content; else defaultRadio">
				<input type="radio" [name]="radioButtonGroup" [value]="radioButtonValue" class="k-radio" [formControl]="formGroup.controls[propertyName]"
					(change)="onChange($event)" [id]="radioButtonId"/>
				<label class="k-radio-label custom-input-label" [ngStyle]="radioStyle" [for]="radioButtonId"></label>
				<otris-pd-panel #panel [uiItemSpec]="radioButtonSpec.content" [formGroup]="formGroup" [pdObject]="pdObject" [relationContext]="relationContext"></otris-pd-panel>
			</div>

			<ng-template #defaultRadio>
				<div class="content-container">
					<input type="radio" [name]="radioButtonGroup" [value]="radioButtonValue" class="k-radio" (change)="onChange($event)"
						[formControl]="formGroup.controls[propertyName]" [id]="radioButtonId"/>
					<label class="k-radio-label label" [for]="radioButtonId">{{rbLabel}}</label>
				</div>
			</ng-template>
		</ng-container>

		<ng-template #readonly>
			<ng-container *ngIf="value === true">
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			</ng-container>
		</ng-template>
	`,
	styles: [`
		.content-container {
			display: flex;
		}
		.custom-input-label {
			padding: 0 0 0 0.5em;
		}
		.label {
			margin: auto 0 auto 0.5em;
		}
	`]
})
export class PDRadioButtonComponent extends PDComponent implements IRadioButtonComponent {

	//@Input() protected pdObject: PDObjectWrapper;
	@ViewChild('panel') panelComponent: any;

	private static counter: number = 0;

	private _radioButtonId: string;

	radioButtonGroup: string;

	private _rbLabel: string;

	get rbLabel(): string {
		return this._rbLabel;
	}

	get bulletLocation(): RadioButtonBulletLocationET {
		let widgetInfo = this.radioButtonWidgetInfo;
		if (widgetInfo && widgetInfo.bulletLocation !== undefined) {
			return widgetInfo.bulletLocation;
		}
		return RadioButtonBulletLocationET.Center;
	}

	radioButtonValue: IEnumerationItem | boolean;

	get radioStyle(): ICssStyle {
		let sAlign = 'center';
		switch (this.bulletLocation) {
			case RadioButtonBulletLocationET.Start:
				sAlign = 'flex-start';
				break;
			case RadioButtonBulletLocationET.Center:
				sAlign = 'center';
				break;
			case RadioButtonBulletLocationET.End:
				sAlign = 'flex-end';
				break;
		}
		return <ICssStyle>{
			'align-self': sAlign
		}
	};

	private get radioButtonGroupSpec(): UIRadioButtonGroupSpec {
		return <UIRadioButtonGroupSpec>this.uiItemSpec.getAncestorOfType(ItemTypeET.UIRadioButtonGroup);
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService, private radioButtonGroupService: PDRadioButtonGroupService) {
		super(router, route, formHandler);
		this._radioButtonId = "pdRadioButton_" + PDRadioButtonComponent.counter.toString();
		PDRadioButtonComponent.counter++;
		radioButtonGroupService.registerRadioButton(this);
	}

	ngOnInit() {		
		this.radioButtonGroup = this.radioButtonGroupService.groupName;
		let rbWidgetInfo = this.radioButtonWidgetInfo;
		let rbGroupSpec: UIRadioButtonGroupSpec = this.radioButtonGroupSpec;
		if (rbGroupSpec && rbWidgetInfo) {
			//this.radioButtonGroup = rbGroupSpec.name;
			switch (rbGroupSpec.dataType) {
				case RadioButtonGroupDataTypeET.Enum:
					{
						let obj = this.pdObject.pdObjectRaw[rbGroupSpec.property + "Object"];
						if (obj instanceof AbstractEnumeration) {
							let abstrEnum = <AbstractEnumeration>obj;
							let enumItem = abstrEnum.getItem(rbWidgetInfo.value);
							this.radioButtonValue = enumItem;
						}
						break;
					}

				case RadioButtonGroupDataTypeET.Boolean:
					{
						if (typeof (rbWidgetInfo.value) === 'boolean') {
							this.radioButtonValue = rbWidgetInfo.value;
						}
						break;
					}
			}
		}
		super.ngOnInit();
		this.onLanguageChanged();
	}

	ngAfterViewInit() {
		if (this.panelComponent && !this._checked) {
			this.panelComponent.disable(true);
		}
	}

	get radioButtonId(): string {
		return this._radioButtonId;
	}

	onChange(evt: any) {
		if (evt.target.checked) {
			this.checked = true;
			/*if (this.panelComponent) {
				this.panelComponent.disable(false);
			}*/
		}
	}

	protected onControlValueChanges(val: any) {
		super.onControlValueChanges(val);
		this.checked = val === this.radioButtonValue;
	}

	protected onPDObjectChanged() {
		super.onPDObjectChanged();
		let ctrl = this.control;
		if (ctrl) {
			this.checked = ctrl.value === this.radioButtonValue; 
		}
	}

	protected onLanguageChanged() {
		super.onLanguageChanged();

		let rbWidgetInfo = this.radioButtonWidgetInfo;
		let rbGroupSpec: UIRadioButtonGroupSpec = this.radioButtonGroupSpec;
		if (rbGroupSpec && rbWidgetInfo) {
			//this.radioButtonGroup = rbGroupSpec.name;
			this.getWidgetInfoLabel().pipe(
				switchMap(wiLabel => {
					switch (rbGroupSpec.dataType) {
						case RadioButtonGroupDataTypeET.Enum:
							{
								let obj = this.pdObject.pdObjectRaw[rbGroupSpec.property + "Object"];
								if (obj instanceof AbstractEnumeration) {
									// todo: constructor.name geht nicht
									return wiLabel ? of(wiLabel) : this.metaDataService.getEnumLiteralErgName(obj.constructor.name, rbWidgetInfo.value);
								}
								break;
							}

						case RadioButtonGroupDataTypeET.Boolean:
							{
								if (typeof (rbWidgetInfo.value) === 'boolean') {
									return of(wiLabel ? wiLabel : (rbWidgetInfo.value ? 'true' : 'false'));
								}
								break;
							}
					}
					return of(undefined);
				}),
				map(label => this._rbLabel = label)
			).subscribe();
		}
	}
	
	public getId(): string | undefined {
		if (this.pdItemSpec.property && this.radioButtonValue !== undefined) {
			return this.getIdFromPropertyName(this.pdItemSpec.property) + this.radioButtonValue.toString();
		}
		return super.getId();
	}

	private getWidgetInfoLabel(): Observable<string | undefined> {
		let rbWidgetInfo = this.radioButtonWidgetInfo;
		if (!rbWidgetInfo) {
			return of(undefined);
		}
		if (rbWidgetInfo.labelId && this.pdObject) {
			return this.pdObject.metaData.getStringFromId(rbWidgetInfo.labelId);
		}
		return of(rbWidgetInfo.label);

	}

	get radioButtonSpec(): PDRadioButtonSpec {
		return this.uiItemSpec instanceof PDRadioButtonSpec ? <PDRadioButtonSpec>this.uiItemSpec : undefined; 
	}

	get radioButtonWidgetInfo(): RadioButtonWidgetInfo {
		if (this.uiItemSpec.widgetInfo instanceof RadioButtonWidgetInfo) {
			return <RadioButtonWidgetInfo>(this.uiItemSpec.widgetInfo);
		}
		return undefined;
	}

	//----------------------------------------------------------------------------------------------
	// IComponent
	//----------------------------------------------------------------------------------------------

	get componentType(): ComponentTypeET {
		return ComponentTypeET.RadioButton;
	}

	disable(flag: boolean): void {
		super.disable(flag);
		if (this.panelComponent && (flag || this.checked)) {
			this.panelComponent.disable(flag);
		}
	}

	reset(): void {
		super.reset();
		if (this.panelComponent) {
			this.panelComponent.reset();
		}
	}

	//----------------------------------------------------------------------------------------------
	// IRadioButtonComponent
	//----------------------------------------------------------------------------------------------

	private _checkedChanged: Subject<IRadioButtonComponent> = new Subject();

	get checkedChanged(): Observable<IRadioButtonComponent> {
		return this._checkedChanged.asObservable();
	}

	get value(): IEnumerationItem | boolean {
		return this.radioButtonValue;
	}

	/*onUncheck(): void {
		this._checked = false;
		if (this.panelComponent) {
			this.panelComponent.disable(true);
		}
	}*/

	get checked(): boolean {
		return this._checked;
	}

	set checked(flag: boolean) {
		if (flag !== this._checked) {
			this._checked = flag;
			let ctrl = this.control;
			if (ctrl) {
				if (flag) {
					ctrl.setValue(this.radioButtonValue);
				}
				else if (ctrl.value === this.radioButtonValue) {
					ctrl.setValue(undefined);
				}
			}
			console.log(`Radio Button '${this.radioButtonValue}' changed to: ${this._checked}`);
			if (this.panelComponent) {
				this.panelComponent.disable(!flag);
			}
			this._checkedChanged.next(this);
		}
	}

	private _checked: boolean = false;

	// todo: muss noch getestet werden. Wird das noch gebraucht? Ist doch dasselbe wie checked?
	check(): void {
		let ctrl = this.control;
		if (ctrl) {
			ctrl.setValue(this.radioButtonValue); // todo: auch bei checked
			this.checked = true;
			//ctrl.setValue(this.pdObject.pdObjectRaw[this.propertyName]);
		}
	}
}
