import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { OtrisNgCoreSharedModule } from '@otris/ng-core-shared';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PDFormHeaderTemplateDirective } from './directives/pd-form-header-template.directive';
import { UIContentComponent } from './components/ui-content/ui-content.component';
import { UISpinnerComponent } from './components/ui-spinner/ui-spinner.component';

import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe, 'de')

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		OtrisNgCoreSharedModule,
		NgxSpinnerModule
	],
	declarations: [
		PDFormHeaderTemplateDirective,
		UIContentComponent,
		UISpinnerComponent
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		OtrisNgCoreSharedModule,
		PDFormHeaderTemplateDirective,
		UIContentComponent,
		UISpinnerComponent
		//NgxSpinnerModule
	],
	providers: [
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	]
})
export class OtrisNgCoreModule {
}
