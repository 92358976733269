import { Injectable } from "@angular/core";
import { IPrivacyNgModuleSettingsService, IModuleDependencies } from "@otris/privacy-ng-lib"
import { Version } from '@otris/ng-core-shared';
import { environment } from '../../environments/environment';
import pkgJson from '../../../../../package.json';
import angularCorePkgJson from '@angular/core/package.json';
import otrisPrivacyNgLibPkgJson from '@otris/privacy-ng-lib/package.json';
import otrisJafWebPkgJson from '@otris/jaf-web/package.json';
import otrisNgCoreSharedPkgJson from '@otris/ng-core-shared/package.json';
import otrisNgCorePkgJson from '@otris/ng-core/package.json';
import otrisKendoUIPkgJson from '@otris/kendo-ui/package.json';

@Injectable()
export class LandingPageSettingsService extends IPrivacyNgModuleSettingsService {
	get initParamsRequestMethod(): string {
		return 'Anfrage::handleModuleRequest'; // todo
	}

	get customRequestMethod(): string {
		return 'Anfrage::handleModuleRequest'; // todo
	}

	get themeId(): string | undefined {
		return undefined;
	}

	get moduleVersion(): Version {
		return new Version(pkgJson.version);
	}
	
	get dependencies(): IModuleDependencies {
		return <IModuleDependencies>{
			privacyNgLibVersion: otrisPrivacyNgLibPkgJson.version,
			otrisJafWebVersion: otrisJafWebPkgJson.version,
			otrisNgCoreSharedVersion: otrisNgCoreSharedPkgJson.version,
			otrisNgCoreVersion: otrisNgCorePkgJson.version,
			otrisKendoUIVersion: otrisKendoUIPkgJson.version,
			angularCoreVersion: angularCorePkgJson.version
		};
	}

	get productionVersion(): boolean {
		return environment.production;
	}
}
