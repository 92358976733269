import { Component, OnInit, Input, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';
import { PDListComponent, ListTypeET } from '../pd-list/pd-list.component';
import { SelectionModeET, PDObject, ISelectObjectOptions, ICssStyle, IPDListColumnInfo } from '@otris/ng-core-shared';
import { IPDColumnProviderService, IPDRootColumnProviderToken } from '@otris/ng-core';
import { Observable } from 'rxjs';


export interface ISelectionResult {
	selection: PDObject | PDObject[];
}

// [listType]="listTypeET.SelectionList"
@Component({
	selector: 'otris-pd-object-selection',
	template: `
		<div class="root-container" [ngStyle]="rootContainerStyle">
			<div>
				<ng-container *ngIf="customContent">
					<ng-container *ngTemplateOutlet="customContent">
					</ng-container>
				</ng-container>
			</div>
			<otris-pd-list #pdList [className]="className" [columnInfos]="columnInfos" [selectionMode]="selectionMode" [staticFilterExpr]="options?.filterExpr"
				[sortExpr]="options?.sortExpr" [listType]="listTypeET.SelectionList" [objects$]="choiceObjects">
			</otris-pd-list>
			<kendo-dialog-actions>
				<button kendoButton [disabled]="!hasSelection" (click)="onOkClick()" primary="true">
					{{ 'system.kendo-ui.components.pd-object-selection.button-select' | translate}}
				</button>
				<button kendoButton (click)="onCancelClick()">{{ 'system.kendo-ui.components.pd-object-selection.button-cancel' | translate }}</button>
			</kendo-dialog-actions>
		</div>
	`,
	styles: [`
		.root-container {
			/* todo: im IE11 gängig machen, evtl spezielle CSS-KLasse für IE11 mittels ngClass, UserAgent setzten */
			display: grid;
			grid-template-rows: max-content minmax(0px, 1fr);
			grid-template-columns: minmax(0px, 1fr);

			/* ALT
			display: grid; IE11 fix
			grid-template-columns: minmax(10px, 1fr);
			grid-template-rows: min-content minmax(10px, 1fr) min-content;
			*/
		}
	`]
})
export class PDObjectSelectionComponent extends DialogContentBase implements OnInit {
	//@Input() objCreator: new () => T;

	@Input() className: string;

	@Input() columnInfos: IPDListColumnInfo[];

	@Input() choiceObjects: Observable<PDObject[]>;

	@Input() selectionMode: SelectionModeET = SelectionModeET.Single;

	@Input() options: ISelectObjectOptions;

	@ViewChild('pdList', { static: true }) pdList: PDListComponent;

	customContent: TemplateRef<any>;

	listTypeET = ListTypeET;

	/*private rootContainerStyle: ICssStyle = <ICssStyle>{
		padding: 0,
		'background-color': 'transparent',
		border: 0,
		flex: '3 3 100px'
	};*/

	get rootContainerStyle(): ICssStyle {
		let style = <ICssStyle>{
			'max-height': '80vh',
			'max-width': '90vw'
		}

		if (this.options) {
			if (this.options.width) {
				style['width'] = this.options.width;
			}
			if (this.options.height) {
				style['height'] = this.options.height;
			}
			if (this.options.minWidth) {
				style['min-width'] = this.options.minWidth;
			}
			if (this.options.maxWidth) {
				style['max-width'] = this.options.maxWidth;
			}
			if (this.options.minHeight) {
				style['min-height'] = this.options.minHeight;
			}
			if (this.options.maxHeight) {
				style['max-height'] = this.options.maxHeight;
			}
		}
		return style;
	}

	get selection(): PDObject {
		return this.pdList.selectedObject;
	}

	get hasSelection(): boolean {
		switch (this.selectionMode) {
			case SelectionModeET.Single:
				return !!this.pdList.selectedObject;
			case SelectionModeET.Multiple:
				return this.pdList.selectedObjects.length > 0;
		}
	}

	
	constructor(@Inject(IPDRootColumnProviderToken) private columnProvider: IPDColumnProviderService, dialog: DialogRef) {
		super(dialog);
	}

	ngOnInit() {
		if (this.options) {
			if (this.options.columnInfos) {
				this.columnInfos = this.options.columnInfos;
			}
			if (this.options.choiceObjects) {
				this.choiceObjects = this.options.choiceObjects;
			}
		}

		if (!this.columnInfos) {
			this.columnInfos = this.columnProvider.getColumns(this.className);
		}
	}

	onOkClick() {
		this.dialog.close(<ISelectionResult>{ selection: this.pdList.selection });
	}

	onCancelClick() {
		this.dialog.close();
	}
}
