import { Component, Inject, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PDLabeledControlComponent, FormHandlerService, DateTimeFieldWidgetInfo } from '@otris/ng-core';
import { ComponentTypeET, IDateTimeFieldComponent, ILocalizationServiceToken, ILocalizationService } from '@otris/ng-core-shared';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
	selector: 'otris-pd-date-time-field',
	template: `
		<otris-pd-labeled-control-frame [labeledControl]="this" (toolBarButtonClick)="onToolBarButtonClick($event)" [pdObject]="pdObject" [relatedFormControl]="this.control">
			<ng-container *ngIf="!isReadonly; else readonly">
					<kendo-datepicker class="datepicker" [format]="'dd. MMM yyyy'" [formControl]="formGroup.controls[propertyName]"
						[placeholder]="placeholder$ | async" [navigation]="showNavigationBar" [readonly]="isReadonly">
						<!-- https://www.telerik.com/forums/datepicker-timepicker-grid---translations-missing -->
						<kendo-datepicker-messages [today]="todayMessage" [toggle]="toggleMessage"></kendo-datepicker-messages>
					</kendo-datepicker>
			</ng-container>
		</otris-pd-labeled-control-frame>

		<ng-template #readonly>
			<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
		</ng-template>
	`,
	styles: [`
		.datepicker {
			flex: 1;
		}
	`]
})
export class PDDateTimeFieldComponent extends PDLabeledControlComponent implements IDateTimeFieldComponent {

	@Input() placeholder: string;

	placeholder$: Observable<string>;

	todayMessage = "Today";
	toggleMessage = "Toggle Calender";

	get dateTimeFieldWidgetInfo(): DateTimeFieldWidgetInfo {
		let wi = this.pdItemSpec.widgetInfo;
		if (wi instanceof DateTimeFieldWidgetInfo) {
			return wi as DateTimeFieldWidgetInfo;
		}
		return undefined;
	}

	get showNavigationBar(): boolean {
		let wi = this.dateTimeFieldWidgetInfo;
		return wi ? wi.showNavigationBar !== false : true;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService,
		@Inject(ILocalizationServiceToken) private localizationService: ILocalizationService) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();
		this.localizationService.changeHandler.pipe(
			tap(() => this.updatePlaceholder()),
			switchMap(() => this.localizationService.getSystemStrings([
				'kendo-ui.components.pd-date-time-field.today-message',
				'kendo-ui.components.pd-date-time-field.toggle-message'
			])),
			tap(trans => {
				this.todayMessage = trans[0],
				this.toggleMessage = trans[1]
			})
		).subscribe();
		this.updatePlaceholder();
	}

	protected onReadonlyChanged(): void {}

	private updatePlaceholder(): void {
		this.placeholder$ = this.placeholder ? of(this.placeholder) :
			this.localizationService.getSystemString('kendo-ui.components.pd-date-time-field.placeholder');
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.DateTime;
	}

	get value(): Date {
		return this.control.value;
	}

	set value(val: Date) {
		this.control.setValue(val);
	}
}
