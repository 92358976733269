import { Component, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import { IToolBarItemResult, ILabeledControlComponent, VisibilityET, LabelPositionET, PDObject } from '@otris/ng-core-shared';
//import { Observable, Subject } from 'rxjs';
import { AbstractControl } from '@angular/forms';

@Component({
	selector: 'otris-pd-labeled-control-frame',
	template: /*html*/`
		<label #label [for]="content" class="label k-form-field" style="display: flex;" (click)="onClick($event)">
		<!-- <label #label class="label k-form-field" style="display: flex;" (click)="onClick($event)"> -->
			<ng-container *ngIf="labeledControl?.labelPosition===labelPositionET.Top">
				<div *ngIf="labeledControl?.labelVisible; else label_else" class="top-label-container" [style.white-space]="wrapLabel ? 'normal' : 'nowrap'">
					<div class="label-text" style="margin: auto 0; overflow-wrap: break-word;"> <!-- word-break: break-all; entfernt -->
						{{labeledControl?.label}}
					</div>
					<otris-pd-short-description [highlighted]="mouseIsOver" *ngIf="labeledControl?.isEnabled && hasShortDescription"
						[pdObject]="pdObject" [pdComponent]="labeledControl">
					</otris-pd-short-description>
					<!--otris-pd-mandatory-indicator *ngIf="mandatoryIndicatorVisible">
					</otris-pd-mandatory-indicator-->
					<otris-pd-error-indicator *ngIf="labeledControl?.isEnabled && hasErrors" [relatedFormControl]="relatedFormControl" [propertyName]="labeledControl.propertyName" [pdObject]="pdObject">
					</otris-pd-error-indicator>
					<otris-tool-bar *ngIf="toolbarVisible" class="toolbar" (buttonClick)="onToolBarButtonClick($event)" [itemSpecs]="labeledControl?.toolbarSpec.items"
						[disabled]="!labeledControl?.isEnabled">
					</otris-tool-bar>
				</div>
				<ng-template #label_else>
					<div *ngIf="labeledControl?.labelVisibility===visibilityET.Hidden" class="top-label-container-empty">
						<otris-pd-short-description [highlighted]="mouseIsOver" *ngIf="labeledControl?.isEnabled && hasShortDescription"
							[pdObject]="pdObject" [pdComponent]="labeledControl">
						</otris-pd-short-description>
						<!--otris-pd-mandatory-indicator *ngIf="mandatoryIndicatorVisible">
						</otris-pd-mandatory-indicator-->
						<otris-pd-error-indicator *ngIf="labeledControl?.isEnabled && hasErrors" [relatedFormControl]="relatedFormControl" [propertyName]="labeledControl.propertyName" [pdObject]="pdObject">
						</otris-pd-error-indicator>
						<otris-tool-bar *ngIf="toolbarVisible" class="toolbar" (buttonClick)="onToolBarButtonClick($event)" [itemSpecs]="labeledControl?.toolbarSpec.items"
							[disabled]="!labeledControl?.isEnabled">
						</otris-tool-bar>
					</div>
				</ng-template>
			</ng-container>

			<div class="content-container">
				<ng-container *ngIf="labeledControl?.labelPosition===labelPositionET.Left">
					<div style="margin: auto 0.5em auto 0" *ngIf="labeledControl?.labelVisible">{{labeledControl?.label}}</div>
					<!--otris-pd-mandatory-indicator *ngIf="mandatoryIndicatorVisible" [margin]="'auto 0 auto 0.5em'">
					</otris-pd-mandatory-indicator-->
				</ng-container>

				<div #content class="ng-content-container">
					<ng-content></ng-content>
				</div>
				
				<ng-container *ngIf="labeledControl?.labelPosition===labelPositionET.Left">
					<otris-pd-short-description [highlighted]="mouseIsOver" style="margin: auto 0;"
						*ngIf="labeledControl?.isEnabled && hasShortDescription" [pdObject]="pdObject" [pdComponent]="labeledControl">
					</otris-pd-short-description>
					<otris-pd-error-indicator *ngIf="labeledControl?.isEnabled && hasErrors" [relatedFormControl]="relatedFormControl" [propertyName]="labeledControl.propertyName" [pdObject]="pdObject">
					</otris-pd-error-indicator>
					<otris-tool-bar *ngIf="toolbarVisible" class="toolbar" (buttonClick)="onToolBarButtonClick($event)" [itemSpecs]="labeledControl?.toolbarSpec.items"
						[disabled]="!labeledControl?.isEnabled">
					</otris-tool-bar>
				</ng-container>

				<ng-container *ngIf="labeledControl?.labelPosition===labelPositionET.Right">
					<div style="margin: auto 0 auto 0.5em;">{{labeledControl?.label}}</div>
					<!--otris-pd-mandatory-indicator *ngIf="mandatoryIndicatorVisible" [margin]="'auto 0 auto 0.5em'">
					</otris-pd-mandatory-indicator-->
					<otris-pd-short-description [highlighted]="mouseIsOver" style="margin: auto 0;"
						*ngIf="labeledControl?.isEnabled && hasShortDescription" [pdObject]="pdObject" [pdComponent]="labeledControl">
					</otris-pd-short-description>
					<otris-pd-error-indicator *ngIf="labeledControl?.isEnabled && hasErrors" [relatedFormControl]="relatedFormControl" [propertyName]="labeledControl.propertyName" [pdObject]="pdObject">
					</otris-pd-error-indicator>
					<otris-tool-bar *ngIf="toolbarVisible" class="toolbar" (buttonClick)="onToolBarButtonClick($event)" [itemSpecs]="labeledControl?.toolbarSpec.items"
						[disabled]="!labeledControl?.isEnabled">
					</otris-tool-bar>
				</ng-container>
			</div>
		</label>
	`,
	styles: [`
		:host {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
		}
		.label {
			flex-direction: column;
			flex: 1 1 auto;
			margin-top: 0;
		}
		.top-label-container {
			display: flex;
			align-items: center;

			margin-bottom: 0.5em;
			/*white-space: nowrap;*/
		}
		.top-label-container-empty {
			display: flex;
			margin-bottom: 0.5em;
			height: 1.5em;
			white-space: nowrap;
		}
		.content-container {
			display: flex;
			flex: 1 1 auto;
			/*align-items: center;  !!!!! macht Probleme */
		}
		.ng-content-container { /* Dazu fällt mir kein vernünftiger Name mehr ein */
			flex: 1; 
			display: flex;
		}
		.toolbar {
			display: flex;
			align-items: center;
			margin-left: auto;
		}
	`]
})
export class PDLabeledControlFrameComponent {

	// notwendig, da sonst z.B. bei einer ObjectReference mit Toolbar beim Clicken auf das Label der 1. Toolbarbutton gedrückt wird
	// todo: ggf. konfigurierbar machen, bei Textfeldern macht das automatische aktivieren des Textfeldes ja Sinn.
	onClick(event: any) {
		this.labelClick.emit();
		/*if (event.currentTarget == this._labelElement.nativeElement) {
			//event.preventDefault();
			return;
		}*/
		//event.stopPropagation();
		/*if (!this.propagateClickEvent) {
			event.preventDefault();
		}*/
	}

	@Input() propagateClickEvent: boolean = false;

	@Input() labeledControl: ILabeledControlComponent;

	@Input() relatedFormControl: AbstractControl;

	@Input() pdObject: PDObject;

	@Output() toolBarButtonClick = new EventEmitter<IToolBarItemResult>();

	@Output() labelClick = new EventEmitter();

	mouseIsOver: boolean = false;

	get hasShortDescription(): boolean {
		return this.labeledControl ? this.labeledControl.hasShortDescription : false;
	}

	get hasErrors(): boolean {
		return this.relatedFormControl /*&& this.relatedFormControl.dirty*/ && !this.relatedFormControl.valid;
	}

	visibilityET = VisibilityET;

	labelPositionET = LabelPositionET;

	@ViewChild('label') _labelElement: ElementRef;

	@HostListener('mouseenter')
	onMouseEnter() {
		this.mouseIsOver = true;
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.mouseIsOver = false;
	}

	get wrapLabel(): boolean {
		if (this.labeledControl && this.labeledControl.wrapLabel === false) {
			return false;
		}
		return true;
		//return this.labeledControl && this.labeledControl.wrapLabel;
	}

	get toolbarVisible(): boolean {
		if (this.labeledControl) {
			return this.labeledControl.toolbarSpec && !this.labeledControl.isReadonly;
		}
		return false;
	}

	get mandatoryIndicatorVisible(): boolean {
		if (this.labeledControl) {
			return this.labeledControl.isRequired && this.labeledControl.isEnabled && !this.labeledControl.isReadonly && !this.labeledControl.getFormControlStatus().valid;
		}
		return false;
	}

	//private _toolbarButtonClickSubject: Subject<IToolBarItemResult> = new Subject();

	//private _toolbarButtonClick: Observable<IToolBarItemResult>;

	/*get toolbarButtonClick(): Observable<IToolBarItemResult> {
		if (!this._toolbarButtonClick) {
			this._toolbarButtonClick = this._toolbarButtonClickSubject.asObservable();
		}
		return this._toolbarButtonClick;
	}*/

	constructor() {
	}

	onToolBarButtonClick(item: IToolBarItemResult) {
		this.toolBarButtonClick.emit(item);
		//this._toolbarButtonClickSubject.next(item);
	}
}
