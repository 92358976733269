import { Observable, of } from 'rxjs';
import {
	IPDObjectMeta, IClassMetaData, ServiceLocator, ShortDescriptionFormatET, TypeET, IStringPropertyMetaData, IMetaDataService
} from '@otris/ng-core-shared';
import { ILocalizationService, ILocalizationServiceToken } from '@otris/ng-core-shared';
import { ValidatorFn } from '@angular/forms';
//import { PDMetaAbstract } from './pd-meta-abstract';

export abstract class CustomPDObjectMeta extends IPDObjectMeta {
	
	protected constructor() {
		super();
		let metaDataService = this.metaDataService;
		let metaData = metaDataService.getMetaData(this.className);
		if (metaData) {
			this.setMetaData(metaData);
		}
		metaDataService.getMetaDataChanged$(this.className).subscribe(data => this.setMetaData(data));
	}

	protected abstract getPropertiesImpl(): string[];

	protected abstract getClassNameImpl(): string | undefined;
	
	get propertyNames(): string[] {
		return this.getPropertiesImpl();
	}

	get className(): string | undefined {
		return this.getClassNameImpl();
	}

	protected get metaDataService():  IMetaDataService {
		return ServiceLocator.injector.get(IMetaDataService);
	}

	isMandatory(prop: string): boolean | undefined {
		let propMeta = this.getPropertyMeta(prop);
		if (propMeta && propMeta.mandatory !== undefined) {
			return propMeta.mandatory;
		}
		let relMeta = this.getRelationMeta(prop);
		if (relMeta && relMeta.mandatory !== undefined) {
			return relMeta.mandatory;
		}
		return undefined;
	}

	getPropertyErgname(prop: string): Observable<string> {
		let localizationService: ILocalizationService = ServiceLocator.injector.get(ILocalizationServiceToken);
		let propMeta = this.getPropertyMeta(prop);
		if (propMeta && propMeta.label) {
			let lang = localizationService.currentLanguage;
			let ergName = propMeta.label.find(n => n[0] == lang.code);
			if (ergName) {
				return of(ergName[1]);
			}
		}
		let declClass = this.getPropertyDeclarationClass(prop);
		return declClass ? localizationService.getPropertyLabel(declClass, prop) : of('n.a.');
	}

	getShortDescription(prop: string): Observable<string> {

		let localizationService: ILocalizationService = ServiceLocator.injector.get(ILocalizationServiceToken);
		let propMeta = this.getPropertyMeta(prop);
		if (propMeta && propMeta.shortDescription) {
			let lang = localizationService.currentLanguage;
			let ergName = propMeta.shortDescription.find(n => n[0] == lang.code);
			if (ergName) {
				return of(ergName[1]);
			}
		}
		let declClass = this.getPropertyDeclarationClass(prop);
		return declClass ? localizationService.getPropertyShortDescription(declClass, prop) : of('n.a.');
	}

	getShortDescriptionFormat(prop: string): Observable<ShortDescriptionFormatET> {
		return this.metaDataService.getDefaultShortDescriptionFormat();
	}

	getStringFromId(id: string): Observable<string> {
		let localizationService: ILocalizationService = ServiceLocator.injector.get(ILocalizationServiceToken);
		let parts = id.split('.');
		if (parts.length == 2) {
			return localizationService.getClassString(parts[0], parts[1]);
		}
		return of('n.a.');
	}

	getMaxStringLength(prop: string): Observable<number | undefined> {
		let propMeta = this.getPropertyMeta(prop);
		if (propMeta && propMeta.type == TypeET.String) {
			let stringPropMeta: IStringPropertyMetaData = propMeta;
			if (stringPropMeta.maxLength !== undefined) {
				return of(stringPropMeta.maxLength);
			}
		}
		return of(undefined);
	}

	getPropertyDeclarationClass(propName: string): string | undefined {
		return undefined;
	}

	protected addValidators(current: ValidatorFn[] | undefined, add: ValidatorFn | ValidatorFn[]): ValidatorFn[] {
		if (!current) {
			current = [];
		}
		if (Array.isArray(add)) {
			current.push(...add);
		}
		else {
			current.push(add);
		}
		return current;
	}
}
