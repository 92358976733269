<kendo-grid #kendoGrid [data]="dataResult" [style.height]="'100%'" scrollable="scrollable" resizable="true" reorderable="true"
	[selectable]="selectionSettings" [loading]="loading"
	[pageable]="pagerSettings" [pageSize]="pageSize" [skip]="skip" (pageChange)="onPageChange($event)"
	[sortable]="sortSettings" [sort]="sortDescriptors" (sortChange)="onSortChange($event)"
	[selectedKeys]="_selection" [kendoGridSelectBy]="selectionKeySelector" (selectionChange)="onSelectionChange($event)">

	<ng-template kendoGridToolbarTemplate [position]="'top'">
		<otris-tool-bar (buttonClick)="onToolBarButtonClick($event)" [showSearchControl]="showSearchControl"
			(searchExpressionChanged)="onSearchExpressionChanged($event)">
		</otris-tool-bar>
	</ng-template>

	<kendo-grid-checkbox-column *ngIf="selectionMode == selectionModeET.Multiple" width="50" resizable="false">
		<ng-template kendoGridCellTemplate let-idx="rowIndex">
			<input [kendoGridSelectionCheckbox]="idx" />
		</ng-template>
	</kendo-grid-checkbox-column>

	<kendo-grid-column *ngFor="let col of columnInfos" minResizableWidth="50"
		field="{{col.field}}"
		title="{{col.header}}"
		width="{{col.width}}"
		filter="{{col.type | typeET_to_kendoGridFilter}}">
		<ng-template kendoGridCellTemplate let-obj>
			<ng-container [ngSwitch]="col.type">
				<ng-container *ngSwitchCase="typeET.Boolean">
					<div *ngIf="obj[col.field]; else elseBoolean">
						<i class="fa fa-lg fa-check"></i>
					</div>
					<ng-template #elseBoolean>
						<i class="fa fa-lg fa-times"></i>
					</ng-template>
				</ng-container>
				<ng-container *ngSwitchCase="typeET.Date">
					{{obj[col.field] | date:'dd. MMM yyyy'}}
				</ng-container>
				<ng-container *ngSwitchCase="typeET.Time">
					{{obj[col.field] | date:'HH:mm:ss'}}
				</ng-container>
				<ng-container *ngSwitchCase="typeET.DateTime">
					{{obj[col.field] | date:'dd. MMM yyyy HH:mm:ss'}}
				</ng-container>
				<ng-container *ngSwitchCase="typeET.Integer">
					{{obj.getPropertyValueFromPath(col.field) | number:'1.0-0'}}
				</ng-container>
				<ng-container *ngSwitchCase="typeET.Double">
					{{obj.getPropertyValueFromPath(col.field) | number:'1.2-2'}}
				</ng-container>
				<ng-container *ngSwitchDefault>
					{{obj[col.field]}}
				</ng-container>
			</ng-container>
		</ng-template>
	</kendo-grid-column>
</kendo-grid>



<!-- ALT
<otris-splitter class="root-splitter">
	<ng-template otrisSplitterPane>
		<kendo-grid #kendoGrid [data]="dataResult" [style.height]="'100%'" scrollable="scrollable" resizable="true" reorderable="true"
			[selectable]="selectionSettings" [loading]="loading"
			[pageable]="pagerSettings" [pageSize]="pageSize" [skip]="skip" (pageChange)="onPageChange($event)"
			[sortable]="sortSettings" [sort]="sortDescriptors" (sortChange)="onSortChange($event)"
			[selectedKeys]="_selection" [kendoGridSelectBy]="selectionKeySelector" (selectionChange)="onSelectionChange($event)">

			<ng-template kendoGridToolbarTemplate [position]="'top'">
				<otris-tool-bar (buttonClick)="onToolBarButtonClick($event)" [showSearchControl]="showSearchControl"
					(searchExpressionChanged)="onSearchExpressionChanged($event)">
				</otris-tool-bar>
			</ng-template>

			<kendo-grid-checkbox-column *ngIf="selectionMode == selectionModeET.Multiple" width="50" resizable="false">
				<ng-template kendoGridCellTemplate let-idx="rowIndex">
					<input [kendoGridSelectionCheckbox]="idx" />
				</ng-template>
			</kendo-grid-checkbox-column>

			<kendo-grid-column *ngFor="let col of columnInfos" minResizableWidth="50"
				field="{{col.field}}"
				title="{{col.header}}"
				width="{{col.width}}"
				filter="{{col.type | typeET_to_kendoGridFilter}}">
				<ng-template kendoGridCellTemplate let-obj>
					<ng-container [ngSwitch]="col.type">
						<ng-container *ngSwitchCase="typeET.Boolean">
							<div *ngIf="obj[col.field]; else elseBoolean">
								<i class="fa fa-lg fa-check"></i>
							</div>
							<ng-template #elseBoolean>
								<i class="fa fa-lg fa-times"></i>
							</ng-template>
						</ng-container>
						<ng-container *ngSwitchCase="typeET.Date">
							{{obj[col.field] | date:'dd. MMM yyyy'}}
						</ng-container>
						<ng-container *ngSwitchCase="typeET.Time">
							{{obj[col.field] | date:'HH:mm:ss'}}
						</ng-container>
						<ng-container *ngSwitchCase="typeET.DateTime">
							{{obj[col.field] | date:'dd. MMM yyyy HH:mm:ss'}}
						</ng-container>
						<ng-container *ngSwitchCase="typeET.Integer">
							{{obj.getPropertyValueFromPath(col.field) | number:'1.0-0'}}
						</ng-container>
						<ng-container *ngSwitchCase="typeET.Double">
							{{obj.getPropertyValueFromPath(col.field) | number:'1.2-2'}}
						</ng-container>
						<ng-container *ngSwitchDefault>
							{{obj[col.field]}}
						</ng-container>
					</ng-container>
				</ng-template>
			</kendo-grid-column>
		</kendo-grid>
	</ng-template>
	<ng-container *ngIf="listType==listeTypeET.List">
		<ng-template otrisSplitterPane [size]="previewPanelSize" [maxSize]="previewPanelMaxSize" [resizable]="previewPaneResizable">
			<router-outlet></router-outlet>
		</ng-template>
	</ng-container>
</otris-splitter>
-->