import { Component, OnInit, Input, HostBinding, Directive } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ICssStyle, PDObject, IFormStatus } from '@otris/ng-core-shared';
import { UIAbstractComponent } from '../ui-abstract/ui-abstract.component';
import { PDItemSpec, UIItemSpec, GridLayoutSpec, FlexLayoutSpec, UIContainerSpec } from '../../model/pd-layout';
import { WidgetET, FlexDirectionET } from '../../model/i-pd-layout';
import { FormHandlerService } from '../../services/form-handler.service';

/*@Component({
	selector: 'otris-pd-container',
	template: ``,
	styles: []
})*/
@Directive()
export abstract class PDContainerComponent extends UIAbstractComponent {

	// todo: wieder rausgenommen, Simon prüfen 
	//@HostBinding('style.flex') styleFlex = '1 1 auto';
	//@HostBinding('style.align-self') styleAlignSelf = 'stretch';

	@HostBinding('style.background-color') styleBackgroundColor: string;
	@HostBinding('style.border-radius') styleBorderRadius: string;

	@Input()
	set pdObject(val: PDObject) {
		if (val && val != this._pdObject) {
			this._pdObject = val;
			this.onPDObjectChanged();
		}
	}

	get pdObject(): PDObject {
		return this._pdObject;
	}

	private _pdObject: PDObject;

	widgetET = WidgetET;

	private _itemSpecs: any[];

	protected abstract get childComponents(): UIAbstractComponent[];

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService) {
		super(router, route, formHandler);
	}

	getFormControlStatus(): IFormStatus {
		let stat = <IFormStatus>{ pristine: true, touched: false, valid: true };
		for (let child of this.childComponents) {
			let childStat = child.getFormControlStatus();
			if (!childStat.pristine) {
				stat.pristine = false;
			}
			if (childStat.touched) {
				stat.touched = true;
			}
			if (!childStat.valid) {
				stat.valid = false;
			}
		}
		return stat;
	}

	private checkPDClassContext(spec: UIItemSpec): boolean {
		/*if (!spec.pdClassContext || spec.pdClassContext.length == 0) {
			return true;
		}

		for (let clName of spec.pdClassContext) {
			if (this.pdMeta.isSuperClass(clName, this.pdObject.className, true)) {
				return true;
			}
		}
		return false;*/
		return true;
	}

	/*private checkEditContext(spec: UIItemSpec): boolean {

		if ((!spec.editContext || spec.editContext.length == 0) && (!spec.invalidEditContext || spec.invalidEditContext.length == 0)) {
			return true;
		}

		let currentEditContext: IEditContextData;
		let currentRoute = this.route.snapshot;
		let resolveData = currentRoute.data['resolveData'] as IPDObjectResolverData<T>;
		while (resolveData && resolveData.editContextInfo) {
			if (resolveData.editContextInfo) {
				currentEditContext = resolveData.editContextInfo;
				break;
			}
			currentRoute = currentRoute.parent;
			resolveData = currentRoute ? currentRoute.data['resolveData'] as IPDObjectResolverData<T> : undefined;
		}

		if (currentEditContext) {
			if (spec.invalidEditContext) {
				let pos = spec.invalidEditContext.indexOf(currentEditContext.id);
				if (pos >= 0) {
					return false;
				}
			}
			return spec.editContext ? (spec.editContext.indexOf(currentEditContext.id) >= 0) : true;
		}

		return false;
	}*/

	get itemSpecs(): { spec: UIItemSpec, pdObject: PDObject, fg: FormGroup }[] {
		/*let specs = [];
		this.containerSpec.items.forEach(i => {
			if (this.checkPDClassContext(i)) { // this.checkEditContext(i)
				specs.push({ spec: i, pdObject: this.pdObject, fg: this.formGroup });
			}
		});
		return specs;*/
		if (!this._itemSpecs) {
			this._itemSpecs = [];
			this.containerSpec.items.forEach(i => {
				if (this.checkPDClassContext(i)) { // this.checkEditContext(i)
					this._itemSpecs.push({ spec: i, pdObject: this.pdObject, fg: this.formGroup });
				}
			});
		}
		return this._itemSpecs;
	}

	protected get containerSpec(): UIContainerSpec {
		return <UIContainerSpec>this.uiItemSpec;
	}

	/*protected get isPDContainer(): boolean {
		return true;
	}*/

	protected onPDObjectChanged(): void {
		for (let spec of this.itemSpecs) {
			spec.pdObject = this.pdObject;
		}
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.containerSpec.backgroundColor) {
			this.styleBackgroundColor = this.containerSpec.backgroundColor;
		}
		if (this.containerSpec.borderRadius) {
			this.styleBorderRadius = this.containerSpec.borderRadius;
		}

		// todo: pruefen, ob man das Stretchen des Containers nicht besser loesen kann
		// bis dahin wird erstmal als Default flex maeßig gestretched, falls der Parent-Container ein Flex-Container ist
		if (!this.flexStyle && this.uiItemSpec.parentContainer && this.uiItemSpec.parentContainer.layout instanceof FlexLayoutSpec) {
			this.flexStyle = '1 1';
		}

		/* todo: Simon prüfen
			if (this.containerSpec.layout instanceof FlexLayoutSpec) {
			let flexLayout = <FlexLayoutSpec>this.containerSpec.layout;
			if (flexLayout.alignSelf) {
				this.styleAlignSelf = flexLayout.alignSelf;
			}
			if (flexLayout.flex) {
				this.styleFlex = flexLayout.flex;
			}
		}*/
	}

	get containerStyle() {
		let style = <ICssStyle>new Object();
		// style.height = '100%'; 100p fix

		// wieder rausgenommen!!!
		//style.flex = '1 1 auto'; // todo: evtl. in die abgeleiteten Klassen verschieben

		// todo: Simon prüfen
		style.flex = '1 1 auto' // 100p fix: Sollte eher eine Ausnahme sein, dass es nicht passt

		/*if (this.containerSpec.backgroundColor) {
			style['background-color'] = this.containerSpec.backgroundColor;
		}*/

		// todo: was ist das Default-Layout für einen Container?
		if (this.containerSpec.layout instanceof GridLayoutSpec) {
			let gridLayout = <GridLayoutSpec>this.containerSpec.layout;
			style.display = 'grid';
			if (gridLayout.columns) {
				let colSpecs = "";
				gridLayout.columns.forEach(c => {
					if (colSpecs.length > 0) {
						colSpecs += " ";
					}
					colSpecs += c.width;
				});
				style['grid-template-columns'] = colSpecs;
			}
			if (gridLayout.rows) {
				let rowSpecs = "";
				gridLayout.rows.forEach(r => {
					if (rowSpecs.length > 0) {
						rowSpecs += " ";
					}
					rowSpecs += r.height;
				});
				style['grid-template-rows'] = rowSpecs;
			}
		}
		else if (this.containerSpec.layout instanceof FlexLayoutSpec) {
			let flexLayout = <FlexLayoutSpec>this.containerSpec.layout;
			style.display = 'flex';
			if (flexLayout.direction) {
				switch (flexLayout.direction) {
					case +FlexDirectionET.Column:
						style['flex-direction'] = 'column';
						//style['flex'] = '1 1 auto';
						break;

					case +FlexDirectionET.Row:
						style['flex-direction'] = 'row';
						break;
				}
			}
			if (flexLayout.wrap) {
				style['flex-wrap'] = flexLayout.wrap;
			}
			if (flexLayout.justifyContent) {
				style['justify-content'] = flexLayout.justifyContent;
			}
			if (flexLayout.alignContent) {
				style['align-content'] = flexLayout.alignContent;
			}
			if (flexLayout.alignItems) {
				style['align-items'] = flexLayout.alignItems;
			}
		}
		return style;
	}
}
