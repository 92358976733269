
import { PDObject, IPDObjectRaw } from '@otris/ng-core-shared';

export class JafWebPDObject extends PDObject {

	get pdObjectRaw(): IPDObjectRaw {
		return this._pdObjectRaw;
	}

	constructor(private _pdObjectRaw: IPDObjectRaw) {
		super(_pdObjectRaw.oid);
	}
}
