import { Input, Component, ViewChild, TemplateRef } from '@angular/core';

export enum AppBarItemTypeET {
	Section,
	Spacer,
	Separator
}

@Component({
	selector: 'otris-app-bar-item',
	template: `
		<ng-container [ngSwitch]="itemType">
			<ng-template #content *ngSwitchCase="appBarItemTypeET.Section">
				<ng-content></ng-content>
			</ng-template>
		</ng-container>
	`,
	styles: []
})
export class AppBarItemComponent {

	@ViewChild('content') content: TemplateRef<any>;

	@Input() itemType: AppBarItemTypeET;

	appBarItemTypeET = AppBarItemTypeET;

	constructor() { }
}
