import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { IUIDrawerItem, ILocalizationService, ILocalizationServiceToken } from '@otris/ng-core-shared';
import { Subscription } from 'rxjs';

@Component({
	selector: 'otris-pd-drawer-item',
	template: `
		<li class="item-root otris-pd-drawer-item"
			[ngClass]="{ 'otris-state-selected': drawerItem.selected, 'otris-pd-drawer-item-disabled': disabled }" 
			(click)="onClick()">
			<div *ngIf="mini || expanded" style="padding: 0.5em;">
				<span [ngClass]="drawerItem.icon + ' item-icon'"></span>
			</div>
			<div *ngIf="expanded" [@expandCollapse] class="item-text">
				<span>{{text}}</span>
			</div>
		</li>
	`,
	styles: [`
		.item-root {
			display: flex;
			align-items: center;
			padding: 0.5em;
			user-select: none;
		}
		.item-icon {
		}
		.item-text {
			margin: 0 0.5em;
			white-space: nowrap;
			overflow-x: hidden;
		}
	`],
	animations: [
		trigger(
		  'expandCollapse', 
			[
				transition(':enter', 
					[
					  style({ width: 0 }),
					  animate('0.25s', style({ width: '*' }))
					]
				 ),
				 transition(':leave', 
					[
					  style({ width: '*' }),
					  animate('0.25s', style({ width: 0 }))
					]
				 )
		  	]
		)
	 ]
})
export class PDDrawerItemComponent implements OnInit, OnDestroy {
	@Input() drawerItem: IUIDrawerItem;

	@Input() mini = false;

	@Input() expanded = false;

	@Input() disabled = false;

	@Output() itemSelected = new EventEmitter<IUIDrawerItem>(); 

	text: string;

	private _subscription: Subscription;

	constructor(private cdref: ChangeDetectorRef, @Inject(ILocalizationServiceToken) private localizationService: ILocalizationService) {
		this._subscription = localizationService.changeHandler.subscribe(
			() => {
				this.updateText();
			}
		);
	}

	ngOnInit(): void {
		if (this.drawerItem?.text) {
			this.text = this.drawerItem.text;
		}
		this.updateText();
	}

	ngOnDestroy(): void {
		if (this._subscription) {
			this._subscription.unsubscribe();
		}
	}

	onClick(): void {
		if (!this.disabled && !this.drawerItem.selected) {
			//this.drawerItem.selected = true;
			this.itemSelected.emit(this.drawerItem);
		}
	}

	private updateText(): void {
		if (this.drawerItem?.textId) {
			this.localizationService.getString(this.drawerItem.textId).subscribe(s => {
				this.text = s;
				this.cdref.detectChanges();
			});
		}
	}
}
