import { Injectable, Inject, Optional, SkipSelf, OnDestroy } from '@angular/core';
import { IPDObjectEditContext, IPDObjectEditContextToken, IPDObjectEditContextManager } from './i-pd-object-edit-context';
import { IPDObjectEditContextManagerToken } from './pd-object-edit-context-manager.service';
import { CustomPDObject, TypeET } from '@otris/ng-core-shared';
import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';


@Injectable()
export class PDObjectEditContext implements IPDObjectEditContext, OnDestroy {
	private _contextObject: CustomPDObject;

	get contextObject(): CustomPDObject {
		return this._contextObject;
	}

	/*set contextObject(obj: CustomPDObject) {
		this._contextObject = obj;
	}*/

	//private _parentObject: PDObject;

	get parentObject(): CustomPDObject | undefined {
		return this.parentEditContext ? this.parentEditContext.contextObject : undefined;
	}

	/*set parentObject(obj: PDObject) {
		this._parentObject = obj;
	}*/

	private _isNewObject: boolean = false;

	get isNewObject(): boolean {
		return this._isNewObject;
	}

	private _roleName: string;

	private _parentId: string;

	private _relationObjectCreatedSubject$: Subject<[string, CustomPDObject, string]> = new Subject();

	//private _relationObjectCreated$: Observable<CustomPDObject>;

	constructor(@Inject(IPDObjectEditContextManagerToken) private editContextManager: IPDObjectEditContextManager,
		@Inject(IPDObjectEditContextToken) @Optional() @SkipSelf() private parentEditContext: IPDObjectEditContext) {

		//console.log(`parentEditContext: ${parentEditContext}`)
		editContextManager.switchContext(this);

		// todo: switch back!!!
	}

	ngOnDestroy() {
		// wird nicht aufgerufen!
		console.log('PDObjectEditContext.ngOnDestroy()');
		//this.editContextManager.releaseCurrentContext();

	}

	setContextObject(obj: CustomPDObject, isNew?: boolean, roleName?: string, parentId?: string): void {
		this._contextObject = obj;
		this._isNewObject = isNew === true;
		this._roleName = roleName;
		this._parentId = parentId;
	}

	getRelationObject(role: string): CustomPDObject | undefined {
		/*if (this._contextObject.metaData.getPropertyType(role) === TypeET.RelationTo1Editable) {
			return this._contextObject.pdObjectRaw[role];

		}*/
		if (this._contextObject.metaData.getPropertyType(role) === TypeET.RelationTo1 &&
			this._contextObject.pdObjectRaw[role] instanceof CustomPDObject) {
			return this._contextObject.pdObjectRaw[role];
		}
		return undefined;
	}

	release(): void {
		this.editContextManager.releaseCurrentContext();
	}

	notifyContextObjectSaved(): void {
		if (this.isNewObject && this.parentEditContext) {
			this.parentEditContext.notifyRelationObjectCreated(this._contextObject, this._roleName, this._parentId);
		}
	}

	notifyRelationObjectCreated(obj: CustomPDObject, roleName: string, parentId: string): void {
		this._relationObjectCreatedSubject$.next([roleName, obj, parentId]);
	}

	getRelationObjectCreated(role: string): Observable<[string, CustomPDObject, string]> {
		return this._relationObjectCreatedSubject$.asObservable().pipe(filter(res => res[0] == role));//.map(res => res[1]);
	}
}
