import { InjectionToken, Injectable, Inject, inject } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import {
	PDObject, CustomPDObject, IPDObjectMeta, TypeET, IPDAccessService, IPDAccessServiceToken, IPDClass, IPDClassToken
} from '@otris/ng-core-shared';
import { map, tap, switchMap } from 'rxjs/operators';

export const IPDObjectFactoryServiceToken = new InjectionToken<IPDObjectFactoryService>(
	'IPDObjectFactoryServiceToken',
	{
		providedIn: 'root',
		factory: () => new PDObjectFactoryService(inject(IPDAccessServiceToken), inject(IPDClassToken))
	}
);

@Injectable()
export abstract class IPDObjectFactoryService {
	//createPDObject<T extends CustomPDObject>(objCreator: new (oid: string) => T, jsonObj: string): Observable<T>;
	abstract createPDObject<T extends CustomPDObject>(jsonObj: string | any): Observable<T>;
	abstract createPDObjects<T extends CustomPDObject>(objs: any[]): Observable<T[]>;
}

@Injectable()
class PDObjectFactoryService implements IPDObjectFactoryService {

	constructor(@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService,
		@Inject(IPDClassToken) private pdClass: IPDClass) {
	}

	createPDObjects<T extends CustomPDObject>(objs: any[]): Observable<T[]> {
		let calls = objs.map(o => this.createPDObject(o));
		return forkJoin(calls).pipe(map(res => res as T[]));
	}

	// todo:
	// Beziehungen zu eigenen Klassen
	createPDObject<T extends CustomPDObject>(jsonObj: string | any): Observable<T> {
		let obj = typeof(jsonObj) === 'string' ? JSON.parse(jsonObj, this.jsonReviver) : jsonObj;
		/*if (!obj.hasOwnProperty('_objectId')) {
			throw new Error('_objectId missing in json-object.');
		}*/
		if (!obj.hasOwnProperty('_className')) {
			throw new Error('_className missing in json-object.');
		}

		let editableRelObjs$: Observable<void>[] = [];
		let className = obj['_className'];
		let isNew = obj['_isNew'] !== false;
		let newObj: T = <T>this.pdClass.createInstance(className, obj['_objectId'], isNew);
		let metaData: IPDObjectMeta = newObj.metaData;
		let relObjs: [string, string][] = [];
		for (let prop of metaData.propertyNames.filter(p => obj.hasOwnProperty('_' + p))) {
			let propName = '_' + prop;
			let val = obj[propName];
			switch (metaData.getPropertyType(prop)) {
				case TypeET.RelationTo1:
					if (val) {
						if (val.hasOwnProperty('_className')) {
							editableRelObjs$.push(
								this.createPDObject(val).pipe(
									tap(relObj => newObj[propName] = relObj),
									map(() => { })
								)
							);
						}
						else {
							relObjs.push([prop, val]);
						}
					}
					break;
				case TypeET.RelationToN:
					if (Array.isArray(val)) {
						newObj[propName] = [];
						val.forEach(v => {
							if (v.hasOwnProperty('_className')) {
								editableRelObjs$.push(
									this.createPDObject(v).pipe(
										tap(relObj => {
											newObj[propName].push(relObj)
										}),
										map(() => { })
									)
								);
							}
							else {
								relObjs.push([prop, v]);
							}
						});
						/*for (let obj of val) {
							relObjs.push([prop, obj]);
						}*/
					}
					break;
				case TypeET.RelationToNEditable:
					if (Array.isArray(val)) {
						newObj[propName] = [];
						for (let obj of val) {
							editableRelObjs$.push(
								this.createPDObject(obj).pipe(
									tap(relObj => {
										newObj[propName].push(relObj)
									}),
									map(() => { })
								)
							);
							//relObjs.push([prop, obj]);
						}
					}
					break;
				case TypeET.Date:
				case TypeET.Time:
				case TypeET.DateTime:
					newObj[propName] = val ? new Date(val) : undefined;
					break;
				case TypeET.Enum:
					newObj[propName] = this.pdClass.createEnumInstance(className, prop, val);
					break;
				/*case TypeET.Double:
				case TypeET.Integer:
					newObj[propName] = val ? +val : undefined;
					break;*/
				default:
					newObj[propName] = val;
					break;
			}
		}

		let newObj$: Observable<T>;
		if (relObjs.length > 0) {
			newObj$ = this.pdAccessService.getObjects(relObjs.map(obj => obj[1])).pipe(
				switchMap(
					objs => {
						if (objs.length == relObjs.length) {
							objs.forEach((o, i) => {
								if (o) {
									let propName = '_' + relObjs[i][0];
									switch (newObj.metaData.getPropertyType(relObjs[i][0])) {
										case TypeET.RelationTo1:
											newObj[propName] = o;
											break;
										case TypeET.RelationToN:
											{
												if (!newObj[propName]) {
													newObj[propName] = [];
												}
												newObj[propName].push(o);
											}
											break;
									}
								}

							})
						}
						return of(newObj);
					})
			)
		}
		else {
			newObj$ = of(newObj);
		}

		if (editableRelObjs$.length > 0) {
			return forkJoin(editableRelObjs$).pipe(switchMap(() => newObj$));
		}
		return newObj$

		//return editableRelObjs$.length > 0 ? Observable.concat(editableRelObjs$).switchMap(() => newObj$) : newObj$;
	}

	// todo: brauchen wir das?
	private jsonReviver(key: string, value: any): any {
		return value;
	}
}

