import { IEnumerationItem } from './types';
import { Observable } from 'rxjs';
import { IMetaDataService } from '../services/meta-data.service';
import { ServiceLocator } from '../services/service-locator';
export { IEnumerationItem } 

export abstract class AbstractEnumeration {
	abstract get choice(): IEnumerationItem[];
	abstract get valueRaw(): number | number[];
	abstract get value(): IEnumerationItem | IEnumerationItem[];
	abstract set value(val: IEnumerationItem | IEnumerationItem[]);
	abstract getItem(val: number): IEnumerationItem;
	abstract updateItemErgNames(): Observable<void>;
}
