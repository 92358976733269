import {
	Component, OnInit, AfterViewInit, AfterContentInit, OnDestroy, Input, Inject,
	ElementRef, ViewChild, ContentChild, ChangeDetectorRef
} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
	SelectableSettings, SelectionEvent, GridDataResult, PageChangeEvent,
	GridComponent, DetailTemplateDirective, RowArgs, SortSettings, PagerSettings
} from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Subscription, Observable } from "rxjs";
import { filter } from "rxjs/operators";
import {
	PDObject, IPDObjectRaw, SelectionModeET, TypeET, IPDAccessService, IPDAccessServiceToken, IToolBarItemResult,
	IErrorHandler, IErrorHandlerToken, IPDListColumnInfo, IError
} from '@otris/ng-core-shared';
import { IPDRootColumnProviderToken, IPDColumnProviderService } from '@otris/ng-core';

/*enum ToolBarButtonIdET {
	New = 'idNew',
	Edit = 'idEdit',
	Delete = 'idDelete',
	Refresh = "idRefresh"
}*/

export enum ListTypeET {
	List,
	SelectionList
}

export enum ListDataSourceET {
	Query,
	ObjectArray
}

/*enum ItemViewTypeET {
	Embedded,
	Popup
}*/

/*export interface INewActionSpec {
	outlet: string,
	url: string
}*/

@Component({
	selector: 'otris-pd-list',
	templateUrl: './pd-list.component.html',
	styles: [`
		:host {
			/* display: grid; IE11 fix
			grid-template-rows: minmax(1px, 1fr); */
			display: flex;
			flex-direction: column;
			flex: 1 1 auto; /* multiselect fix */
		}

		/*.root-splitter {
			display: flex;
		}*/
		/*.pd-list-container {
			height: 100%;
			display: grid;
			grid-template-columns: minmax(100px, 1fr) max-content;
			grid-template-rows: minmax(100px, 1fr);
		}
		.list-area {
			grid-column: 1;
		}
		.details-area {
			grid-column: 2;
		}*/
	`],
	providers: [
	]
})
export class PDListComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {

	@Input() className: string;

	@Input() columnInfos: IPDListColumnInfo[];

	@Input() listType: ListTypeET = ListTypeET.List;

	@Input() showSearchControl: boolean = true;

	@Input() selectionMode: SelectionModeET = SelectionModeET.Single;

	@Input() staticFilterExpr: string;

	@Input() previewPanelDefaultSize: string = '35%';

	@Input() objects$: Observable<PDObject[]>;

	private _objects: PDObject[];

	private _filteredObjects: PDObject[];

	private selectedDataMap: Map<string, PDObject> = new Map<string, PDObject>();

	get sortExpr(): string {
		return this._sortExpr;
	}

	@Input()
	set sortExpr(val: string) {
		this._sortExpr = val;
		this.sortDescriptors = [];
		if (!val) {
			return;
		}
		for (let expr of val.split(',')) {
			if (expr.length == 0)
				continue;
			let firstChar = expr.charAt(0);
			let descriptor = <SortDescriptor>{};
			descriptor.dir = firstChar == '-' ? 'desc' : 'asc';
			descriptor.field = firstChar == '-' || firstChar == '+' ? expr.substr(1) : expr;
			this.sortDescriptors.push(descriptor);
		}
	}

	private _sortExpr: string;

	@ViewChild('kendoGrid') gridViewChild: GridComponent;

	@ContentChild(DetailTemplateDirective) detailTemplateDirective: DetailTemplateDirective;

	listeTypeET = ListTypeET;

	get selectedObject(): PDObject | undefined {
		return this.selectionMode == SelectionModeET.Single && this._selection.length == 1 ? this.data.get(this._selection[0]) : undefined;
	}

	get selectedObjects(): PDObject[] {
		return this.selectionMode == SelectionModeET.Multiple ? this._selection.map(oid => this.data.get(oid)) : [];
	}

	get selection(): PDObject | PDObject[] | undefined {
		switch (this.selectionMode) {
			case SelectionModeET.Single:
				// {
				// 	return this._selection.length == 1 ? this.data.get(this._selection[0]) : undefined;
				// }
				return this.selectedDataMap.size == 1 ? this.selectedDataMap.get(this._selection[0]) : undefined;
			case SelectionModeET.Multiple:
				// return this._selection.map(oid => this.data.get(oid))
				return Array.from(this.selectedDataMap.values());
		}
	}

	//_selection: PDObjectWrapper[] = []; // todo: wieder private?
	_selection: string[] = []; // todo: IPDObject oder so wg. OID
	
	private data: Map<string, PDObject> = new Map<string, PDObject>();

	get selectionSettings(): SelectableSettings {
		return <SelectableSettings>{
			checkboxOnly: this.selectionMode == SelectionModeET.Multiple,
			mode: this.selectionMode == SelectionModeET.Single ? 'single' : 'multiple'
		};
	}

	selectionKeySelector(context: RowArgs): any {
		return context.dataItem.oid;
	}

	private readonly itemViewTypeParam = "itemViewType";

	private readonly listIdParam = "PDListComponent-Id";

	private static listCounter: number = 0;

	private readonly listId: number = ++PDListComponent.listCounter;

	dataResult: GridDataResult;

	loading: boolean = false;

	pageSize: number = 100;

	private pageIndex: number = 0;

	skip: number = 0;

	sortDescriptors: SortDescriptor[] = [];

	private queryFilterExpr: string;

	private fullTextSearchExpr: string;

	private subscription: Subscription;

	typeET = TypeET;

	selectionModeET = SelectionModeET;

	private rootUrl: string;

	previewPanelSize: string = "0";

	previewPanelMaxSize: string = "0%";

	previewPaneResizable: boolean = false;

	//private hasPreviewObject: boolean = false;

	listDataSourceET = ListDataSourceET;

	dataSource: ListDataSourceET = ListDataSourceET.Query;

		// todo: konfigurierbar machen
	get sortSettings(): SortSettings | boolean {
		switch (this.dataSource) {
			case ListDataSourceET.Query:
				return <SortSettings>{ allowUnsort: true, mode: 'multiple', showIndexes: true };
			default:
				return <SortSettings>{ allowUnsort: true, mode: 'single' };
		}
		/*switch (this.dataSource) {
			case ListDataSourceET.Query:
				return <SortSettings>{ allowUnsort: true, mode: 'multiple', showIndexes: true };
			default:
				return false;
		}*/
	}

	// todo: konfigurierbar machen
	get pagerSettings(): PagerSettings | boolean {
		return <PagerSettings>{ buttonCount: 5 };
		/*switch (this.dataSource) {
			case ListDataSourceET.Query:
				return <PagerSettings>{ buttonCount: 5 };
			default:
				return false;
		}*/
	}

	constructor(private router: Router, private route: ActivatedRoute,
		@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService,
		//@Inject(IPDListColumnProviderToken) private columnInfoProvider: IPDListColumnProvider,
		@Inject(IErrorHandlerToken) private errorHandler: IErrorHandler,
		@Inject(IPDRootColumnProviderToken) private columnProvider: IPDColumnProviderService,
		private cdRef: ChangeDetectorRef) {

		/*let rootTree = this.router.parseUrl(route.snapshot.pathFromRoot
			.map(v => v.url.map(segment => segment.toString()).join('/'))
			.join('/'));*/
		//this.rootUrl = router.routerState.snapshot.url;
		this.subscription = router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((event: NavigationEnd) => {
				let pos1 = event.url.indexOf('/view/');
				let oid;
				if (pos1 > 0) {
					oid = event.url.substr(pos1 + 6);
					/*let pos2 = event.url.indexOf('/', pos1 + 6);
					if (pos2 > 0) {
						oid = event.url.substring(pos1 + 6, pos2);
					}*/
				}

				if (oid) { // todo: ggf. pathFromRoot mit einbeziehen!
					//this.hasPreviewObject = true;
					this.previewPanelMaxSize = "80%"; // todo
					this.previewPanelSize = this.previewPanelDefaultSize;
					this.previewPaneResizable = true;
					this._selection = [oid];
				}
				else {
					//this.hasPreviewObject = false;
					this.previewPaneResizable = false;
					this.previewPanelSize = "0";
					this._selection = [];
				}
				//
				// Preview Panel ggf. schliesen
				//
				//let rootTree = this.router.parseUrl(this.rootUrl);
				/*let currentTree = this.router.parseUrl(event.url);
				let rootUrlSegments = UrlTreeAnalyzer.determineUrlSegments(rootTree);
				let currentUrlSegments = UrlTreeAnalyzer.determineUrlSegments(currentTree);

				if (rootUrlSegments.length == currentUrlSegments.length) {
					// Test
					//this.previewPaneResizable = false;
					//this.previewPanelSize = "0";
				}*/

				/*let newUrl = event.url;
				// URL anpassen wegen Klammer-Bug bei angular auxiliary routes
				if (!newUrl.includes('//') && newUrl.includes('(')) {
					newUrl = newUrl.replace('(', '');
					newUrl = newUrl.replace(')', '');
				}
				let rootUrlAdapted = this.rootUrl;
				if (!rootUrlAdapted.includes('//') && rootUrlAdapted.includes('(')) {
					rootUrlAdapted = rootUrlAdapted.replace('(', '');
					rootUrlAdapted = rootUrlAdapted.replace(')', '');
				}
				if (newUrl == rootUrlAdapted) {
					this.previewPaneResizable = false;
					this.previewPanelSize = "0";
				}*/
			});

		/*this.subscription.add(
			this.childComponentCloser.getCloseHandler(this.listIdParam, this.listId.toString()).subscribe(
				params => {
					switch (+params.params[this.itemViewTypeParam]) {
						case ItemViewTypeET.Popup:
							{
								let outlets = new Object();
								outlets[this.newActionSpec.outlet] = null;
								if (params.result && params.result.success && params.result.isNew) {
									let msg = this.hasPreviewObject ?
										'Soll der Datensatz zur weiteren Bearbeitung angezeigt und der aktuell bearbeitete Datensatz geschlossen werden?' :
										'Soll der Datensatz zur weiteren Bearbeitung angezeigt werden?';
									this.interactionService.showConfirmMessage("Datensatz bearbeiten", msg)
										.subscribe(res => {
											if (res === DialogResultET.Ok) {
												this.pdAccessService.getObject<T>(params.result.realObjectId).subscribe(res => this.edit(res));
											}
										});
								}
								router.navigate([{ outlets: outlets }], { relativeTo: this.route.parent });
								return;
							}
						case ItemViewTypeET.Embedded:
							this.hasPreviewObject = false;
							this.router.navigate(["."], { relativeTo: params.childRoute.parent.parent });
							return;
					}
				}
			)
		);*/
	}

	ngOnInit() {
		if (this.staticFilterExpr) {
			this.queryFilterExpr = this.staticFilterExpr;
		}
		if (!this.columnInfos) {
			this.columnInfos = this.columnProvider.getColumns(this.className);
		}
		this.dataSource = this.objects$ ? ListDataSourceET.ObjectArray : ListDataSourceET.Query;
		//this.showSearchControl = this.dataSource === ListDataSourceET.Query;
		this.loadData(true);

		// Test
		/*this.route.url.subscribe(url => {
			console.log(url);
		});*/

		/*let newButton: IToolBarButtonSpec;
		if (this.listType == ListTypeET.List) {
			if (this.newActionSpec) {
				let subClasses = this.pdMeta.getSubClasses(this.objCreator, true, false);
				if (subClasses.length == 1) {
					newButton = <IToolBarButtonSpec>{ id: ToolBarButtonIdET.New, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-file-o' };
				}
				else if (subClasses.length > 1) {
					//  id: ToolBarButtonIdET.New,
					let dropDownItems: IToolBarDropDownButtonItem[] = [];
					for (let subClass of subClasses) {
						dropDownItems.push(<IToolBarDropDownButtonItem>{
							id: ToolBarButtonIdET.New, text: this.pdMeta.getClassErgName(subClass), tag: subClass
						});
					}
					newButton = <IToolBarDropDownButtonSpec>{ type: ToolBarItemTypeET.DropDownButton, iconClass: 'fa fa-lg fa-file-o', items: dropDownItems };
				}
			}

			this.toolbarItems = [
				<IToolBarButtonSpec>{ id: ToolBarButtonIdET.Edit, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-edit', enabledWhen: EnabledWhenET.SingleItemSelected },
				<IToolBarButtonSpec>{ id: ToolBarButtonIdET.Delete, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-remove', enabledWhen: EnabledWhenET.ItemsSelected },
				<IToolBarButtonSpec>{ id: ToolBarButtonIdET.Refresh, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-refresh' }
			];
			if (newButton) {
				this.toolbarItems.unshift(newButton);
			}
		}*/
	}

	ngAfterViewInit() {
		if (this.detailTemplateDirective) {
			// todo: muss anders gelöst werden!
			//this.gridViewChild.detailTemplate = this.detailTemplateDirective;
			this.cdRef.detectChanges();
		}
	}

	ngAfterContentInit() {
	}

	ngOnDestroy() {
		console.log("PDListComponent.ngOnDestroy()");
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	private loadData(reset: boolean): void {
		if (reset) {
			this.pageIndex = 0;
			this.skip = 0;
		}
		switch (this.dataSource) {
			case ListDataSourceET.Query:
				this.loadDataByQuery(reset);
				break;

			case ListDataSourceET.ObjectArray:
				{
					if (this.objects$) {
						let selectPage: () => void =
							() => {
								let start = this.pageIndex * this.pageSize;
								let end = start + this.pageSize;
								let objs: PDObject[] = [];
								if (start < this._filteredObjects.length) {
									if (end > this._filteredObjects.length) {
										end = this._filteredObjects.length;
									}
									objs = this._filteredObjects.slice(start, end);
								}
								this.data = objs.reduce(
									(map, obj) => {
										map.set(obj.objectId.oid, obj);
										return map;
									}, new Map<string, PDObject>());
								this.dataResult = { data: objs.map(d => d.pdObjectRaw), total: this._filteredObjects.length };
							};

						if (!this._objects) {
							this.loading = true;
							this.objects$.subscribe(
								res => {
									this._objects = res;
									this.updateFilteredObjects();
									this.sortFilteredObjects();
									selectPage();
								},
								() => { this.loading = false; },
								() => { this.loading = false; }
							);
						}
						else {
							selectPage();
						}
					}
					break;
				}
		}
	}

	private updateFilteredObjects(): void {
		if (this.dataSource !== ListDataSourceET.ObjectArray) {
			return;
		}

		if (this.fullTextSearchExpr && this.columnInfos && this.columnInfos.length > 0) {
			let stringColInfos = this.columnInfos.filter(ci => ci.type == TypeET.String);
			let searchExpr = this.fullTextSearchExpr.toLowerCase();
			this._filteredObjects = this._objects.filter(obj => {
				for (let colInfo of stringColInfos) {
					if ((obj.pdObjectRaw[colInfo.field] as string).toLowerCase().includes(searchExpr))	{
						return true;
					}
				}
				return false;
			});
		}
		else {
			this._filteredObjects = [...this._objects];
		}
		//this.sortFilteredObjects();
	}

	private sortFilteredObjects(): void {
		if (this.dataSource !== ListDataSourceET.ObjectArray) {
			return;
		}
		if (this.sortDescriptors) {
			let sortDescs = this.sortDescriptors.filter(s => s.dir);
			if (sortDescs.length > 0) {
				let sortDesc = sortDescs[0];
				this._filteredObjects.sort((obj1, obj2) => {
					if (obj1.pdObjectRaw[sortDesc.field] === obj2.pdObjectRaw[sortDesc.field]) {
						return 0;
					}
					if (sortDesc.dir === 'asc') {
						return obj1.pdObjectRaw[sortDesc.field] < obj2.pdObjectRaw[sortDesc.field] ? -1 : 1;
					}
					else {
						return obj1.pdObjectRaw[sortDesc.field] < obj2.pdObjectRaw[sortDesc.field] ? 1 : -1;
					}
				});
				return;
			}
		}
		this.updateFilteredObjects();
	}

	private loadDataByQuery(reset: boolean): void {
		this.loading = true;
		const sub = this.pdAccessService.getExtentByPage(this.className, this.pageIndex, this.pageSize, false, this.queryFilterExpr,
			this.sortExpr, this.columnInfos.map(ci => ci.field))
			.subscribe(
				res => {
					this.dataResult = { data: res.data.map(d => d.pdObjectRaw), total: res.itemCount };
					this.data = res.data.reduce((map, obj) => {
						map.set(obj.objectId.oid, obj);
						return map;
					}, new Map<string, PDObject>());
					this.loading = false;
				},
				err => {
					this.errorHandler.handleError(<IError>{ details: `Error in PDListComponent.loadDataByQuery(). Details: ${err}` } );
					this.loading = false;
				}
			);
	}

	/*private updateFilterExpr() {
		let newFilter: string = undefined;
		if (expr) {
			expr = expr.trim();
			if (expr.length == 0) {
				newFilter = undefined;
			}
			else if (this.columnInfos && this.columnInfos.length > 0) {
				newFilter = "";
				for (let colInfo of this.columnInfos.filter(ci => ci.type == TypeET.String)) {
					if (newFilter.length > 0) {
						newFilter += " || ";
					}
					//let attrName = this.pdMeta.getAttributeNameFromTSProperty(colInfo.field);
					let attrName = colInfo.field;
					newFilter += attrName + "=" + "'*" + expr + "*'";
				}
			}
		}

		if (newFilter != this.filterExpr) {
			console.log(`new filter: ${newFilter}`);
			this.filterExpr = newFilter;
			this.loadData(true);
		}
	}*/

	onPageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.pageIndex = event.skip / this.pageSize;
		this.loadData(false);
	}

	onSortChange(sort: SortDescriptor[]): void {
		this.sortDescriptors = sort;
		if (this.dataSource == ListDataSourceET.ObjectArray) {
			this.sortFilteredObjects();
			this.loadData(true);
			return;
		}

		this._sortExpr = "";
		if (sort) {
			for (let expr of sort.filter(s => s.dir)) {
				if (this._sortExpr.length > 0) {
					this._sortExpr += ',';
				}
				this._sortExpr += expr.dir == 'asc' ? '+' : '-';
				//this.sortExpr += this.pdMeta.getAttributeNameFromTSProperty(expr.field);
				this._sortExpr += expr.field;
			}
		}
		this.loadData(true);
	}

	onSearchExpressionChanged(expr: string) {
		if (expr) {
			expr = expr.trim();
		}
		this.fullTextSearchExpr = expr ? expr : undefined;

		if (this.dataSource == ListDataSourceET.ObjectArray) {
			this.updateFilteredObjects();
			this.sortFilteredObjects();
			this.loadData(true);
			return;
		}

		let newFilter: string = undefined;
		if (expr) {
			if (expr.length == 0) {
				newFilter = undefined;
			}
			else if (this.columnInfos && this.columnInfos.length > 0) {
				newFilter = "";
				for (let colInfo of this.columnInfos.filter(ci => ci.type == TypeET.String)) {
					if (newFilter.length > 0) {
						newFilter += " || ";
					}
					//let attrName = this.pdMeta.getAttributeNameFromTSProperty(colInfo.field);
					let attrName = colInfo.field;
					newFilter += attrName + "=" + "'*" + expr + "*'";
				}
			}
		}

		if (this.staticFilterExpr) {
			newFilter = newFilter ? '(' + this.staticFilterExpr + ') && (' + newFilter + ')' : this.staticFilterExpr;
		}

		if (newFilter != this.queryFilterExpr) {
			this.queryFilterExpr = newFilter;
			this.loadData(true);
		}
	}

	private showDetails(objectId: string | undefined) {
		/*this.hasPreviewObject = !!objectId;
		this.previewPanelMaxSize = "80%";
		this.previewPanelSize = objectId ? this.previewPanelDefaultSize : '0';
		this.previewPaneResizable = !!objectId;*/

		/*let queryParams: Params = {};
		queryParams[this.itemViewTypeParam] = ItemViewTypeET.Embedded;
		queryParams[this.listIdParam] = this.listId.toString();
		if (this.objCreator.name != this._baseObjCreator.name) {
			queryParams[PDClassNameQueryParamName] = this.objCreator.name;
		}*/
		let path = objectId ? ['view', objectId] : ['.'];
		this.router.navigate(path, { relativeTo: this.route });
	}

	/*private edit(obj: T) {
		this.hasPreviewObject = true;
		this.previewPanelMaxSize = "80%";
		this.previewPanelSize = "50%";
		this.previewPaneResizable = true;
		let queryParams: Params = {};
		queryParams[this.itemViewTypeParam] = ItemViewTypeET.Embedded;
		queryParams[this.listIdParam] = this.listId.toString();
		this.router.navigate(["edit", obj.id], { relativeTo: this.route, queryParams: queryParams });
	}*/

	/*private createNew(className: string | undefined) {
		let outlets = new Object();
		outlets[this.newActionSpec.outlet] = [this.newActionSpec.url];

		let queryParams: Params = {};
		queryParams[this.itemViewTypeParam] = ItemViewTypeET.Popup;
		queryParams[this.listIdParam] = this.listId.toString();
		if (className) {
			queryParams[GlobalQueryParamsET.PDObjectResolver_ClassName] = className;
		}
		this.router.navigate([{ outlets: outlets }], { relativeTo: this.route.parent, queryParams: queryParams });
	}*/

	private refresh() {
		this.loadData(false);
	}

	onToolBarButtonClick(item: IToolBarItemResult) {
		console.log(`PDListComponent.onButtonClick(${item.id})`);
		/*switch (item.id) {
			case ToolBarButtonIdET.Edit:
				if (this.selectedObject) {
					this.edit(this.selectedObject);
				}
				break;
			case ToolBarButtonIdET.New:
				this.createNew(item.tag);
				break;
			case ToolBarButtonIdET.Refresh:
				this.refresh();
				break;
		}*/
	}

	onSelectionChange(e: SelectionEvent) {
		if (this.listType == ListTypeET.SelectionList) {

			if (e.selectedRows.length >= 0) { 
				e.selectedRows.forEach(selection => {
					this.selectedDataMap.set(selection.dataItem.oid, this.data.get(selection.dataItem.oid));
				});
			}
			if (e.deselectedRows.length >= 0) {
				e.deselectedRows.forEach(selection => {
					this.selectedDataMap.delete(selection.dataItem.oid);
				})
			}
		}

		if (this.listType != ListTypeET.List) {
			return;
		}
		if (e.selectedRows.length == 1) {
			this.showDetails((<IPDObjectRaw>e.selectedRows[0].dataItem).oid);
		}
		else {
			this.showDetails(undefined);
		}

		//console.log(e);
		//this.selectionService.setSelection(this.selection);
	}
}
