import { PDObject } from './pd-object';

export interface ICssStyle {
	[key: string]: any
}

export interface IEnumerationItem {
	enumConst: number;
	ergName: string;
}

export interface IRelationContext {
	source: PDObject;
	path: string;
	isMultiple?: boolean;
	index?: string;
	//metaData?: IRelationMetaData;
}

export interface IPDListColumnInfo {
	field: string;
	header: string;
	width?: number;
	type?: TypeET;
}

export interface IPDDocument {
	fileName: string;
	data: string;
	size: number;
}

export enum TypeET {
	String,
	StringArray,
	Boolean,
	Integer,
	Double,
	Date,
	Time,
	DateTime,
	Structure,
	Enum,
	Document,
	DocumentArray,
	RelationTo1,
	RelationToN,
	//RelationTo1Editable,
	RelationToNEditable // ist das nicht schon mittlerweile mit RelationToN abgedeckt
}

export enum ComponentTypeET {
	TextField,
	ObjectReference,
	DateTime,
	ComboBox,
	DropDownList,
	Boolean,
	GroupBox,
	Panel,
	TabView,
	MultiSelect,
	RelationTab,
	RadioButton,
	RadioButtonGroup,
	FileUpload,
	Drawer,
	NumericText,
	Undefined
}

export enum SelectionModeET {
	Single,
	Multiple
}

export enum VisibilityET {
	Visible,
	Hidden,
	Collapsed
}

export enum LabelPositionET {
	Top,
	Left,
	Right
}

export interface IFormStatus {
	valid: boolean;
	pristine: boolean;
	touched: boolean;
};

export enum ShortDescriptionFormatET {
	Text,
	Html
}

export enum LanguageCodeET {
	de = 'de',
	en = 'en'
}

/*export interface IErgName {
	names: [LanguageCodeET, string][];
}*/

export type ErgName = [LanguageCodeET, string][];