import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { ILocalizationService, ILocalizationServiceToken } from '@otris/ng-core-shared';
import { PDObject } from '@otris/ng-core-shared';
import { Observable, forkJoin } from 'rxjs';
import  { map, switchMap } from 'rxjs/operators';

// fa-exclamation
@Component({
	selector: 'otris-pd-error-indicator',
	template: `
		<div class="image error-indicator " kendoTooltip filter="span">
			<span class="fa fa-fw fa-exclamation-triangle" title="{{errorMessage | async}}"></span>
		</div>
	`,
	styles: [`
		.image {
			cursor: help;
		}
		.error-indicator {
			margin-left: 0.5em;
			color: red;
		}
	`]
})
export class PDErrorIndicatorComponent implements OnInit {

	//@Input() margin: string;

	@Input() relatedFormControl: AbstractControl;

	@Input() pdObject: PDObject;

	@Input() propertyName: string;

	get errorMessage(): Observable<string> {
		let ctrl = this.relatedFormControl;
		if (ctrl && ctrl.errors) {
			let errMsgs: Observable<string>[] = [];
			if (ctrl.errors.required) {
				let msgKey = ctrl instanceof FormArray ? 'kendo-ui.components.pd-error-indicator.required-relation' :
					'kendo-ui.components.pd-error-indicator.required';
				let requiredErrMsg$ = this.localizationService.getSystemString(msgKey);
				errMsgs.push(requiredErrMsg$);
			}
			if (ctrl.errors.maxlength) {
				let maxLengthErrMsg$ = this.pdObject.metaData.getMaxStringLength(this.propertyName).pipe(
					switchMap(length => {
						return this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.max-length', { value: length });
					})
				);
				errMsgs.push(maxLengthErrMsg$);
			}
			if (ctrl.errors.validateEmail === true) {
				let validateEmailErrMsg$ = this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.validate-email');
				errMsgs.push(validateEmailErrMsg$);
			}
			/* funktioniert nicht, wird über errors.required abgehandelt
			if (ctrl.errors.requiredTrue === true) {
				let errMsg$ = this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.required-true');
				errMsgs.push(errMsg$);
			}*/
			if (errMsgs.length > 0) {
				return forkJoin(errMsgs).pipe(
					map(res => {
						let msg = '';
						res.forEach(s => {
							if (msg.length > 0) {
								msg += '; '
							}
							msg += s;
						});
						return msg;
					})
				);
			}
			
			/*if (ctrl.errors.required) {
				return this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.required');
			}
			if (ctrl.errors.maxlength) {
				return this.pdObject.metaData.getMaxStringLength(this.propertyName).pipe(
					switchMap(length => {
						return this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.max-length', { value: length });
					})
				);
			}*/
		}
		return this.localizationService.getSystemString('kendo-ui.components.pd-error-indicator.unknown');
	}

	constructor(@Inject(ILocalizationServiceToken) private localizationService: ILocalizationService) { }

	ngOnInit() {
		/*this.localizationService.changeHandler.pipe(
			switchMap(r => this.localizationService.getSystemString('kendo-ui.components.mandatory-indicator.title').map(trans => this.title = trans))
		).subscribe();*/
	}

}
