import { Component, OnInit, OnDestroy, AfterViewInit, AfterContentInit, Input, HostBinding, Host, Directive, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { UIItemSpec, FlexItemPlacement, GridItemPlacement, WidgetInfo, UIContainerSpec } from '../../model/pd-layout';
import { IComponent, ComponentTypeET, ICssStyle, IRelationContext, ServiceLocator, IFormStatus } from '@otris/ng-core-shared';
import { FormHandlerService } from '../../services/form-handler.service';
import { ILocalizationService, ILocalizationServiceToken } from '@otris/ng-core-shared';
import { IEventProcessingContext } from '../../model/i-event-processing-context';
import { GlobalTemplateProviderService } from '../../services/global-template-provider.service';

export const PD_COMPONENT_READONLY_TEMPLATE_ID = 'PDComponentReadonlyTemplateId';
@Directive()
export abstract class UIAbstractComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy, IComponent {
	
	@Input() formGroup: FormGroup;

	@Input() uiItemSpec: UIItemSpec;

	@Input() relationContext: IRelationContext;

	@Input() visible = true;

	@HostBinding('id') cssId: string;

	@HostBinding('style.display') get styleDisplay(): string | undefined {
		return !this.visible ? 'none' : undefined;
	}

	@HostBinding('style.margin-right') styleMarginRight: string;

	@HostBinding('style.margin-bottom') styleMarginBottom: string;

	@HostBinding('style.margin-left') styleMarginLeft: string;
	
	@HostBinding('style.margin-top') styleMarginTop: string;

	@HostBinding('style.padding-right') stylePaddingRight: string;

	@HostBinding('style.padding-bottom') stylePaddingBottom: string;

	@HostBinding('style.padding-left') stylePaddingLeft: string;

	@HostBinding('style.padding-top') stylePaddingTop: string;

	@HostBinding('style.flex') flexStyle: string;

	@HostBinding('style.align-self') alignSelfStyle: string;

	@HostBinding('style.width') widthStyle: string;

	@HostBinding('style.min-width') minWidthStyle: string;

	@HostBinding('style.max-width') maxWidthStyle: string;

	@HostBinding('style.height') heightStyle: string;

	@HostBinding('style.grid-column-start') gridColumnStartStyle: string;

	@HostBinding('style.grid-column-end') gridColumnEndStyle: string;

	private _subscriptions: Subscription;

	get customData(): any {
		return this.uiItemSpec.customData;
	}

	private static componentCounter: number = 0;

	protected readonly componentNumber: number = ++UIAbstractComponent.componentCounter;
	
	private _readonlyTemplate: TemplateRef<any>;

	get readonlyTemplate(): TemplateRef<any> | undefined {
		return this._readonlyTemplate;
	}

	get readonlyTemplateContext(): { comp: UIAbstractComponent } {
		return { comp: this };
	}

	constructor(protected router: Router, protected route: ActivatedRoute, protected formHandler: FormHandlerService) {
		let templateProviderService = ServiceLocator.injector.get(GlobalTemplateProviderService);
		let directive = templateProviderService.getTemplate(PD_COMPONENT_READONLY_TEMPLATE_ID);
		if (directive) {
			this._readonlyTemplate = directive.template;
		}
	}

	ngOnInit() {
		if (this.uiItemSpec.parentContainer && (!this.isPDContainer() || !this.uiItemSpec.parentContainer.disableChildItemMarginsInContainer)) {
			let childMargin = this.uiItemSpec.parentContainer.childItemMargin;
			if (childMargin) {
				this.styleMargin = childMargin;
			}
			let childMarginTop = this.uiItemSpec.parentContainer.childItemMarginTop;
			if (childMarginTop) {
				this.styleMarginTop = childMarginTop;
			}
			let childMarginRight = this.uiItemSpec.parentContainer.childItemMarginRight;
			if (childMarginRight) {
				this.styleMarginRight = childMarginRight;
			}
			let childMarginBottom = this.uiItemSpec.parentContainer.childItemMarginBottom;
			if (childMarginBottom) {
				this.styleMarginBottom = childMarginBottom;
			}
			let childMarginLeft = this.uiItemSpec.parentContainer.childItemMarginLeft;
			if (childMarginLeft) {
				this.styleMarginLeft = childMarginLeft;
			}
		}

		if (this.uiItemSpec.width) {
			this.widthStyle = this.uiItemSpec.width;
		}
		if (this.uiItemSpec.height) {
			this.heightStyle = this.uiItemSpec.height;
		}
		if (this.uiItemSpec.minWidth) {
			this.minWidthStyle = this.uiItemSpec.minWidth;
		}
		if (this.uiItemSpec.maxWidth) {
			this.maxWidthStyle = this.uiItemSpec.maxWidth;
		}
		if (this.uiItemSpec.margin) {
			this.styleMargin = this.uiItemSpec.margin;
		}
		if (this.uiItemSpec.marginLeft) {
			this.styleMarginLeft = this.uiItemSpec.marginLeft;
		}
		if (this.uiItemSpec.marginTop) {
			this.styleMarginTop = this.uiItemSpec.marginTop;
		}
		if (this.uiItemSpec.marginRight) {
			this.styleMarginRight = this.uiItemSpec.marginRight;
		}
		if (this.uiItemSpec.marginBottom) {
			this.styleMarginBottom = this.uiItemSpec.marginBottom;
		}

		if (this.uiItemSpec.padding) {
			this.stylePadding = this.uiItemSpec.padding;
		}
		if (this.uiItemSpec.paddingLeft) {
			this.stylePaddingLeft = this.uiItemSpec.paddingLeft;
		}
		if (this.uiItemSpec.paddingTop) {
			this.stylePaddingTop = this.uiItemSpec.paddingTop;
		}
		if (this.uiItemSpec.paddingRight) {
			this.stylePaddingRight = this.uiItemSpec.paddingRight;
		}
		if (this.uiItemSpec.paddingBottom) {
			this.stylePaddingBottom = this.uiItemSpec.paddingBottom;
		}

		if (this.uiItemSpec.placement instanceof FlexItemPlacement) {
			let placement = <FlexItemPlacement>this.uiItemSpec.placement;
			if (placement.flex) {
				this.flexStyle = placement.flex;
			}
			if (placement.align) {
				this.alignSelfStyle = placement.align;
			}
		}
		else if (this.uiItemSpec.placement instanceof GridItemPlacement) {
			let placement = <GridItemPlacement>this.uiItemSpec.placement;
			if (placement.col) {
				this.gridColumnStartStyle = placement.col.toString();
			}
			if (placement.colspan) {
				this.gridColumnEndStyle = "span " + placement.colspan.toString();
			}
			// ...
		}
		if (this.uiItemSpec.mandatoryCustomActivated) {
			this._mandatoryCustomActivated = true;
		}
		if (!this.uiItemSpec.visible) {
			this.visible = false;
		}

		if (this.hasId()) {
			this.formHandler.registerComponent(this);
			this.cssId = this.getId();
		}

		let localizationService: ILocalizationService = ServiceLocator.injector.get(ILocalizationServiceToken);
		this._subscriptions = localizationService.changeHandler.subscribe(
			lang => {
				this.onLanguageChanged();
			}
		);
	}

	ngAfterViewInit() {
		if (this.uiItemSpec.disabled) {
			this.disable(true);
		}
	}

	ngAfterContentInit() {
	}

	ngOnDestroy() {
		console.debug(`Component '${this.id}' destroyed.`);
		if (this._subscriptions) {
			this._subscriptions.unsubscribe();
		}
		this.formHandler.unregisterComponent(this);
	}

	abstract getFormControlStatus(): IFormStatus;

	protected getIdFromPropertyName(prop: string): string | undefined {
		if (!prop) {
			return undefined;
		}
		if (this.relationContext) {
			let ctrlId = this.relationContext.path;
			return this.relationContext.isMultiple ?
				ctrlId + '.' + this.relationContext.index + '.' + prop :
				ctrlId + '.' + prop;
		}
		return prop;
	}

	getId(): string | undefined {
		if (!this.hasId) {
			return undefined;
		}
		if (this.relationContext) {
			let ctrlId = this.relationContext.path;
			return this.relationContext.isMultiple ?
				ctrlId + '.' + this.relationContext.index + '.' + this.uiItemSpec.id :
				ctrlId + '.' + this.uiItemSpec.id;
		}
		return this.uiItemSpec.id;
	}

	get hasErrors(): boolean {
		let stat = this.getFormControlStatus();
		return !stat.valid;
	}

	protected hasId(): boolean {
		return this.uiItemSpec && !!this.uiItemSpec.id;
	}

	protected onLanguageChanged() {}

	protected onMandatoryCustomChanged() {}

	protected convertLengthString(value: string): [string, string, string, string] {
		if (value) {
			let tempArray = value.split(' ');
			switch (tempArray.length) {
				case 1: {
					return [tempArray[0], tempArray[0], tempArray[0], tempArray[0]];
				}
				case 2: {
					return [tempArray[0], tempArray[1], tempArray[0], tempArray[1]];
				}
				case 3: {
					return [tempArray[0], tempArray[1], tempArray[2], tempArray[1]];
				}
				case 4: {
					return [tempArray[0], tempArray[1], tempArray[2], tempArray[3]];
				}
			}
		}
		return [undefined, undefined, undefined, undefined];
	}

	protected updateValidators(ctrl?: AbstractControl): void { }


	protected get widgetInfo(): WidgetInfo {
		return this.uiItemSpec.widgetInfo
	}

	protected isPDContainer(): boolean {
		return this.uiItemSpec instanceof UIContainerSpec;
	}

	protected createEventProcessingContext(): IEventProcessingContext {
		return {
			formHandler: this.formHandler
		};
	}

	/*get hasId(): boolean {
		return this.id && this.id.length > 0;
	}*/

	set styleMargin(value: string) {
		let tuple = this.convertLengthString(value);
		this.styleMarginTop = tuple[0];
		this.styleMarginRight = tuple[1];
		this.styleMarginBottom = tuple[2];
		this.styleMarginLeft = tuple[3];
	}

	set stylePadding(value: string) {
		let tuple = this.convertLengthString(value);
		this.stylePaddingTop = tuple[0];
		this.stylePaddingRight = tuple[1];
		this.stylePaddingBottom = tuple[2];
		this.stylePaddingLeft = tuple[3];
	}
	

	//
	// IComponent
	//

	get id(): string | undefined {
		return this.getId();
		/*if (this.relationContext) {
			let ctrlId = this.relationContext.path;
			return this.relationContext.isMultiple ?
				this.relationContext.path + '.' + this.relationContext.index + '.' + this.uiItemSpec.id :
				this.relationContext.path + '.' + this.uiItemSpec.id;
		}
		return this.uiItemSpec.id;*/
	}


	get isRequired(): boolean {
		return this._isMandatoryModel || this._mandatoryCustom;
	}

	get mandatoryCustomActivated(): boolean {
		return this._mandatoryCustomActivated;
	}

	set mandatoryCustomActivated(val: boolean) {
		this._mandatoryCustomActivated = val;
	}

	protected _mandatoryCustomActivated: boolean = false;

	get mandatoryCustom(): boolean {
		return this._mandatoryCustom;
	}

	set mandatoryCustom(val: boolean) {
		this.setMandatoryCustom(val);
	}

	protected setMandatoryCustom(val: boolean): void {
		if (this._mandatoryCustom != val) {
			this._mandatoryCustom = val;
			this.updateValidators();
			this.onMandatoryCustomChanged();
		}
	}

	protected _mandatoryCustom: boolean = false;

	protected _isMandatoryModel: boolean = false;

	abstract get componentType(): ComponentTypeET;

	abstract get isEnabled(): boolean; 

	abstract get isDisabled(): boolean;

	abstract disable(flag: boolean): void;

	abstract reset(): void;
}
