import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidatorFn, Validators} from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, delay, tap } from 'rxjs/operators';
import { ComponentTypeET, IComboBoxComponent, PDObject, IPDAccessService, IPDAccessServiceToken } from '@otris/ng-core-shared';
import { PDItemSpec, ComboBoxWidgetInfo, PDLabeledControlComponent, FormHandlerService } from '@otris/ng-core';

interface IChoiceData {
	ergName: string;
	valueAsString: string;
	value: PDObject;
}

// as choiceData
@Component({
	selector: 'otris-pd-combo-box',
	template: `
		<ng-container *ngIf="active else inactive">
			<otris-pd-labeled-control-frame [labeledControl]="this" (toolBarButtonClick)="onToolBarButtonClick($event)"
				[relatedFormControl]="this.control" [pdObject]="pdObject">
				<div class="input-container">						
					<kendo-combobox class="combo-box" [data]="choiceData" [loading]="isLoading"
						[textField]="'ergName'" [valueField]="'valueAsString'" [valuePrimitive]="false" [allowCustom]="true"
						[value]="currentValue" (valueChange)="onValueChange($event)" (selectionChange)="onSelectionChange($event)"
						[valueNormalizer]="valueConverter">
				</kendo-combobox>
				</div>
			</otris-pd-labeled-control-frame>
		</ng-container>
		<ng-template #inactive>
			<otris-pd-labeled-control-frame [labeledControl]="this">
				<kendo-dropdownlist class="drop-down-list" [disabled]="true">
				</kendo-dropdownlist>
			</otris-pd-labeled-control-frame>
		</ng-template>
	`,
	styles: [`
		.combo-box {
			/*width: 100%;*/
			flex: 1;
		}
		.drop-down-list {
			/*width: 100%;*/
			/*margin-top: 0.5em;*/
			flex: 1;
		}
		.errorImage {
			margin: auto 0 auto 3px;
			color: red;
		}
		.input-container {
			/*margin-top: 0.5em;*/
			display: flex;
			flex: 1;
		}
	`]
})
export class PDComboBoxComponent extends PDLabeledControlComponent implements IComboBoxComponent {

	choiceData: IChoiceData[];

	isLoading = false;

	private _className: string;

	private _displayTemplate: string;

	private _valueTemplate: string;

	private get comboBoxWidgetInfo() {
		return this.pdItemSpec.widgetInfo instanceof ComboBoxWidgetInfo ?
			<ComboBoxWidgetInfo>this.pdItemSpec.widgetInfo : undefined;
	}

	private _currentValue: IChoiceData;

	get currentValue(): IChoiceData {
		return this._currentValue;
	}

	valueConverter = (text: Observable<string>) => text.pipe(
		map(txt => {
			return <IChoiceData>{ ergName: txt, valueAsString: txt }
		})
	);

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService,
		@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService, private cdRef: ChangeDetectorRef) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();
		this._valueChangedSubject$ = new BehaviorSubject(this._currentValue ? this._currentValue.valueAsString : undefined);
		if (this.comboBoxWidgetInfo) {
			this._displayTemplate = this.comboBoxWidgetInfo.displayTemplate;
			this._valueTemplate = this.comboBoxWidgetInfo.valueTemplate;
			this._className = this.comboBoxWidgetInfo.className;
			this.active = this.comboBoxWidgetInfo.active;
		}

		this.updateChoice();
	}

	onValueChange(val: IChoiceData) {
		this.setCurrentValue(val);
	}

	onSelectionChange(sel: IChoiceData) {
		console.log(sel);
	}

	/**
	 * Merkt den currentValue und speichert den Wert (valueAsString) im zugehörigen FormControl
	 * @param val 
	 */
	private setCurrentValue(val: IChoiceData): void {
		if (val !== this._currentValue) {
			this._currentValue = val;
			let ctrl = this.control;
			let stringVal = val ? val.valueAsString : undefined;
			if (ctrl) {
				ctrl.setValue(stringVal);
				ctrl.markAsDirty();
			}
			if (this._valueChangedSubject$) {
				this._valueChangedSubject$.next(stringVal);
			}
		}
	}

	/**
	 * Sucht in choiceData einen Eintrag bei dem valuesAsString dem aktuellen value des FormControls entspricht und speichert diesen
	 * in _currentValue. Falls kein Eintrag gefunden wurde, wird ein neues IChoiceData erzeugt und in _currentValue gespeichert
	 */
	private updateCurrentValue(): void {
		let ctrl = this.control;
		if (!ctrl) {
			return;		
		}

		let newVal: IChoiceData;
		if (ctrl.value && this.choiceData) {
			newVal = this.choiceData.find(c => c.valueAsString === ctrl.value);
			if (!newVal) {
				newVal = <IChoiceData>{ ergName: ctrl.value, valueAsString: ctrl.value }
			}
		}
		this._currentValue = newVal;
		this.cdRef.detectChanges();
	}

	protected onControlValueChanges(val: any) {
		super.onControlValueChanges(val);
		this.updateCurrentValue();
	}

	protected onPDObjectChanged() {
		super.onPDObjectChanged();
	}

	protected getCustomValidators(): Observable<ValidatorFn[] | undefined> {
		return this.pdObject.metaData.getMaxStringLength(this.propertyName).pipe(
			map(res => {
				if (Number.isInteger(res)) {
					return [Validators.maxLength(res)];
				}
				return undefined;
			})
		);
	}

	private get choiceFilterExpr(): string | undefined {
		if (this._customChoiceFilterProvider) {
			return this._customChoiceFilterProvider();
		}
		if (this.comboBoxWidgetInfo) {
			return this.comboBoxWidgetInfo.choiceFilterExpr;
		}
		return undefined;
	}

	private get choiceSortExpr(): string | undefined {
		if (this.comboBoxWidgetInfo) {
			return this.comboBoxWidgetInfo.choiceSortExpr;
		}
		return undefined;
	}

	updateChoice() {
		if (this.active) {
			this.isLoading = true;
			let data$: Observable<PDObject[]>;
			if (this._customChoiceProvider) {
				data$ = this._customChoiceProvider();
			}
			else if (this._className) {
				data$ = this.pdAccessService.getExtent(this._className, this.choiceFilterExpr, this.choiceSortExpr);
			}
			if (data$) {
				data$.pipe(
					map(objs => {
						this.choiceData = [];
						if (objs) {
							for (let obj of objs) {
								let info = obj.getStatus(this._displayTemplate);
								let value = obj.getStatus(this._valueTemplate ? this._valueTemplate : this._displayTemplate);
								this.choiceData.push(<IChoiceData>{ ergName: info, valueAsString: value, value: obj });
							}
						}
						this.cdRef.detectChanges();
						this.updateCurrentValue();
					}),
					//delay(5000), // TEST
					tap(() => this.isLoading = false)
				).subscribe();
				return;
			}
		}
		this.choiceData = [];
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.ComboBox;
	}

	//
	// IValueComponent
	//

	get value(): any {
		return this._currentValue ? this._currentValue.valueAsString : undefined;
	}

	set value(val: any) {
		if (!val || typeof(val) !== 'string') {
			this.setCurrentValue(undefined);
			return;
		}

		let newVal = this.choiceData ? this.choiceData.find(c => c.valueAsString === val) : undefined;
		this.setCurrentValue(newVal ? newVal : <IChoiceData>{ ergName: val, valueAsString: val });
	}

	private _valueChangedSubject$: BehaviorSubject<string | undefined>;

	private _valueChanged$: Observable<string | undefined>;

	get valueChanged(): Observable<any> {
		if (!this._valueChanged$) {
			this._valueChanged$ = this._valueChangedSubject$.asObservable();
		}
		return this._valueChanged$;
	}

	//
	// Interface IComboxBoxComponent
	//

	get active(): boolean {
		return this._active;
	}

	set active(val: boolean) {
		if (val !== this._active) {
			this._active = val;
			this.disable(!val);
			this.updateChoice();
		}
	}

	private _active: boolean = true;

	set customChoiceProvider(cb: () => Observable<PDObject[]>) {
		this._customChoiceProvider = cb;
		this.updateChoice();
	}

	private _customChoiceProvider: () => Observable<PDObject[]>;

	set customChoiceFilterProvider(cb: () => string) {
		this._customChoiceFilterProvider = cb;
		this.updateChoice();
	}

	private _customChoiceFilterProvider: () => string;
}
