import { Component, OnInit, Input, ViewChildren, QueryList, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PDContainerComponent, UIContentComponent, FormHandlerService, UIAbstractComponent, FlexLayoutSpec, UIPanelSpec } from '@otris/ng-core';
import { ComponentTypeET } from '@otris/ng-core-shared';
import { PDTextFieldComponent } from '../pd-text-field/pd-text-field.component';
import { PDNumericTextFieldComponent } from '../pd-numeric-text-field/pd-numeric-text-field.component';
import { PDCheckboxComponent } from '../pd-checkbox/pd-checkbox.component';
import { PDRadioButtonComponent } from '../pd-radio-button/pd-radio-button.component';
import { PDInputSwitchComponent } from '../pd-input-switch/pd-input-switch.component';
import { PDDateTimeFieldComponent } from '../pd-date-time-field/pd-date-time-field.component';
import { PDDropDownComponent } from '../pd-drop-down/pd-drop-down.component';
import { PDMultiSelectComponent } from '../pd-multi-select/pd-multi-select.component';
import { PDAutoCompleteComponent } from '../pd-auto-complete/pd-auto-complete.component';
import { PDObjectReferenceComponent } from '../pd-object-reference/pd-object-reference.component';
import { PDGroupboxComponent } from '../pd-groupbox/pd-groupbox.component';
import { PDRadioButtonGroupComponent } from '../pd-radio-button-group/pd-radio-button-group.component';
import { PDTabViewComponent } from '../pd-tab-view/pd-tab-view.component';
import { PDComboBoxComponent } from '../pd-combo-box/pd-combo-box.component';
import { PDRelationTabComponent } from '../pd-relation-tab/pd-relation-tab.component';
import { PDButtonComponent } from '../pd-button/pd-button.component';
import { PDInfoFieldComponent } from '../pd-info-field/pd-info-field.component';
import { PDFileUploadComponent } from '../pd-file-upload/pd-file-upload.component';
import { PDDrawerComponent } from '../pd-drawer/pd-drawer.component';

@Component({
	selector: 'otris-pd-panel',
	template: `
		<div [ngStyle]="containerStyle">

			<ng-container *ngFor="let item of itemSpecs">
				<ng-container [ngSwitch]="item.spec.widget">
					<ng-container *ngSwitchCase="widgetET.TextField">
						<otris-pd-text-field [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-text-field>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.NumericTextField">
						<otris-pd-numeric-text-field [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-numeric-text-field>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.Checkbox">
						<otris-pd-checkbox [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-checkbox>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.RadioButton">
						<otris-pd-radio-button [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-radio-button>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.InputSwitch">
						<otris-pd-input-switch [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-input-switch>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.DateTimeField">
						<otris-pd-date-time-field [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-date-time-field>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.DropDown">
						<otris-pd-drop-down [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-drop-down>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.ComboBox">
						<otris-pd-combo-box [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-combo-box>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.MultiSelect">
						<otris-pd-multi-select [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-multi-select>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.AutoComplete">
						<otris-pd-auto-complete [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-auto-complete>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.ObjectReference">
						<otris-pd-object-reference [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-object-reference>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.RelationTab">
						<otris-pd-relation-tab [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-relation-tab>
					</ng-container>
					<!--
					<ng-container *ngSwitchCase="widgetET.RelationList">
						<otris-pd-relation-list [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-relation-list>
					</ng-container>
					-->
					<ng-container *ngSwitchCase="widgetET.Panel">
						<otris-pd-panel [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-panel>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.Groupbox">
						<otris-pd-groupbox [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext">
						</otris-pd-groupbox>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.RadioButtonGroup">
						<otris-pd-radio-button-group [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-radio-button-group>
					</ng-container>
					<!--
					<ng-container *ngSwitchCase="widgetET.Accordion">
						<otris-pd-accordion [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject"></otris-pd-accordion>
					</ng-container>
					-->
					<ng-container *ngSwitchCase="widgetET.TabView">
						<otris-pd-tab-view [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-tab-view>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.Drawer">
						<otris-pd-drawer [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-drawer>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.Label">
						<otris-pd-label [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-label>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.Button">
						<otris-pd-button [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-button>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.ContentTemplate">
						<otris-ui-content [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-ui-content>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.InfoField">
						<otris-pd-info-field [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-info-field>
					</ng-container>
					<ng-container *ngSwitchCase="widgetET.FileUpload">
						<otris-pd-file-upload [uiItemSpec]="item.spec" [formGroup]="item.fg" [pdObject]="item.pdObject" [relationContext]="relationContext"></otris-pd-file-upload>
					</ng-container>
					<ng-container *ngSwitchDefault>
						<div>unknown widget</div>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	`,
	styles: [`
		:host {
			display: flex;
		}
	`]
})
export class PDPanelComponent extends PDContainerComponent {
	@ViewChildren(PDTextFieldComponent) textFields: QueryList<PDTextFieldComponent>;
	@ViewChildren(PDNumericTextFieldComponent) numericTextFields: QueryList<PDTextFieldComponent>;
	@ViewChildren(PDCheckboxComponent) checkBoxes: QueryList<PDCheckboxComponent>;
	@ViewChildren(PDRadioButtonComponent) radioButtons: QueryList<PDRadioButtonComponent>;
	@ViewChildren(PDInputSwitchComponent) inputSwitches: QueryList<PDInputSwitchComponent>;
	@ViewChildren(PDDateTimeFieldComponent) dateTimeFields: QueryList<PDDateTimeFieldComponent>;
	@ViewChildren(PDDropDownComponent) dropDowns: QueryList<PDDropDownComponent>;
	@ViewChildren(PDMultiSelectComponent) multiSelects: QueryList<PDMultiSelectComponent>;
	@ViewChildren(PDAutoCompleteComponent) autoCompletes: QueryList<PDAutoCompleteComponent>;
	@ViewChildren(PDObjectReferenceComponent) objectReferences: QueryList<PDObjectReferenceComponent>;
	@ViewChildren(PDGroupboxComponent) groupboxes: QueryList<PDGroupboxComponent>;
	@ViewChildren(PDRadioButtonGroupComponent) radioButtonGroups: QueryList<PDRadioButtonGroupComponent>;
	@ViewChildren(PDTabViewComponent) tabViews: QueryList<PDTabViewComponent>;
	@ViewChildren(PDComboBoxComponent) comboBoxes: QueryList<PDComboBoxComponent>;
	@ViewChildren(PDRelationTabComponent) relationTabs: QueryList<PDRelationTabComponent>;
	@ViewChildren(PDPanelComponent) panels: QueryList<PDPanelComponent>;
	@ViewChildren(PDButtonComponent) buttons: QueryList<PDButtonComponent>;
	@ViewChildren(UIContentComponent) contentComps: QueryList<UIContentComponent>;
	@ViewChildren(PDInfoFieldComponent) infoFields: QueryList<PDInfoFieldComponent>;
	@ViewChildren(PDFileUploadComponent) fileUploads: QueryList<PDFileUploadComponent>;
	@ViewChildren(PDDrawerComponent) drawers: QueryList<PDDrawerComponent>;

	@HostBinding('class.otris-pd-panel-background-color') get bgColor() {
		return !this.panelSpec.transparent;
	}

	protected get childComponents(): UIAbstractComponent[] {
		let comps: UIAbstractComponent[] = [];
		if (this.textFields) {
			comps.push(...this.textFields.toArray());
		}
		if (this.numericTextFields) {
			comps.push(...this.numericTextFields.toArray());
		}
		if (this.checkBoxes) {
			comps.push(...this.checkBoxes.toArray());
		}
		if (this.radioButtons) {
			comps.push(...this.radioButtons.toArray());
		}
		if (this.inputSwitches) {
			comps.push(...this.inputSwitches.toArray());
		}
		if (this.dateTimeFields) {
			comps.push(...this.dateTimeFields.toArray());
		}
		if (this.dropDowns) {
			comps.push(...this.dropDowns.toArray());
		}
		if (this.multiSelects) {
			comps.push(...this.multiSelects.toArray());
		}
		if (this.autoCompletes) {
			comps.push(...this.autoCompletes.toArray());
		}
		if (this.objectReferences) {
			comps.push(...this.objectReferences.toArray());
		}
		if (this.groupboxes) {
			comps.push(...this.groupboxes.toArray());
		}
		if (this.radioButtonGroups) {
			comps.push(...this.radioButtonGroups.toArray());
		}
		if (this.relationTabs) {
			comps.push(...this.relationTabs.toArray());
		}
		if (this.comboBoxes) {
			comps.push(...this.comboBoxes.toArray());
		}
		if (this.tabViews) {
			comps.push(...this.tabViews.toArray());
		}
		if (this.panels) {
			comps.push(...this.panels.toArray());
		}
		if (this.buttons) {
			comps.push(...this.buttons.toArray());
		}
		if (this.contentComps) {
			comps.push(...this.contentComps.toArray());
		}
		if (this.infoFields) {
			comps.push(...this.infoFields.toArray());
		}
		if (this.fileUploads) {
			comps.push(...this.fileUploads.toArray());
		}
		if (this.drawers) {
			comps.push(...this.drawers.toArray());
		}
		return comps;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		if (this.uiItemSpec.disabled) {
			this.disable(true);
		}
	}

	get panelSpec(): UIPanelSpec {
		if (!(this.uiItemSpec instanceof UIPanelSpec)) {
			throw Error('No UIPanelSpec assigned.');
		}
		return <UIPanelSpec>this.uiItemSpec;
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.Panel;
	}

	get isEnabled(): boolean {
		return !this._disabled;
	}

	get isDisabled(): boolean {
		return this._disabled;
	}

	private _disabled: boolean = false;

	disable(flag: boolean): void {
		this._disabled = flag;

		if (this.textFields) {
			this.textFields.forEach(item => item.disable(flag));
		}
		if (this.numericTextFields) {
			this.numericTextFields.forEach(item => item.disable(flag));
		}
		if (this.checkBoxes) {
			this.checkBoxes.forEach(item => item.disable(flag));
		}
		if (this.radioButtons) {
			this.radioButtons.forEach(item => item.disable(flag));
		}
		if (this.inputSwitches) {
			this.inputSwitches.forEach(item => item.disable(flag));
		}
		if (this.dateTimeFields) {
			this.dateTimeFields.forEach(item => item.disable(flag));
		}
		if (this.dropDowns) {
			this.dropDowns.forEach(item => item.disable(flag));
		}
		if (this.multiSelects) {
			this.multiSelects.forEach(item => item.disable(flag));
		}
		if (this.autoCompletes) {
			this.autoCompletes.forEach(item => item.disable(flag));
		}
		if (this.objectReferences) {
			this.objectReferences.forEach(item => item.disable(flag));
		}
		if (this.groupboxes) {
			this.groupboxes.forEach(item => item.disable(flag));
		}
		if (this.radioButtonGroups) {
			this.radioButtonGroups.forEach(item => item.disable(flag));
		}
		if (this.relationTabs) {
			this.relationTabs.forEach(item => item.disable(flag));
		}
		if (this.comboBoxes) {
			this.comboBoxes.forEach(item => item.disable(flag));
		}
		if (this.tabViews) {
			this.tabViews.forEach(item => item.disable(flag));
		}
		if (this.panels) {
			this.panels.forEach(item => item.disable(flag));
		}
		if (this.buttons) {
			this.buttons.forEach(item => item.disable(flag));
		}
		if (this.contentComps) {
			this.contentComps.forEach(item => item.disable(flag));
		}
		if (this.infoFields) {
			this.infoFields.forEach(item => item.disable(flag));
		}
		if (this.fileUploads) {
			this.fileUploads.forEach(item => item.disable(flag));
		}
		if (this.drawers) {
			this.drawers.forEach(item => item.disable(flag));
		}
	}

	reset(): void {
		if (this.textFields) {
			this.textFields.forEach(item => item.reset());
		}
		if (this.numericTextFields) {
			this.numericTextFields.forEach(item => item.reset());
		}
		if (this.checkBoxes) {
			this.checkBoxes.forEach(item => item.reset());
		}
		if (this.radioButtons) {
			this.radioButtons.forEach(item => item.reset());
		}
		if (this.inputSwitches) {
			this.inputSwitches.forEach(item => item.reset());
		}
		if (this.dateTimeFields) {
			this.dateTimeFields.forEach(item => item.reset());
		}
		if (this.dropDowns) {
			this.dropDowns.forEach(item => item.reset());
		}
		if (this.multiSelects) {
			this.multiSelects.forEach(item => item.reset());
		}
		if (this.autoCompletes) {
			this.autoCompletes.forEach(item => item.reset());
		}
		if (this.objectReferences) {
			this.objectReferences.forEach(item => item.reset());
		}
		if (this.groupboxes) {
			this.groupboxes.forEach(item => item.reset());
		}
		if (this.radioButtonGroups) {
			this.radioButtonGroups.forEach(item => item.reset());
		}
		if (this.relationTabs) {
			this.relationTabs.forEach(item => item.reset());
		}
		if (this.comboBoxes) {
			this.comboBoxes.forEach(item => item.reset());
		}
		if (this.tabViews) {
			this.tabViews.forEach(item => item.reset());
		}
		if (this.panels) {
			this.panels.forEach(item => item.reset());
		}
		if (this.buttons) {
			this.buttons.forEach(item => item.reset());
		}
		if (this.contentComps) {
			this.contentComps.forEach(item => item.reset());
		}
		if (this.infoFields) {
			this.infoFields.forEach(item => item.reset());
		}
		if (this.fileUploads) {
			this.fileUploads.forEach(item => item.reset());
		}
		if (this.drawers) {
			this.drawers.forEach(item => item.reset());
		}
	}
}
