import { Injectable, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Observable } from "rxjs";
/*import { ISelectionComponent } from '../model/i-selection-component';
import { IComponent } from '../model/i-component';
import { IBooleanComponent } from '../model/i-boolean-component';
import { ComponentTypeET } from '../model/types';*/
import { IComponent, IBooleanComponent, ComponentTypeET, IFormStatus, CustomPDObject } from '@otris/ng-core-shared';
import { UIItemSpec, UIContainerSpec, PDItemSpec } from '../model/pd-layout';
import { PDComponent } from '../components/pd/pd.component';
import { CustomPDObjectMeta } from '../model/custom-pd-object-meta';

//export type MandatoryCheckCallback = (paramName: string) => boolean | undefined;
export type MandatoryCheckCallback = (comp: IComponent) => boolean | undefined;

// Todo: Interface bereitstellen
@Injectable()
export class FormHandlerService {
	//private _selectionChanges: Subject<ISelectionComponent> = new Subject();

	//private _checkedChanges: Subject<IBooleanComponent> = new Subject();

	private _formStatusChanges: Subject<IFormStatus> = new Subject();

	private registeredComponents: Map<string, Map<ComponentTypeET, IComponent>> = new Map<string, Map<ComponentTypeET, IComponent>>();

	private _mandatoryCheckCallback: MandatoryCheckCallback;

	private _form: FormGroup;

	/*get selectionChanges(): Subject<ISelectionComponent> {
		return this._selectionChanges;
	}*/

	/*get checkedChanges(): Subject<IBooleanComponent> {
		return this._checkedChanges;
	}*/

	get formStatusChanges(): Observable<IFormStatus> {
		return this._formStatusChanges.asObservable();
	}

	get formStatus(): IFormStatus {
		return <IFormStatus>{
			valid: this._form ? this._form.valid : false,
			pristine: this._form ? this._form.pristine : true,
			touched: this._form ? this._form.touched: false
		};
	}

	private _registeredUITemplates: Map<string, TemplateRef<any>> = new Map<string, TemplateRef<any>>();

	private _uiTemplateRegistered$: Subject<string> = new Subject();

	get uiTemplateRegistered$(): Observable<string> {
		return this._uiTemplateRegistered$.asObservable();
	}

	constructor() { }

	/*notifySelectionChanged(source: ISelectionComponent) {
		this._selectionChanges.next(source);
	}*/

	/*notifyCheckChanged(source: IBooleanComponent) {
		this._checkedChanges.next(source);
	}*/

	registerForm(form: FormGroup) {
		if (this._form) {
			throw new Error('Form already registered.');
		}
		this._form = form;
		this._form.statusChanges.subscribe(v => {
			this._formStatusChanges.next(<IFormStatus>{
				valid: this._form.valid,
				pristine: this._form.pristine,
				touched: this._form.touched
			});
		});
		/*this._form.valueChanges.subscribe(v => {
			this._formStatusChanges.next(<IFormStatus>{ valid: this._form.valid, pristine: this._form.pristine });
		});*/
	}

	registerComponent(comp: IComponent) {
		let id = comp.id;
		if (id && id.length > 0 /*&& !this.registeredComponents.has(id)*/) {
			if (!this.registeredComponents.has(id)) {
				this.registeredComponents.set(id, new Map<ComponentTypeET, IComponent>());
			}
			let map = this.registeredComponents.get(id);
			map.set(comp.componentType, comp);
			//this.registeredComponents.set(id, comp);
		}
	}

	unregisterComponent(comp: IComponent) {
		let id = comp.id;
		if (id && id.length > 0 && this.registeredComponents.has(id)) {
			let map = this.registeredComponents.get(id);
			if (map.has(comp.componentType)) {
				map.delete(comp.componentType);
				if (map.size == 0) {
					this.registeredComponents.delete(id);
				}
			}
			//this.registeredComponents.delete(id);
		}
	}

	getComponent<T extends IComponent>(id: string, type?: ComponentTypeET): T {
		if (this.registeredComponents.has(id)) {
			let map = this.registeredComponents.get(id);
			if (!type) {
				return map.size == 1 ? <T>map.values().next().value : undefined;
			}
			if (map.has(type)) {
				return <T>map.get(type);
			}
			//return this.registeredComponents.get(id);
		}
		return undefined;
	}

	setMandatoryCheckCallback(callback: MandatoryCheckCallback) {
		this._mandatoryCheckCallback = callback;
	}

	/*isMandatory(paramName: string): boolean | undefined {
		if (this._mandatoryCheckCallback) {
			return this._mandatoryCheckCallback(paramName);
		}
		return undefined;
	}*/

	/*updateMandatoryStatus(paramName: string): void {
		let comp = this.getComponent(paramName);
		if (comp) {
			let mandatory = this.isMandatory(paramName);
			if (mandatory != undefined) {
				comp.mandatoryCustom = mandatory;
			}
		}
	}*/
	
	updateMandatoryStatus(): void {
		if (!this._mandatoryCheckCallback) {
			return;
		}
		for (let compMap of this.registeredComponents.values()) {
			for (let comp of compMap.values()) {
				//if (comp instanceof PDComponent) {
					//let pdComp = <PDComponent>comp;
					if (comp.mandatoryCustomActivated) {
						let val = this._mandatoryCheckCallback(comp);
						if (val === true || val === false) {
							comp.mandatoryCustom = val;
						}
					}
				//}
			}
		}
	}

	registerUITemplate(name: string, templ: TemplateRef<any>): void  {
		if (this._registeredUITemplates.has(name)) {
			throw new Error(`UI template with name '${name}' already registered.`);
		}
		this._registeredUITemplates.set(name, templ);
		this._uiTemplateRegistered$.next(name);
	}

	hasUITemplate(name: string): boolean {
		return this._registeredUITemplates.has(name);
	}

	getUITemplate(name: string): TemplateRef<any> | undefined {
		if (this._registeredUITemplates.has(name)) {
			return this._registeredUITemplates.get(name);
		}
		return undefined;
	}

	// todo: muss überarbeitet werden, zu n Relations (RelationTabComponent) funktioniert so z.B. nicht
	/*getFormControlStatus(spec: UIItemSpec): IFormStatus {
		let stat = <IFormStatus>{ pristine: true, touched: false, valid: true };
		if (spec instanceof UIContainerSpec) {
			for (let child of (<UIContainerSpec>spec).allItems.filter(i => i instanceof PDItemSpec).map(i => <PDItemSpec>i)) {
				let ctrl = (child.property && this._form.controls[child.property]) ? this._form.controls[child.property] : undefined;
				if (ctrl && !ctrl.disabled) {
					if (!ctrl.pristine) {
						stat.pristine = false;
					}
					if (ctrl.touched) {
						stat.touched = true;
					}
					if (!ctrl.valid) {
						stat.valid = false;
					}
				}
			}
		}
		else if (spec instanceof PDItemSpec) {
			let pdItemSpec = <PDItemSpec>spec;
			let ctrl = (pdItemSpec.property && this._form.controls[pdItemSpec.property]) ? this._form.controls[pdItemSpec.property] : undefined;
			if (ctrl && !ctrl.disabled) {
				stat.pristine = ctrl.pristine;
				stat.touched = ctrl.touched;
				stat.valid = ctrl.valid;
			}
		}

		return stat;
	}*/
}
