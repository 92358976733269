import {
	Component, OnInit, AfterViewInit, Inject, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, HostBinding
} from '@angular/core';
import { Observable, Subscription, of, forkJoin, fromEvent } from "rxjs";
import {
	IToolBarItemSpec, IToolBarButtonSpec, IToolBarDropDownButtonSpec, IToolBarItemResult, IToolBarDropDownButtonItem, ToolBarItemTypeET, ICssStyle,
	ILocalizationService, ILocalizationServiceToken
} from '@otris/ng-core-shared';
import { DropDownToolBarButtonComponent } from './drop-down-tool-bar-button.component';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
export { DropDownToolBarButtonComponent };

interface IKendoDropDownItemSpec {
	text?: string;
	[propName: string]: any;
}

//button,kendo-dropdownbutton
// {{ anchor.nativeElement.parentElement.parentElement.getAttribute("class") }}
// + ' otris-pd-toolbar-kendo-toolbar-button-icon-class'
@Component({
	selector: 'otris-tool-bar',
	template: `
		<div class="toolbar-container" kendoTooltip tooltipClass="otris-tooltip" filter="kendo-toolbar-renderer" [tooltipTemplate]="template" position="bottom">
			<ng-template #template let-anchor>
				<span>{{getShortDescription(anchor.nativeElement) | async}}</span>
			</ng-template>

			<!--
			<ng-container *ngFor="let spec of itemSpecs">
				<ng-container *ngIf="!spec.hidden || spec.hidden===false">
					<otris-button *ngIf="spec.type===toolBarItemTypeET.Button" class="toolbar-button" [flat]="true" [icon]="spec.iconClass" [label]="spec.text"
						[disabled]="disabled || spec.disabled" (buttonClick)="onClick(spec)">
					</otris-button>
					<otris-drop-down-tool-bar-button *ngIf="spec.type===toolBarItemTypeET.DropDownButton" class="toolbar-button" [toolBarButtonSpec]="spec"
						[disabled]="disabled" (itemClick)="onClick($event)">
					</otris-drop-down-tool-bar-button>
				</ng-container>
			</ng-container>
			-->
			
			<kendo-toolbar [ngStyle]="style">
				<ng-container *ngFor="let spec of itemSpecs">
					<ng-container *ngIf="!spec.hidden || spec.hidden===false">
						<kendo-toolbar-button *ngIf="spec.type===toolBarItemTypeET.Button" id="{{spec.id}}" [iconClass]="spec.iconClass"
							[disabled]="disabled || spec.disabled" [text]="getToolBarButtonText(spec) | async" (click)="onButtonClick(spec)" look="bare" [style]="spec.style">
						</kendo-toolbar-button>
						<kendo-toolbar-dropdownbutton *ngIf="spec.type===toolBarItemTypeET.DropDownButton" id="{{spec.id}}" [iconClass]="spec.iconClass" [disabled]="disabled || spec.disabled"
							[text]="getToolBarButtonText(spec) | async" [buttonClass]="'otris-kendo-toolbar-dropdownbutton'" (itemClick)="onDropDownButtonClick($event)"
							look="bare" [data]="createKendoDropDownButtonItems(spec) | async" [style]="spec.style">
						</kendo-toolbar-dropdownbutton>
					</ng-container>
				</ng-container>
			</kendo-toolbar>

			<div *ngIf="showSearchControl" class="search-control-container">
				<input #searchInputRef class="search-control k-textbox" kendoTextBox type="text" [placeholder]="placeholder$ | async"/>
				<div style="margin: auto 0.5em"><i class="fa fa-lg fa-search" aria-hidden="true"></i></div>
			</div>
		</div>
	`,
	styles: [`
		.toolbar-container {
			display: flex;
			padding: 0 0.5em;
			background-color: inherit;
			color: inherit;
		}

		.toolbar-button {
			margin-right: 0.25em;
		}

		.search-control-container {
			display: flex;
			min-width: 20em;
			flex: 1 1 100px;
		}
		.search-control {
			border-radius: 1em;
			flex: 1 1 auto;
		}
	`]
})
export class ToolBarComponent implements OnInit, AfterViewInit {
	private _itemSpecs: IToolBarItemSpec[] = [];

	@Input() placeholder: string;

	@HostBinding('class') classOtrisToolBar = 'otris-tool-bar';

	placeholder$: Observable<string>;

	@Input()
	set itemSpecs(specs: IToolBarItemSpec[]) {
		if (specs) {
			this._itemSpecs = specs;
			//this.cdRef.detectChanges();
		}
	}

	get itemSpecs(): IToolBarItemSpec[] {
		return this._itemSpecs;
	}

	toolBarItemTypeET = ToolBarItemTypeET;

	@Input() showSearchControl: boolean = false;

	@Input()
	set disabled(flag: boolean) {
		this._disabled = flag;
	}

	get disabled(): boolean {
		return this._disabled;
	}

	_disabled: boolean = false;

	//@Input() backgroundColor: string;

	@Output() buttonClick = new EventEmitter<IToolBarItemResult>();

	@Output() searchExpressionChanged = new EventEmitter<string>();

	@ViewChild('searchInputRef') searchInputRef: ElementRef;

	// class geht nicht, warum auch immer
	get style(): ICssStyle {
		let s = <ICssStyle>{
			padding: 0,
			'background-color': 'inherit',
			border: 0,
			flex: '1 1 auto'
		}
		/*if (this.backgroundColor) {
			s['background-color'] = this.backgroundColor;
		}*/
		return s;
	};

	private subscription: Subscription;


	get searchExpression(): string {
		return this.searchInputRef ? this.searchInputRef.nativeElement.value : undefined;
	}

	constructor(@Inject(ILocalizationServiceToken) private localizationService: ILocalizationService, private cdRef: ChangeDetectorRef) { }

	ngOnInit() {
		this.localizationService.changeHandler.subscribe(lang => this.updatePlaceholder());
		this.updatePlaceholder();

		/*if (this.selectionService) {
			this.onSelectionChanged(undefined);
			this.subscription = this.selectionService.selectionSubject.subscribe(sel => {
				this.onSelectionChanged(sel);
			});
		}*/
	}

	ngAfterViewInit() {
		if (this.searchInputRef) {
			fromEvent(this.searchInputRef.nativeElement, 'keyup').pipe(
				// get value
				map((evt: any) => evt.target.value),
				// text length must be > 2 chars
				//.filter(res => res.length > 2)
				// emit after 1s of silence
				debounceTime(1000),
				// emit only if data changes since the last emit 
				distinctUntilChanged(),
				// subscription
			).subscribe((text: string) => this.searchExpressionChanged.emit(text));
		}
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	createKendoDropDownButtonItems(spec: IToolBarDropDownButtonSpec): Observable<IKendoDropDownItemSpec[]> {
		let itemsSpecs$ = spec.items.map(i => {
			let text$: Observable<string>;
			if (i.text){
				text$ = of(i.text);
			}
			else if (i.textId) {
				text$ =  this.localizationService.getString(i.textId);
			}
			else {
				text$ = of('');
			}
			return text$.pipe(
				map(t => {
					let itemSpec: IKendoDropDownItemSpec = Object.assign({}, i);
					itemSpec.text = t;
					return itemSpec;
				})
			);
		});
		return forkJoin(itemsSpecs$);
	}

	getShortDescription(element: any): Observable<string> {
		let searchElement = element;
		for (let i = 0; i < searchElement.parentElement.children.length; i++) {
			if (searchElement.parentElement.children[i] === searchElement) {
				if (i < this._itemSpecs.length) {
					if (this._itemSpecs[i].shortDescriptionId) {
						return this.localizationService.getString(this._itemSpecs[i].shortDescriptionId);
					}
					return of(this._itemSpecs[i].shortDescription);
				}
			}
		}
		return of(undefined);

		/*let searchElement = element.parentElement;
		for (let i = 0; i < searchElement.parentElement.children.length; i++) {
			if (searchElement.parentElement.children[i] === searchElement) {
				if (i < this._itemSpecs.length) {
					if (this._itemSpecs[i].shortDescriptionId) {
						return this.localizationService.getString(this._itemSpecs[i].shortDescriptionId);
					}
					return Observable.of(this._itemSpecs[i].shortDescription);
				}
			}
		}
		return Observable.of(undefined);*/
	}

	/*private onSelectionChanged(sel: Selection) {
		for (let spec of this.itemSpecs) {
			if (spec.enabledWhen) {
				switch (spec.enabledWhen) {
					case EnabledWhenET.MultipleItemsSelected:
						spec.disabled = !sel || !sel.arrayValue || sel.arrayValue.length == 0;
						break;
					case EnabledWhenET.SingleItemSelected:
						spec.disabled = !(sel && sel.isSingle);
						break;
					case EnabledWhenET.ItemsSelected:
						spec.disabled = !sel || !sel.value || (sel.isArray && sel.arrayValue.length == 0);
						break;
				}
			}
		}
	}*/

	getToolBarButtonText(spec: IToolBarItemSpec): Observable<string> | undefined {
		if (spec.text) {
			return of(spec.text);
		}
		if (spec.textId) {
			return this.localizationService.getString(spec.textId);
		}
		return undefined;
	}

	private updatePlaceholder(): void {
		this.placeholder$ = this.placeholder ? of(this.placeholder) :
			this.localizationService.getSystemString('kendo-ui.components.tool-bar.search-control-container-text');
	}

	onButtonClick(item: IToolBarButtonSpec): void {
		this.buttonClick.emit(<IToolBarItemResult>{ id: item.id, tag: item.tag });
	}

	onDropDownButtonClick(item: IToolBarDropDownButtonItem): void {
		this.buttonClick.emit(<IToolBarItemResult>{ id: item.id, tag: item.tag });
	}
}
