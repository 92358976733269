import { Injectable, TemplateRef, Inject, InjectionToken } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap, tap } from "rxjs/operators";
import { DialogService, DialogCloseResult, DialogAction, DialogRef, DialogSettings } from '@progress/kendo-angular-dialog';
import {
	IInteractionService, DialogResultET, SelectionModeET, ISelectPDObjectResult, ISelectPDObjectsResult, ISelectObjectOptions, IActionButton, PDObject,
	IErrorHandler, IErrorHandlerToken, ServiceLocator, ILocalizationServiceToken, ILocalizationService, IDialogSettings
} from '@otris/ng-core-shared';
import { PDObjectSelectionComponent, ISelectionResult } from '../components/pd-object-selection/pd-object-selection.component';


//IInteractionServiceToken = new InjectionToken<IInteractionService>('IInteractionServiceToken');

@Injectable()
export class InteractionService implements IInteractionService {

	private openDialogs: DialogRef[] = [];

	constructor(private dialogService: DialogService, 
		@Inject(IErrorHandlerToken) private errorHandler: IErrorHandler) {
		errorHandler.errorOccurred.subscribe(() => {
			this.openDialogs.forEach(d => d.close());
			this.openDialogs = [];
		});
	}

	showOkMessage(title: string, message: string, settings?: IDialogSettings): Observable<void> {
		let dlgSettings = this.mergeDialogSettings(<DialogSettings>
			{
				title: title,
				content: message,
				actions: [
					{ text: 'Ok', primary: true }
				]
			}, settings);
		let dialog = this.dialogService.open(dlgSettings);
		return dialog.result.pipe(map(res => { }));
	}

	showConfirmMessage(title: string, message: string, settings?: IDialogSettings): Observable<DialogResultET> {

		let localizationService: ILocalizationService = ServiceLocator.injector.get(ILocalizationServiceToken);
		
		// TODO: Schöner machen die nested observables ... 
		return forkJoin([
			localizationService.getSystemString('kendo-ui.components.dialog.ok'),
			localizationService.getSystemString('kendo-ui.components.dialog.cancel')
		]).pipe(
			switchMap(res => {
				let dlgSettings = this.mergeDialogSettings(<DialogSettings>
					{
						title: title,
						content: message,
						actions: [
							{ text: res[0], primary: true, id: DialogResultET.Ok },
							{ text: res[1], id: DialogResultET.Cancel }
						]
					}, settings);
				let dialog = this.dialogService.open(dlgSettings);
				return dialog.result.pipe(
					map(res => {
						if (!(res instanceof DialogCloseResult)) {
							return (<any>res).id;
						}
						return DialogResultET.Cancel;
					})
				);
			})
		);
	}

	showYesNoMessage(title: string, message: string, settings?: IDialogSettings): Observable<DialogResultET> {

		let localizationService: ILocalizationService = ServiceLocator.injector.get(ILocalizationServiceToken);

		return localizationService.getSystemStrings([
			'kendo-ui.components.dialog.yes',
			'kendo-ui.components.dialog.no'
		]).pipe(
			map(trans => {
				return this.mergeDialogSettings(<DialogSettings>
					{
						title: title,
						content: message,
						actions: [
							{ text: trans[0], primary: true, id: DialogResultET.Yes },
							{ text: trans[1], id: DialogResultET.No }
						]
					}, settings);
			}),
			switchMap(dlgSettings => this.dialogService.open(dlgSettings).result),
			map(res => {
				if (!(res instanceof DialogCloseResult)) {
					return (<any>res).id;
				}
				return DialogResultET.No;
			})
		)

		// let dlgSettings = this.mergeDialogSettings(<DialogSettings>
		// 	{
		// 		title: title,
		// 		content: message,
		// 		actions: [
		// 			{ text: 'Ja', primary: true, id: DialogResultET.Yes },
		// 			{ text: 'Nein', id: DialogResultET.No }
		// 		]
		// 	}, settings);
		// let dialog = this.dialogService.open(dlgSettings);
		// return dialog.result.pipe(
		// 	map(res => {
		// 		if (!(res instanceof DialogCloseResult)) {
		// 			return (<any>res).id;
		// 		}
		// 		return DialogResultET.No;
		// 	})
		// );
	}

	selectPDObject(className: string, title: string, options?: ISelectObjectOptions, customContent?: TemplateRef<any>): Observable<ISelectPDObjectResult> {
		let dialog = this.dialogService.open({
			title: title,
			content: PDObjectSelectionComponent
		});

		let comp: PDObjectSelectionComponent = dialog.content.instance;
		comp.className = className;
		comp.selectionMode = SelectionModeET.Single;
		comp.customContent = customContent;
		comp.options = options;
		this.openDialogs.push(dialog);

		return dialog.result.pipe(
			tap(() => {
				let i = this.openDialogs.indexOf(dialog);
				this.openDialogs.splice(i, 1);
			}),
			map(res => {
				let selectedObject: PDObject;
				let result = <ISelectionResult>res;
				if (result && result.selection && !Array.isArray(result.selection)) {
					selectedObject = result.selection;
				}
				return <ISelectPDObjectResult>{ selection: selectedObject };
			})
		);
	}

	selectPDObjects(className: string, title: string, options?: ISelectObjectOptions, customContent?: TemplateRef<any>): Observable<ISelectPDObjectsResult> {
		let dialog = this.dialogService.open({
			title: title,
			content: PDObjectSelectionComponent
		});

		let comp: PDObjectSelectionComponent = dialog.content.instance;
		comp.className = className;
		comp.selectionMode = SelectionModeET.Multiple;
		comp.customContent = customContent;
		comp.options = options;
		this.openDialogs.push(dialog);

		return dialog.result.pipe(
			tap(() => {
				let i = this.openDialogs.indexOf(dialog);
				this.openDialogs.splice(i, 1);
			}),
			map(res => {
				let selectedObjects: PDObject[];
				let result = <ISelectionResult>res;
				if (result && result.selection && Array.isArray(result.selection)) {
					selectedObjects = result.selection;
				}
				return <ISelectPDObjectsResult>{ selection: selectedObjects };
			})
		);
	}

	showCustomMessage(title: string, buttons: IActionButton[], content: TemplateRef<any>): Observable<DialogResultET> {
		let dialog = this.dialogService.open({
			title: title,
			content: content,
			actions: buttons
		});
		this.openDialogs.push(dialog);

		return dialog.result.pipe(
			tap(() => {
				let i = this.openDialogs.indexOf(dialog);
				this.openDialogs.splice(i, 1);
			}),
			map(res => {
				if (!(res instanceof DialogCloseResult)) {
					return (<any>res).id;
				}
				return DialogResultET.Cancel;
			})
		);
	}

	private mergeDialogSettings(dlgSettings: DialogSettings, settings: IDialogSettings): DialogSettings {
		if (settings) {
			if (settings.height) {
				dlgSettings.height = settings.height;
			}
			if (settings.width) {
				dlgSettings.width = settings.width;
			}
			if (settings.minHeight) {
				dlgSettings.minHeight = settings.minHeight;
			}
			if (settings.minWidth) {
				dlgSettings.minWidth = settings.minWidth;
			}
			if (settings.maxHeight) {
				dlgSettings.maxHeight = settings.maxHeight;
			}
			if (settings.maxWidth) {
				dlgSettings.maxWidth = settings.maxWidth;
			}
		}
		return dlgSettings;
	}
}
