import { Component, HostListener, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { PDComponent, FormHandlerService, CheckboxWidgetInfo } from '@otris/ng-core';
import { ComponentTypeET, IBooleanComponent } from '@otris/ng-core-shared';
import { ValidatorFn, Validators } from '@angular/forms';

@Component({
	selector: 'otris-pd-checkbox',
	template: `
		<div class="main-container">
			<ng-container *ngIf="!isReadonly; else readonly">
				<div class="checkbox-container">
					<input type="checkbox" class="k-checkbox" [formControl]="formGroup.controls[propertyName]" [id]="checkboxId" (change)="onChange($event)"/>
					<label class="k-checkbox-label checkbox-label" [for]="checkboxId">{{label}}</label>
					<otris-pd-short-description [highlighted]="mouseIsOver" *ngIf="isEnabled && hasShortDescription" [pdObject]="pdObject" [pdComponent]="this">
					</otris-pd-short-description>
					<otris-pd-error-indicator *ngIf="isEnabled && control && !control.valid" [relatedFormControl]="control" [propertyName]="propertyName" [pdObject]="pdObject">
					</otris-pd-error-indicator>
				</div>
			</ng-container>

			<ng-template #readonly>
				<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
			</ng-template>
		</div> <!--  main-container -->
	`,
	styles: [`
		.main-container {
			display: flex;
			flex-direction: column;
			height: 100%;
		}
		.label-place-holder {
			margin-bottom: 0.5em;
			height: 1.2em;
		}
		.checkbox-container {
			display: flex;
			flex: 1 1 auto;
		}
		.checkbox-label {
			margin: auto 0 auto 0.5em;
			white-space: nowrap;
		}
	`]
})
export class PDCheckboxComponent extends PDComponent implements IBooleanComponent {

	private static counter: number = 0;

	private _checkboxId: string;

	mouseIsOver: boolean = false;

	@HostListener('mouseenter')
	onMouseEnter() {
		this.mouseIsOver = true;
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.mouseIsOver = false;
	}

	@Input()
	requiredTrue: boolean;

	get checkboxWidgetInfo(): CheckboxWidgetInfo | undefined {
		return this.pdItemSpec.widgetInfo instanceof CheckboxWidgetInfo ?
			this.pdItemSpec.widgetInfo as CheckboxWidgetInfo : undefined;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService) {
		super(router, route, formHandler);
		this._checkboxId = "pdCheckbox_" + PDCheckboxComponent.counter.toString();
		PDCheckboxComponent.counter++;		
	}

	ngOnInit() {
		super.ngOnInit();
		this._valueChangedSubject$ = new BehaviorSubject(this.control ? this.control.value : undefined);
		/*if (this.uiItemSpec.placement instanceof FlexItemPlacement) {
			this.alignSelfStyle = "flex-end";
		}
		else if (this.uiItemSpec.placement instanceof GridItemPlacement) {
			this.alignSelfStyle = "end";
		}*/
	}

	protected getCustomValidators(): Observable<ValidatorFn[] | undefined> {
		let wi = this.checkboxWidgetInfo;
		return (this.requiredTrue || (wi && wi.requiredTrue)) ? of([Validators.requiredTrue]) : of(undefined);
	}

	get checkboxId(): string {
		return this._checkboxId;
	}

	onChange(event: any) {
		/*if (this.hasId) {
			this.formHandler.notifyCheckChanged(this);
		}*/
		this._valueChangedSubject$.next(this.checked);
	}

	//----------------------------------------------------------------------------------------------
	// IComponent
	//----------------------------------------------------------------------------------------------

	get componentType(): ComponentTypeET {
		return ComponentTypeET.Boolean;
	}

	//
	// IValueComponent
	//

	private _currentValue: boolean;


	get value(): any {
		return this._currentValue;
	}

	set value(val: any) {
		if (this.control && typeof (val) === 'boolean') {
			//this._currentValue = val;
			this.control.setValue(val);
		}
	}

	private _valueChangedSubject$: BehaviorSubject<boolean>;// = new Subject();

	private _valueChanged$: Observable<boolean>;

	get valueChanged(): Observable<boolean> {
		if (!this._valueChanged$) {
			this._valueChanged$ = this._valueChangedSubject$.asObservable();
		}
		return this._valueChanged$;
	}

	//----------------------------------------------------------------------------------------------
	// IBooleanComponent
	//----------------------------------------------------------------------------------------------

	get checked(): boolean {
		//return this.formGroup.get(this.propertyName).value;
		return this.control ? this.control.value : false;
	}
}
