import { InjectionToken, Injectable } from '@angular/core';
import { CustomPDObject } from '@otris/ng-core-shared';
import { Observable } from 'rxjs';

export const IPDObjectEditContextToken = new InjectionToken<IPDObjectEditContext>('IPDObjectEditContextToken');

//export const IPDObjectEditContextManagerToken = new InjectionToken<IPDObjectEditContextManager>('IPDObjectEditContextManagerToken');

@Injectable()
export abstract class IPDObjectEditContextManager {
	currentContext: IPDObjectEditContext;
	abstract switchContext(ctx: IPDObjectEditContext): void;
	abstract releaseCurrentContext(): void;
}

@Injectable()
export abstract class IPDObjectEditContext {
	contextObject: CustomPDObject;
	parentObject: CustomPDObject | undefined;
	isNewObject: boolean;
	abstract setContextObject(obj: CustomPDObject, isNew?: boolean, roleName?: string, parentId?: string): void;
	abstract getRelationObject(role: string): CustomPDObject | undefined;
	abstract release(): void;
	abstract notifyContextObjectSaved(): void;
	abstract notifyRelationObjectCreated(obj: CustomPDObject, roleName: string, parentId: string): void;
	abstract getRelationObjectCreated(role: string): Observable<[string, CustomPDObject, string]>;
}
