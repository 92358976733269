import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { UIAbstractComponent, UIRadioButtonGroupSpec, FormHandlerService } from '@otris/ng-core';
import { ComponentTypeET, PDObject, IRadioButtonComponent, IRadioButtonGroupComponent, IFormStatus, IEnumerationItem } from '@otris/ng-core-shared';
import { PDRadioButtonGroupService } from '../../services/pd-radio-button-group.service';
import { AbstractControl } from '@angular/forms';

@Component({
	selector: 'otris-pd-radio-button-group',
	template: `
		<label class="label k-form-field">
			<div *ngIf="isLabelVisible" class="label-container" [style.white-space]="wrapLabel ? 'normal' : 'nowrap'">
				<span>{{label}}</span>
				<otris-pd-short-description [highlighted]="mouseIsOver" *ngIf="isEnabled && hasShortDescription"
					[pdObject]="pdObject" [pdComponent]="this">
				</otris-pd-short-description>
				<otris-pd-error-indicator *ngIf="isEnabled && relatedFormControl && hasErrors"
					[relatedFormControl]="relatedFormControl" [propertyName]="property" [pdObject]="pdObject">
				</otris-pd-error-indicator>
				<!--otris-pd-mandatory-indicator *ngIf="mandatoryIndicatorVisible"></otris-pd-mandatory-indicator-->
			</div>
			<ng-container *ngIf="!isReadonly; else readonly">
				<div class="input-container" *ngIf="radioButtonGroupSpec.content">
					<otris-pd-panel #panel [uiItemSpec]="radioButtonGroupSpec.content" [formGroup]="formGroup" [pdObject]="pdObject" [relationContext]="relationContext"></otris-pd-panel>
				</div>
			</ng-container>
		</label>

		<ng-template #readonly>
			<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
		</ng-template>
	`,
	styles: [`
		.label {
			margin-top: 0;
		}
		.label-container {
			display: flex;

			margin-bottom: 0.5em;
			/*white-space: nowrap;*/
		}
		.input-container {
			margin: 0.5em;
		}
	`],
	providers: [
		PDRadioButtonGroupService
	]
})
// ToDo Klee: Warum ist das nicht von PDComponent abgeleitet?
// Antwort: Da die dazugehörige UIItemSpec eine UIContainerSpec sein muss, die wiederum nicht von PDItemSpec abgeleitet ist. Eine PDComponent braucht aber eine PDItemSpec!
export class PDRadioButtonGroupComponent extends UIAbstractComponent implements IRadioButtonGroupComponent {
	
	@Input()
	set pdObject(val: PDObject) {
		if (val && val != this._pdObject) {
			this.onPDObjectChanging();
			this._pdObject = val;
			this.onPDObjectChanged();
		}
	}

	get pdObject(): PDObject {
		return this._pdObject;
	}

	private _pdObject: PDObject;

	@ViewChild('panel') panelComponent: any;
	
	/*get isRequired(): boolean {
		return this._isMandatoryModel || this._mandatoryCustom;
	}*/

	//private _isMandatoryModel: boolean = false;

	isLabelVisible: boolean = true; // todo

	get radioButtonGroupSpec(): UIRadioButtonGroupSpec {
		if (!(this.uiItemSpec instanceof UIRadioButtonGroupSpec)) {
			throw Error('No UIRadioButtonGroupSpec assigned.');
		}
		return <UIRadioButtonGroupSpec>this.uiItemSpec;
	}

	get property(): string | undefined {
		let spec = this.radioButtonGroupSpec;
		return spec ? spec.property : undefined;
	}

	get label(): string {
		if (this.radioButtonGroupSpec.header) {
			return this.radioButtonGroupSpec.header;
		}
		
		//return (this.pdObject && this.radioButtonGroupSpec.property) ? this.pdObject.metaData.getPropertyErgname(this.radioButtonGroupSpec.property) : "n.a.";
		return this._label;
	}

	private _label: string;

	private _subscription: Subscription;

	get wrapLabel(): boolean {
		return this.radioButtonGroupSpec.wrapLabel;
	}

	get mandatoryIndicatorVisible(): boolean {
		return this.isRequired && this.isEnabled /*&& !this.isReadonly*/ && !this.getFormControlStatus().valid; // todo: readonly
	}

	get relatedFormControl(): AbstractControl | undefined {
		if (this.radioButtonGroupService.radioButtons.length > 0) {
			return this.radioButtonGroupService.radioButtons[0].control;
		}
		return undefined;
	}
	
	get hasShortDescription(): boolean {
		if (this.pdObject) {
			return this.pdObject.metaData.hasShortDescription(this.property);
		}
		return false;
	}

	private _shortDescription: string;

	get shortDescription(): string | undefined {
		return this._shortDescription;
	}

	mouseIsOver: boolean = false;

	@HostListener('mouseenter')
	onMouseEnter() {
		this.mouseIsOver = true;
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.mouseIsOver = false;
	}

	constructor(
		router: Router,
		route: ActivatedRoute, 
		formHandler: FormHandlerService, 
		private radioButtonGroupService: PDRadioButtonGroupService,

	) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();

		this._subscription = this.selectionChanged.subscribe(val => {
			this._selection = val;
			this.formHandler.updateMandatoryStatus();
		});
		this.radioButtonGroupService.groupName = this.id;
		this.onLanguageChanged();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		if (this.uiItemSpec.disabled) {
			this.disable(true);
		}
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		if (this._subscription) {
			this._subscription.unsubscribe();
		}
	}

	getFormControlStatus(): IFormStatus {
		let stat = <IFormStatus>{ pristine: true, touched: false, valid: true };
		if (this.panelComponent) {
			stat = (this.panelComponent as UIAbstractComponent).getFormControlStatus();
		}
		return stat;
	}

	protected isPDContainer(): boolean {
		return false;
	}

	protected onPDObjectChanging() {
	}

	protected onPDObjectChanged() {
		if (!this._pdObject) {
			return;
		}
		if (this.radioButtonGroupSpec.property) {
			let mandatory = this.pdObject.metaData.isMandatory(this.radioButtonGroupSpec.property);
			this._isMandatoryModel = mandatory !== undefined ? mandatory : false;
		}
		this.onLanguageChanged();
	}

	protected onLanguageChanged() {
		super.onLanguageChanged();
		if (this.radioButtonGroupSpec.property) {
			this._pdObject.metaData.getPropertyErgname(this.radioButtonGroupSpec.property).subscribe(res => this._label = res);
		}
		if (this.property) {
			this._pdObject.metaData.getShortDescription(this.property).subscribe(res => this._shortDescription = res);
		}
	}


	public getId(): string | undefined {
		let prop = this.property;
		if (prop) {
			return this.getIdFromPropertyName(prop);
		}
		return super.getId();
	}

	protected hasId(): boolean {
		if (this.property) {
			return true;
		}
		return super.hasId();
	}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.RadioButtonGroup;
	}

	get isEnabled(): boolean {
		return !this._disabled;
	}

	get isDisabled(): boolean {
		return this._disabled;
	}

	private _disabled: boolean = false;

	disable(flag: boolean): void {
		this._disabled = flag;
		if (this.panelComponent) {
			this.panelComponent.disable(flag);
		}
	}

	reset(): void {
		if (this.panelComponent) {
			this.panelComponent.reset();
		}
	}
	
	get mandatoryCustom(): boolean {
		return this._mandatoryCustom;
	}

	set mandatoryCustom(val: boolean) {
		if (this._mandatoryCustom != val) {
			this._mandatoryCustom = val;
		}
	}

	protected _mandatoryCustom: boolean = false;	

	get selection(): IRadioButtonComponent | undefined {
		return this._selection;
	}

	private _selection: IRadioButtonComponent | undefined;

	get selectionChanged(): Observable<IRadioButtonComponent> {
		return this.radioButtonGroupService.selectionChanged;
	}

	setSelection(sel: IRadioButtonComponent | undefined): void {
		this.radioButtonGroupService.setSelection(sel);
	}

	setSelectionFromValue(sel: IEnumerationItem | boolean | undefined): void {
		this.radioButtonGroupService.setSelectionFromValue(sel);
		/*let selRB: IRadioButtonComponent;
		if (sel != undefined) {
			selRB = this.radioButtonGroupService.radioButtons.find(rb => rb.value === sel);
			if (!selRB) {
				return;
			}
		}
		this.setSelection(selRB);*/
	}

	get isReadonly(): boolean {
		return this.selection?.isReadonly;
	}
}
