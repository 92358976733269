import { Inject, Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { CustomPDObject, IPDClass, IPDClassToken } from '@otris/ng-core-shared';
import { IPDAccessService, IPDAccessServiceToken } from '@otris/ng-core-shared';
import { IPDObjectEditContextManager, IPDObjectEditContext } from './i-pd-object-edit-context';
import { IPDObjectEditContextManagerToken } from './pd-object-edit-context-manager.service';
import { IUIFormSpec } from '../model/i-pd-layout';
import { IFormSpecProvider } from './i-form-spec-provider';

export enum PDObjectResolverModeET {
	EditRelationObject,
	CreateRelationObject
}

export interface IPDObjectResolverData {
	mode: PDObjectResolverModeET;
}

export interface IPDObjectResolveResult {
	pdObject: CustomPDObject;
	formSpec: IUIFormSpec;
	isNewObject: boolean;
	roleName: string;
	parentId: string;
}

@Injectable({ providedIn: 'root' })
export class PDObjectResolver implements Resolve<CustomPDObject | IPDObjectResolveResult> { // todo: nur noch IPDObjectResolveResult zurückgeben

	constructor(private router: Router, private route: ActivatedRoute,
		@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService,
		@Inject(IPDObjectEditContextManagerToken) private editContextManager: IPDObjectEditContextManager,
		private formSpecProvider: IFormSpecProvider,
		//private metaDataService: IMetaDataService,
		@Inject(IPDClassToken) private pdClass: IPDClass) {
		console.log('Resolver created.');
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomPDObject | IPDObjectResolveResult> {
		//let id = route.paramMap.get('id');
		//console.log(`PDObjectResolver: ${id}`);
		let resolveData = route.data['resolveData'] as IPDObjectResolverData;
		if (!resolveData) {
			throw new Error("No resolveData found!");
		}

		//let pdObjectEditContext: IPDObjectEditContext = this.editContextManager.currentContext;
		switch (resolveData.mode) {
			case PDObjectResolverModeET.EditRelationObject:
				{
					// todo: Fehlerbehandlung, falls
					let role = route.paramMap.get('role');
					if (role) {
						let relObj = this.editContextManager.currentContext.getRelationObject(role);
						if (relObj) {
							return of(relObj);
						}
					}
					return EMPTY;
				}
			case PDObjectResolverModeET.CreateRelationObject:
				{
					let role = route.paramMap.get('role');
					if (!role) {
						console.log('No Parameter role.');
						return EMPTY;
					}
					let relClass = route.paramMap.get('relationClass');
					if (!relClass) {
						console.log('No Parameter relClass.');
						return EMPTY;
					}
					// todo: Fehler falls parentId nicht vorhanden
					let parentId = route.paramMap.get('parentId');

					//let basisClass = this.editContextManager.currentContext.contextObject.getClassName();
					/*let relationClass = this.metaDataService.getRelationClass(basisClass, role);
					if (!relationClass) {
						console.log(`Relation class for role '${role}' not found.`);
						return EMPTY;
					}*/
					let relObj = this.pdClass.createInstance(relClass);
					let formSpec = this.formSpecProvider.getFormSpec(relClass);
					return of(<IPDObjectResolveResult>{ pdObject: relObj, formSpec: formSpec, isNewObject: true, roleName: role, parentId: parentId });
				}
		}
		return of(undefined);
	}

	/* ALT
	 * resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PDObject> {
		let id = route.paramMap.get('id');

		return this.pdAccessService.getObject(id).pipe(
			take(1),
			mergeMap(obj => {
				if (obj) {
					return of(obj);
				}
				this.router.navigate(['.']);
				return EMPTY;
			})
		);
	}*/
}
