import { Component, Input, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject, Subscription, of, BehaviorSubject, asyncScheduler, forkJoin, defer } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { GroupResult, groupBy } from '@progress/kendo-data-query';

import {
	PDLabeledControlComponent, ObjectReferenceWidgetInfo, FormHandlerService, IPDChoiceSpec,
	ObjectReferenceTypeET,
	IPopupManagerServiceToken,
	IPopupManagerService,
	IPDObjectEditContextToken,
	IPDObjectEditContext,
	IAsyncActionManagerService,
	IAsyncActionManagerServiceToken
} from '@otris/ng-core';
import {
	ComponentTypeET, CustomPDObject, PDObject, IPDObjectId, IPDAccessService, IPDAccessServiceToken, 
	IObjectReferenceComponent, ICustomChoiceProviderResult, IToolBarItemResult,
	IToolBarItemSpec, IToolBarButtonSpec, ToolBarItemTypeET, IInteractionService, IInteractionServiceToken, 
	ISelectObjectOptions, ISelectPDObjectResult, IPDListColumnInfo, ServiceLocator,
	ILocalizationServiceToken, ILocalizationService, SelectionChangingArgs
} from '@otris/ng-core-shared';

interface IChoiceData {
	ergName: string;
	value: PDObject;
	className?: string;
	classErgName?: string;
}

enum ToolBarButtonIdET {
	Select = 'idSelect',
	New = 'idNew'
}

@Component({
	selector: 'otris-pd-object-reference',
	template: `
			<ng-container *ngIf="active else inactive">

				<!-- <ng-container *ngIf="!isReadonly && canChange===true else readonly"> -->

					<ng-container [ngSwitch]="objectReferenceType">
						<ng-container *ngSwitchCase="objectReferenceTypeET.SelectionList">
							<otris-pd-labeled-control-frame [labeledControl]="this" (toolBarButtonClick)="onToolBarButtonClick($event)" [pdObject]="pdObject" [relatedFormControl]="this.control">
								<ng-container *ngIf="isChangeable; else readonly">
									<input class="k-textbox input-text" [readonly]="true" [value]="selectedValue?.ergName"/>
									<otris-tool-bar *ngIf="isEnabled" class="toolbar" (buttonClick)="onToolBarButtonClick($event)" [itemSpecs]="toolBarItems">
									</otris-tool-bar>
								</ng-container>
							</otris-pd-labeled-control-frame>
						</ng-container>

						<ng-container *ngSwitchDefault>
							<ng-container *ngIf="choiceData$ | async else loading">
								<otris-pd-labeled-control-frame [labeledControl]="this" (toolBarButtonClick)="onToolBarButtonClick($event)" [pdObject]="pdObject" [relatedFormControl]="this.control">
									<ng-container *ngIf="isChangeable; else readonly">
										<kendo-dropdownlist #kendoDropDownList class="drop-down-list" [data]="filteredChoiceData" [textField]="'ergName'" [valueField]="'value'"
											[filterable]="objectReferenceWidgetInfo?.filteringEnabled" (filterChange)="onFilterChange($event)" [(value)]="selectedValue" [disabled]="!isEnabled"
											[defaultItem]="noSelectionItem$ | async">
										</kendo-dropdownlist>
										<otris-tool-bar *ngIf="isEnabled && toolBarItems && toolBarItems.length > 0" class="toolbar" (buttonClick)="onToolBarButtonClick($event)" [itemSpecs]="toolBarItems">
										</otris-tool-bar>
									</ng-container>
								</otris-pd-labeled-control-frame>
							</ng-container>
							<ng-template #loading>
								<div>Loading ...</div> <!-- TODO: spinner -->
							</ng-template>
						</ng-container>
					</ng-container>
				<!-- </ng-container> Readonly -->

				<ng-template #readonly>
					<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
				</ng-template>

			</ng-container> <!-- Inactive -->

			<ng-template #inactive>
				<otris-pd-labeled-control-frame [labeledControl]="this" [pdObject]="pdObject" [relatedFormControl]="this.control">
					<input class="k-textbox input-text" [disabled]="true" [value]="selectedValue?.ergName"/>
				</otris-pd-labeled-control-frame>
			</ng-template>

	`,
	styles: [`
		.drop-down-list {
			flex: 1;
		}
		.input-text {
			flex: 1;
		}
		.toolbar {
			margin: auto 0;
		}
	`]
})
export class PDObjectReferenceComponent extends PDLabeledControlComponent implements IObjectReferenceComponent {

	@ViewChild('kendoDropDownList') dropDownList: DropDownListComponent;

	@Input() noSelectionItemText: string;

	noSelectionItem$: Observable<IChoiceData>;

	private _customChoiceSpecs: IPDChoiceSpec[];

	private get choiceSpecs(): IPDChoiceSpec[] {
		if (this._customChoiceSpecs) {
			return this._customChoiceSpecs;
		}
		if (!this.objectReferenceWidgetInfo || !this.objectReferenceWidgetInfo.choiceSpec) {
			return [];
		}
		if (!Array.isArray(this.objectReferenceWidgetInfo.choiceSpec)) {
			return [this.objectReferenceWidgetInfo.choiceSpec];
		}
		return this.objectReferenceWidgetInfo.choiceSpec;
	}

	choiceData$: Observable<IChoiceData[]>;

	choiceData: GroupResult[] | IChoiceData[] = [];

	filteredChoiceData: GroupResult[] | IChoiceData[];

	private _choiceFilter: string;

	private choiceDataMap: Map<string, IChoiceData> = new Map<string, IChoiceData>();

	private subscription: Subscription;

	get objectReferenceWidgetInfo(): ObjectReferenceWidgetInfo {
		if (this.pdItemSpec.widgetInfo instanceof ObjectReferenceWidgetInfo) {
			return <ObjectReferenceWidgetInfo>this.pdItemSpec.widgetInfo;
		}
		return undefined;
	}

	get selectedValue(): IChoiceData {
		return this._selectedValue;
	}

	set selectedValue(val: IChoiceData) {
		let ctrl = this.control;
		if (!ctrl) {
			return;
		}
		if (this._selectedValue !== val && (this._cancelSelectionPending || !this._setSelectionPending)) {
			this._setSelectionPending = true;
			let cancel$ = of(false);
			if (!this._cancelSelectionPending) {
				let args: SelectionChangingArgs = {
					oldSelection: this._selectedValue ? this._selectedValue.value : undefined,
					newSelection: val ? val.value : undefined
				};
				this._selectionChangingSubject$.next(args);
				if (args.cancel$) {
					cancel$ = args.cancel$;
				}
			}
			cancel$.subscribe(res => {
				// cancel
				if (res) {
					let oldVal = this._selectedValue;
					this._selectedValue = val;
					this._cancelSelectionPending = true;
					ctrl.setValue(val ? val.value : undefined);

					asyncScheduler.schedule(() => {
						this._selectedValue = oldVal;
						ctrl.setValue(oldVal);
						ctrl.markAsDirty();
						this.cdref.detectChanges();
						this._cancelSelectionPending = false;
					});
					this._setSelectionPending = false;
					return;
				}
				this._selectedValue = val;
				if (!!this._selectedValue){
					if (!(this._selectedValue.value instanceof CustomPDObject)) {
						this.removeCustomObjectFromChoice();
					}
				}
				ctrl.markAsDirty();
				ctrl.setValue(val ? val.value : undefined);
				if (!this._cancelSelectionPending) {
					this._selectionChangedSubject$.next(this._selectedValue ? this._selectedValue.value : null);
				}
				this._setSelectionPending = false;
			});
		}
	}

	private _selectedValue: IChoiceData;

	private _cancelSelectionPending = false;

	private _setSelectionPending = false;

	objectReferenceTypeET = ObjectReferenceTypeET;

	toolBarItems: IToolBarItemSpec[];

	get objectReferenceType(): ObjectReferenceTypeET {
		let wi = this.objectReferenceWidgetInfo;
		return wi ? wi.type : ObjectReferenceTypeET.DropDownList;
	}

	private _choiceUpdatedSubject$: Subject<void> = new Subject();

	private _choiceUpdated$: Observable<void>;

	get choiceUpdated(): Observable<void> {
		if (!this._choiceUpdated$) {
			this._choiceUpdated$ = this._choiceUpdatedSubject$.asObservable();
		}
		return this._choiceUpdated$;
	}

	private _newObject: IChoiceData;

	private _choiceDataAction$: Observable<IChoiceData[]>;

	canChange: boolean;

	get isChangeable(): boolean {
		return this.isReadonly !== true && this.canChange !== false;
	}

	private get newObjectPossible(): boolean {
		let wi = this.objectReferenceWidgetInfo;
		return wi && wi.newAction && wi.editingSpec && !Array.isArray(wi.editingSpec.className);
	}

	constructor(router: Router, route: ActivatedRoute,
		@Inject(IPDAccessServiceToken) private pdAccessService: IPDAccessService,
		@Inject(ILocalizationServiceToken) private localizationService: ILocalizationService,
		@Inject(IInteractionServiceToken) private interactionService: IInteractionService,
		formHandler: FormHandlerService,
		@Inject(IPopupManagerServiceToken) private popupManagerService: IPopupManagerService,
		@Inject(IPDObjectEditContextToken) private pdObjectEditContext: IPDObjectEditContext,
		@Inject(IAsyncActionManagerServiceToken) private asyncActionManagerService: IAsyncActionManagerService,
		private cdref: ChangeDetectorRef) {
			super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();
		if (this.objectReferenceWidgetInfo) {
			this.active = this.objectReferenceWidgetInfo.active;

			if (this.objectReferenceWidgetInfo.noSelectionItemText) {
				this.noSelectionItemText = this.objectReferenceWidgetInfo.noSelectionItemText;
			}
		}

		switch (this.objectReferenceType) {
			case ObjectReferenceTypeET.SelectionList:
				{
					this.toolBarItems = [
						<IToolBarButtonSpec>{
							id: ToolBarButtonIdET.Select, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-list',
							shortDescriptionId: 'system.kendo-ui.components.pd-object-reference.tool-bar-button-select'
						}
					];
					let wi = this.objectReferenceWidgetInfo;
					if (wi) {
						if (wi.newAction) {
							this.toolBarItems.push(
								<IToolBarButtonSpec>{
									id: ToolBarButtonIdET.New, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-file-o',
									shortDescriptionId: 'system.kendo-ui.components.pd-object-reference.tool-bar-button-new'
								}
							);
						}
					}				
					break;
				}

			case ObjectReferenceTypeET.DropDownList:
				{
					let wi = this.objectReferenceWidgetInfo;
					if (wi) {
						this.toolBarItems = [];
						if (wi.newAction) {
							this.toolBarItems.push(
								<IToolBarButtonSpec>{
									id: ToolBarButtonIdET.New, type: ToolBarItemTypeET.Button, iconClass: 'fa fa-lg fa-file-o',
									shortDescriptionId: 'system.kendo-ui.components.pd-object-reference.tool-bar-button-new'
								}
							);
						}
					}
					break;
				}
		}

		this.updateChoice();

		/*this.subscription = this.formGroup.valueChanges.subscribe(v => {
			if (v.hasOwnProperty(this.propertyName) && !v[this.propertyName]) {
				this.selectedValue = undefined;
			}
		});*/

		this.subscription = this.control.valueChanges.subscribe(v => {
			if (!v) {
				this.selectedValue = undefined;
			}
		});

		this.localizationService.changeHandler.subscribe(lang => {
			this.updateNoSelectionItem();
			this.updateChoice();
			this.updateSelectedValueErgName();
		});
		this.updateNoSelectionItem();

		if (this.newObjectPossible) {
			this.subscription.add(this.pdObjectEditContext.getRelationObjectCreated(this.propertyName).subscribe(
				data => {
					if (data[2] == this.pdObject.objectId.oid) {
						this.addNewCustomObject(data[1]);
					}
				}
			));
			}
		this.applyAccessRights();
		this.updateToolbar();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		if (this._choiceDataAction$) {
			this.asyncActionManagerService.notifyActionFinished(this._choiceDataAction$);
		}

		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		super.ngOnDestroy();
	}

	onFilterChange(value: string) {
		this._choiceFilter = !value ? undefined : value;
		this.updateFilteredChoiceData();
	}

	onToolBarButtonClick(item: IToolBarItemResult) {
		switch (item.id) {
			case ToolBarButtonIdET.Select:
				this.selectObject();
				break;

			case ToolBarButtonIdET.New:
				this.createNewObject();
				break;

			default:
				super.onToolBarButtonClick(item);
				break;
		}
	}

	protected onReadonlyChanged(): void {
	}

	protected onMetaDataChanged(): void {
		super.onMetaDataChanged();
		//this.applyAccessRights();
		this.updateToolbar();
	}

	protected onMandatoryCustomChanged() {
		this.updateNoSelectionItem();
	}

	private addNewCustomObject(obj: CustomPDObject) {
		if (!this.newObjectPossible || !obj.isNew || this.choiceSpecs.length > 1) {
			return;
		}

		let wi = this.objectReferenceWidgetInfo;
		/*if (!wi || !wi.newAction || !wi.editingSpec || Array.isArray(wi.editingSpec.className)) {
			return;
		}*/
		let editingSpec = wi.editingSpec;

		let newData: IChoiceData = {
			ergName: obj.getStatus(editingSpec.objectInfo), value: obj, className: <string>editingSpec.className
		};

		switch (this.objectReferenceType) {
			case ObjectReferenceTypeET.DropDownList: {
				(this.choiceData as IChoiceData[]).unshift(newData);
				let datas: any[] = this.choiceData as IChoiceData[];
				if (this._newObject) {
					if (this.choiceDataMap.has(this._newObject.value.objectId.oid)) {
						this.choiceDataMap.delete(this._newObject.value.objectId.oid);
					}
					let pos = datas.indexOf(this._newObject);
					if (pos >= 0) {
						datas.splice(pos, 1);
					}
				}
				this.choiceDataMap.set(obj.objectId.oid, newData);
				this.updateFilteredChoiceData();
				this.selection = obj;
				this._newObject = newData;
				this._choiceUpdatedSubject$.next();
				break;
			}

			case ObjectReferenceTypeET.SelectionList: {
				this.selection = obj;
				this._newObject = newData;
				break;
			}
		}
	}

	private removeCustomObjectFromChoice() {
		if (!this._newObject || this.objectReferenceType !== ObjectReferenceTypeET.DropDownList) {
			return;
		}

		let specs = this.choiceSpecs;
		let choiceDatas: any[];
		if (specs.length == 1) {
			choiceDatas = this.choiceData as IChoiceData[];
		}
		else if (specs.length > 1) {
			let obj = this._newObject.value as CustomPDObject;
			let spec = specs.find(s => s.className == obj.getClassName());
			let group = (this.choiceData as GroupResult[]).find(g => g.field == obj.getClassName());
			if (group && spec) {
				choiceDatas = group.items;
			}
		}

		if (choiceDatas) {
			if (this.choiceDataMap.has(this._newObject.value.objectId.oid)) {
				this.choiceDataMap.delete(this._newObject.value.objectId.oid);
			}
			let pos = choiceDatas.indexOf(this._newObject);
			if (pos >= 0) {
				choiceDatas.splice(pos, 1);
			}
			this._newObject = undefined;
		}
	}

	protected onPDObjectChanged() {
		super.onPDObjectChanged();

		if (this.pdObject && this.propertyName) {
			if (this.pdObject[this.propertyName] instanceof CustomPDObject) {
				let custObj = this.pdObject[this.propertyName] as CustomPDObject;
				if (custObj.isNew) {
					this.addNewCustomObject(custObj);
				}
			}
		}
		this.updateSelectedValue();
	}

	private updateFilteredChoiceData() {
		if (this.choiceData && this._choiceFilter && this.choiceSpecs.length == 1) {
			let filter = this._choiceFilter.toLowerCase();
			this.filteredChoiceData = (<IChoiceData[]>this.choiceData).filter(data => data.ergName.toLowerCase().indexOf(filter) !== -1);
		}
		else {
			this.filteredChoiceData = this.choiceData;
		}
	}

	private updateNoSelectionItem() {
		this.noSelectionItem$ = this.isRequired ? of(undefined) :
			(this.noSelectionItemText ? of(<IChoiceData>{ ergName: this.noSelectionItemText, value: undefined }) :
				this.localizationService.getSystemString('kendo-ui.components.pd-object-reference.no-selection-item-text').pipe(
					map(str => <IChoiceData>{ ergName: str, value: undefined }))
				)
	}

	private updateSelectedValue() {
		let ctrl = this.formGroup.controls[this.propertyName];
		if (ctrl) {
			let sel = ctrl.value instanceof PDObject ? <PDObject>ctrl.value : undefined;
			if (sel) {
				switch (this.objectReferenceType) {
					case ObjectReferenceTypeET.DropDownList:
						if (this.choiceDataMap.has(sel.objectId.oid)) {
							this.selectedValue = this.choiceDataMap.get(sel.objectId.oid);
							return;
						}
						break;

					case ObjectReferenceTypeET.SelectionList:
						if (sel instanceof CustomPDObject) {
							if (this.newObjectPossible) {
								let wi = this.objectReferenceWidgetInfo;
								this.selectedValue = {
									className: <string>wi.editingSpec.className,
									ergName: sel.getStatus(wi.editingSpec.objectInfo),
									value: sel
								}
								return;
							}
						}
						else {
							if (this.choiceSpecs.length == 1) {
								this.selectedValue = <IChoiceData>{
									className: this.choiceSpecs[0].className, ergName: sel.getStatus(this.getRelationInfo(this.choiceSpecs[0])), value: sel
								}
								return;
							}
						}
						break;
				}
			}
		}
		this.selectedValue = undefined;
	}

	private getChoiceFilterExpression(spec: IPDChoiceSpec): string | undefined {
		/*if (this.choiceSpecs.length == 1) {
			   if (this._customChoiceFilterProvider && typeof (this._customChoiceFilterProvider) == 'string') {
					  return this._customChoiceFilterProvider;
			   }
		}
		else if (this._customChoiceFilterProvider instanceof Map && this._customChoiceFilterProvider.has(spec.className)) {
			   return this._customChoiceFilterProvider.get(spec.className);
		}*/
		if (this._customChoiceFilterProvider) {
			let res = this._customChoiceFilterProvider();
			if (this.choiceSpecs.length == 1) {
				if (typeof (res) === 'string') {
					return res;
				}
			}
			else if (res instanceof Map && res.has(spec.className)) {
				return res.get(spec.className);
			}
		}

		return spec.filterExpr;
	}

	private updateSelectedValueErgName(): void {
		if (this.objectReferenceType !== ObjectReferenceTypeET.SelectionList || !this.selectedValue || this.choiceSpecs.length != 1) {
			return;
		}
		
		if (this.selectedValue.value instanceof CustomPDObject) {
			if (this.newObjectPossible) {
				let wi = this.objectReferenceWidgetInfo;
				this.selectedValue.ergName = this.selectedValue.value.getStatus(wi.editingSpec.objectInfo);
			}
		}
		else {
			this.selectedValue.ergName = this.selectedValue.value.getStatus(this.getRelationInfo(this.choiceSpecs[0]));
		}
	}

	updateChoice() {
		if (this.objectReferenceType !== ObjectReferenceTypeET.DropDownList) {
			return;
		}

		if (this.active) {
			let choiceClassNames: string[];
			this._customChoiceSpecs = undefined;
			let data$: Observable<[string, string, PDObject[]][]>;
			if (this._customChoiceProvider) {
				let res = this._customChoiceProvider();
				choiceClassNames = res.map(res => res.className);
				this._customChoiceSpecs = res.map(r => <IPDChoiceSpec>{ className: r.className, relationInfo: r.objectInfo });
				let extents = res.map(r => r.data.pipe(map(d => <[string, string, PDObject[]]>[r.className, r.objectInfo, d])));
				data$ = forkJoin(extents);
			}
			else if (this.choiceSpecs.length > 0) {
				choiceClassNames = this.choiceSpecs.map(s => s.className);
				let getExtentCalls: Observable<[string, string, PDObject[]]>[] = [];
				for (let spec of this.choiceSpecs) {
					let filterExpr = this.getChoiceFilterExpression(spec);
					let call: Observable<[string, string, PDObject[]]> = this.pdAccessService.getExtent(spec.className, filterExpr, spec.sortExpr, spec.properties).pipe(
						map(res => 
							<[string, string, PDObject[]]>[spec.className, this.getRelationInfo(spec), res]
						)
					);
					getExtentCalls.push(call);
				}
				data$ = forkJoin(getExtentCalls);
			}

			if (data$) {
				let getClassErgNames$ = choiceClassNames.map(cls => this.metaDataService.getClassErgName(cls).pipe(map(erg => <[string, string]>[cls, erg])));
				let action$ = forkJoin(getClassErgNames$).pipe(
					switchMap(ergNames => {
						let classErgNames = ergNames.reduce(
							(map, val) => {
								map.set(val[0], val[1]);
								return map;
							},
							new Map<string, string>()
						);
						return data$.pipe(
							map(results => {
								let result: IChoiceData[] = [];
								this.choiceDataMap.clear();
								for (let res of results) {
									for (let obj of res[2]) {
										let classErgName = classErgNames.has(res[0]) ? classErgNames.get(res[0]) : res[0];
										let data = <IChoiceData>{ ergName: obj.getStatus(res[1]), value: obj, className: res[0], classErgName: classErgName };
										result.push(data);
										this.choiceDataMap.set(obj.objectId.oid, data);
									}
								}
								return result;
							}),
							tap(res => {
								if (this._newObject) {
									this.choiceDataMap.set(this._newObject.value.objectId.oid, this._newObject);
									res.unshift(this._newObject);
									let ctrl = this.control;
									if (ctrl) {
										ctrl.setValue(this._newObject.value);
									}
								}
								let fGroup = res.length > 1 && res.findIndex(item => item.className !== res[0].className) != -1;
								this.choiceData = fGroup ? groupBy(res, [{ field: "classErgName" }]) : res;
								this.updateFilteredChoiceData();
								this.updateSelectedValue();
								this._choiceUpdatedSubject$.next();
							})//,
								//() => { this.asyncActionManagerService.notifyActionFinished(action$); },
								//() => { this.asyncActionManagerService.notifyActionFinished(action$); }
						)
					})
				);
				//this.asyncActionManagerService.notifyActionStarted(action);
				//this.choiceData$ = action;
				this.choiceData$ = defer(
					() => {
						//this.asyncActionManagerService.notifyActionStarted(action$);
						//return action$;
						if (this._choiceDataAction$) {
							this.asyncActionManagerService.notifyActionFinished(this._choiceDataAction$);
						}
						this.asyncActionManagerService.notifyActionStarted(action$);
						this._choiceDataAction$ = action$;
						return action$;

				}).pipe(
					tap(
						() => { },
						() => { this.asyncActionManagerService.notifyActionFinished(action$); this._choiceDataAction$ = undefined; },
						() => { this.asyncActionManagerService.notifyActionFinished(action$); this._choiceDataAction$ = undefined; }
					)
				);
				return;
			}
		}
		this.choiceData$ = undefined;
		this.choiceData = [];
		this.choiceDataMap.clear();
		this.updateFilteredChoiceData();
		this.updateSelectedValue();
	}

	private selectObject() {
		if (this.choiceSpecs.length != 1) {
			return;
		}

		let className = this.choiceSpecs[0].className;
		let listSpec = (this.objectReferenceWidgetInfo && this.objectReferenceWidgetInfo.selectionListSpec) ? this.objectReferenceWidgetInfo.selectionListSpec : undefined;
		let options: ISelectObjectOptions = {
			width: listSpec && listSpec.width ? listSpec.width : '60vw',
			height: listSpec && listSpec.width ? listSpec.width : '90vh',
			//columnInfos: listSpec && listSpec.columns ? listSpec.columns : undefined,
			sortExpr: this.choiceSpecs[0].sortExpr
		}

		if (this._selectionListColumnProvider) {
			options.columnInfos = this._selectionListColumnProvider();
		}
		else if (listSpec && listSpec.columns) {
			options.columnInfos = listSpec.columns;
		}

		if (this._customChoiceFilterProvider) {
			let filter = this._customChoiceFilterProvider();
			if (typeof (filter) === 'string') {
				options.filterExpr = filter;
			}
		}
		else if (this.choiceSpecs[0].filterExpr) {
			options.filterExpr = this.choiceSpecs[0].filterExpr;
		}

		let evalResult: (res: ISelectPDObjectResult) => void =
			(res) => {
				if (res.selection) {
					this.selectedValue = <IChoiceData>{
						className: className, ergName: res.selection.getStatus(this.getRelationInfo(this.choiceSpecs[0])), value: res.selection
					}
				}
			};

		let showSelectDialog: (title: string) => Observable<ISelectPDObjectResult> =
			(title) => {
				if (this._customChoiceProvider) {
					let res = this._customChoiceProvider();
					if (res && res.length == 1) {
						options.choiceObjects = <Observable<PDObject[]>>res[0].data;
						return this.interactionService.selectPDObject(className, title, options).pipe(
							tap(res => evalResult(res)) // todo: mit evalResult zusammenfassen
						);
						/*return res[0].data.switchMap(objects => {
							options.choiceObjects = objects;
							return this.interactionService.selectPDObject(className, title, options).do(res => evalResult(res)); // todo: mit evalResult zusammenfassen
						});*/
					}
					return of(<ISelectPDObjectResult>{ selection: undefined })
				}
				else if (listSpec && listSpec.pdObjectProvider) {
					options.choiceObjects = listSpec.pdObjectProvider(this.pdAccessService);
					return this.interactionService.selectPDObject(className, title, options).pipe(
						tap(res => evalResult(res))  // todo: mit evalResult zusammenfassen
					);
					/*return listSpec.pdObjectProvider(this.pdAccessService)
						.switchMap(
							objects => {
								options.choiceObjects = objects;
								return this.interactionService.selectPDObject(className, title, options).do(res => evalResult(res));  // todo: mit evalResult zusammenfassen
							}
						);*/
				}
				else {
					return this.interactionService.selectPDObject(className, title, options).pipe(
						tap(res => evalResult(res))  // todo: mit evalResult zusammenfassen
					);
				}
			};

		if (listSpec) {
			if (listSpec.title) {
				showSelectDialog(listSpec.title).subscribe();
				return;
			}
			else if (listSpec.titleId) {
				this.localizationService.getString(listSpec.titleId, { value: className }).pipe(
					switchMap(title => showSelectDialog(title))
				).subscribe();
				return;
			}
		}

		//let pdMeta: PDMetaAbstract = ServiceLocator.injector.get(PDMetaAbstract);
		this.metaDataService.getClassErgName(className).pipe(
			switchMap(erg => {
				return this.localizationService.getSystemString('kendo-ui.components.pd-object-reference.selection-list-title', { value: erg }).pipe(
					switchMap(title => showSelectDialog(title))
				);
			})
		).subscribe();
	}

	private createNewObject(): void {
		let wi = this.objectReferenceWidgetInfo;
		if (!wi || !wi.newAction || !wi.editingSpec) {
			return;
		}
		let editingSpec = wi.editingSpec;
		let clsName = wi.editingSpec.className
		let url = [];
		url.push(...editingSpec.url);
		url.push(this.propertyName);
		url.push(clsName);
		url.push(this.pdObject.objectId.oid);
		this.popupManagerService.showPopup(editingSpec.outletName, url, this.route.parent, 'Popup_' + this.propertyName);
	}

	private getRelationInfo(choiceSpec: IPDChoiceSpec) {
		return choiceSpec.relationInfoProvider ? choiceSpec.relationInfoProvider(this.localizationService) :
			(choiceSpec.relationInfo ? choiceSpec.relationInfo : 'n.a.');
	}

	protected applyAccessRights(): void {
		super.applyAccessRights();
		let relAccessRights = this.pdObject.metaData.getRelationMeta(this.propertyName)?.accessRights;
		this.canChange = (relAccessRights && relAccessRights.change !== undefined) ? relAccessRights.change : undefined;
	}

	private updateToolbar(): void {
		if (this.objectReferenceType != ObjectReferenceTypeET.SelectionList || !this.toolBarItems) {
			return;
		}

		let tbItemSelect = this.toolBarItems.find(item => item.id == ToolBarButtonIdET.Select);
		tbItemSelect.disabled = this.canChange === false;
	}

	//
	// IComponent overrides
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.ObjectReference;
	}

	disable(flag: boolean): void {
		if (!this.active && !flag) {
			console.debug(`An inactive component can't be enabled (id: '${this.id}')`);
			return;
		}
		super.disable(flag);
	}

	//
	// IObjectReferenceComponent
	//

	get active(): boolean {
		return this._active;
	}

	set active(val: boolean) {
		if (val != this._active) {
			this._active = val;
			this.disable(!val);
			this.updateChoice();
		}
	}

	private _active: boolean = true;

	get selection(): PDObject | undefined {
		return this._selectedValue ? this._selectedValue.value : undefined;
	}

	set selection(obj: PDObject | undefined) {
		if (!obj || !obj.objectId) {
			this.selectedValue = undefined;
			return;
		}

		switch (this.objectReferenceType) {
			case ObjectReferenceTypeET.DropDownList:
				{
					if (this.choiceDataMap.has(obj.objectId.oid)) {
						this.selectedValue = this.choiceDataMap.get(obj.objectId.oid);
					}
					break;
				}

			case ObjectReferenceTypeET.SelectionList:
				if (obj instanceof CustomPDObject) {
					if (this.newObjectPossible) {
						let wi = this.objectReferenceWidgetInfo;
						this.selectedValue = {
							className: <string>wi.editingSpec.className,
							ergName: obj.getStatus(wi.editingSpec.objectInfo),
							value: obj
						}
					}
				}
				else {
					if (this.choiceSpecs.length == 1) {
						this.selectedValue = <IChoiceData>{
							className: this.choiceSpecs[0].className,
							ergName: obj.getStatus(this.getRelationInfo(this.choiceSpecs[0])),
							value: obj
						}
					}
				}
				break;
		}
	}

	private _selectionChangedSubject$: BehaviorSubject<PDObject | undefined | null> = new BehaviorSubject(undefined);

	private _selectionChanged$: Observable<PDObject | undefined | null>;

	get selectionChanged(): Observable<PDObject | undefined | null> {
		if (!this._selectionChanged$) {
			this._selectionChanged$ = this._selectionChangedSubject$.asObservable();
		}
		return this._selectionChanged$;
	}

	private _selectionChangingSubject$: Subject<SelectionChangingArgs> = new Subject();

	private _selectionChanging$: Observable<SelectionChangingArgs>;

	get selectionChanging$(): Observable<SelectionChangingArgs> {
		if (!this._selectionChanging$) {
			this._selectionChanging$ = this._selectionChangingSubject$.asObservable();
		}
		return this._selectionChanging$;
	}

	set customChoiceProvider(cb: () => ICustomChoiceProviderResult[]) {
		this._customChoiceProvider = cb;
		this.updateChoice();
	}

	private _customChoiceProvider: () => ICustomChoiceProviderResult[];

	set customChoiceFilterProvider(cb: () => string | Map<string, string>) {
		this._customChoiceFilterProvider = cb;
		this.updateChoice();
	}

	private _customChoiceFilterProvider: () => string | Map<string, string>;

	set selectionListColumnProvider(cb: () => IPDListColumnInfo[]) {
		this._selectionListColumnProvider = cb;
	}

	private _selectionListColumnProvider: () => IPDListColumnInfo[];

	get choiceObjects(): PDObject[] {
		if (this.objectReferenceType !== ObjectReferenceTypeET.DropDownList || this.choiceDataMap.size == 0) {
			return [];
		}
		return Array.from(this.choiceDataMap.values()).map(item => item.value);
	}
}
