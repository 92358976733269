import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { PDLabeledControlComponent } from '../../../otris-ng-core/components/pd-labeled-control/pd-labeled-control.component';
import { PDLabeledControlComponent, FormHandlerService, NumericTextFieldWidgetInfo } from '@otris/ng-core';

//import { FormHandlerService } from '../../../otris-ng-core-shared/services/form-handler.service';
//import { ComponentTypeET } from '../../../otris-ng-core-shared/model/types';
import { ComponentTypeET, INumericTextFieldComponent } from '@otris/ng-core-shared';

//[min]="0" [max]="100" [autoCorrect]="autoCorrect"
@Component({
	selector: 'otris-pd-numeric-text-field',
	template: `
		<otris-pd-labeled-control-frame [labeledControl]="this" (toolBarButtonClick)="onToolBarButtonClick($event)" [pdObject]="pdObject" [relatedFormControl]="this.control">
			<ng-container *ngIf="!isReadonly; else readonly">
				<kendo-numerictextbox class="numeric-text-box" [formControl]="formGroup.controls[propertyName]"
					[step]="step" [min]="min" [max]="max" [decimals]="decimals" [format]="format"
					[spinners]="showSpinners" autoCorrect="true" [readonly]="isReadonly">
				</kendo-numerictextbox>
			</ng-container>
		</otris-pd-labeled-control-frame>

		<ng-template #readonly>
			<ng-container *ngTemplateOutlet="readonlyTemplate;context:readonlyTemplateContext"></ng-container>
		</ng-template>
	`,
	styles: [`
		:host{
			flex: 1 1 auto;
		}
		/*
		.input-container {
			margin-top: 0.5em;
			display: flex;
		}*/
		.numeric-text-box {
			flex: 1 1 auto;
		}
	`]
})
export class PDNumericTextFieldComponent extends PDLabeledControlComponent implements INumericTextFieldComponent {

	get numericTextFieldWidgetInfo(): NumericTextFieldWidgetInfo | undefined {
		let wi = this.labeledControlWidgetInfo;
		if (wi instanceof NumericTextFieldWidgetInfo) {
			return <NumericTextFieldWidgetInfo>wi;
		}
		return undefined;
	}

	get showSpinners(): boolean {
		let wi = this.numericTextFieldWidgetInfo;
		return !wi || !(wi.showSpinners !== true)
	}

	get min(): number {
		let wi = this.numericTextFieldWidgetInfo;
		return (wi && wi.min) ? wi.min : 0;
	}

	get max(): number {
		let wi = this.numericTextFieldWidgetInfo;
		return (wi && wi.max) ? wi.max : 1;
	}

	get step(): number {
		let wi = this.numericTextFieldWidgetInfo;
		return (wi && wi.step) ? wi.step : 0.1;
	}
	
	get format(): string {
		let wi = this.numericTextFieldWidgetInfo;
		return (wi && wi.format) ? wi.format : 'n2';
	}

	get decimals(): number {
		let wi = this.numericTextFieldWidgetInfo;
		return (wi && wi.decimals) ? wi.decimals : 2;
	}

	constructor(router: Router, route: ActivatedRoute, formHandler: FormHandlerService) {
		super(router, route, formHandler);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	protected onReadonlyChanged(): void {}

	//
	// Interface IComponent
	//

	get componentType(): ComponentTypeET {
		return ComponentTypeET.NumericText;
	}

	//
	// Interface ITextFieldComponent
	//

	get value(): number {
		return this.control.value;
	}

	set value(val: number) {
		this.control.setValue(val);
	}
}
